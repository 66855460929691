import React from "react";

export default function IconAmericana(props) {
	return (
		<svg aria-hidden="true" viewBox="0 0 1024 650" {...props}>
			<path d="M571.2 768c-6.4 1.6-12.8 3.2-19.2 3.2 70.4 44.8 115.2 89.6 115.2 89.6s6.4 43.2 44.8 54.4c46.4 14.4 70.4-14.4 70.4-14.4l-67.2-59.2-68.8-81.6h-3.2c-27.2 0-52.8 3.2-72 8z"></path>
			<path d="M793.6 420.8h24c46.4 0 49.6 28.8 89.6 28.8 43.2 0 56-52.8 36.8-68.8-19.2-16-184-145.6-184-145.6l-52.8-60.8c-16 38.4 0 62.4 0 62.4-28.8 0-78.4 19.2-139.2 56-24 14.4-52.8 32-81.6 49.6 8 6.4 14.4 12.8 20.8 17.6 24 22.4 28.8 44.8 30.4 59.2 0 19.2-8 38.4-22.4 52.8-14.4 14.4-33.6 22.4-54.4 22.4-17.6 0-35.2-6.4-49.6-17.6-17.6 20.8-49.6 54.4-64 68.8-19.2 19.2-40 28.8-60.8 28.8-16 0-32-6.4-46.4-16-17.6-12.8-27.2-32-28.8-52.8-1.6-20.8 6.4-43.2 22.4-62.4 1.6-3.2 4.8-6.4 9.6-9.6-6.4-1.6-14.4-4.8-20.8-6.4-27.2-8-51.2-16-72-32-24-3.2-51.2-6.4-78.4-8v283.2c1.6 1.6 4.8 3.2 8 3.2 48 19.2 97.6 25.6 140.8 25.6 78.4 0 139.2-20.8 139.2-20.8 25.6 28.8 68.8 44.8 113.6 48 8 0 14.4 1.6 22.4 1.6 22.4 0 43.2-3.2 64-8 16-3.2 32-6.4 48-6.4 12.8-1.6 25.6-1.6 35.2-1.6 28.8 0 49.6 1.6 49.6 1.6l105.6 68.8s12.8 40 52.8 44.8h9.6c33.6 0 48-27.2 48-27.2s-78.4-65.6-128-105.6c-38.4-32-51.2-41.6-105.6-62.4-48-17.6-56-19.2-102.4-35.2 9.6-27.2 0-59.2 0-59.2L699.2 384c0 1.6 30.4 36.8 94.4 36.8z"></path>
			<path d="M390.4 184c38.4 0 68.8 30.4 59.2 73.6-11.2 52.8-20.8 97.6-20.8 97.6s30.4 28.8 44.8 41.6c19.2 16 16 32 6.4 43.2-6.4 6.4-12.8 9.6-20.8 9.6s-16-3.2-25.6-11.2c-9.6-9.6-43.2-40-57.6-54.4l-3.2-3.2-4.8-4.8 16-76.8-99.2 25.6s65.6 35.2 88 54.4l3.2 3.2c19.2 19.2 20.8 38.4 1.6 60.8-17.6 19.2-51.2 56-65.6 70.4-9.6 9.6-17.6 14.4-27.2 14.4-4.8 0-11.2-1.6-16-6.4-14.4-11.2-14.4-28.8 0-44.8 12.8-14.4 52.8-59.2 52.8-59.2-64-38.4-131.2-38.4-155.2-70.4-16-24-11.2-73.6 38.4-96 36.8-16 158.4-62.4 158.4-62.4 8-3.2 17.6-4.8 27.2-4.8m187.2-1.6c-8 33.6-40 54.4-73.6 48-33.6-8-54.4-40-48-73.6 8-33.6 40-54.4 73.6-48 33.6 8 54.4 40 48 73.6"></path>
		</svg>
	);
}
