import React from "react";

import CloudWinwdowsModal from "../../../../compenent/modals/";
import FromTopes from "../topesFromGeneral";

const ModalCloudGeneral = ({
	stateModalWindow,
	stateSeleccion,
	onSummitStateWindows,
	stateTopesDeportes,
	stateTopesAmericana,
	stateNacionales,
	stateTopesLoteria,
}) => {
	return (
		<CloudWinwdowsModal
			children={
				<FromTopes
					dataDepo={stateTopesDeportes}
					dataAme={stateTopesAmericana}
					dataNaci={stateNacionales}
					dataLote={stateTopesLoteria}
					recordSelect={stateSeleccion}
					sumitModalControl={onSummitStateWindows}
				/>
			}
			modalState={stateModalWindow}
			onCancel={() => onSummitStateWindows(false)}
			classNm={{ background: "rgb(244, 244, 244)" }}
			classFrm={"from-modal-tope"}
			title="Detalle de Topes GENERALES"
		/>
	);
};
export default ModalCloudGeneral;
