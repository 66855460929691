import React, { Component } from 'react';
import {Row,Col,Icon,List,Tree,Avatar} from "antd";
import   './cloudwindows.scss'; 
var _ =  require('lodash');
const { TreeNode } = Tree;
class cloudwindows extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id_select:-1
        }
    }
    listMainP=(listData,onChange,tipodeList)=>{
        var {id_select}=this.state
        return (
            tipodeList===1 ?
            <List
            itemLayout="vertical"
            size="small"
            locale={{ emptyText:<label style={{color:'#fff'}}> <Icon type="delete" style={{fontSize: '22px',color:'#fff'}}/>No hay Informacion</label> }} 
            dataSource={listData}
            renderItem={(item,index) => (
            <List.Item
                className={id_select===index?"listStyItemWdo":''}
                key={item.title}
                onClick={()=>{
                    this.setState({id_select:index})
                    onChange(item.id,item.title,index)
                }}
                >
                <List.Item.Meta
            title={<label className={`mainLabletitle ${item.existe?(item.existe?'texttackslabel':'' ):'' }`}>{!_.isUndefined(item.activa)&&(item.activa===1?<Icon type="check-circle" style={{ fontSize: '16px', color: 'whitesmoke' }} />:<Icon type="close-circle" style={{ fontSize: '16px', color: 'darkred' }} />)}{item.title}</label>}
                description={<label className={`mainLabledescrp ${item.existe?(item.existe?'texttackslabel':'' ):'' }`}>{item.description}</label>}
                />
            </List.Item>
            )}
            />
            :
            <Tree
                showIcon
                className="treeWindows-pather"
                onSelect={(e)=>onChange(e)}
            >
                {listData.map((value,index)=>
                    <TreeNode className="treeWindows-childre-0" icon={ <Avatar style={{ backgroundColor:"#40A9FF", verticalAlign: 'middle' }} size="small">{value.indice}</Avatar>} title="" key={`0-${index}`}>
                        {value.lstiu.map((value1,index1)=>
                            <TreeNode className="treeWindows-childre-1" icon={<Icon type="user" style={{color:"#000"}} />} title={<span className="treeWindows">{value1.Usuario}</span>} key={`${value.indice}-${value1.iu}`} />
                        )}
                    </TreeNode>
                )
                }   
            </Tree>
        )
    }
    
    render() {
        var {title              // Titulo de la Ventana
            ,icon               // Icono JSX de la Ventana al lado del Titulo (Derecho)
            ,barrIcons          // Barra de Comandos JSX 
            ,classMain          // ClassName principal de la Ventada, define tamaño y altura de la misma asi como el color de fonde de la ventana
            ,classMainList      // ClassName de la Lista de Derecha para de la ventana, si esta activada
            ,inside             // Contenido de la ventana principal, JSX
            ,listData           // Valores JSON del List que se muestra del lado derecho de la ventada
            ,onChange           // Funcion a llamar al dar click a la ventana de la lista parametros (id,titulo,index)
            ,statusList         // True: Habilita el ListData, False: Deshabilita el ListData, con false es solo una ventana con el mismo formato definido
            ,titleBkgn          // Color Backgroud del Titulo de la ventana, si no se coloca todo por default  #939696
            ,colorBkg           // Color de las Letras del titulo, si no se coloca todo por default  #000 
            ,classWindowsTxt    // ClassName del titulo Tipo de letra, tamaño, color, de formato General
            ,classMainInside    // ClassMain class del Main windows de la ventana General
            ,tipodeList         // Si statusList===true, el tipodeList se habilita opcion 1 = List Normal, 2 = Tree ( Arbol ) Basado en la Data de parent y children!
            }=this.props;

            if (_.isUndefined(tipodeList)) tipodeList=1;
    
        return (
            <div className={_.isUndefined(classMain)?"mainwindows":classMain} >
            <Row >
                <Col span={15} className="barrwindows_1" style={{background:_.isUndefined(titleBkgn)?"#939696":titleBkgn,color:_.isUndefined(colorBkg)?"#000":colorBkg}}> 
                       {barrIcons}
                </Col>
                <Col span={9} className="barrwindows_2" style={{background:_.isUndefined(titleBkgn)?"#939696":titleBkgn,color:_.isUndefined(colorBkg)?"#000":colorBkg}}> 
                <Row type="flex" justify="end" align="bottom">
                     <label className={_.isUndefined(classWindowsTxt)?"barrwindowstext":classWindowsTxt} style={{color:_.isUndefined(colorBkg)?"#000":colorBkg}}>{_.isUndefined(icon)?<Icon type="deployment-unit" />:icon}&nbsp;{title}</label>
                </Row>
                </Col>
            </Row>
            <Row gutter={2}>
                {statusList?
                <div>
                <Col span={9} >
                    <div className={_.isUndefined(classMainList)?"mainListwindows":classMainList}>
                       {this.listMainP(listData,onChange,tipodeList)}
                    </div>
                </Col>
                
                <Col span={15} >
                    <div className={_.isUndefined(classMainInside)?"mainWindowsContent":classMainInside}>
                        {inside}
                    </div>
                </Col>
                </div>
                :
                <Col span={24}  >
                        {inside}
                </Col>
                }
            </Row>
        </div>
        );
    }
}

export default cloudwindows;