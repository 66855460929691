import React, { Component } from 'react'
import { Radio, Button, DatePicker, Select, Modal, Row, Col, Tag } from 'antd'
import moment from 'moment'
import { styleBg, systeEt } from './globals.js'
import Cloudwindows from './cloudwindows'
import CloudReporteCreate from './cloudReporteCreate'
import { mascquertt } from './function'
import { baseHipodromo, queryreporteGeneralFN, gethipobydateFn } from './functions/reports.js'
import './cloudReportGeneral-1.css'
const Option = Select.Option

const system = systeEt
class cloudReportGeneral1 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      titulo: 'Reporte de Ventas/Premios',
      Subtitulo: 'Resumido',
      tpoRpt: 1,
      tpoHipo: 0,
      btnHb: false,
      zona: '',
      modal1Visible: false,
      bodyRepor: [],
      Hipodromos: [baseHipodromo],
      desde: moment().format('DD/M/YYYY'),
      hasta: moment().format('DD/M/YYYY')
    }
    this.queryreporteGeneralFN = queryreporteGeneralFN.bind(this)
    this.mascquertt = mascquertt.bind(this)
    this.gethipobydateFn = gethipobydateFn.bind(this)
  }

  setHipodromosState = (desde, hasta) => {
    this.gethipobydateFn(desde, hasta)
  }
  selectTpreporte = (value) => {
    var { Subtitulo, tpoRpt } = this.state
    switch (value.target.value) {
      case 'a':
        Subtitulo = 'Resumido'
        tpoRpt = 1
        break

      default:
        Subtitulo = 'Detallado'
        tpoRpt = 2
        break
    }
    this.setState({ Subtitulo, tpoRpt })
  }

  bodyReportApp = (btnHb, r12, snivel, polla, Hipodromos) => (
    <Row>
      <Row className="divTitulo">
        <Col span={3}>
          <label>Desde:</label>
        </Col>
        <Col span={8}>
          <DatePicker
            size="small"
            allowClear={false}
            defaultValue={moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')}
            format="DD/MM/YYYY"
            onChange={(date) => this.setState({ desde: date.format('DD/M/YYYY') })}
          />
        </Col>
        <Col span={3}>
          <label>Hasta:</label>ß
        </Col>
        <Col span={8}>
          <DatePicker
            size="small"
            allowClear={false}
            defaultValue={moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')}
            format="DD/MM/YYYY"
            onChange={(date) => this.setState({ hasta: date.format('DD/M/YYYY') })}
          />
        </Col>
      </Row>
      <Row className="CellSelectOne">
        <Col span={6}>
          <label>Tipo de Reporte:</label>
        </Col>
        <Col span={12}>
          <Radio.Group
            defaultValue="a"
            buttonStyle="solid"
            onChange={(value) => {
              this.selectTpreporte(value)
            }}
          >
            <Radio.Button name="Resumido" value="a">
              Resumido
            </Radio.Button>
            <Radio.Button name="Detallado" value="b">
              Detallado
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      <Row className="CellSelectOne">
        <Col span={6}>
          <label>Hipodromo:</label>
        </Col>
        <Col span={12}>
          <Select
            onChange={(tpoHipo) => this.setState({ tpoHipo })}
            defaultValue={0}
            size="small"
            style={{ width: '100%' }}
          >
            {Hipodromos.map((value, index) => (
              <Option key={`jnd-hip-${index.toString()}`} value={value.code}>
                {value.Texto}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row className="CellSelectOne">
        <Col>
          <Select mode="multiple" placeholder="Seleccione" defaultValue={snivel}>
            {system.map((value, index) =>
              r12[index] ? (
                <Option key={`op_2341_${index}`} value={index}>
                  <Tag key={`op_2342_${index}`} color={styleBg[index]}>
                    {' '}
                    {value === 'Nacionales' ? (polla ? 'Polla' : value) : value}
                  </Tag>
                </Option>
              ) : (
                ''
              )
            )}
          </Select>
        </Col>
      </Row>
      <Row type="flex" justify="end" className="CellButtom">
        <Col span={7}>
          <Button
            disabled={btnHb}
            type="primary"
            shape="round"
            icon="eye"
            size="small"
            onClick={() => this.reporteQRcall(true)}
          >
            Ver Reporte
          </Button>
        </Col>
      </Row>
    </Row>
  )
  setModal1VisibleCancel = (modal1Visible) => {
    this.setState({ modal1Visible })
  }
  reporteQRcall = async (modal1Visible) => {
    var { desde, hasta, Subtitulo, tpoHipo } = this.state
    var { idPRV } = this.props
    await this.queryreporteGeneralFN({ desde, hasta, zonat: idPRV, tipo: [1, 2], gnrpt: Subtitulo, idhipo: tpoHipo })
    this.setState({ modal1Visible })
  }
  componentDidMount = () => {
    const { desde, hasta } = this.state
    this.setHipodromosState(desde, hasta)
  }
  shouldComponentUpdate = (_p, nextState) => {
    const { desde, hasta } = this.state
    const { desde: n_desde, hasta: n_hasta } = nextState
    if (n_desde !== desde || n_hasta !== hasta) {
      this.setHipodromosState(n_desde, n_hasta)
    }
    return true
  }
  render() {
    var { racceso, snivel, polla } = this.mascquertt('sub5', 'r1-2')
    const { Hipodromos } = this.state
    return (
      <div>
        <Cloudwindows
          title="Reporte Ventas/Premios"
          classMain="mainwindowsReport1"
          classMainInside="mainWindowsContentNac"
          classMainList="mainlistLlNacionales"
          classWindowsTxt="TextTitleLllNacionales"
          titleBkgn="#607d8b"
          colorBkg="#FFF"
          inside={this.bodyReportApp(false, racceso, snivel, polla, Hipodromos)}
          statusList={false}
        />
        <Modal
          wrapClassName="modal-Reportes-One"
          title="Visualizador de Reporte"
          centered
          style={{ top: 20 }}
          visible={this.state.modal1Visible}
          onCancel={() => this.setModal1VisibleCancel(false)}
          footer={false}
        >
          <CloudReporteCreate {...this.state} />
        </Modal>
      </div>
    )
  }
}

export default cloudReportGeneral1
