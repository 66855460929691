import React from "react";
import { message, Icon } from "antd";

export const viewMesseger = (type) => {
	switch (type) {
		case "ok":
			message.success(<Icon type="like" theme="twoTone" />);
			break;
		case "error":
			message.error("Lamentablemente tenemos un error! Verifique!");
			break;
		case "error-network":
			message.error("Posiblemente no tienes conexion con la red o es un error grave!");
			break;
		default:
			break;
	}
	return true;
};
