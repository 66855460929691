import React, { useState, useEffect } from "react";
import {
	Form,
	Select,
	Col,
	Row,
	Button,
	Input,
	Tag,
	Divider,
	Icon,
} from "antd";
import { useTopesRecover } from "../../../../hooks/useTopesRecover";
import { grabarTopeDeportes } from "../../../../service/mutationTopes";
import { viewMesseger } from "../../../../service/viewMesseger";
const FormItem = Form.Item;
const Option = Select.Option;
const recordSelectDefautl = [
	{
		cnf: -1,
		texto: ``,
		mmjpd: 0,
		mmjpp: 0,
		maxpremio: 0,
		mmdp: 0,
		mma: 0,
		mma2: 0,
		cjmp: 0,
		cdpi: 0,
		Participacion1: 0,
		pVentas: 0,
		cmaxelim: 0,
		Participacion2: 0,
		pVentaspd: 0,
		Eminutos: 0,
		mxpjpd: 0,
		chp: 0,
		pdrl: 0,
		ventaMin: 0,
		ventaMinDer: 0
	},
];

const IuTopesDeportes = ({
	form,
	dataDepo = recordSelectDefautl,
	sumitModalControl,
}) => {
	const { getFieldDecorator, resetFields } = form;
	const { onRecovertRecordDep } = useTopesRecover();
	const [recordSelect, setrecordSelect] = useState(dataDepo[0]);
	const selectDataDepo =
		dataDepo[0].cnf !== -1 ? recordSelectDefautl.concat(dataDepo) : dataDepo;

	useEffect(() => {
		setrecordSelect(dataDepo[0]);
	}, [dataDepo]);

	const onSaveProccessDeport = (record, del) => {
		grabarTopeDeportes(record, del).then((value) => {
			if (value === null) {
				viewMesseger("error-network");
			} else if (value) {
				viewMesseger("ok");
				sumitModalControl(false);
			} else viewMesseger("error");
			resetFields();
		});
	};

	const handleOnSaveDeport = (e) => {
		e.preventDefault();
		form.validateFields((err, valores) => {
			if (!err) {
				////*******Eval Json data **********/
				for (let props in recordSelectDefautl[0]) {
					let evalField = valores[props];
					if (!evalField) valores[props] = 0;
				}
				onSaveProccessDeport(onRecovertRecordDep(valores), 0);
			}
		});
	};
	const handleOnDeleteDeport = () => {
		onSaveProccessDeport(onRecovertRecordDep(recordSelect), 1);
	};
	const handleExit = () => {
		resetFields();
		sumitModalControl(false);
	};
	const handleChangeDeport = (e) => {
		let idx = selectDataDepo.findIndex((props) => props.cnf === e);
		setrecordSelect(selectDataDepo[idx]);
	};

	return (
		<Form onSubmit={handleOnSaveDeport}>
			<Row className="from-Topes-Col-Bottom">
				<Row>
					<Col span={11}>
						<FormItem label="Numero de Tope:">
							{getFieldDecorator("cnf", {
								initialValue: Number(recordSelect.cnf),
								rules: [
									{
										required: true,
										message:
											"Por favor seleccione el tope a utilizar para deportes!",
									},
								],
							})(
								<Select onChange={handleChangeDeport}>
									{selectDataDepo.map((value) => (
										<Option key={`A${value.cnf}`} value={value.cnf}>
											{value.cnf === -1 ? (
												<Tag color="#f50">Nuevo</Tag>
											) : (
												`${value.cnf}.-${value.texto}`
											)}
										</Option>
									))}
								</Select>
							)}
						</FormItem>
					</Col>
					<Col span={11} offset={1}>
						<FormItem label="Nombre del Tope:">
							{getFieldDecorator("texto", {
								initialValue: recordSelect.texto,
								rules: [
									{
										type: "string",
										message: "Debe tener alguna descripcion !",
									},
									{
										required: true,
										message: "Por favor requiero alguna descripcion !",
									},
								],
							})(<Input />)}
						</FormItem>
					</Col>
				</Row>

				<Row>
					<Col span={11} className="from-Topes-Col">
						<Divider orientation="right" className="from-Topes-Divider">
							<Icon type="info-circle" theme="outlined" />
							&nbsp;Montos Maximos
						</Divider>
						<FormItem label="Directa:">
							{getFieldDecorator("mmjpd", {
								initialValue: recordSelect.mmjpd,
								rules: [
									{
										required: true,
										message:
											"Por favor requiero este dato, (0 es no tomar en cuenta) !",
									},
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
								],
							})(<Input />)}
						</FormItem>
						<FormItem label="Parlay:">
							{getFieldDecorator("mmjpp", {
								initialValue: recordSelect.mmjpp,
								rules: [
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
									{
										required: true,
										message:
											"Por favor requiero este dato, (0 es no tomar en cuenta) !",
									},
								],
							})(<Input />)}
						</FormItem>
						<FormItem label="Directa(Solo Runline):">
							{getFieldDecorator("pdrl", {
								initialValue: recordSelect.pdrl,
								rules: [
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
									{
										required: true,
										message:
											"Por favor requiero este dato, (0 es no tomar en cuenta) !",
									},
								],
							})(<Input />)}
						</FormItem>
					</Col>
					<Col span={11} offset={1} className="from-Topes-Col">
						<Divider orientation="right" className="from-Topes-Divider">
							<Icon type="info-circle" theme="outlined" />
							&nbsp;Premios Maximos
						</Divider>
						<FormItem label="Premio por (Unico Monto):">
							{getFieldDecorator("maxpremio", {
								initialValue: recordSelect.maxpremio,
								rules: [
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
									{
										required: true,
										message:
											"Por favor requiero este dato, (0 es no tomar en cuenta) !",
									},
								],
							})(<Input />)}
						</FormItem>
						<FormItem label="Premio por Apuesta 1x:">
							{getFieldDecorator("mmdp", {
								initialValue: recordSelect.mmdp,
								rules: [
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
									{
										required: true,
										message:
											"Por favor requiero este dato, (0 es no tomar en cuenta) !",
									},
								],
							})(<Input />)}
						</FormItem>
						<FormItem label="Premio por Derecho:">
							{getFieldDecorator("mxpjpd", {
								initialValue: recordSelect.mxpjpd,
								rules: [
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
									{
										required: true,
										message:
											"Por favor requiero este dato, (0 es no tomar en cuenta) !",
									},
								],
							})(<Input />)}
						</FormItem>
					</Col>
				</Row>

				<Row>
					<Col span={11} className="from-Topes-Col">
						<Divider orientation="right" className="from-Topes-Divider">
							<Icon type="info-circle" theme="outlined" />
							&nbsp;Otras configuraciones
						</Divider>
						<FormItem label="Parlay Iguales:">
							<Col span={11}>
								<FormItem>
									{getFieldDecorator("cdpi", {
										initialValue: recordSelect.cdpi,
										rules: [
											{
												type: "number",
												message: "Este valor NO es un numero !",
												transform(value) {
													return Number(value);
												},
											},
											{
												required: true,
												message:
													"Por favor requiero este dato, (0 es no tomar en cuenta) !",
											},
										],
									})(<Input />)}
								</FormItem>
							</Col>
							<Col span={2}>
								<span
									style={{
										display: "inline-block",
										width: "100%",
										textAlign: "center",
									}}
								>
									de
								</span>
							</Col>
							<Col span={11}>
								<FormItem>
									{getFieldDecorator("mma", {
										initialValue: recordSelect.mma,
										rules: [
											{
												type: "number",
												message: "Este valor NO es un numero !",
												transform(value) {
													return Number(value);
												},
											},
											{
												required: true,
												message:
													"Por favor requiero este dato, (0 es no tomar en cuenta) !",
											},
										],
									})(<Input />)}
								</FormItem>
							</Col>
						</FormItem>
						<FormItem label="Cantidad de Apuestas Positivas:">
							{getFieldDecorator("chp", {
								initialValue: recordSelect.chp,
								rules: [
									{
										type: "number",
										message: "Este valor es no un numero !",
										transform(value) {
											return Number(value);
										},
									},
									{
										required: true,
										message:
											"Por favor requiero este dato, (0 es no tomar en cuenta) !",
									},
								],
							})(<Input />)}
						</FormItem>
						<FormItem label="Cantidad de Jugadas:">
							{getFieldDecorator("cjmp", {
								initialValue: recordSelect.cjmp,
								rules: [
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
									{
										required: true,
										message:
											"Por favor requiero este dato, (0 es no tomar en cuenta) !",
									},
								],
							})(<Input />)}
						</FormItem>
					</Col>
					<Col span={11} offset={1} className="from-Topes-Col">
						<Divider orientation="right" className="from-Topes-Divider">
							<Icon type="info-circle" theme="outlined" />
							&nbsp;Anulacion de Apuesta
						</Divider>
						<FormItem label="Tiempo Maximo de Anulacion:">
							{getFieldDecorator("Eminutos", {
								initialValue: recordSelect.Eminutos,
								rules: [
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
									{
										required: true,
										message:
											"Por favor requiero este dato, (0 es no tomar en cuenta) !",
									},
								],
							})(<Input />)}
						</FormItem>
						<FormItem label="Cantidad de Apuesta:">
							{getFieldDecorator("cmaxelim", {
								initialValue: recordSelect.cmaxelim,
								rules: [
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
									{
										required: true,
										message:
											"Por favor requiero este dato, (0 es no tomar en cuenta) !",
									},
								],
							})(<Input />)}
						</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span={11} className="from-Topes-Col">
						<Divider orientation="right" className="from-Topes-Divider">
							<Icon type="info-circle" theme="outlined" />
							&nbsp;Porcentaje de Venta
						</Divider>
						<FormItem label="Ganacia:">
							{getFieldDecorator("pVentas", {
								initialValue: recordSelect.pVentas,
								rules: [
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
									{
										required: true,
										message:
											"Por favor requiero este dato, (0 es no tomar en cuenta) !",
									},
								],
							})(<Input />)}
						</FormItem>
						<FormItem label="Perdida:">
							{getFieldDecorator("pVentaspd", {
								initialValue: recordSelect.pVentaspd,
								rules: [
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
									{
										required: true,
										message:
											"Por favor requiero este dato, (0 es no tomar en cuenta) !",
									},
								],
							})(<Input />)}
						</FormItem>
					</Col>

					<Col span={11} offset={1} className="from-Topes-Col">
						<Divider orientation="right" className="from-Topes-Divider">
							<Icon type="info-circle" theme="outlined" />
							&nbsp;Participacion
						</Divider>
						<FormItem label="Ganacia:">
							{getFieldDecorator("Participacion1", {
								initialValue: recordSelect.Participacion1,
								rules: [
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
									{
										required: true,
										message:
											"Por favor requiero este dato, (0 es no tomar en cuenta) !",
									},
								],
							})(<Input />)}
						</FormItem>
						<FormItem label="Perdida:">
							{getFieldDecorator("Participacion2", {
								initialValue: recordSelect.Participacion2,
								rules: [
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
									{
										required: true,
										message:
											"Por favor requiero este dato, (0 es no tomar en cuenta) !",
									},
								],
							})(<Input />)}
						</FormItem>
					</Col>
				</Row>
			</Row>
			<FormItem>
				<Row gutter={16}>
					<Col span={8}>
						<Button type="primary" htmlType="submit">
							{recordSelect.cnf !== -1
								? "Actualizar Registro"
								: "Registra Nuevo"}
						</Button>
					</Col>
					<Col span={8}>
						<Button onClick={handleExit}>Cancelar</Button>
					</Col>
					{recordSelect.cnf !== -1 ? (
						<Col span={8}>
							<Button type="danger" onClick={handleOnDeleteDeport}>
								Borrar
							</Button>
						</Col>
					) : (
						""
					)}
				</Row>
			</FormItem>
		</Form>
	);
};
const EnhancedFormApp = Form.create()(IuTopesDeportes);
export default EnhancedFormApp;

//  class FromApp extends Component {

// 	render() {
// 		const { getFieldDecorator } = this.props.form;
// 		const recordSelect = this.props.tthis.state.seleccion;
// 		const formItemLayout = {
// 			labelCol: {
// 				xs: { span: 30 },
// 				sm: { span: 10 },
// 			},
// 			wrapperCol: {
// 				xs: { span: 12 },
// 				sm: { span: 6 },
// 			},
// 		};
// 		const formItemLayout2 = {
// 			labelCol: {
// 				xs: { span: 35 },
// 				sm: { span: 5 },
// 			},
// 			wrapperCol: {
// 				xs: { span: 25 },
// 				sm: { span: 10 },
// 			},
// 		};
// 		console.log(recordSelect);

// 	}
// }
