import React, { Component } from "react";

import { Steps, Hints, } from 'intro.js-react';


import { Menu, Icon, Layout, Button, Result, Row, Col } from "antd";
import Cloudltapuesta from "./cloudltapuesta";
import Cloudltloteria from "./cloudltloteria";
import Cloudltrestricciones from "./cloudltrestricciones";
import Cloufltescrute from "./cloudEscruteLt";
import CloudGNVerJugada from "./verjugadaGen";
import CloudReportGeneral1 from "./cloudReportGeneral-1";

import CloudGanadoresJnd from "./cloud.Gana-Jnd";
import CloudSettingPolla from "./cloudSettingPolla";
import CloudSettingGenPolla from "./cloudSettingGenPolla";
import CloudLlNacionales from "./cloudLlNacionales";
import CloudParticipacionNC from "./cloudParticipacionNC";
import CloudCapturaJornadas from './module/ganadores/captureCentinela/';


import IuUser from "./iuUser";
import { IuTopesGeneral } from "./module/generales/topes";
import IuTransacciones from "./module/generales/transacciones";
import IuEditorPage from "./module/generales/editorPage/";

import CloudcscGroupPoint from "./cloudcscGroupPoint";

import IconBeisball from "./compenent/icons/iconBeisball";
import IconAmericana from "./compenent/icons/iconAmericana";
import IconNacionales from "./compenent/icons/iconNacionales";
import IconLotteri from "./compenent/icons/iconLotteri";

import {hashSet}  from './service/general'

import "./InitMenu.scss";
import "intro.js/introjs.css";

var _ = require("lodash");

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const { Header, Content, Sider } = Layout;



const steps = [
	{
	  title: 'Bienvenidos al TUTORIAL',
	  element: '.head-tutorial',
	  intro: 'Bienvenidos al TUTORIAL, para el sistema Administrativo de SBOnline, Iniciemos...',
	  position: 'right',
	},
	{
		title: 'Menu Principal',
		element: ".menu-tutorial",
		intro: "Este es el Menu Principal del SBOline, con diferentes opciones según el sistema."
	},
	{
		title: 'Menu Principal',
		element: ".menu-deporte",
		intro: "Este es la opción de Deportes, donde se puede configurar las opciones de Parlay."
	},
	{
		title: 'Menu Principal',
		element: ".menu-americana",
		intro: "Este es la opción de Americana, donde se puede configurar las opciones carreras Americanas."
	},
	{
		title: 'Menu Principal',
		element: ".menu-nacionales",
		intro: "Este es la opción de Nacionales, donde se puede configurar las opciones carreras Nacionales."
	},
	{
		title: 'Menu Principal',
		element: ".menu-loteria",
		intro: "Este es la opción de Nacionales, donde se puede configurar las opciones carreras Nacionales."
	}
  ];

class InitMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			collapsed: false,
			inside: "",
			_PRIVATE: [],
			MasterInfo: "Administrador",
			idPRV: 0,
			id: 0,
			iu: "",
			stepsEnabled: false,
      		initialStep: 0,
			hintsEnabled: true,
			hints: [
			{
				element: ".head-tutorial",
				hint: "Hello hint",
				hintPosition: "middle-right"
			}
			]
		};
	}
	handleClick = (e) => {
		var { inside, idPRV } = this.state;
		switch (e.key) {
			case "n2-1":
				inside = <CloudGanadoresJnd />;
				break;
			case "n1-2":
				inside = <CloudSettingGenPolla />;
				break;
			case "n1-1":
				inside = <CloudSettingPolla />;
				break;
			case "n1-3":
				var _PRV = {};
				if (this.state.idPRV !== 0) _PRV = this.state._PRIVATE.sbmodule.sub6;
				inside = <CloudParticipacionNC {...this.state} idPRV={idPRV} />;
				break;
			case "n8-1":
				inside = <CloudLlNacionales />;
				break;
			case "n8-2":
				inside =<CloudCapturaJornadas/>;
				break;
			case "l1-3":
				inside = <Cloudltrestricciones />;
				break;
			case "l1-2":
				inside = <Cloudltapuesta />;
				break;
			case "l1-1":
				inside = <Cloudltloteria />;
				break;
			case "l2-1":
				inside = <CloudcscGroupPoint />;
				break;
			case "l3-1":
				inside = <Cloufltescrute />;
				break;
			case "r1-1":
				inside = <CloudGNVerJugada {...this.state} />;
				break;
			case "r1-2":
				inside = <CloudReportGeneral1 {...this.state} />;
				break;
			case "g1-1":
			    _PRV = {};
				if (this.state.idPRV !== 0) _PRV = this.state._PRIVATE.sbmodule.sub6;
				inside = <IuUser {..._PRV} idPRV={idPRV} />;
				break;
			case "g1-2":
				inside = <IuTopesGeneral />;
				break;
			case "g1-3":
				inside = <IuTransacciones idPRV={idPRV} />;
				break;
			case "g1-4":
				inside = <IuEditorPage />;
				break;
			case "exit":
				this.props.history.replace({ pathname: "/" });
				hashSet('0')
				break;
			case "tour":
				this.setState(() => ({ stepsEnabled: true }));
				break;
			default:
				inside = "";
		}
		this.setState({ inside });
	};
	toggleCollapsed = () => {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};
	componentDidMount = () => {
		var { idPRV, _PRIVATE, MasterInfo } = this.state;
		if (!_.isUndefined(this.props.history.location.state)) {
			if (!_.isNull(this.props.history.location.state._PRIVATE)) {
		
				_PRIVATE = JSON.parse(this.props.history.location.state._PRIVATE);
				idPRV = _PRIVATE._PRIVATE;
				MasterInfo = _PRIVATE.Organizacion;
			}
			var { id, iu } = this.props.history.location.state;
			if (id !== 0) {
				this.setState({ idPRV, _PRIVATE, id, iu, MasterInfo });
			}
		}
	};
	onExit = () => {
		this.setState(() => ({ stepsEnabled: false }));
	  };
	render() {
		var { _PRIVATE, id, MasterInfo, idPRV, collapsed,stepsEnabled,initialStep,hintsEnabled,hints } = this.state;
		let polla=false;
		if (_PRIVATE.length !== 0) {
			var tmodule = _PRIVATE.module;
			var { menu } = tmodule;
			polla = menu.polla;
		} else {
			menu = {
				sub1: 1,
				sub2: 1,
				sub3: 1,
				sub4: 1,
				sub5: 1,
				sub6: 1,
			};
		}
		return (
			<div>
				<Steps
					enabled={stepsEnabled}
					steps={steps}
					initialStep={initialStep}
					onExit={this.onExit}
					options={{
						nextLabel:'Siguiente',
						prevLabel:'Atras',
						doneLabel:'Salir',
						skipLabel:'Salir',
						hintPosition:'letf'
					}}
				/>
				 <Hints enabled={hintsEnabled} hints={hints} />
				{id ? (
					
					<Layout style={{ minHeight: "500vh", background: "#2c3e50" }}>
						<Sider
							collapsible
							collapsed={collapsed}
							onCollapse={() => this.setState({ collapsed: !collapsed })}
						>	
							<div className="head-tutorial">
								<Row  style={{ marginLeft: "10px" }}>
									{collapsed ? (
										<Col span={4}>
											<label style={styles.textTitulo1}>SB</label>
										</Col>
									) : (
										<Col span={8}>
											<label style={styles.textTitulo1}>SportsBook</label>
											<label style={styles.textTitulo2}>Masters</label>{" "}
										</Col>
									)}
								</Row>
							</div>
							{/* <Button
								type="primary"
								// onClick={this.toggleCollapsed}
								style={{ marginBottom: 16 }}
							>
								<Icon type={collapsed ? "menu-unfold" : "menu-fold"} />
							</Button> */}
							<Menu
								onClick={(e) => this.handleClick(e)}
								mode="inline"
								theme="dark"
								className="menu-tutorial"
								inlineCollapsed={collapsed}
							>
								{menu.sub1 && (
									<SubMenu
										key="sub1"
										className="menu-deporte"
										title={
											<span>
												{/* <MyIcon type="icon-sport" /> */}
												<IconBeisball fill="#fff" height={16} width={16} />
												{collapsed ? (
													""
												) : (
													<span className="init-menu-Text-Menu">Deportes</span>
												)}
											</span>
										}
									>
										<MenuItemGroup
											key="d1"
											title={
												<span>
													<Icon type="more" />
													<span className="Subtitle">Logros/Odds</span>
												</span>
											}
										>
											<Menu.Item key="d1-1">Logros de iKronos</Menu.Item>
											<Menu.Item key="d1-2">Jornada</Menu.Item>
											<Menu.Item key="d1-3">
												Deportes/Apuestas/Grupos/Formato{" "}
											</Menu.Item>
											<Menu.Item key="d1-4">Participacion </Menu.Item>
										</MenuItemGroup>
										<MenuItemGroup
											key="d2"
											title={
												<span>
													<Icon type="more" />
													<span className="Subtitle">Concesiones/Grupos</span>
												</span>
											}
										>
											<Menu.Item key="d2-1">Concesiones/Grupos</Menu.Item>
											<Menu.Item key="d2-2">Participacion</Menu.Item>
										</MenuItemGroup>
										<MenuItemGroup
											key="d3"
											title={
												<span>
													<Icon type="more" />
													<span className="Subtitle">Estadisticas</span>
												</span>
											}
										>
											<Menu.Item key="d3-1">Auditorias de Logros</Menu.Item>
											<Menu.Item key="d3-2">Auditorias de Acceso</Menu.Item>
											<Menu.Item key="d3-3">Estadisticas</Menu.Item>
										</MenuItemGroup>
										<MenuItemGroup
											key="d4"
											title={
												<span>
													<Icon type="more" />
													<span className="Subtitle">Escrutes/Resultados</span>
												</span>
											}
										>
											<Menu.Item key="d4-1">Escrute de Partidos</Menu.Item>
										</MenuItemGroup>
									</SubMenu>
								)}
								{menu.sub2 && (
									<SubMenu
										className="menu-americana"
										key="sub2"
										title={
											<span>
												<IconAmericana fill="#fff" height={16} width={16} />
												{collapsed ? (
													""
												) : (
													<span className="init-menu-Text-Menu">Americana</span>
												)}
											</span>
										}
									></SubMenu>
								)}
								{menu.sub3 && (
									<SubMenu
										className="menu-nacionales"
										key="sub3"
										title={
											<span>
												<IconNacionales fill="#fff" height={16} width={16} />
												<span className="init-menu-Text-Menu">
													{collapsed ?"":polla?'Polla':'Nacionales'}
												</span>
											</span>
										}
									>
										{polla?'':
										<MenuItemGroup
											key="n2"
											title={
												<span>
													<Icon type="more" />
													<span className="Subtitle">Ganadores</span>
												</span>
											}
										>
											<Menu.Item key="n2-1">Jornadas</Menu.Item>
											<Menu.Item key="n2-2">Grupos/Puntos de Ventas</Menu.Item>
										</MenuItemGroup>
										}
										<MenuItemGroup
											key="n1"
											title={
												<span>
													<Icon type="more" />
													<span className="Subtitle">Polla</span>
												</span>
											}
										>
											<Menu.Item key="n1-1">Jornadas</Menu.Item>
											<Menu.Item key="n1-2">Premiacion</Menu.Item>
											<Menu.Item key="n1-3">Porcentajes </Menu.Item>
										</MenuItemGroup>
										<MenuItemGroup
											key="n8"
											title={
												<span>
													<Icon type="more" />
													<span className="Subtitle">General</span>
												</span>
											}
										>
											<Menu.Item key="n8-1">Conformes</Menu.Item>
											<Menu.Item key="n8-2">Captura</Menu.Item>
										</MenuItemGroup>
									</SubMenu>
								)}
								{menu.sub4 && (
									<SubMenu
										className="menu-loteria"
										key="sub4"
										title={
											<span>
												<IconLotteri fill="red" height={16} width={16} />
												{collapsed ? (
													""
												) : (
													<span className="init-menu-Text-Menu">Loterias</span>
												)}
											</span>
										}
									>
										<MenuItemGroup
											key="l1"
											title={
												<span>
													<Icon type="more" />
													<span className="Subtitle">Sorteos</span>
												</span>
											}
										>
											<Menu.Item key="l1-1">Loterias/Sorteos</Menu.Item>
											<Menu.Item key="l1-2">Apuestas </Menu.Item>
											<Menu.Item key="l1-3">Participacion </Menu.Item>
										</MenuItemGroup>
										<MenuItemGroup
											key="l2"
											title={
												<span>
													<Icon type="more" />
													<span className="Subtitle">Concesiones/Grupos</span>
												</span>
											}
										>
											<Menu.Item key="l2-1">Puntos de Ventas/Grupos</Menu.Item>
											<Menu.Item key="l2-2">Participacion</Menu.Item>
										</MenuItemGroup>
										<MenuItemGroup
											key="l3"
											title={
												<span>
													<Icon type="more" />
													<span className="Subtitle">Resultados</span>
												</span>
											}
										>
											<Menu.Item key="l3-1">Escrute de Sorteo</Menu.Item>
										</MenuItemGroup>
									</SubMenu>
								)}
								{menu.sub5 && (
									<SubMenu
										key="sub5"
										title={
											<span>
												<Icon type="align-left" />
												<span>Reportes</span>
											</span>
										}
									>
										<Menu.Item key="r1-1">Ver Tickets</Menu.Item>
										<Menu.Item key="r1-2">Ventas y Premios General</Menu.Item>
									</SubMenu>
								)}
								{menu.sub6 && (
									<SubMenu
										key="sub6"
										title={
											<span>
												<Icon type="setting" />
												<span>Generales</span>
											</span>
										}
									>
										<Menu.Item key="g1-1">Usuarios</Menu.Item>
										<Menu.Item key="g1-2">Topes Generales</Menu.Item>
										<Menu.Item key="g1-3">Transacciones</Menu.Item>
										<Menu.Item key="g1-4">Modificar de Pagina</Menu.Item>
									</SubMenu>
								)}
								<Menu.Item key="exit">
									<Icon type="close-circle" /> <span>Logout</span>
								</Menu.Item>
								<Menu.Item key="tour">
									<Icon  /> <span>Tutorial</span>
								</Menu.Item>
							</Menu>
						</Sider>
						<Layout>
							<Header className="header">
								<Row gutter={1}>
									<Row type="flex" justify="end">
										<Col   span={2} className="main-iden-ln1">
											<label> Usuario:</label>
										</Col>
										<Col   data-testid="iu-login" span={8} className="main-iden-ln2">
											<label> {this.state.iu}</label>
										</Col>
									</Row>
									<Row type="flex" justify="end">
										<Col span={2} className="main-iden-ln1">
											<label> Master:</label>
										</Col>
										<Col span={8} className="main-iden-ln2">
											<label> {MasterInfo}</label>
										</Col>
									</Row>
									{idPRV ? (
										<Row type="flex" justify="end">
											<Col span={2} className="main-iden-ln1">
												<label> Code:</label>
											</Col>
											<Col span={8} className="main-iden-ln2">
												<label> {idPRV}</label>
											</Col>
										</Row>
									) : (
										""
									)}
								</Row>
							</Header>
							<Content style={{ background: "#2d2d2d" }}>
								{this.state.inside}
							</Content>
						</Layout>
					</Layout>
				) : (
					<Result
						status="error"
						title={<label className="CloudError">Fallo de Acceso</label>}
						subTitle={
							<label className="CloudError">
								Por favor coloque los parametros correctos para poder acceder al
								sistema.
							</label>
						}
						extra={[
							<Button
								type="primary"
								key="console"
								onClick={() => this.props.history.replace({ pathname: "/" })}
							>
								Salir
							</Button>,
						]}
					></Result>
				)}
			</div>
		);
	}
}

const styles = {
	textTitulo1: {
		fontSize: "25px",
		color: "#FF5733",
		fontWeight: "600",
	},
	textTitulo2: {
		fontSize: "15px",
		color: "#415d76",
	},
};
export default InitMenu;
