import React,{ Component } from "react";
import {Button,List,Icon,Row,Col,Popconfirm,Avatar} from "antd";
import store from './store';
import * as func from './function';

// var _ =  require('lodash');
const ButtonGroup = Button.Group;
class ComponetLoteria extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cmd:'',
            id_mark:0,
            insider:'',
            collapsed:false,
            listDataLt:[],
            id_select:-1
        }
        
        this.queryLoterialtFn_componet=func.queryLoterialtFn_componet.bind(this);
        this.mutationLoteriatsFn=func.mutationLoteriatsFn.bind(this);
        store.subscribe(() => {
            this.setState({
                listDataLt:store.getState().listDataLt,
                insider:store.getState().insider,
            })
        })
    }
    onloadInside=(insider)=>{
        this.setState({insider})
    }
    callPropsAct=(id,index,etiqueta)=>{
        this.setState({id_mark:id,id_select:index})
        this.props.onClickMainList_one(id,index,etiqueta,this)
    }
    componentDidMount=()=>{
        store.dispatch({ type: 'IN_data_insider',insider:''});
        this.queryLoterialtFn_componet();
    }

    render(){
        return(
            <div className="mainwdw_lt" style={{width:this.props.wth}}>
                <div className="barrwdw">
                {this.props.barcommando &&
                    <ButtonGroup >
                        
                            <Button  className="barrwdw_bnt" ghost type="link" onClick={()=>this.props.commandBarr_one.bind(this,-1)} ><Icon type="plus" /></Button>
                            <Popconfirm title="Esta Seguro de Borrar esta configuracion de Loteria?" onConfirm={()=>this.props.commandBarr_one.bind(this,-2)}  okText="Borrar" cancelText="Cancelar">
                                <Button   className="barrwdw_bnt" ghost type="link" ><Icon type="minus" /></Button>
                            </Popconfirm >
                            <Button   className="barrwdw_bnt" ghost type="link"><Icon type="diff" /></Button>
                        
                    </ButtonGroup>
                }
                    <div className="barrwdwtitle">
                        <label className="barrwdwtext"><Icon type="deployment-unit" />&nbsp;{this.props.title}</label>
                    </div>
                </div>
                <Row >
                    <Col span={7} >
                        <div className='mainList_lt'>
                            <List
                                    itemLayout="vertical"
                                    size="small"
                                    locale={{ emptyText:<label style={{color:'#fff'}}> <Icon type="delete" style={{fontSize: '22px',color:'#fff'}}/>No hay Informacion</label> }}
                                    dataSource={this.state.listDataLt}
                                    renderItem={(item,index) => (
                                    <List.Item
                                        className={this.state.id_select===index?"listStyItem":''}
                                        key={item.title}
                                        onClick={()=>this.callPropsAct(item.id,index,item.etiqueta)}
                                        >
                                        <List.Item.Meta
                                        avatar={<Avatar style={{ backgroundColor:'#f56a00', verticalAlign: 'middle' }} size="large" >{item.etiqueta}</Avatar>}
                                        title={<label className="mainLabletitle_lt">{item.title}</label>}
                                        />
                                    </List.Item>
                                    )}
                                />
                        </div>
                    </Col>
                    <Col span={7} >
                        <div className='mainContent_componet' >
                            {this.state.insider}
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default ComponetLoteria;