import { errorServerMessage } from '../compenent/errorMensaje'
import { gql } from '@apollo/client'
import client from '../conex'

export const baseHipodromo = { code: 0, Texto: 'Todos' }

const gethipobydate = gql`
  query gethipobydate($desde: String, $hasta: String) {
    gethipobydate(desde: $desde, hasta: $hasta) {
      code
      Texto
    }
  }
`
export function gethipobydateFn(desde, hasta) {
  client
    .query({
      variables: { desde, hasta },
      query: gethipobydate
    })
    .then(({ data: gethipobydate }) => {
      const getdatahipodromos = gethipobydate.gethipobydate

      const getHipodromos = getdatahipodromos.map(({ code, Texto }) => {
        return {
          code,
          Texto
        }
      })
      this.setState({ Hipodromos: [baseHipodromo, ...getHipodromos] })
    })
    .catch((e) => {
      errorServerMessage()
    })
}

/////////////////queryreporteGeneralFN///////////////////
const queryreporteGeneral = gql`
  query reporteGeneral($desde: String, $hasta: String, $zona: String, $tipo: [Int], $idhipo: Int) {
    reporteGeneral(desde: $desde, hasta: $hasta, zona: $zona, tipo: $tipo, idhipo: $idhipo) {
      idc
      porcentapartici
      porcentadeventas
      transacciones {
        fecha
        serial
        ventas
        premios
      }
    }
  }
`
export async function queryreporteGeneralFN({ desde, hasta, zonat, tipo, gnrpt, idhipo }) {
  var zona = zonat.toString()
  await client
    .query({
      query: queryreporteGeneral,
      variables: { desde, hasta, zona, tipo, idhipo }
    })
    .then((data) => {
      var { reporteGeneral } = data.data
      if (reporteGeneral.length !== 0) {
        var bodyRepor = []

        if (gnrpt === 'Resumido') {
          reporteGeneral.forEach((values) => {
            var { idc, transacciones, porcentadeventas, porcentapartici } = values
            var subventas = 0,
              subpremios = 0
            transacciones.forEach((values1) => {
              var { premios, ventas } = values1
              subventas += ventas
              subpremios += premios
            })
            var subprocentaje = (subventas * porcentadeventas) / 100
            var diferencia = subventas - subpremios - subprocentaje
            bodyRepor.push({
              idc,
              subventas,
              subprocentaje,
              subpremios,
              diferencia,
              participa: (diferencia * porcentapartici) / 100
            })
          })

          this.setState({ bodyRepor })
        } else {
          this.setState({ bodyRepor: reporteGeneral })
        }
      }
    })
}
