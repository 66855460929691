import {useState,useEffect} from 'react'
import {getlistCapture} from '../service/getlistCapture' 

export function useListData() {
    const [listData, setstateListadata] = useState([]);

    useEffect(() => {
		getCaptureFunction()
		return () => {};
	}, [listData]);

    const getCaptureFunction=()=>{
		getlistCapture().then(setstateListadata);
	}
    return {listData,getCaptureFunction}
}
