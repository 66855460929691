import client from "../conex";
import {gql} from "@apollo/client";


const queryJornadaNacionalesCapture = gql`
	query captaJornadasNacionales {
        captaJornadasNacionales {
          id
          description
          title
          cantidad
          inicio
          code
          existe
        }
      }
`;

export const getlistCapture = () => {
    return client
    .query({
        query: queryJornadaNacionalesCapture,
    })
    .then(({ data }) => {
        let { captaJornadasNacionales } = data;
        return captaJornadasNacionales;
    });
}
