import React, { Component } from 'react';
import {Row,Col,Input,Button,Tag} from "antd";
import Cloudwindows from "./cloudwindows";
import Spinnerwait from './spinnerwait';
import * as func from './function';
import   './cloudParticipacionNC.scss'; 
var _ =  require('lodash');
class cloudParticipacionNC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewIUCnfg:[],inside:'',
            porcentaje:0,
            participa:0
        }


        this.queryviewIUCnfgFn= func.queryviewIUCnfgFn.bind(this);
        this.muttationSavePartPollaFN=func.muttationSavePartPollaFN.bind(this);
    }
    onclickData=(e)=>{
       this.searchData(e)
    }
    insideFrm=(registro)=>(
        <Row  className="frmPar">
             <Row className="frmPar-ln0">
                 <Row type="flex" justify="end">
                    <Col   >
                        {registro.activo?<Tag color="blue">Activo</Tag>:<Tag color="red">Suspendido</Tag>}
                    </Col>
                </Row>
            </Row>
            <Row className="frmPar-lnsub1">
                <Col  span={23} >
                    <span className="frmPar-ln1-txt1" >Usuario:</span><samp className="frmPar-ln1-txt2">{registro.Usuario}</samp>
                </Col>
            </Row>
             <Row className="frmPar-ln1">
                <Col  span={20} >
                    <span className="frmPar-ln1-txt1" >Punto de Venta de:</span><samp className="frmPar-ln1-txt2">{registro.Nombre}</samp>
                </Col>
            </Row>
            <Row  className="frmPar-ln2">
                <Col  span={8}>
                    <span>Porcentaje:</span>
                </Col>
                <Col  span={8} >
                    <Input   type="Number"   size="small" min={0} max={100} value={registro.porcentaje} onChange={(e)=>this.onChange(e.target.value,registro)} />
                </Col>
            </Row>
            <Row  className="frmPar-ln3"> 
                <Col  span={8}>
                    <span>Participacion:</span>
                </Col>
                <Col  span={8} >
                    <Input   type="Number"   size="small" min={0} max={100} value={registro.participa}  onChange={(e)=>this.onChange2(e.target.value,registro)} />
                </Col>
            </Row>
            <Row  className="frmPar-ln4"> 
                <Col>
                    <Button type="primary" onClick={()=>this.saveData(registro)}>Grabar</Button>
                </Col>
            </Row>
        </Row>
    )
    onChange=(porcentaje,registro)=>{
        registro.porcentaje=porcentaje
        this.setState({porcentaje, inside:this.insideFrm(registro)});
    }
    onChange2=(participa,registro)=>{
        registro.participa=participa
        this.setState({participa, inside:this.insideFrm(registro)});
    }
    saveData=(registro)=>{
        var {porcentaje,participa}=this.state
        var {IDC}=registro;
        var cJson={IDC,porcentaje,participa};
        this.muttationSavePartPollaFN(cJson,registro)
    }
    searchData=(value)=>{
        var {viewIUCnfg}=this.state;
        var vlr=_.split(value,'-');
        if (vlr[0]!=='0'){ 
            var indice=vlr[0]
            var iu =  _.toNumber(vlr[1])
            var idx=_.findIndex(viewIUCnfg, function(o) { return o.indice === indice; });
            if (idx!==-1){
                var {lstiu}=viewIUCnfg[idx];
                idx=_.findIndex(lstiu, function(o) { return o.iu === iu; });
                if (idx!==-1){
                    var {Usuario,activo,Nombre,participa,porcentaje,cscNacionales}=lstiu[idx];
                    this.setState({participa,porcentaje,inside:this.insideFrm({IDC:cscNacionales,Usuario,activo,Nombre,participa,porcentaje,indice,iu})})
                }
            }
        }           
    }
    
    componentDidMount=()=>{
        var {idPRV}=this.props
        this.queryviewIUCnfgFn(idPRV);
    }
    render() {
        var {viewIUCnfg,inside}=this.state;
        return (
            viewIUCnfg.length!==0?
                <div>
                  <Cloudwindows
                    title="Participacion/Porcentajes"
                    classMain="mainWindowsParticipacion"
                    classMainInside="mainWindowsContentNac"
                    classMainList="mainlistLlParticipacion"
                    classWindowsTxt="TextTitleLllNacionales"
                    titleBkgn="#939696"
                    colorBkg="#FFF"
                    onChange={(e)=>this.onclickData(e)}
                    listData={viewIUCnfg}
                    statusList={true}
                    tipodeList={2}
                    inside={inside}
                />
            </div>
            :<Spinnerwait/>
        );
    }
}

export default cloudParticipacionNC;