import client from "../conex";
import {gql} from "@apollo/client";
const encode = require("nodejs-base64-encode");

const queryvieWebSite = gql`
	query vieWebSite($site: Int) {
		vieWebSite(site: $site)
	}
`;

export const queryvieWebSiteFn = (site) => {
	return client
		.query({
			query: queryvieWebSite,
			variables: { site: site },
		})
		.then(({ data }) => {
			const { vieWebSite } = data;
			return vieWebSite;//.replace(new RegExp('[/u0000-/u001F]','g'), "");
		});
};

const mutationiwebsite = gql`
	mutation iwebsite($istrp: String, $site: Int) {
		iwebsite(istrp: $istrp, site: $site)
	}
`;
export const mutationiwebsiteFn = (istrp, site) => {
	const data = encode.encode(JSON.stringify(istrp, replacer), "base64");
	return client
		.mutate({
			mutation: mutationiwebsite,
			variables: { site: site, istrp: data },
		})
		.then(({ data }) => {
			const { iwebsite } = data;
			if (iwebsite === 0 || iwebsite === 1  ) return true;
			else return false;
		})
		.catch(() => {
			return null;
		});
};
function replacer(key, value) {
	if (key === "style") {
		if (value === "bgcolor-rgb(255,255,255)")
			return "bgcolor-rgb(255, 255, 255,0) ";
	}
	return value;
}
