import React, { Component } from 'react'
import { Row, Col, Button } from 'antd'
import ReactToPrint from 'react-to-print'
import './cloudReportGeneral-1.css'
var _ = require('lodash')
var totales = []
class BodyReportCloud extends Component {
  render() {
    var { tilefrm, rbodyRepor, classN, tpoHipo, Hipodromos } = this.props
    const { Texto } = Hipodromos.find(({ code }) => code === tpoHipo)
    return (
      <Row>
        <Row className="rpt-Title">
          <Col>
            <label>
              {this.props.titulo}-{this.props.Subtitulo}
            </label>
          </Col>
        </Row>
        <Row className="rpt-SubTitle">
          <Col>
            <label>
              Hipodromo:<label className="rpt-SubtInter">{Texto}</label>
            </label>
          </Col>
        </Row>
        <Row className="rpt-SubTitle" type="flex" justify="center">
          <Col span={8}>
            <label>Desde:{this.props.desde}</label>
          </Col>
          <Col span={8}>
            <label>Hasta:{this.props.hasta}</label>
          </Col>
        </Row>
        <Row className="rpt-Row-Main-ini">
          {tilefrm.map((value, index) => (
            <Col key={index.toString()} span={value.size} className="rpt-Col-Main">
              {value.title}
            </Col>
          ))}
        </Row>
        <Row>
          {rbodyRepor.map((value, index) => (
            <Row
              key={index.toString()}
              className={
                value.idc === 'TOTALES'
                  ? 'rpt-srows-Repor-Total'
                  : 'rpt-srows-Repor-Ln' +
                    (classN === 'value' ? (value.idc === '*Sub-total*:' ? '1' : '2') : index % 2 ? '1' : '2')
              }
            >
              <Col span={tilefrm[0].size}>{value.idc}</Col>
              <Col span={tilefrm[1].size}>{value.tsubventas}</Col>
              <Col span={tilefrm[2].size}>{value.tsubprocentaje}</Col>
              <Col span={tilefrm[3].size}>{value.tsubpremios}</Col>
              <Col span={tilefrm[4].size}>{value.tdiferencia}</Col>
              <Col span={tilefrm[5].size}>{value.tparticipa}</Col>
            </Row>
          ))}
        </Row>
      </Row>
    )
  }
}
class Reporte1 extends Component {
  render() {
    var { bodyRepor } = this.props
    totales = ['TOTALES', 0, 0, 0, 0, 0]
    var rbodyRepor = []
    var tabspc = 14
    bodyRepor.forEach((element) => {
      var { idc, subventas, subprocentaje, subpremios, diferencia, participa } = element
      totales[1] += subventas
      totales[2] += subprocentaje
      totales[3] += subpremios
      totales[4] += diferencia
      totales[5] += participa
      rbodyRepor.push({
        idc,
        tsubventas: _.padStart(this.props.converInt(subventas), tabspc, '_'),
        tsubprocentaje: _.padStart(this.props.converInt(subprocentaje), tabspc - 3, '_'),
        tsubpremios: _.padStart(this.props.converInt(subpremios), tabspc, '_'),
        tdiferencia: _.padStart(this.props.converInt(diferencia), tabspc, '_'),
        tparticipa: _.padStart(this.props.converInt(participa), tabspc - 3, '_')
      })
    })
    rbodyRepor.push({
      idc: totales[0],
      tsubventas: _.padStart(this.props.converInt(totales[1]), tabspc, '_'),
      tsubprocentaje: _.padStart(this.props.converInt(totales[2]), tabspc - 3, '_'),
      tsubpremios: _.padStart(this.props.converInt(totales[3]), tabspc, '_'),
      tdiferencia: _.padStart(this.props.converInt(totales[4]), tabspc, '_'),
      tparticipa: _.padStart(this.props.converInt(totales[5]), tabspc - 3, '_')
    })

    return <BodyReportCloud {...this.props} rbodyRepor={rbodyRepor} classN="index" />
  }
}

class Reporte2 extends Component {
  render() {
    var { bodyRepor } = this.props
    totales = ['TOTALES', 0, 0, 0, 0, 0]
    var rbodyRepor = []
    var tabspc = 14
    bodyRepor.forEach((element) => {
      var { idc, porcentadeventas, porcentapartici, transacciones } = element
      var tsubventas = '',
        tsubprocentaje = '',
        tsubpremios = '',
        tdiferencia = '',
        tparticipa = ''
      rbodyRepor.push({
        idc,
        tsubventas,
        tsubprocentaje,
        tsubpremios,
        tdiferencia,
        tparticipa
      })

      var subtotales = ['*Sub-total*:', 0, 0, 0, 0, 0]
      transacciones.forEach((element) => {
        var { fecha, premios, ventas } = element
        var tprocentaje = (ventas * porcentadeventas) / 100
        var tdiferencia = ventas - premios - tprocentaje
        var tparticipa = (tdiferencia * porcentapartici) / 100

        subtotales[1] += ventas
        subtotales[2] += tprocentaje
        subtotales[3] += premios
        subtotales[4] += tdiferencia
        subtotales[5] += tparticipa

        totales[1] += ventas
        totales[2] += tprocentaje
        totales[3] += premios
        totales[4] += tdiferencia
        totales[5] += tparticipa

        rbodyRepor.push({
          idc: fecha,
          tsubventas: _.padStart(this.props.converInt(ventas), tabspc, '_'),
          tsubprocentaje: _.padStart(this.props.converInt(tprocentaje), tabspc - 3, '_'),
          tsubpremios: _.padStart(this.props.converInt(premios), tabspc, '_'),
          tdiferencia: _.padStart(this.props.converInt(tdiferencia), tabspc, '_'),
          tparticipa: _.padStart(this.props.converInt(tparticipa), tabspc - 3, '_')
        })
      })

      rbodyRepor.push({
        idc: subtotales[0],
        tsubventas: _.padStart(this.props.converInt(subtotales[1]), tabspc, '_'),
        tsubprocentaje: _.padStart(this.props.converInt(subtotales[2]), tabspc - 3, '_'),
        tsubpremios: _.padStart(this.props.converInt(subtotales[3]), tabspc, '_'),
        tdiferencia: _.padStart(this.props.converInt(subtotales[4]), tabspc, '_'),
        tparticipa: _.padStart(this.props.converInt(subtotales[5]), tabspc - 3, '_')
      })
    })
    rbodyRepor.push({
      idc: totales[0],
      tsubventas: _.padStart(this.props.converInt(totales[1]), tabspc, '_'),
      tsubprocentaje: _.padStart(this.props.converInt(totales[2]), tabspc - 3, '_'),
      tsubpremios: _.padStart(this.props.converInt(totales[3]), tabspc, '_'),
      tdiferencia: _.padStart(this.props.converInt(totales[4]), tabspc, '_'),
      tparticipa: _.padStart(this.props.converInt(totales[5]), tabspc - 3, '_')
    })
    return <BodyReportCloud {...this.props} rbodyRepor={rbodyRepor} classN="value" />
  }
}

class cloudReporteCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      iBodyReportes: '',
      titulo: '',
      Subtitulo: '',
      desde: '',
      hasta: '',
      tpoRpt: 1,
      bodyRepor: [],
      totales: [],
      tilefrm: [
        { title: 'PV', size: 6, key: 'idc', ksz: 15, tsz: 13 },
        { title: 'Ventas', size: 4, key: 'subventas', ksz: 14, tsz: 14 },
        { title: '%', size: 3, key: 'subprocentaje', ksz: 11, tsz: 11 },
        { title: 'Premios', size: 4, key: 'subpremios', ksz: 14, tsz: 14 },
        { title: 'Diferencia', size: 4, key: 'diferencia', ksz: 14, tsz: 14 },
        { title: 'Participa', size: 3, key: 'participa', ksz: 11, tsz: 11 }
      ]
    }
  }

  settingReport = (tpoRpt) => {
    switch (tpoRpt) {
      case 1:
        return (
          <Reporte1 ref={(el) => (this.componentRef = el)} {...this.state} {...this.props} converInt={this.converInt} />
        )

      default:
        return (
          <Reporte2 ref={(el) => (this.componentRef = el)} {...this.state} {...this.props} converInt={this.converInt} />
        )
    }
  }

  converInt = (data) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(data)
  }

  render() {
    var { tpoRpt } = this.props

    return (
      <Row>
        <Row className="rpt-Row-BtnPrin" type="flex" justify="end">
          <Col>
            <ReactToPrint
              trigger={() => <Button type="primary">Imprimir Reporte</Button>}
              content={() => this.componentRef}
            />
          </Col>
        </Row>
        <Row>
          <Col>{this.settingReport(tpoRpt)}</Col>
        </Row>
      </Row>
    )
  }
}
// </Text>
// <Text style={styles.subtitle} fixed>
//    Desde:{this.props.desde}       Hasta:{this.props.hasta}
// </Text>
//     {tpoRpt===1?
//     this.bodyreporTb(bodyRepor)
//     :
//     this.bodyreporTb2(bodyRepor)}
// </View>
// </Page>
// </Document>
// </PDFViewer>

// <Table
// data={rbodyRepor}
// >
// <TableHeader style={styles.h1}>

// </TableHeader>
// <TableBody>
//     <DataTableCell getContent={(r) => r.idc}/>
//     <DataTableCell getContent={(r) => r.tsubventas}/>
//     <DataTableCell getContent={(r) => r.tsubprocentaje}/>
//     <DataTableCell getContent={(r) => r.tsubpremios}/>
//     <DataTableCell getContent={(r) => r.tdiferencia}/>
//     <DataTableCell getContent={(r) => r.tparticipa}/>
// </TableBody>
// </Table>

// </Row>

export default cloudReporteCreate
