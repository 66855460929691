import { useState, useEffect } from "react";
import queryAllTopes from "../service/getTopesGeneral";
import {
	queryAllTopesAmericana,
	queryAllTopesDeportes,
	queryAllTopesLoterias,
	queryAllTopesNacionales,
	cnfNacionalesFn,
} from "../service/getTopesList";

export function useTopesGeneral() {
	const [topesGeneral, setTopesGeneral] = useState([]);
	const [stateTopesNacionales, setstateTopesNacionales] = useState([]);
	const [stateOptionsNacional, setstateOptionsNacional] = useState([]);
	const [stateCnfHipo, setstateCnfHipo] = useState({RecordHipi:[],Ifield:[]});

	//***************************/
	/// TOPES GENERALES Query/UseEffect
	const updateQueryAllTopes = () => {
		return queryAllTopes()
			.then((result) => {
				setTopesGeneral(result);
				return result;
			})
			.catch((error) => console.log(error));
	};
	useEffect(() => {
		updateQueryAllTopes();
	}, [topesGeneral]);
	//***************************/
	/// DEPORTES Query
	const updateQueryDeportes = () => {
		return queryAllTopesDeportes()
			.then(value=>{return value})
			.catch((error) => console.log(error));
	};
	///***************************/
	/// AMERICANAS Query
	const updateQueryAmericana = () => {
		return queryAllTopesAmericana()
			.then(value=>{return value})
			.catch((error) => console.log(error));
	};
	///***************************/
	/// NACIONALES Query
	const updateQueryNacionales =() => {
		 return queryAllTopesNacionales()
			.then((data) => {
				let resolver = [];
				data.forEach((value) => {
					let addRecord = [];
					let { jSONcnf } = value;
					if (jSONcnf !== "0") {
						let jSONcnf = JSON.parse(value.jSONcnf);
						for (let rows in jSONcnf) {
							let ix = jSONcnf[rows];
							let { DividendosMaximos, DividendoBanca, CuposMaxi } = ix;
							let idx = ix.idhipo;
							addRecord[`DividendosMaximos_${idx}`] = Number(DividendosMaximos);
							addRecord[`DividendoBanca_${idx}`] = Number(DividendoBanca);
							addRecord[`CuposMaxi_${idx}`] = Number(CuposMaxi);
						}
					}
					resolver.push({
						...value,
						...addRecord,
					});
				});
				setstateTopesNacionales(resolver)
				return (resolver);
			})
			.catch((error) => console.log(error));
	}

	/*** Query Adicionales de NACIONALES */
	useEffect(() => {
		cnfNacionalesFn()
			.then(value=>{
				const {cnfHipo}=value
				setstateOptionsNacional(value)
				onReconstField(cnfHipo)
			})
			.catch((error) => console.log(error));
	}, [stateOptionsNacional]);
	//** Reconstruccion de JSON NACIONALES */
	const onReconstField = (recordHipo) => {
		let Ifield = [];
		let RecordHipi = [];
		let temp = [];
		for (var rows in recordHipo) {
			temp.push("DividendoBanca_" + recordHipo[rows].idhipo);
			temp.push("CuposMaxi_" + recordHipo[rows].idhipo);
			temp.push("DividendosMaximos_" + recordHipo[rows].idhipo);
			RecordHipi.push({
				id: recordHipo[rows].idhipo,
				texto: recordHipo[rows].texto,
				siglas: recordHipo[rows].siglas,
			});
			Ifield.push(temp);
			temp = [];
		}
		setstateCnfHipo({RecordHipi,Ifield})
	};
	///********************/
	/// LOTERIAS Query/UseEffect
	const updateQueryLoterias=()=>{
		return queryAllTopesLoterias()
			.then(value=>{return value})
			.catch((error) => console.log(error));
	};

	return {
		topesGeneral,
		stateTopesNacionales,
		stateOptionsNacional,
		stateCnfHipo,
		updateQueryAllTopes,
		updateQueryDeportes,
		updateQueryAmericana,
		updateQueryNacionales,
		updateQueryLoterias
	};
}
