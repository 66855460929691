import React,{ Component } from "react";
import {Button,List,Icon,Row,Col,InputNumber, Input,Table,Form,Radio,Popconfirm,Select} from "antd";
import   './cloudtlrestricciones.scss'; 
import store from './store';
var _ =  require('lodash');
const ButtonGroup = Button.Group;
const { Option } = Select;
const FormItem = Form.Item;
var id_select;
const EditableContext = React.createContext();
const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);
class EditableCell extends React.Component {
    state = {
        editing: false,
    }

    toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
        if (editing) {
            this.input.focus();
        }
        });
    }

    save = (e) => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
        if (error && error[e.currentTarget.id]) {
            return;
        }
        this.toggleEdit();
        handleSave({ ...record, ...values });
        });
    }

    render() {
        const { editing } = this.state;
        const {
        editable,
        dataIndex,
        title,
        record,
        index,
        handleSave,
        ...restProps
        } = this.props;
        return (
        <td {...restProps}>
            {editable ? (
            <EditableContext.Consumer>
                {(form) => {
                this.form = form;
                return (
                    editing ? (
                    <FormItem style={{ margin: 0 }}>
                        {form.getFieldDecorator(dataIndex, {
                        rules: [{
                            required: true,
                            message: `${title} is required.`,
                        }],
                        initialValue: record[dataIndex],
                        })(
                        <Input
                            ref={node => (this.input = node)}
                            onPressEnter={this.save}
                            onBlur={this.save}
                        />
                        )}
                    </FormItem>
                    ) : (
                    <div
                        className="editable-cell-value-wrap"
                        style={{ paddingRight: 24 }}
                        onClick={this.toggleEdit}
                    >
                        {restProps.children}
                    </div>
                    )
                );
                }}
            </EditableContext.Consumer>
            ) : restProps.children}
        </td>
        );
    }
    }
    // var seleccionRow;
    const rowSelection = {
        onChange: (selectedRowKeys) => {
           return selectedRowKeys;
        },
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }),
    };
class FromRestricciones extends Component{
    constructor(props) {
        super(props);
        this.state = {
            id:-1,
            idSelAp:0,
            type:0,
            Numero:-1,
            Monto:0,
            selec:0,
            dataSource:[],
            lstApuestas:[{id:1,text:'Numero+Combinacion',type:1},{id:2,text:'Animalitos',type:2}],
            lstAdd:[{lisID:1,list:[{id:1,text:'Aries'},{id:2,text:'Virgo'}]},{lisID:2,list:[{id:1,text:'Perro'},{id:2,text:'Gatos'}]}],
            columns:[ {
                title: 'id',
                dataIndex: 'key',
                width: '5%',
                editable: false,
            },{
                title: 'Apuesta',
                dataIndex: 'apuesta',
                width: '65%',
                editable: false,
            }, {
                title: 'Monto Restrigido',
                dataIndex: 'monto',
                width: '30%',
                editable: true,
            }],
        }
    }
    handleSave = (row) => {
        var  newData;
        
        newData = [...this.state.dataSource];

        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });

        this.setState({ dataSource: newData });
    }
    handleAdd = (combi) => {
            const {  dataSource } = this.state;
            
            var count=dataSource.length;
            const newData = {
                key: count+1,
                apuesta:combi.apuesta,
                monto: combi.Monto,

            };
            this.setState({
                dataSource: [...dataSource, newData]
            });
        
       
    }
    ClickChgCmbap=(id)=>{
        var {lstApuestas}=this.state;
        var idx=_.findIndex(lstApuestas, function(o) { return o.id === id; });
        var {type}=lstApuestas[idx];
        this.setState({type,idSelAp:id});
    }
    handleNumberChange = (currency,tipo) => {
        switch (tipo){
            case 1: 
                this.setState({ Numero:currency.target.value });
                break;
            case 2: 
                this.setState({ Monto:currency.target.value });
                break;
            default: 
        }
    }
    handleCurrencyChange = (selec,tipo)=>{
        this.setState({selec})
    }
    AddClick=()=>{
        var { selec,Numero,idSelAp,lstAdd,Monto,type} = this.state;
        var idx=_.findIndex(lstAdd, function(o) { return o.lisID === idSelAp; });
        var lstAdd_n1=lstAdd[idx].list;
        idx=_.findIndex(lstAdd_n1, function(o) { return o.id === selec; });
        var val_txt='';
        if (type===1){
            if (Numero==='-1') val_txt=lstAdd_n1[idx].text;
            else  val_txt=Numero+'-'+lstAdd_n1[idx].text;
        }else
            val_txt=lstAdd_n1[idx].text;
        
        this.handleAdd({apuesta:val_txt,Monto});
        this.setState({type:0,selec:0,Monto:0,Numero:-1})
    }
    render(){
        const {lstApuestas,type,dataSource,lstAdd,idSelAp}=this.state;
        const { getFieldDecorator} = this.props.form;
        var lstSideA=[];
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
            };
            const columns = this.state.columns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: this.handleSave,
                }),
            };
            });
            var idx=_.findIndex(lstAdd, function(o) { return o.lisID === idSelAp; });
            if (idx!==-1) lstSideA=lstAdd[idx].list;
            
        return(
            <div>
                <Form className="FromTransDB" >
                    <FormItem label="Maximo de Ventas por loteria:" className="FromiTemone_rp">
                            {getFieldDecorator('nombre', {initialValue:0,
                            rules: [{required: false, message: 'Por favor requiero la Descripcion de la Apuesta!'}],
                            })(
                                <InputNumber placeholder="Maximo de Venta " />
                            )}
                    </FormItem>
                    <FormItem label="Agregar combinaciones de apuestas:" className="FromiTemone_rp">
                        <Radio.Group  buttonStyle="solid" >
                            {lstApuestas.map((value,index)=>
                                <Radio.Button key={index} value={value.id} onClick={(e)=>this.ClickChgCmbap(value.id)}>{value.text}</Radio.Button>
                            )}
                        </Radio.Group>
                    </FormItem>
                    {type===1 &&
                    <FormItem label="Adicionar Restriccion:" className="FromiTemone_rp">
                            <div>
                                <Input
                                type="text"
                                placeholder="Numero"
                                onChange={(e)=>this.handleNumberChange(e,1)}
                                style={{ width: '20%',marginRight: '5px'}}
                                />
                                <Select
                                placeholder="Combinado"
                                style={{ width: '30%',marginRight: '5px' }}
                                onChange={(e)=>this.handleCurrencyChange(e,1)}
                                >
                                    {lstSideA.map((value,index)=>
                                        <Option   key={index} value={value.id}>{value.text}</Option>
                                    )}
                                </Select>
                                <Input
                                type="Number"
                                placeholder="Maximo"
                                onChange={(e)=>this.handleNumberChange(e,2)}
                                style={{ width: '20%',marginRight: '10px' }}
                                />
                                <Button type="primary" onClick={()=>this.AddClick()}>Agregar</Button>
                            </div>
                    </FormItem>}
                    {type===2 &&
                    <FormItem label="Adicionar Restriccion:" className="FromiTemone_rp">
                            <div>
                                <Select
                                placeholder="Combinado"
                                style={{ width: '30%',marginRight: '5px' }}
                                onChange={(e)=>this.handleCurrencyChange(e,2)}
                                >
                                    {lstSideA.map((value,index)=>
                                        <Option key={index} value={value.id}>{value.text}</Option>
                                    )}
                                </Select>
                                <Input
                                type="Number"
                                placeholder="Maximo"
                                onChange={(e)=>this.handleNumberChange(e,2)}
                                style={{ width: '20%', marginRight: '10px' }}
                                />
                                <Button type="primary" htmlType="submit" onClick={()=>this.AddClick()}>Agregar</Button>
                            </div>
                    </FormItem>}
                    <FormItem label="Lista de Restricciones">
                        <div className='FromiTemLista_rp'>
                            <div className="barrwdw_rp">
                            <ButtonGroup >
                                    <Button className="ant-btn-primary_rp"  type="primary" onClick={()=>this.handleDelete()} ><Icon type="minus" /></Button>
                            </ButtonGroup>
                            </div>
                            <Table
                                components={components}
                                rowClassName={() => 'editable-row'}
                                bordered
                                dataSource={dataSource}
                                columns={columns}
                                rowSelection={rowSelection}
                                pagination={{ pageSize: 50 }} 
                                scroll={{ y: 180 }}
                            />
                        </div>
                    </FormItem>
                    <FormItem >
                    <Row gutter={20}>
                        <Col span={10}>
                            <Button type="primary" onClick={(e)=>this.handleWebsiteChange()}>{this.state.id===-1?'Grabar':'Modificar'}</Button>
                        </Col>
                    </Row>
                    </FormItem>
                </Form>
            </div>
        )
    }

}
const EnhancedFromRestricciones = Form.create()(FromRestricciones);

class MainltRestricciones extends Component{
    constructor(props) {
        super(props);
        this.state = {
            id_mark:0,
            insider:'',
            collapsed:false,
        }
        store.subscribe(() => {
            this.setState({
                insider:store.getState().insider,
            })
        })
    }
    commandBarr=(type)=>{
        switch(type){
            case -1:
                store.dispatch({ type: 'IN_data_insider',insider:<EnhancedFromRestricciones id={type}/>});
                break;
         
            default:
        }
    }
render(){
    return(
        <div className="mainwdw_rp">
            <div className="barrwdw_rp">
                <ButtonGroup >
                        <Button className="ant-btn-primary_rp" type="primary" onClick={()=>this.commandBarr(-1)} ><Icon type="plus" /></Button>
                        <Popconfirm title="Esta Seguro de Borrar esta configuracion de Apuesta?" onConfirm={()=>this.commandBarr(-2)}  okText="Borrar" cancelText="Cancelar">
                            <Button className="ant-btn-primary_rp" type="primary" ><Icon type="minus" /></Button>
                        </Popconfirm >
                        <Button  className="ant-btn-primary_rp" type="primary"><Icon type="diff" /></Button>
                </ButtonGroup>
                <div className="barrwdwtitle">
                    <label className="barrwdwtext"><Icon type="deployment-unit" />&nbsp;Restricciones</label>
                </div>
            </div>
            <Row gutter={2}>
                <Col span={9} >
                    <div className='mainList_rp'>
                        <List
                                itemLayout="vertical"
                                size="small"
                                locale={{ emptyText:<label style={{color:'#fff'}}> <Icon type="delete" style={{fontSize: '22px',color:'#fff'}}/>No hay Informacion</label> }} 
                                dataSource={this.state.listData}
                                renderItem={(item,index) => (
                                <List.Item
                                    className={id_select===index?"listStyItem":''}
                                    key={item.title}
                                    onClick={()=>this.onClickMainList(item.id,index)}
                                    >
                                    <List.Item.Meta
                                    title={<label className="mainLabletitle">{item.title}</label>}
                                    description={<label className="mainLabledescrp">{item.description}</label>}
                                    />
                                </List.Item>
                                )}
                            />
                    </div>
                </Col>
                <Col span={9} >
                    <div className='mainContent'>
                        {this.state.insider}
                    </div>
                </Col>
            </Row>
        </div>
    )
}
}
export default MainltRestricciones;