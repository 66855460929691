import { useState } from "react";
import {
	queryRecordCuentasFn,
	queryHistoryCuentasFn,
} from "../service/getTransacciones";

export function useTranssaccion() {
	const [stateLodding, setstateLodding] = useState(false);
	const updateQueryTranssacciones = (idPRV) => {
		return queryRecordCuentasFn(idPRV.toString())
			.then((result) => {
				return result;
			})
			.catch((error) => console.log(error));
	};

	const updateQueryPendientes = (idPRV, iu, modo, limited_min) => {
		setstateLodding(false);
		return queryHistoryCuentasFn(idPRV.toString(), iu, modo, limited_min)
			.then((result) => {
				setstateLodding(true);
				return result;
			})
			.catch((error) => {
				setstateLodding(true);
				console.log(error);
			});
	};

	return { stateLodding, updateQueryTranssacciones, updateQueryPendientes };
}
