import React from 'react'
import { message, Icon, notification } from 'antd'
import moment from 'moment'
import { errorServerMessage } from './compenent/errorMensaje'
import client from './conex'
import { gql } from '@apollo/client'

import { hashGet, hashSet } from './service/general'

import store from './store'
var _ = require('lodash')
var bcrypt = require('bcryptjs')
var nJwt = require('njwt')
var ip = require('ip')
const encode = require('nodejs-base64-encode')

const queryApuestalt = gql`
  query viewLtApuestaLt {
    viewLtApuestaLt {
      id
      title
      description
      idap
      data
    }
  }
`
const queryLoterialt = gql`
  query viewLtLoteriaLt {
    viewLtLoteriaLt {
      id
      title
      etiqueta
      seleccion_ap
      seleccion_apwid {
        text
        ida
      }
      premiacion {
        id
        PremioxAp
        lismark {
          dig
          ltr
        }
      }
      sorteo {
        key
        hora
        descri
      }
      horario {
        key
        hora
      }
    }
  }
`
const queryLoterialt_componet = gql`
  query viewLtLoteriaLt {
    loterias: viewLtLoteriaLt {
      id
      title
      etiqueta
      seleccion_ap
      seleccion_apwid {
        text
        ida
      }
      premiacion {
        id
        PremioxAp
        lismark {
          dig
          ltr
        }
      }
      sorteo {
        key
        hora
        descri
      }
      horario {
        key
        hora
      }
    }
    premios: viewLtlstapuestas {
      id
      text
      type
      leng
      combi
    }
    apuestas: viewLtApuestaLt {
      id
      title
      description
      idap
      data
    }
  }
`
const queryLtlstapuestas = gql`
  query viewLtlstapuestas {
    viewLtlstapuestas {
      id
      text
      type
      leng
      combi
    }
  }
`
const mutationApuestalts = gql`
  mutation muttationApuestaLt($input: apuestalt, $operation: Int) {
    muttationApuestaLt(input: $input, operation: $operation) {
      listApuesta {
        id
        title
        description
        idap
        data
      }
      responder
    }
  }
`

const muttationLoteriaLt = gql`
  mutation muttationLoteriaLt($input: loterialt, $operation: Int) {
    muttationLoteriaLt(input: $input, operation: $operation) {
      listLoteria {
        id
        title
        etiqueta
        seleccion_ap
        premiacion {
          id
          PremioxAp
          lismark {
            dig
            ltr
          }
        }
        sorteo {
          key
          hora
          descri
        }
        horario {
          key
          hora
        }
      }
      responder
    }
  }
`
const viewLtGroupPoint = gql`
  query viewLtGroupPoint {
    viewLtGroupPoint {
      dataList {
        title
        key
      }
      gData {
        title
        key
        responsable
        participacionPorcen
        ventasPorcentaje
        children {
          title
          key
          id
          participacionPorcen
          ventasPorcentaje
          ticketanular
          telefono
          prefix
          idg
        }
      }
    }
  }
`
const muttationGroup = gql`
  mutation muttationGroup($input: recordGroup, $operation: Int) {
    muttationGroup(input: $input, operation: $operation) {
      dataList {
        title
        key
      }
      gData {
        title
        key
        responsable
        participacionPorcen
        ventasPorcentaje
        children {
          title
          key
          id
          participacionPorcen
          ventasPorcentaje
          ticketanular
          telefono
          prefix
          idg
        }
      }
      responder
    }
  }
`
const muttationPoint = gql`
  mutation muttationPoint($input: recordPoint, $operation: Int) {
    muttationPoint(input: $input, operation: $operation) {
      dataList {
        title
        key
      }
      gData {
        title
        key
        responsable
        participacionPorcen
        ventasPorcentaje
        children {
          title
          key
          id
          participacionPorcen
          ventasPorcentaje
          ticketanular
          telefono
          prefix
          idg
        }
      }
      responder
    }
  }
`
const view_blockPointVentas = gql`
  query view_blockPointVentas($idc: String!) {
    view_blockPointVentas(idc: $idc) {
      id
      ida
      idl
      premio
      ventasmaxima
    }
  }
`
const muttation_blockPointVentas = gql`
  mutation muttation_blockPointVentas($input: _blockPointVentas, $operation: Int) {
    muttation_blockPointVentas(input: $input, operation: $operation) {
      dataList {
        id
        ida
        idl
        premio
        ventasmaxima
      }
      responder
    }
  }
`
const listado_lstApuestas = gql`
  query listado_lstApuestas($idl: Int) {
    listado_lstApuestas(idl: $idl) {
      lstApuestas {
        id
        text
        type
        idals
      }
      lstAdd {
        lisID
        ida
        list {
          key
          name
        }
      }
    }
  }
`
const muttation_Apuestas = gql`
  mutation muttation_Apuestas($input: blockAp, $operation: Int) {
    muttation_Apuestas(input: $input, operation: $operation)
  }
`
const listado_blockAp = gql`
  query listado_blockAp($idc: String, $idl: Int) {
    listado_blockAp(idc: $idc, idl: $idl) {
      MaximoVenta
      blockAp {
        key
        apuesta
        monto
        ida
        numero
        lstAddKey
        ikey
      }
    }
  }
`
const muttationResultadosLt = gql`
  mutation muttationResultadosLt($input: recordResultados) {
    muttationResultadosLt(input: $input)
  }
`
const view_blockResuladosLt = gql`
  query view_blockResuladosLt($fecha: String, $idl: Int) {
    view_blockResuladosLt(fecha: $fecha, idl: $idl) {
      id
      idl
      idj
      json
    }
  }
`

const queryviewTickets = gql`
  query viewTicketsGn($fecha: String, $tipo1: Int, $tipo2: Int, $tipo3: Int, $tipo4: Int, $sistem: [Int]) {
    sinprocesar: viewTicketsGeneral(fecha: $fecha, tipo: $tipo1, sistem: $sistem) {
      key
      serial
      fecha
      hora
      apuesta
      isys
      isys_Desc
      cobra
      se
      idc
      ip
      idusu
      moneda
    }

    posibleganar: viewTicketsGeneral(fecha: $fecha, tipo: $tipo2, sistem: $sistem) {
      key
      serial
      fecha
      hora
      apuesta
      isys
      isys_Desc
      cobra
      se
      idc
      ip
      idusu
      moneda
    }

    posibleganador: viewTicketsGeneral(fecha: $fecha, tipo: $tipo3, sistem: $sistem) {
      key
      serial
      fecha
      hora
      apuesta
      isys
      isys_Desc
      cobra
      se
      idc
      ip
      idusu
      moneda
    }

    anulados: viewTicketsGeneral(fecha: $fecha, tipo: $tipo4, sistem: $sistem) {
      key
      serial
      fecha
      hora
      apuesta
      isys
      isys_Desc
      cobra
      se
      idc
      ip
      idusu
      moneda
    }
  }
`
const queryviewOneTicket = gql`
  query viewOneTicket($serial: Int) {
    viewOneTicket(serial: $serial) {
      serial
      idc
      fecha
      hora
      apuesta
      isys
      cobra
      se
      status
      moment
      detalle {
        partido
        hora
        equipo
        apuesta
        odd
        escrute
      }
    }
  }
`
const queryviewOneTicketLot = gql`
  query viewOneTicketLot($serial: Int) {
    viewOneTicketLot(serial: $serial) {
      serial
      idc
      fecha
      hora
      total
      idj
      estatus
      se
      isys
      cobra
      moment
      detalle {
        ln
        numeros
        monto
        loteria
        sorteo
        escrute
      }
    }
  }
`
const queryviewOneTicketNac = gql`
  query viewOneTicketNac($serial: Int) {
    viewOneTicketNac(serial: $serial) {
      serial
      idc
      fecha
      hora
      apuesta
      ValorR
      hipo
      carr
      IDCN
      estatus
      cobra
      se
      isys
      tipo
      moment
      detalle {
        eje
        text
        g
      }
    }
  }
`
const queryviewOneTicketAme = gql`
  query viewOneTicketAme($serial: Int) {
    viewOneTicketAme(serial: $serial) {
      serial
      idc
      fecha
      hora
      apuesta
      ValorR
      hipo
      carr
      IDCN
      estatus
      se
      isys
      cobra
      tipo
      moment
      detalle {
        eje
        text
        g
        p
        s
      }
    }
  }
`
const mutationanulOneTicket = gql`
  mutation anulOneTicket($serial: Int, $iu: Int, $ip: String) {
    anulOneTicket(serial: $serial, iu: $iu, ip: $ip) {
      res
      text
    }
  }
`
const mutationanulOneTicketAme = gql`
  mutation anulOneTicketAme($serial: Int, $iu: Int, $ip: String) {
    anulOneTicketAme(serial: $serial, iu: $iu, ip: $ip) {
      res
      text
    }
  }
`
const mutationanulOneTicketNac = gql`
  mutation anulOneTicketNac($serial: Int, $iu: Int, $ip: String) {
    anulOneTicketNac(serial: $serial, iu: $iu, ip: $ip) {
      res
      text
    }
  }
`
const mutationanulOneTicketLot = gql`
  mutation anulOneTicketLoteria($serial: Int, $iu: Int, $ip: String) {
    anulOneTicketLoteria(serial: $serial, iu: $iu, ip: $ip) {
      res
      text
    }
  }
`
const viewNacionalesDTA = gql`
  query viewNacionalesDTA($dia: String, $tipo: Int, $idcn: Int) {
    viewNacionalesDTA(dia: $dia, tipo: $tipo, idcn: $idcn) {
      idHipo
      IDCN
      Descripcion
      siglas
      CantCarr
      Fecha
      estatus
      moment
      Ejemplares {
        Carrera
        cantEje
        horse {
          Noeje
          Nombre
          status
        }
      }
      JugadaEspecial
      RowSetting {
        i
        cantidad
        DateJnd
        start
        scratch
        exacta
        trifec
        superfec
      }
      Condiciones
    }
  }
`

export async function viewNacionalesDTAFn(dia, id, newreg) {
  await client
    .query({
      query: viewNacionalesDTA,
      variables: { dia, tipo: 2 }
    })
    .then(async ({ data }) => {
      let { viewNacionalesDTA } = data
      if (viewNacionalesDTA.length !== 0) {
        let confJnd = viewNacionalesDTA
        if (id === 0) {
          const { listData } = this.state
          const elementsIDCNcaptado = listData.map(({ id }) => id)
          confJnd = viewNacionalesDTA.filter(({ IDCN }) => !elementsIDCNcaptado.includes(IDCN))
          console.log(confJnd)
          if (confJnd.length === 0 && viewNacionalesDTA.length !== 0) {
            message.error('Disculpe, pero ya no hay Jornada NUEVAS que configurar por hoy')
            this.setState({ inside: '', addNewConfigPollaBtn: true })
            return true
          }
          var { IDCN } = confJnd[0]
          if (IDCN === 0) {
            message.error('Disculpe, pero no hay jornadas activa para este dia')
          }
        } else {
          IDCN = id
        }

        this.setState({
          confJnd,
          newreg
        })
        this.queActSycn(IDCN, 1)
      }
      //  message.success('La Informacion fue Actualizada..!');
    })
    .catch((error) => {
      console.log(error)
      errorServerMessage()
      this.setState({ inside: <div></div> })
      return false
    })
}
export function viewNacionalesDTAFnX2(dia, id, newreg) {
  console.log(id)
  client
    .query({
      query: viewNacionalesDTA,
      variables: { idcn: id, tipo: 2 }
    })
    .then(async ({ data }) => {
      var { viewNacionalesDTA } = data
      var confJnd = viewNacionalesDTA[0]
      if (confJnd.length !== 0) {
        var { idHipo, IDCN, Ejemplares, CantCarr, Fecha, estatus, JugadaEspecial, RowSetting } = confJnd
        console.log(Ejemplares)
        var xmoment = confJnd.moment
        var SavaJndEspecial = JSON.parse(JugadaEspecial)
        var RowsSetting = []
        /**
         * Inicializacion de la Configuracion de Jugada Especial
         * ! JndEspecial
         * ? SavaJndEspecial= Son los datos enviado por Graphql
         */
        var { JndEspecial } = this.state
        for (const x in JndEspecial) JndEspecial[x].Cantidad = 0 ///Inicio las cantidades de Jugadas Especial
        /** Busco en SavaJndEspecial las Cantidades por tipo  de las Jugadas Especiales */
        for (const x in SavaJndEspecial) {
          let { Tipo } = SavaJndEspecial[x]
          var idxF = _.findIndex(JndEspecial, (o) => {
            return o.Tipo === Tipo
          })
          if (idxF !== -1) JndEspecial[idxF].Cantidad++
        }
        /** Fint de la Configuracion de Jugada Especial  */
        for (var x in RowSetting) {
          var { i, cantidad, DateJnd, start, scratch, exacta, trifec, superfec } = RowSetting[x]
          if (scratch.length === 1) {
            if (scratch[0] === 0) scratch = []
          }
          RowsSetting.push({
            i,
            cantidad,
            DateJnd: moment(moment().format(DateJnd), 'DD/MM/YYYY'),
            start: moment(start, 'HH:mm'),
            scratch,
            exacta,
            trifec,
            superfec
          })
        }
        if (id !== 0) {
          idHipo = confJnd.idHipo
        } else {
          idHipo = 1
          IDCN = id
          console.log('nuevo')
        }
        var SavaJndGeneral = {
          Fecha: moment(moment().format(Fecha), 'DD/MM/YYYY'),
          IDhipo: idHipo,
          IDCN,
          estatus,
          moment: _.toNumber(xmoment),
          carreras: CantCarr,
          especial: SavaJndEspecial,
          RowsSetting
        }
        this.setState({
          IDhipo: idHipo,
          estatus,
          SavaJndGeneral,
          RowsSetting,
          SavaJndEspecial,
          JndEspecial,
          confJnd,
          newreg,
          Ejemplares
        })
      }
      this.refresSync()
    })
    .catch(() => {
      errorServerMessage()
      this.setState({ inside: <div></div> })
      return false
    })
}
const queryCnfPollaAdm = gql`
  query allSettingPolla($IDCN: Int!) {
    cnf: queryCnfPollaAdm(IDCN: $IDCN) {
      IDCN
      activo
      precio
      store
      settiRet
      settiFab
      config
      premio
      pSpecial
      dolar
      fecha
    }
    monedas: monedas {
      id
      moneda
      text: descripcion
    }
    premiodefault:getListPremioDefault{
      str
      data {
            id
            tipo
            monto 
          }
    }
  }
`
export async function queryCnfPollaAdmFN(IDCN, op) {
  await client
    .query({
      query: queryCnfPollaAdm,
      variables: { IDCN }
    })
    .then(async ({ data }) => {
      let { cnf, monedas,premiodefault } = data
      if (cnf.length !== 0) {
        if (op === 1){
          const premio  = JSON.parse(cnf.premio)
          const pSpecial  = JSON.parse(cnf.pSpecial)
          const defaPremio = JSON.parse(premiodefault.str)
          const setting = {
            ...cnf,
            premio,
            pSpecial
          }
          this.setState({
            setting,defaPremio
          })}
        else return cnf
      }
      this.setState({ monedas })
      //  message.success('La Informacion fue Actualizada..!');
    })
}
const muttation_muttationCnfPollaAdm = gql`
  mutation muttationCnfPollaAdm($Json: String!) {
    muttationCnfPollaAdm(Json: $Json) {
      fecha
      id: IDCN
      description: Hipodromo
      title: fecha
      Hipodromo
      activa
    }
  }
`
export function savePollaJND(setting) {
  message.loading('Espere un momento, por favor..', 100000)
  console.log(setting)
  var Json = JSON.stringify(setting)
  client
    .mutate({
      mutation: muttation_muttationCnfPollaAdm,
      variables: {
        Json
      },
      update: (proxy, data) => {
        message.destroy()
        var listData = data.data.muttationCnfPollaAdm
        let { id } = listData[0]
        if (id > 0) {
          message.success(<Icon type="like" theme="twoTone" />)
          this.setState({ listData })
        } else {
          let messegerError = 'Disculpe, no pude almacenar su configuracion!'
          if (id === -2) messegerError = 'No coloco el Precio de la Polla, en ninguna Modena'
          if (id === -3) messegerError = 'No Configuro Ninguna Carrera para la Polla'
          message.error(messegerError)
        }
      }
    })
    .catch(() => {
      message.destroy()
      errorServerMessage()
    })
}
const query_viewListPollaAdm = gql`
  query viewListPollaAdm {
    viewListPollaAdm {
      fecha
      id: IDCN
      description: Hipodromo
      title: fecha
      Hipodromo
      activa
    }
  }
`
export async function query_viewListPollaAdmFN() {
  await client
    .query({
      query: query_viewListPollaAdm
    })
    .then(async ({ data }) => {
      let { viewListPollaAdm } = data
      if (viewListPollaAdm.length !== 0) {
        this.setState({ listData: viewListPollaAdm })
      }
      //  message.success('La Informacion fue Actualizada..!');
    })
    .catch(() => {
      errorServerMessage()
    })
}

const query_viewListNACAdm = gql`
  query viewListNACAdm($limit: Int!) {
    viewListNACAdm(limit: $limit) {
      fecha
      id: IDCN
      description: Hipodromo
      title: fecha
      Hipodromo
      activa
    }
    Hipodromos: listHipodromosPolla {
      code
      Texto
    }
  }
`
export async function query_viewListNACAdmFN() {
  await client
    .query({
      query: query_viewListNACAdm,
      variables: { limit: 20 }
    })
    .then(async ({ data }) => {
      let { viewListNACAdm, Hipodromos } = data
      if (viewListNACAdm.length !== 0) {
        this.setState({ listData: viewListNACAdm })
      }
      this.setState({ Hipodromos })
      //  message.success('La Informacion fue Actualizada..!');
    })
    .catch(() => {
      errorServerMessage()
    })
}

const queryCnfPollaPrmAdm = gql`
  query queryCnfPolla {
    cnf: queryCnfPollaPrmAdm {
      puestos
      poss {
        id
        puntos
        empate
      }
      premio {
        id
        tipo
        monto
      }
    }
    monedas: monedas {
      id
      moneda
      text: descripcion
    }
  }
`
export async function queryCnfPollaPrmAdmFN() {
  await client
    .query({
      query: queryCnfPollaPrmAdm
    })
    .then(async (data) => {
      var setting = data.data.cnf
      var { monedas } = data.data
      if (setting.length !== 0) this.setState({ setting })

      this.setState({ monedas })
      //  message.success('La Informacion fue Actualizada..!');
    })
}
const muttation_muttationCnfPollaPrmAdm = gql`
  mutation muttationCnfPollaPrmAdm($Json: String!) {
    muttationCnfPollaPrmAdm(Json: $Json)
  }
`
export function savePollaGenJND(setting) {
  message.loading('Espere un momento, por favor..', 100000)
  var Json = JSON.stringify(setting)
  client.mutate({
    mutation: muttation_muttationCnfPollaPrmAdm,
    variables: {
      Json
    },
    update: (proxy, data) => {
      message.destroy()
      var listData = data.data.muttationCnfPollaPrmAdm
      if (listData === 1) message.success(<Icon type="like" theme="twoTone" />)
      else message.error('Disculpe, no pude almacenar su configuracion!')
    }
  })
}
const queryGanadoresGen = gql`
  query queryGanadoresGen($nJnd: Int!) {
    queryGanadoresGen(nJnd: $nJnd) {
      id: IDCN
      title: Fecha
      description: hipodromo
      siglas
      ganadores {
        carr
        lle1
        lle2
        lle3
        confirmado
        llegada
        dividendos
      }
    }
  }
`
export async function queryGanadoresGenFN(nJnd) {
  await client
    .query({
      query: queryGanadoresGen,
      variables: { nJnd }
    })
    .then(async (data) => {
      var listData = data.data.queryGanadoresGen
      if (listData.length !== 0) {
        this.setState({ listData })
      }
    })
    .catch(() => {
      errorServerMessage()
    })
}
////////////////////////////
const muttationChngDiviNac = gql`
  mutation muttationChngDiviNac($input: diviChange) {
    muttationChngDiviNac(input: $input)
  }
`
export function muttationChngDiviNacFn(input) {
  message.loading('Espere un momento, por favor..', 100000)

  return client
    .mutate({
      mutation: muttationChngDiviNac,
      variables: {
        input
      },
      update: (proxy, data) => {
        message.destroy()
        var resp = data.data.muttationChngDiviNacFn
        if (resp === 0) {
          notification.error({
            message: 'Error en Anular',
            placement: 'topLeft',
            description: 'No podemos grabar la informacion!'
          })
        } else message.success(<Icon type="like" theme="twoTone" />)
        return resp
      }
    })
    .catch(() => {
      message.destroy()
      errorServerMessage()
      return false
    })
}
////////////////grabarIU////////////////////
const mutationIU = gql`
  mutation registroUI($input: RecordUsuario) {
    registroUI(input: $input)
  }
`
export function grabarIU(registo, nivel) {
  message.loading('Espere un momento, por favor..', 100000)
  client.mutate({
    mutation: mutationIU,
    variables: {
      input: registo
    },
    update: (proxy, data) => {
      message.destroy()
      var resp = data.data.registroUI
      if (resp !== '-1') {
        switch (nivel) {
          case 1:
            this.setModal1VisibleCancel(false)
            this.actualizarLista()
            message.success(<Icon type="like" theme="twoTone" />)
            break
          case 2:
            this.initFromApp()
            notification.success({
              message: 'Registro Aceptado',
              description: 'Su registro fue aceptado, ingrese al sistema con sus nuevo usuario! Gracias'
            })
            break
          default:
            break
        }
      } else message.error('Lamentablemente tenemos un error! Verifique!')
    }
  })
}
/////////////////generarClave///////////////////
// const queryGenerarPw = gql`
// 	query newpw($iu: String) {
// 		newpw(iu: $iu) {
// 			iu
// 			pw
// 		}
// 	}
// `;
export function generarClave() {
  const newpwCl = '0000000'
    .replace(/0/g, function () {
      return (0 | (Math.random() * 16)).toString(10)
    })
    .substr(0, 5)

  this.props.tthis.setClaveNueva(newpwCl)
}
// client
// 	.query({
// 		variables: { iu: "-1" },
// 		query: queryGenerarPw,
// 	})
// 	.then((data) => {
// 		var nuevaClave = data.data.newpw[0].pw;
// 		var pasww = JSON.parse(encode.decode(nuevaClave, "base64"));
// 		this.props.tthis.setClaveNueva(pasww);
// 	});
// }
///////////////queryviewBancosFn/////////////////////
const queryviewBancos = gql`
  query queryAllData {
    viewBancos: viewBancos {
      key
      Text
    }
    monedas: monedas {
      id
      moneda
      text: descripcion
    }
  }
`
export function queryviewBancosFn() {
  client
    .query({
      query: queryviewBancos
    })
    .then((data) => {
      var { viewBancos, monedas } = data.data
      this.setState({ bancos: viewBancos, monedas })
    })
}
///////////////viewListIU/////////////////////
const queryViewIU = gql`
  query viewIU($key: Int, $iu: Int, $like: String, $idPRV: String) {
    viewIU(key: $key, iu: $iu, like: $like, idPRV: $idPRV) {
      iu
      Usuario
      Nombre
      email
      tipo
      identif
      Moneda
      idioma
      activo
      Deporte
      Americana
      Nacionales
      Loteria
      tope
      pago
      pw
      ticket
      fn
      tc
      banco
      cb
      ntlf
      nmsg
      avatar
      cscDeporte
      cscAmericana
      cscNacionales
      cscLoteria
      AnulDeporte
      AnulAmericana
      AnulNacionales
      AnulLoteria
      modedepor
      receptor
    }
  }
`
export function viewListIU(key, like, idPRV) {
  /// key 1: Usuario de Venta Directa o taquilla
  /// key 2: lo Super Administradores
  /// key 3: Los registrados pero no activos! se van a verificar si no hay data en
  client
    .query({
      variables: { key, like, idPRV },
      query: queryViewIU
    })
    .then((data) => {
      var queryViewIU = data.data.viewIU
      this.setDataIU(queryViewIU, key)
    })
    .catch(() => {
      errorServerMessage()
    })
}
///////////////viewListIU/////////////////////
const queryviewIUCnfg = gql`
  query viewIUCnfg($idPRV: String) {
    viewIUCnfg(idPRV: $idPRV) {
      indice
      lstiu {
        iu
        avatar
        Usuario
        Nombre
        Moneda
        porcentaje
        participa
        activo
        cscDeporte
        cscAmericana
        cscNacionales
        cscLoteria
      }
    }
  }
`
export function queryviewIUCnfgFn(idPRV) {
  client
    .query({
      variables: { idPRV: idPRV.toString() },
      query: queryviewIUCnfg
    })
    .then((data) => {
      var { viewIUCnfg } = data.data
      this.setState({ viewIUCnfg })
    })
    .catch(() => {
      errorServerMessage()
    })
}
////////////////queryviewCscAllFn////////////////////
const queryviewCscAll = gql`
  query viewCscAll {
    viewCscAll {
      ListaDeporte {
        IDC
        Text
        Grupo
      }
      ListaAmericana {
        IDC
        Text
        Grupo
      }
      ListaNacionales {
        IDC
        Text
        Grupo
      }
    }
  }
`
export function queryviewCscAllFn() {
  client
    .query({
      query: queryviewCscAll
    })
    .then((data) => {
      var resp = data.data.viewCscAll
      if (resp.length !== 0) {
        this.setDataIU(resp, 4)
      }
    })
    .catch(() => {
      errorServerMessage()
    })
}
////////////////searchIU////////////////////
const querySearchIU = gql`
  query searchIU {
    searchIU
  }
`
export function searchIU() {
  client
    .query({
      query: querySearchIU
    })
    .then((data) => {
      var searchIU = JSON.parse(encode.decode(data.data.searchIU, 'base64'))
      this.setStatusIU(searchIU)
    })
}
/////////////////queryAllTopes///////////////////
const queryViewTopesGw = gql`
  query viewTopesG {
    viewTopesG {
      tope
      texto
      cnfD
      cnfA
      cnfN
      cnfL
    }
  }
`
export function queryAllTopes() {
  client
    .query({
      query: queryViewTopesGw
    })
    .then((data) => {
      var viewTopesG = data.data.viewTopesG
      //props.setDatalist(viewTopesG)
      this.setDatalist(viewTopesG)
    })
}
////////////////////////////////////

export function mascquertt(modulo, submodulo) {
  var { _PRIVATE } = this.props
  var racceso = [1, 1, 1, 1],
    snivel = []
  if (_PRIVATE.length !== 0) {
    var mmd = this.props._PRIVATE.sbmodule[modulo]
    var smmd = mmd[submodulo]
    var {
      menu: { polla }
    } = _PRIVATE.module
    // var r12=this.props._PRIVATE.sbmodule.sub5["r1-2"];
    racceso = [smmd.deportes, smmd.americana, smmd.nacionales, smmd.loteria]
    if (racceso[0]) snivel.push(0)
    if (racceso[1]) snivel.push(1)
    if (racceso[2]) snivel.push(2)
    if (racceso[3]) snivel.push(3)
  } else {
    snivel = [0, 1, 2, 3]
    polla = false
  }

  return { racceso, snivel, polla }
}
////////////////iloginFnAdmin////////////////////
const queryiLoginAdmin = gql`
  query iloginAdmon($input: String) {
    iloginAdmon(input: $input) {
      res
      text
      key
    }
  }
`

export async function iloginFnAdmin() {
  const { iu, pw, refIu } = this.state
  this.setState({ logginon: true })
  message.loading('Espere un momento, por favor..', 100000)

  const salt = bcrypt.genSaltSync(12)
  const hash = bcrypt.hashSync(_.toString(pw), salt)
  const claims = {
    iu,
    hash
  }
  let decc = codeSend(claims, false)
  client
    .query({
      query: queryiLoginAdmin,
      variables: { input: decc }
    })
    .then((data) => {
      message.destroy()
      var ilogin = data.data.iloginAdmon
      if (ilogin.res === 0) {
        var key = JSON.parse(encode.decode(ilogin.key, 'base64'))
        var { id, nombre, _JSON_PRIVATE, hash } = key
        hashSet(hash)
        this.props.history.replace({
          pathname: '/InitMenu',
          state: { id, iu: nombre, _PRIVATE: _JSON_PRIVATE }
        })
      } else {
        hashSet('0')
        this.setState({ logginon: false })
        refIu.select()
        refIu.focus()
        message.error(ilogin.text)
      }
    })
}
////////////////codeSend////////////////////
export function codeSend(claims, isNotlogin = true) {
  let tk

  if (isNotlogin) {
    const getJti = hashGet()
    if (getJti === null) tk = 'f5a241ad-3dda-4ab2-bdc7-33495f152adb'
    else tk = getJti
  } else {
    tk = 'f5a241ad-3dda-4ab2-bdc7-33495f152adb'
  }

  var jwt = nJwt.create(claims, tk, 'HS256')
  // if (getJti===null)
  //     tk=jwt.body.jti

  var token = jwt.compact()
  var decc = encode.encode(token, 'base64')
  return decc
}

/////////////////muttationSavePartPollaFN///////////////////
const muttationSavePartPolla = gql`
  mutation muttationSavePartPolla($Json: String!) {
    muttationSavePartPolla(Json: $Json)
  }
`
export function muttationSavePartPollaFN(cJson, registro) {
  message.loading('Espere un momento, por favor..', 100000)
  var Json = JSON.stringify(cJson)
  client.mutate({
    mutation: muttationSavePartPolla,
    variables: { Json },
    update: (proxy, data) => {
      message.destroy()
      var { muttationSavePartPolla } = data.data
      if (muttationSavePartPolla !== 0) {
        var { viewIUCnfg } = this.state
        var { indice, iu } = registro
        var { porcentaje, participa } = cJson
        var idx1 = _.findIndex(viewIUCnfg, function (o) {
          return o.indice === indice
        })
        var { lstiu } = viewIUCnfg[idx1]
        var idx2 = _.findIndex(lstiu, function (o) {
          return o.iu === iu
        })
        lstiu[idx2].porcentaje = porcentaje
        lstiu[idx2].participa = participa
        this.setState({ viewIUCnfg })
        message.success(<Icon type="like" theme="twoTone" />)
      } else message.error('Lamentablemente no puedo actualizar su Informacion! ')
    }
  })
}
////////////////////////////////////
// const queryCnfPollaAdm=gql`
// query monedas{
//     monedas{
//       id
//       moneda
//       text:descripcion
//     }
//   }
// `;
// export async function queryCnfPollaAdmFN(IDCN,op){
//     await  client.query({
//             query: queryCnfPollaAdm,
//             variables:{ IDCN },
//     })
//     .then(async (data) =>{
//         var setting=data.data.cnf;
//         var {monedas}=data.data;
//         if (setting.length!==0 )  {
//             if (op===1)
//             this.setState({
//                 setting
//             })
//             else
//                 return setting
//         }
//         this.setState({ monedas })
//       //  message.success('La Informacion fue Actualizada..!');
//     })
// }
const muttaioncaptureCnfJdnNacionales = gql`
  mutation muttaioncaptureCnfJdnNacionales($Json: String!) {
    muttaioncaptureCnfJdnNacionales(Json: $Json) {
      IDCN
      respuesta
    }
  }
`
export function muttaioncaptureCnfJdnNacionalesFN(Json) {
  client
    .mutate({
      mutation: muttaioncaptureCnfJdnNacionales,
      variables: {
        Json
      },
      update: (proxy, data) => {
        var { IDCN } = data.data.muttaioncaptureCnfJdnNacionales
        this.queActSycn(IDCN, 1)
      }
    })
    .catch(() => {
      errorServerMessage()
    })
}

////////////////////////////////////
////////////////////////////////////
export function mutationanulOneTicketFn(serial, iu, isys) {
  message.loading('Espere un momento, por favor..', 100000)
  console.log(serial, iu, isys)
  var mutt
  switch (isys) {
    case 1:
      mutt = mutationanulOneTicket
      break
    case 2:
      mutt = mutationanulOneTicketAme
      break
    case 3:
      mutt = mutationanulOneTicketNac
      break
    case 4:
      mutt = mutationanulOneTicketLot
      break
    default:
  }
  client.mutate({
    mutation: mutt,
    variables: {
      serial: serial,
      iu: iu,
      ip: ip.address()
    },
    update: (proxy, data) => {
      message.destroy()
      var resp
      switch (isys) {
        case 1:
          resp = data.data.anulOneTicket
          break
        case 2:
          resp = data.data.anulOneTicketAme
          break
        case 3:
          resp = data.data.anulOneTicketNac
          break
        case 4:
          resp = data.data.anulOneTicketLoteria
          break
        default:
      }
      if (resp.res === -1) {
        notification.error({
          message: 'Error en Anular',
          placement: 'topLeft',
          description: resp.text
        })
      } else {
        message.success('Ticket Anulado!')
        this.callAutoRmv(serial, isys)
      }
    }
  })
}

export function queryviewOneTicketFn(serial, op) {
  if (op === 1) {
    client
      .query({
        query: queryviewOneTicket,
        variables: { serial: serial }
      })
      .then((data) => {
        var viewOneTickets = data.data.viewOneTicket
        this.setDatalistTk(viewOneTickets, [])
      })
      .catch(() => {
        errorServerMessage()
      })
  }
  if (op === 2) {
    client
      .query({
        query: queryviewOneTicketAme,
        variables: { serial: serial }
      })
      .then((data) => {
        var viewOneTicketAme = data.data.viewOneTicketAme
        this.setDatalistTk(viewOneTicketAme, viewOneTicketAme.detalle)
      })
      .catch(() => {
        errorServerMessage()
      })
  }
  if (op === 3) {
    client
      .query({
        query: queryviewOneTicketNac,
        variables: { serial: serial }
      })
      .then((data) => {
        var viewOneTicketNac = data.data.viewOneTicketNac
        this.setDatalistTk(viewOneTicketNac)
      })
      .catch(() => {
        errorServerMessage()
      })
  }
  if (op === 4) {
    client
      .query({
        query: queryviewOneTicketLot,
        variables: { serial: serial }
      })
      .then((data) => {
        var viewOneTicketLot = data.data.viewOneTicketLot
        /// agrupando para imprimir solo eso detalle del ticket
        var { detalle } = viewOneTicketLot
        var cloteria = '',
          csorteo = '',
          detakk = [],
          display = []
        for (var i in detalle) {
          var { loteria, sorteo, numeros, monto } = detalle[i]
          if (cloteria !== loteria || csorteo !== sorteo) {
            if (cloteria !== '' || csorteo !== '') {
              detakk.push({
                loteria: cloteria,
                sorteo: csorteo,
                display
              })
            }
            cloteria = loteria
            csorteo = sorteo
            display = []
          }
          display.push({
            numeros: `${numeros}x${monto}`
          })
        }
        detakk.push({
          loteria: cloteria,
          sorteo: csorteo,
          display
        })
        this.setDatalistTk(viewOneTicketLot, detakk)
      })
      .catch(() => {
        errorServerMessage()
      })
  }
}
export function queryviewTicketsFn(fecha, tipo, sistem) {
  client
    .query({
      query: queryviewTickets,
      variables: {
        fecha: fecha,
        tipo1: 1,
        tipo2: 2,
        tipo3: 3,
        tipo4: 4,
        sistem
      }
    })
    .then((data) => {
      var viewRecordTickets = data.data
      this.setDatalist(viewRecordTickets, tipo)
      this.setState({ loading: false })
      this.LoadingBar.complete()
    })
}

export async function view_blockResuladosLtFn(fecha, idl) {
  await client
    .query({
      query: view_blockResuladosLt,
      variables: { fecha, idl }
    })
    .then((data) => {
      var repuesta = data.data.view_blockResuladosLt
      var { genPremio } = this.state
      if (repuesta !== null) {
        if (repuesta.length !== 0) {
          var { json, idj } = repuesta[0]
          if (json !== '') {
            var decc = encode.decode(json, 'base64')
            genPremio = JSON.parse(decc)
          }
          this.setState({ genPremio, idj })
        } else this.setState({ genPremio: [], idj: 0 })
      } else {
        this.setState({ genPremio: [], idj: 0 })
        message.error('Disculpe, pero no hay jornada habilitada')
      }
    })
}
export function muttationResultadosLtFn(input) {
  client.mutate({
    mutation: muttationResultadosLt,
    variables: { input },
    update: (proxy, data) => {
      var resp = data.data.muttationResultadosLt
      if (resp === -1 || resp === null) message.error('Disculpe, pero no pude actualizar su solicitud!')
      else if (resp === -2) message.error('Disculpe, pero no hay jornada habilitada')
    }
  })
}
export function listado_blockApFn(idc, idl) {
  client
    .query({
      query: listado_blockAp,
      variables: { idc, idl }
    })
    .then((data) => {
      var listado_blockAp = data.data.listado_blockAp
      if (listado_lstApuestas !== null) {
        var { blockAp, MaximoVenta } = listado_blockAp
        this.setState({
          dataSource: blockAp,
          MaximoVenta
        })
      } else {
        this.setState({
          dataSource: [],
          MaximoVenta: 0
        })
      }
      // message.success('La Informacion fue Actualizada..!');
    })
}
export function muttation_ApuestasFn(input, operation, seleccionRow) {
  client.mutate({
    mutation: muttation_Apuestas,
    variables: { input, operation },
    update: (proxy, data) => {
      var resp = data.data.muttation_Apuestas
      if (resp.responder !== -1 || resp.responder === null) {
        if (operation === 2) {
          var dataSource
          dataSource = [...this.state.dataSource]
          this.setState({
            dataSource: dataSource.filter((item) => _.indexOf(seleccionRow, item.key) === -1)
          })
        }
        message.success(<Icon type="like" theme="twoTone" />)
      } else {
        message.error('Disculpe, pero no pude actualizar su solicitud!')
      }
    }
  })
}
export function listado_lstApuestasFn(idl) {
  client
    .query({
      query: listado_lstApuestas,
      variables: { idl }
    })
    .then((data) => {
      var listado_lstApuestas = data.data.listado_lstApuestas
      if (listado_lstApuestas !== null) {
        var { lstApuestas, lstAdd } = listado_lstApuestas
        this.setState({
          lstApuestas,
          lstAdd
        })
      } else {
        this.setState({
          lstApuestas: [],
          lstAdd: []
        })
      }
      //  message.success('La Informacion fue Actualizada..!');
    })
}
export function view_blockPointVentasFn(idc) {
  client
    .query({
      query: view_blockPointVentas,
      variables: { idc }
    })
    .then((data) => {
      var data_blockPointVentas = data.data.view_blockPointVentas
      if (data_blockPointVentas !== null) {
        store.dispatch({
          type: 'IN_data_List_dataListPointVentas',
          data_blockPointVentas
        })
      } else {
        store.dispatch({
          type: 'IN_data_List_dataListPointVentas',
          data_blockPointVentas: []
        })
      }
      // message.success('La Informacion fue Actualizada..!');
    })
}
export function muttation_blockPointVentasFn(input, operation) {
  client.mutate({
    mutation: muttation_blockPointVentas,
    variables: { input, operation },
    update: (proxy, data) => {
      var resp = data.data.muttation_blockPointVentas
      if (resp.responder !== -1) {
        var data_blockPointVentas = resp.dataList
        store.dispatch({
          type: 'IN_data_List_dataListPointVentas',
          data_blockPointVentas
        })
        this.setState({ lstapuestas: [] })
        //  message.success('La Informacion fue Actualizada..!');
      } else {
        message.error(
          'Disculpe, pero no pude actualizar su solicitud! (Puede ser que esta Apuesta esta asociada a otra combinada)'
        )
      }
    }
  })
}
export function mutationLoteriatsFn(input, operation) {
  client.mutate({
    mutation: muttationLoteriaLt,
    variables: { input, operation },
    update: (proxy, data) => {
      var resp = data.data.muttationLoteriaLt
      if (resp.responder !== -1) {
        if (input.ida === -1) this.setState({ id: resp.responder })
        store.dispatch({
          type: 'IN_data_List_Loteria',
          listDataLt: resp.listLoteria
        })
        store.dispatch({ type: 'IN_data_insider', insider: '' })
        //  message.success('La Informacion fue Actualizada..!');
      } else {
        message.error(
          'Disculpe, pero no pude actualizar su solicitud! (Puede ser que esta Apuesta esta asociada a otra combinada)'
        )
      }
    }
  })
}

export function mutationApuestaltsFn(input, operation) {
  client.mutate({
    mutation: mutationApuestalts,
    variables: { input, operation },
    update: (proxy, data) => {
      var resp = data.data.muttationApuestaLt
      if (resp.responder !== -1) {
        if (input.ida === -1) this.setState({ id: resp.responder })
        store.dispatch({
          type: 'IN_data_List_Apuesta',
          listData: resp.listApuesta
        })
        store.dispatch({ type: 'IN_data_insider', insider: '' })
        // message.success('La Informacion fue Actualizada..!');
      } else {
        message.error(
          'Disculpe, pero no pude actualizar su solicitud! (Puede ser que esta Apuesta esta asociada a otra combinada)'
        )
      }
    }
  })
}
export function muttationPointFn(input, operation) {
  client.mutate({
    mutation: muttationPoint,
    variables: { input, operation },
    update: (proxy, data) => {
      var resp = data.data.muttationPoint
      if (resp.responder !== -1) {
        if (input.idg === -1) this.setState({ id: resp.responder })
        store.dispatch({
          type: 'IN_data_List_dataList',
          dataList: resp.dataList
        })
        store.dispatch({ type: 'IN_data_List_gData', gData: resp.gData })
        store.dispatch({ type: 'IN_data_insider', insider: '' })
        //  message.success('La Informacion fue Actualizada..!');
      } else {
        message.error(
          'Disculpe, pero no pude actualizar su solicitud! (Puede ser que esta Apuesta esta asociada a otra combinada)'
        )
      }
    }
  })
}
export function muttationGroupFn(input, operation) {
  client.mutate({
    mutation: muttationGroup,
    variables: { input, operation },
    update: (proxy, data) => {
      var resp = data.data.muttationGroup
      if (resp.responder !== -1) {
        if (input.idg === -1) this.setState({ id: resp.responder })
        store.dispatch({
          type: 'IN_data_List_dataList',
          dataList: resp.dataList
        })
        store.dispatch({ type: 'IN_data_List_gData', gData: resp.gData })
        store.dispatch({ type: 'IN_data_insider', insider: '' })
        // message.success('La Informacion fue Actualizada..!');
      } else {
        message.error(
          'Disculpe, pero no pude actualizar su solicitud! (Puede ser que esta Apuesta esta asociada a otra combinada)'
        )
      }
    }
  })
}
export function viewLtGroupPointFn() {
  client
    .query({
      query: viewLtGroupPoint
    })
    .then((data) => {
      var viewLtGroup = data.data.viewLtGroupPoint
      if (viewLtGroup !== null) {
        store.dispatch({
          type: 'IN_data_List_dataList',
          dataList: viewLtGroup.dataList
        })
        store.dispatch({
          type: 'IN_data_List_gData',
          gData: viewLtGroup.gData
        })
      } else {
        store.dispatch({ type: 'IN_data_List_dataList', dataList: [] })
        store.dispatch({ type: 'IN_data_List_gData', gData: [] })
      }
      // message.success('La Informacion fue Actualizada..!');
    })
}
export function queryApuestaltFn() {
  client
    .query({
      query: queryApuestalt
    })
    .then((data) => {
      var listData = data.data.viewLtApuestaLt
      if (listData !== null) store.dispatch({ type: 'IN_data_List_Apuesta', listData })
      else store.dispatch({ type: 'IN_data_List_Apuesta', listData: [] })
      // message.success('La Informacion fue Actualizada..!');
    })
}

export function queryLoterialtFn() {
  client
    .query({
      query: queryLoterialt
    })
    .then((data) => {
      var listDataLt = data.data.viewLtLoteriaLt
      if (listDataLt !== null) store.dispatch({ type: 'IN_data_List_Loteria', listDataLt })
      else store.dispatch({ type: 'IN_data_List_Loteria', listDataLt: [] })
      // message.success('La Informacion fue Actualizada..!');
    })
}
export function queryLoterialtFn_componet() {
  client
    .query({
      query: queryLoterialt_componet
    })
    .then((data) => {
      var listDataLt = data.data
      if (listDataLt !== null) {
        store.dispatch({
          type: 'IN_data_List_Loteria',
          listDataLt: listDataLt.loterias
        })
        store.dispatch({ type: 'IN_data_globalData', globalData: listDataLt })
      } else {
        store.dispatch({ type: 'IN_data_List_Loteria', listDataLt: [] })
        store.dispatch({ type: 'IN_data_globalData', globalData: [] })
      }
    })
}

export async function queryLtlstapuestasFn() {
  await client
    .query({
      query: queryLtlstapuestas
    })
    .then((data) => {
      var lstapuestas = data.data.viewLtlstapuestas
      if (lstapuestas !== null) this.setState({ lstapuestas })
    })
}
