import React from "react";

import CloudWinwdowsModal from "../../../../compenent/modals/";
import IuTopesDeportes from "../topesDeport";

const ModalCloudDeportes = ({
	stateModalWindow,
	sumitModalControl,
	stateTopesDeportes,
}) => {
	return (
		<CloudWinwdowsModal
			children={
				<IuTopesDeportes
					dataDepo={stateTopesDeportes}
					sumitModalControl={sumitModalControl}
				/>
			}
			modalState={stateModalWindow}
			onCancel={() => sumitModalControl(false)}
			classNm={{ background: "rgb(244, 244, 244)" }}
			classFrm={"from-modal-tope-Deportes "}
			title="Detalle de Topes DEPORTES"
		/>
	);
};
export default ModalCloudDeportes;
