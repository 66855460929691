import React from "react";

import { useTopesGeneral } from "../../../../hooks/useTopesGeneral";
import CloudWinwdowsModal from "../../../../compenent/modals/";
import IuTopesNacional from "../topesNacional";

const ModalCloudNacionales = ({ stateModalWindow, sumitModalControl,stateTopesNacionales }) => {
	const { stateOptionsNacional:{cnfBlt},stateCnfHipo } = useTopesGeneral();
	// console.log(stateTopesNacionales)

	return (
		<CloudWinwdowsModal
			children={
				<IuTopesNacional
					dataNac={stateTopesNacionales}
					sumitModalControl={sumitModalControl}
					recordHipo={stateCnfHipo}
					recordBoleto={cnfBlt}
				/>
			}
			modalState={stateModalWindow}
			onCancel={() => sumitModalControl(false)}
			classNm={{ background: "rgb(244, 244, 244)" }}
			classFrm={"from-modal-tope-Nacionales"}
			title="Detalle de Topes NACIONALES"
		/>
	);
};
export default ModalCloudNacionales;
