import React,{ Component } from "react";
import {Button,Popover,Icon,Row,Col,Form,Tree, Input,Select,Popconfirm,Tabs,List,Avatar,InputNumber,Table,Radio,message} from "antd";
import   './cloudcscGroupPoint.scss'; 
import * as func from './function';
import store from './store';
var _ =  require('lodash');

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const ButtonGroup = Button.Group;
const { Option } = Select;
const { TreeNode } = Tree;
const Search = Input.Search;

/////// From para los Grupos de Ventas //////
class FromEditGrupos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            val_data:{},
            id:-1,
        }
        this.muttationGroupFn=func.muttationGroupFn.bind(this);
    }
    handleWebsiteChange = () => {
        var {id}=this.state;
        this.props.form.validateFields(
            (err,value) => {
            if (!err) {
                var input_json={
                    idg:            _.toInteger(id),
                    nombre:         value.nombre,
                    responsable:    value.responsable,
                    participacion:  _.toNumber(value.participacion),
                    venta:          _.toNumber(value.venta)}
                this.muttationGroupFn(input_json,1);
            }
            },
        );
    }
    searchMountData=(id)=>{
        if (id!==-1){
            var {gData}=store.getState();
            var idx=_.findIndex(gData,function(o) { return o.key === _.toString(id);  });
            if (idx!==-1)
                var  val_data={...gData[idx]}
            this.setState({val_data,id})
        }else{
            this.setState({ 
                id:-1,
                val_data:{
                title:'',
                key:'',
                responsable:'',
                participacionPorcen:0,
                ventasPorcentaje:0}})
        }
    }   
    componentDidUpdate=()=>{
        if (this.props.id!==this.state.id) this.searchMountData(this.props.id)
    }
    componentDidMount=()=>{
        this.searchMountData(this.props.id)
    }

render() {
    var {val_data}=this.state;
    const { getFieldDecorator} = this.props.form;
 
    return (
        <div className="fromPendientes">
        <Form className="FromTransDB" >
            <FormItem label="Nombre (Descripcion):" className="FromiTemone">
                    {getFieldDecorator('nombre', {initialValue:val_data.title,
                    rules: [{required: true, message: 'Por favor requiero la Descripcion el este Grupo de Ventas!'},
                        {type: 'string',  message: 'Debe tener alguna descripcion !'}],
                    })(
                    <Input />
                    )}
            </FormItem>
            <FormItem label="Responsable:"  className="FromiTemtwo">
                    {getFieldDecorator('responsable', {initialValue:val_data.responsable})(
                    <Input />
                    )}
            </FormItem>
            <FormItem label="% de Participacion:"  className="FromiTemtwo">
                    {getFieldDecorator('participacion', {initialValue:val_data.participacionPorcen,
                    rules: [
                        {type: 'number', message: 'Este valor NO es un numero !', 
                        transform(value) {
                            return _.toNumber(value);
                        }}],
                    })(
                    <Input />
                    )}
            </FormItem>
            <FormItem label="% de Venta:"  className="FromiTemtwo">
                    {getFieldDecorator('venta', {initialValue:val_data.ventasPorcentaje,
                    rules: [
                        {type: 'number', message: 'Este valor NO es un numero !', 
                        transform(value) {
                            return _.toNumber(value);
                        }}],
                    })(
                    <Input />
                    )}
            </FormItem>
            <FormItem >
            <Row gutter={20}>
                <Col span={10}>
                    <Button onClick={(e)=>this.handleWebsiteChange()}>{this.state.id===-1?'Grabar':'Modificar'}</Button>
                </Col>
            </Row>
            </FormItem>
        </Form>
        </div>
        )
    }
}

const EnhancedFromEditGrupos = Form.create()(FromEditGrupos);
//////////////////////////////////////////////////////////////////////////////////
/////// From para los Puntos de Ventas //////
class FromEditPointSales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            val_data:{},
            id:-1
        }
        this.muttationPointFn=func.muttationPointFn.bind(this);
    }
    handleWebsiteChange = () => {
        var {val_data}=this.state;
        this.props.form.validateFields(
            (err,value) => {
            if (!err) {
                var {idg}=val_data;
                if (val_data.id===-1) idg=this.props.GrupoPoint
                var input_json={
                    idc:            value.autocode,
                    idg:             _.toNumber(idg),
                    nombre:         value.nombre,
                    phone:          value.prefix+'-'+value.phone,
                    participacion:  _.toNumber(value.participacion),
                    venta:          _.toNumber(value.venta),
                    anular:         _.toNumber(value.anular),
                    id:            _.toNumber(val_data.id),
                    };
                this.muttationPointFn(input_json,1);
            }
            },
        );
    }
    searchMountData=(id)=>{
        if (id!==-1){
            var {gData}=store.getState();
            var val_data={};
            for (var rows in gData) {
                var {children}=gData[rows];
                var idx=_.findIndex(children,function(o) { return o.key === _.toString(id);  });
                if (idx!==-1) { val_data={...children[idx]}; break; }
            }
            this.setState({val_data,id})
        }else{
            this.setState({ 
                id:-1,
                val_data:{
                    id:-1,
                    title:'',
                    key:'',
                    telefono:'',
                    participacionPorcen:0,
                    ventasPorcentaje:0,
                    ticketanular:0,
                    prefix:'414'
            }})
        }
    }   
    componentDidUpdate=()=>{
        if (this.props.id!==this.state.id) this.searchMountData(this.props.id)
    }
    componentDidMount=()=>{
        console.log(this.props)
        this.searchMountData(this.props.id)
    }

render() {
    var {val_data}=this.state;
    const { getFieldDecorator} = this.props.form;

    const prefixSelector = getFieldDecorator('prefix', {
        initialValue: val_data.prefix,
        })(
        <Select style={{ width: 100 }}>
            <Option value="414">+414</Option>
            <Option value="424">+424</Option>
            <Option value="416">+416</Option>
            <Option value="426">+426</Option>
            <Option value="412">+412</Option>
        </Select>
        );
    return (
     
                <div className="fromPendientes">
                <Form className="FromTransDB" >
                    <FormItem label="Codigo(Auto):" className="FromiTemone">
                            {getFieldDecorator('autocode', {initialValue:val_data.key})(
                            <Input disabled />
                            )}
                    </FormItem>
                    <FormItem label="Nombre (Descripcion):" className="FromiTemone">
                            {getFieldDecorator('nombre', {initialValue:val_data.title,
                            rules: [{required: true, message: 'Por favor requiero la Descripcion el este Punto de Venta!'},
                                {type: 'string',  message: 'Debe tener alguna descripcion !'}],
                            })(
                            <Input />
                            )}
                    </FormItem>
                    <FormItem label="Telefono:"  className="FromiTemtwo">
                            {getFieldDecorator('phone', {initialValue:val_data.telefono,
                            rules: [{ required: true, message: 'Por Favor introduzca un numero telefonico!' }],
                            })(
                                <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
                            )}
                    </FormItem>
                    <FormItem label="% de Venta:"  className="FromiTemtwo">
                            {getFieldDecorator('venta', {initialValue:val_data.ventasPorcentaje,
                            rules: [
                                {type: 'number', message: 'Este valor NO es un numero !', 
                                transform(value) {
                                    return _.toNumber(value);
                                }}],
                            })(
                            <Input />
                            )}
                    </FormItem>
                    <FormItem label="% de Participacion:"  className="FromiTemtwo">
                            {getFieldDecorator('participacion', {initialValue:val_data.participacionPorcen,
                            rules: [
                                {type: 'number', message: 'Este valor NO es un numero !', 
                                transform(value) {
                                    return _.toNumber(value);
                                }}],
                            })(
                            <Input />
                            )}
                    </FormItem>
                    <FormItem label="Cantidad de tickets que puede anular:"  className="FromiTemtwo">
                            {getFieldDecorator('anular', {initialValue:val_data.ticketanular,
                            rules: [
                                {type: 'number', message: 'Este valor NO es un numero !', 
                                transform(value) {
                                    return _.toNumber(value);
                                }}],
                            })(
                            <Input />
                            )}
                    </FormItem>
                
                    <FormItem >
                    <Row gutter={20}>
                        <Col span={10}>
                            <Button  onClick={(e)=>this.handleWebsiteChange()}>{this.state.id===-1?'Grabar':'Modificar'}</Button>
                        </Col>
                    </Row>
                    </FormItem>
                </Form>
            </div>
           
        )
    }
}

const EnhancedFromEditPointSales = Form.create()(FromEditPointSales);
var id_select
//////////////////////////////////////////////////////////////////////////
/////// From Pagos/Ventas Maximos Puntos de Ventas  //////
class FromPagosVentasPointSales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            val_data:{},
            id:-1,
            idlot:-1,
            listDataLt:[],
            insiderInside:'',
            lstapuestas:[],
            data_blockPointVentas:[],
            seleccion:0,
            premio:0,
            ventasmaxima:0
        }
        this.queryLoterialtFn=func.queryLoterialtFn.bind(this);
        this.view_blockPointVentasFn=func.view_blockPointVentasFn.bind(this);
        this.muttation_blockPointVentasFn=func.muttation_blockPointVentasFn.bind(this);
        store.subscribe(() => {
            this.setState({
                lstapuestas:store.getState().lstapuestas,
                listDataLt:store.getState().listDataLt,
                data_blockPointVentas:store.getState().data_blockPointVentas,
            })
        })
    }
    handleWebsiteChange = () => {
        var {ventasmaxima,premio,idlot,seleccion,id}=this.state;
                var input_json={
                    id:            _.toNumber(id),
                    idc:            this.props.id, ///<= id==IDC del punto de ventas seleccionado
                    ida:            _.toNumber(seleccion),
                    idl:            _.toNumber(idlot),
                    premio:         _.toNumber(premio),
                    ventasmaxima:   _.toNumber(ventasmaxima)
                    };
                this.setState({seleccion:0,premio:0})    
                this.muttation_blockPointVentasFn(input_json,1);
    }
    handleChgPremio=(seleccion)=>{
        var val_data={premio:0,ventasmaxima:0,id:-1}
        var {data_blockPointVentas,idlot,id}=this.state;
        this.setState({seleccion})
        var idx=_.findIndex(data_blockPointVentas,function(o) { return o.ida === seleccion && o.idl === idlot;  });
        if (idx!==-1) { val_data={...data_blockPointVentas[idx]};  }
        var {premio,ventasmaxima}=val_data;
        // const ventasmaxima1=ventasmaxima;
        // const premio1=premio;
        id=val_data.id
        this.setState({val_data,premio,ventasmaxima,id})
    }
    onChange=(e,type)=>{
        console.log(e.target.value,type)
        switch(type){
            case 1:
                this.setState({premio:e.target.value});
                break;
            default:
                this.setState({ventasmaxima:e.target.value});
        }
    }

    onClickMainList=(id,index)=>{
        var {listDataLt}=this.state;
        id_select=index;
        var lstapuestas=listDataLt[index].seleccion_apwid;
        store.dispatch({ type: 'IN_data_List_lstapuestas',lstapuestas});
        this.setState({idlot:id});

    }
    componentDidMount=()=>{
        id_select=-1;
        this.setState({insiderInside:''})
        this.queryLoterialtFn();
        this.view_blockPointVentasFn(this.props.id)
    }
render() {
    var {lstapuestas,premio,ventasmaxima}=this.state;
    return(
        <div className="mainwdw_ps">
            <Row gutter={2}>
                <Col span={9} >
                    <div className='mainList_ps'>
                    <List
                                itemLayout="vertical"
                                size="small"
                                locale={{ emptyText:<label style={{color:'#fff'}}> <Icon type="delete" style={{fontSize: '22px',color:'#fff'}}/>No hay Informacion</label> }}
                                dataSource={this.state.listDataLt}
                                className="ant-list-item-prx"
                                renderItem={(item,index) => (
                                <List.Item
                                    className={id_select===index?"listStyItem_gp":''}
                                    key={item.title}
                                    onClick={()=>this.onClickMainList(item.id,index)}
                                    >
                                    <List.Item.Meta
                                    avatar={<Avatar style={{ backgroundColor:'#f56a00', verticalAlign: 'middle' }} size="large" >{item.etiqueta}</Avatar>}
                                    title={<label className="mainLabletitle_lt">{item.title}</label>}
                                    />
                                </List.Item>
                                )}
                            />
                    </div>
                </Col>
                <Col span={9} >
                    <div className='mainContent_ps'>
                    {lstapuestas.length!==0 && 
                    <>
                        <div className='FromiTemSelect_prm_point'>
                            <div className='FromiTemSelect_prm_point1'>
                                <label> Apuesta : </label>
                                <div className='FromiTemSelect_prm_subpoint1'>
                                            <Select
                                                showSearch
                                                onChange={(e)=>this.handleChgPremio(e)}
                                                style={{ width: 200 }}
                                                placeholder="Seleccione la apuesta"
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                {lstapuestas.map((value,index)=>
                                                        <Option key={index} value={value.ida}>{value.text}</Option>
                                                )}
                                            </Select>
                                </div>
                            </div>
                            <div className='FromiTemSelect_prm_point2'>
                                <label> Premio 1x: </label>
                                <div className='FromiTemSelect_prm_subpoint1'>
                                <Input
                                    type="Number"  
                                    value={premio}
                                    onChange={(e)=>this.onChange(e,1)}/>
                                </div>
                            </div>
                            <div className='FromiTemSelect_prm_point2'>
                                <label> Ventas Maximas: </label>
                                <div className='FromiTemSelect_prm_subpoint1'>
                                <Input
                                    type="Number"  
                                    value={ventasmaxima}
                                    onChange={(e)=>this.onChange(e,2)}/>
                                </div>
                            </div>
                            <div className='FromiTemSelect_prm_point_bnt'>
                                <Button  onClick={(e)=>this.handleWebsiteChange()}>Grabar</Button>
                            </div>
                        </div>
                        </>
                    }
                    </div>
                </Col>
            </Row>
        </div>
    )
}
}
//////////////////////////////////////////////////////////////////////////
/////// Cuadro de Edicion de 
const EditableContext = React.createContext();
const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);
class EditableCell extends React.Component {
    state = {
        editing: false,
    }

    toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
        if (editing) {
            this.input.focus();
        }
        });
    }

    save = (e) => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return;
            }
            this.toggleEdit();
            handleSave({ ...record, ...values });
        });
    }

    render() {
        const { editing } = this.state;
        const {
        editable,
        dataIndex,
        title,
        record,
        index,
        handleSave,
        ...restProps
        } = this.props;
        return (
        <td {...restProps}>
            {editable ? (
            <EditableContext.Consumer>
                {(form) => {
                this.form = form;
                return (
                    editing ? (
                    <FormItem style={{ margin: 0 }}>
                        {form.getFieldDecorator(dataIndex, {
                        rules: [{
                            required: true,
                            message: `${title} es necesario.`,
                        }],
                        initialValue: record[dataIndex],
                        })(
                        <Input
                            ref={node => (this.input = node)}
                            onPressEnter={this.save}
                            onBlur={this.save}
                        />
                        )}
                    </FormItem>
                    ) : (
                    <div
                        className="editable-cell-value-wrap"
                        style={{ paddingRight: 24 }}
                        onClick={this.toggleEdit}
                    >
                        {restProps.children}
                    </div>
                    )
                );
                }}
            </EditableContext.Consumer>
            ) : restProps.children}
        </td>
        );
    }
    }
    var seleccionRow;
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            seleccionRow=selectedRowKeys;
        },
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }),
    };
class FromRestricciones extends Component{
    constructor(props) {
        super(props);
        this.state = {
            id:-1,
            idSelAp:0,
            type:0,
            Numero:'-1',
            Monto:0,
            selec:0,
            MaximoVenta:0,
            dataSource:[],   // zona 1   si formato 123 Numero si tiene 4 Combinacion , solo tiene 4 el nombre de la lista  caso -> /// zona 2
            lstApuestas:[{id:1,text:'A',type:1},{id:2,text:'B',type:2}],
            lstAdd:[{lisID:1,list:[{id:1,text:'Aries'},{id:2,text:'Virgo'}]},{lisID:2,list:[{id:1,text:'Perro'},{id:2,text:'Gatos'}]}],
            columns:[ {
                title: 'id',
                dataIndex: 'key',
                width: '5%',
                editable: false,
            },{
                title: 'Apuesta',
                dataIndex: 'apuesta',
                width: '65%',
                editable: false,
            }, {
                title: 'Monto Restrigido',
                dataIndex: 'monto',
                width: '30%',
                editable: true,
            }],
        }
        this.listado_lstApuestasFn=func.listado_lstApuestasFn.bind(this);
        this.muttation_ApuestasFn=func.muttation_ApuestasFn.bind(this);
        this.listado_blockApFn=func.listado_blockApFn.bind(this);
    }

    handleWebsiteChange = () => {
        var {dataSource}=this.state;
        this.props.form.validateFields(
            (err,value) => {
            if (!err) {
                
                // var {idg}=val_data;
                // if (val_data.id===-1) idg=this.props.GrupoPoint

                console.log('dataSource',dataSource)
                if (dataSource.length===0)
                        dataSource={
                            key: -1,
                            apuesta:'',
                            monto: '',
                            ida: -1,
                            numero: '',
                            lstAddKey: -1,
                            ida_ap:-1,
                        }
                var input_json={
                    _blockApuestas:dataSource,
                    MaximoVenta: value.MaximoVenta,
                    idc:        this.props.idc,
                    idl:        this.props.idl
                    };
                    console.log(input_json,value)
                this.muttation_ApuestasFn(input_json,1,seleccionRow);
            }
            },
        );
    }
    handleSave = (row) => {
        var  newData;
        console.log('row',row)
        
        newData = [...this.state.dataSource];

        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });

        this.setState({ dataSource: newData });
    }
    handleAdd = (combi) => {
            const {  dataSource } = this.state;
            console.log('combi',combi)
            var count=dataSource.length;
            const newData = {
                key: count+1,
                apuesta:combi.apuesta,
                monto: combi.Monto,
                ida: combi.ida,
                numero: combi.Numero,
                lstAddKey: combi.key,
                ida_ap:combi.ida_ap
            };
            this.setState({
                dataSource: [...dataSource, newData]
            });
    }
    handleDelete=()=>{
        var {dataSource}=this.state;
        console.log(seleccionRow)
        var dataEle=[]
        for (const rows in seleccionRow) {
            const selectRow= seleccionRow[rows];
            var idx=_.findIndex(dataSource, function(o) { return o.key === selectRow });
            var newData = {
                key:dataSource[idx].ikey ,
                apuesta:'',
                monto: '',
                ida: -1,
                numero: '',
                lstAddKey: -1,
            };
            dataEle= [...dataEle, newData]
        }
        console.log(dataEle)
        var input_json={
            _blockApuestas:dataEle,
            MaximoVenta: -1,
            idc:        this.props.idc,
            idl:        this.props.idl
            };
        this.muttation_ApuestasFn(input_json,2,seleccionRow);

       

    }
    ClickChgCmbap=(id)=>{
        var {lstApuestas}=this.state;
        console.log('lstApuestas',lstApuestas)
        var idx=_.findIndex(lstApuestas, function(o) { return o.id === id; });
        var {type}=lstApuestas[idx];
        this.setState({type,idSelAp:id});
    }
    handleNumberChange = (currency,tipo) => {
        switch (tipo){
            case 1: 
                this.setState({ Numero:currency.target.value });
                break;
            case 2: 
                this.setState({ Monto:currency.target.value });
                break;
            default: 
        }
    }
    handleCurrencyChange = (selec,tipo)=>{
        this.setState({selec})
    }
    AddClick=()=>{
        var { selec,Numero,idSelAp,lstAdd,Monto,type,lstApuestas} = this.state;
        console.log(selec,Numero,idSelAp,lstAdd,Monto,type)
        var idn=_.findIndex(lstApuestas, function(o) { return o.id === idSelAp; });
        var ida_ap=lstApuestas[idn].idals[0];
        var idx=-1
        if (lstAdd.length!==0){
            idx=_.findIndex(lstAdd, function(o) { return o.lisID === idSelAp; });
            if (idx!==-1){
                var lstAdd_n1=lstAdd[idx].list;
                var {ida}=lstAdd[idx];
                idx=_.findIndex(lstAdd_n1, function(o) { return o.key === selec; });
            }
        }
        var val_txt='',key=-1;
        if (idx!==-1){
            key=selec
            if (type===1 ){
                if (Numero==='-1') val_txt=lstAdd_n1[idx].name;
                else  val_txt=Numero+'-'+lstAdd_n1[idx].name;
            }else{
                val_txt=lstAdd_n1[idx].name;
                idSelAp=0;
            }
        }else{
        val_txt=Numero;
        idSelAp=0;
        }
        this.handleAdd({apuesta:val_txt,Monto,ida,Numero,key,idSelAp,ida_ap});
        this.setState({type:0,selec:0,Monto:0,Numero:'-1'})
    }
    allDataCall=()=>{
        this.props.form.resetFields();
        seleccionRow=[];
        this.listado_lstApuestasFn(this.props.idl)
        this.listado_blockApFn(this.props.idc,this.props.idl)
    }
    componentDidMount=()=>{
      this.allDataCall()
    }
    componentDidUpdate=(prevProps, prevState, snapshot)=>{
        if (prevProps.idl!==this.props.idl)   this.allDataCall()
    }
    render(){
        const {lstApuestas,type,dataSource,lstAdd,idSelAp,MaximoVenta}=this.state;
        const { getFieldDecorator} = this.props.form;
        var lstSideA=[];
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
            };
            const columns = this.state.columns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: this.handleSave,
                }),
            };
            });
            var idx=_.findIndex(lstAdd, function(o) { return o.lisID === idSelAp; });
            if (idx!==-1) lstSideA=lstAdd[idx].list;
            
        return(
            <div>
                <Form className="FromTransDB" >
                    <FormItem label="Maximo de Ventas por loteria:" className="FromiTemone_rp">
                            {getFieldDecorator('MaximoVenta', {initialValue:MaximoVenta,
                            rules: [{required: false, message: 'Por favor requiero la Descripcion de la Apuesta!'}],
                            })(
                                <InputNumber placeholder="Maximo de Venta " />
                            )}
                    </FormItem>
                    <FormItem label="Agregar combinaciones de apuestas:" className="FromiTemone_rp">
                        <Radio.Group  buttonStyle="solid" >
                            {lstApuestas.map((value,index)=>
                                <Radio.Button key={index} value={value.id} onClick={(e)=>this.ClickChgCmbap(value.id)}>{value.text}</Radio.Button>
                            )}
                        </Radio.Group>
                    </FormItem>
                    {type===1 &&
                    <FormItem label="Adicionar Restriccion:" className="FromiTemone_rp">
                            <div>
                                <Input
                                type="text"
                                placeholder="Numero"
                                onChange={(e)=>this.handleNumberChange(e,1)}
                                style={{ width: '20%',marginRight: '3px'}}
                                />
                                <Select
                                placeholder="Combinado"
                                style={{ width: '30%',marginRight: '3px' }}
                                onChange={(e)=>this.handleCurrencyChange(e,1)}
                                >
                                    {lstSideA.map((value,index)=>
                                        <Option   key={index} value={value.key}>{value.name}</Option>
                                    )}
                                </Select>
                                <Input
                                type="Number"
                                placeholder="Maximo"
                                onChange={(e)=>this.handleNumberChange(e,2)}
                                style={{ width: '20%',marginRight: '5px' }}
                                />
                                <Button  onClick={()=>this.AddClick()}>Agregar</Button>
                            </div>
                    </FormItem>}
                    {type===2 &&
                    <FormItem label="Adicionar Restriccion:" className="FromiTemone_rp">
                            <div>
                                <Select
                                placeholder="Combinado"
                                style={{ width: '30%',marginRight: '5px' }}
                                onChange={(e)=>this.handleCurrencyChange(e,2)}
                                >
                                    {lstSideA.map((value,index)=>
                                        <Option key={index} value={value.key}>{value.name}</Option>
                                    )}
                                </Select>
                                <Input
                                type="Number"
                                placeholder="Maximo"
                                onChange={(e)=>this.handleNumberChange(e,2)}
                                style={{ width: '20%', marginRight: '10px' }}
                                />
                                <Button htmlType="submit" onClick={()=>this.AddClick()}>Agregar</Button>
                            </div>
                    </FormItem>}
                    <FormItem label="Lista de Restricciones">
                        <div className='FromiTemLista_rp'>
                            <div className="barrwdw_rp">
                            <ButtonGroup >
                                    <Button className="ant-btn-primary_rp"   onClick={()=>this.handleDelete()} ><Icon type="minus" /></Button>
                            </ButtonGroup>
                            </div>
                            <Table
                                components={components}
                                rowClassName={() => 'editable-row'}
                                bordered
                                dataSource={dataSource}
                                columns={columns}
                                rowSelection={rowSelection}
                                pagination={{ pageSize: 50 }} 
                                scroll={{ y: 180 }}
                            />
                        </div>
                    </FormItem>
                    <FormItem >
                    <Row gutter={20}>
                        <Col span={10}>
                            <Button onClick={(e)=>this.handleWebsiteChange()}>{this.state.id===-1?'Grabar':'Modificar'}</Button>
                        </Col>
                    </Row>
                    </FormItem>
                </Form>
            </div>
        )
    }

}
const EnhancedFromRestricciones = Form.create()(FromRestricciones);
//////////////////////////////////////////////////////////////////////////
/////// From Topes Maximos Puntos de Ventas  //////
class FromTopesPointSales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            val_data:{},
            id:-1,
            listDataLt:[],
            insiderInside:'',
            lstapuestas:[]
        }
        this.queryLoterialtFn=func.queryLoterialtFn.bind(this);
        store.subscribe(() => {
            this.setState({
                insiderInside:store.getState().insiderInside,
                listDataLt:store.getState().listDataLt,
            })
        })
    }

    onClickMainList=(id,index)=>{
        var {listDataLt}=this.state;
        id_select=index;
        console.log('Click',id,index,'listDataLt[index].seleccion_ap',listDataLt[index].seleccion_ap)
        var lstapuestas=listDataLt[index].seleccion_ap;
        store.dispatch({ type: 'IN_data_insiderInside',insiderInside:<EnhancedFromRestricciones idl={id} idc={this.props.id}/>});
        this.setState({lstapuestas});

    }
    componentDidMount=()=>{
        id_select=-1;
        this.setState({insiderInside:''})
        this.queryLoterialtFn();
    }
render() {
    return(
        <div className="mainwdw_tp">
            <Row gutter={2}>
                <Col span={6} >
                    <div className='mainList_ps'>
                    <List
                                itemLayout="vertical"
                                size="small"
                                locale={{ emptyText:<label style={{color:'#fff'}}> <Icon type="delete" style={{fontSize: '22px',color:'#fff'}}/>No hay Informacion</label> }}
                                dataSource={this.state.listDataLt}
                                className="ant-list-item-prx"
                                renderItem={(item,index) => (
                                <List.Item
                                    className={id_select===index?"listStyItem_gp":''}
                                    key={item.title}
                                    onClick={()=>this.onClickMainList(item.id,index)}
                                    >
                                    <List.Item.Meta
                                    avatar={<Avatar style={{ backgroundColor:'#f56a00', verticalAlign: 'middle' }} size="large" >{item.etiqueta}</Avatar>}
                                    title={<label className="mainLabletitle_lt">{item.title}</label>}
                                    />
                                </List.Item>
                                )}
                            />
                    </div>
                </Col>
                <Col span={9} >
                    <div className='mainContent_tp'>
                        {this.state.insiderInside}
                    </div>
                </Col>
            </Row>
        </div>
    )
}
}
//////////////////////////////////////////////////////////////////////////
/////// From para los Puntos de Ventas //////
class FromGeneralPointSales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            val_data:{},
            id:-1,
            activeKey_tab:"1"
        }
        store.subscribe(() => {
            this.setState({
                activeKey_tab:store.getState().activeKey_tab,
            })
        })
    }
    
    onTabClick=activeKey_tab=>{
        store.dispatch({ type: 'IN_data_activeKey_tab',activeKey_tab});
    }
render() {
    return(
        <Tabs className="tabfrm_grp" activeKey={this.state.activeKey_tab} onTabClick={(e)=>this.onTabClick(e)} >
            <TabPane tab="General" key="1">
                    <EnhancedFromEditPointSales id={this.props.id}  GrupoPoint={this.props.GrupoPoint}/>
            </TabPane>
            <TabPane tab="Pagos/Ventas Maximos" key="2">
                    <FromPagosVentasPointSales  id={this.props.id}/>
            </TabPane>
            <TabPane tab="Topes" key="3">
                    <FromTopesPointSales  id={this.props.id}/>
            </TabPane>
        </Tabs>
    )
}
}
const getParentKey = (key, tree) => {
        let parentKey;
        for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
            if (node.children.some(item => item.key === key)) {
            parentKey = node.key;
            } else if (getParentKey(key, node.children)) {
            parentKey = getParentKey(key, node.children);
            }
        }
        }
        return parentKey;
    };
class MainGroupPoint extends Component{
    constructor(props) {
        super(props);
        this.state = {
            idg_Selecc:-1,
            visible: false,
            expandedKeys: [],
            searchValue: '',
            autoExpandParent: true,
            dataList:[],
            gData:[]
        }
        this.viewLtGroupPointFn=func.viewLtGroupPointFn.bind(this);
        this.muttationPointFn=func.muttationPointFn.bind(this);
        store.subscribe(() => {
            this.setState({
                dataList:store.getState().dataList,
                gData:store.getState().gData,
                insider:store.getState().insider,
            })
        })
    }


    onExpand = (expandedKeys) => {
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    }
    onChange = (e) => {
        var {dataList,gData}=this.state;
        const value = e.target.value;
        const expandedKeys = dataList.map((item) => {
            if (item.title.indexOf(value) > -1) {
            return getParentKey(item.key, gData);
            }
            return null;
        }).filter((item, i, self) => item && self.indexOf(item) === i);
        this.setState({
            expandedKeys,
            searchValue: value,
            autoExpandParent: true,
        });
    }
    onMenuGroupPoint=(ode,keyck)=>{
        switch(ode){
            case 1:
                store.dispatch({ type: 'IN_data_insider',insider:<EnhancedFromEditGrupos id={keyck}/>});
                break;
            default:
                var topCreate=true;
                if (keyck===-1) topCreate=this.state.idg_Selecc!==-1
                if (topCreate)
                    store.dispatch({ type: 'IN_data_insider',insider:<FromGeneralPointSales id={keyck} GrupoPoint={this.state.idg_Selecc}/>});
                else
                message.error('Disculpe, pero para poder crear un Punto de Venta debe seleccionar el Grupo al que va a pertenecer!');
        }
        this.hide();
    }
    contenIdeIf=()=>(
            <>
                <div  className="conteClick_grp"><div onClick={()=>this.onMenuGroupPoint(1,-1)}><Icon type="appstore" /> Grupo de Venta</div></div>
                <div  className="conteClick_grp"><div onClick={()=>this.onMenuGroupPoint(2,-1)}><Icon type="contacts" /> Punto de Venta</div></div>
            </>
    )
    handleVisibleChange = (visible) => {
        this.setState({ visible });
    }
    hide = () => {
    this.setState({
        visible: false,
    });
    }
    onCallSelect=(e)=>{
        var id=e[0]; 
        var idg_Selecc=-1;
        if (!_.isUndefined(id)){
            var nivel=_.isNaN(_.toNumber(id))?1:0;
            if (nivel===1){/// Point Sales
                this.onMenuGroupPoint(2,id)
            }else{
                this.onMenuGroupPoint(1,id)
                idg_Selecc=id;
            }
        }else   
        store.dispatch({ type: 'IN_data_insider',insider:''});
        store.dispatch({ type: 'IN_data_activeKey_tab',activeKey_tab:'1'});
        store.dispatch({ type: 'IN_data_insiderInside',insiderInside:''});
        store.dispatch({ type: 'IN_data_List_lstapuestas',lstapuestas:[]});
        this.setState({idg_Selecc})
    }
    onDropChangePointSale=(info)=>{
        var Origin=info.dragNodesKeys[0];
        var Destin=info.node.props.eventKey;
        var nivel=_.isNaN(_.toNumber(Origin))?1:0;
        if (nivel===1){
            var val_data={};
            var {gData}=this.state;
            for (var rows in gData) {
                var {children}=gData[rows];
                var idx=_.findIndex(children,function(o) { return o.key === _.toString(Origin);  });
                if (idx!==-1) { val_data={...children[idx]}; break; }
            }
            var input_json={
                idc:            Origin,
                idg:             _.toNumber(Destin),
                nombre:         val_data.title,
                phone:          val_data.prefix+'-'+val_data.telefono,
                participacion:  _.toNumber(val_data.participacionPorcen),
                venta:          _.toNumber(val_data.ventasPorcentaje),
                anular:         _.toNumber(val_data.ticketanular),
                id:            _.toNumber(val_data.id),
                };
            this.muttationPointFn(input_json,1);
        }
    }
    componentDidMount=()=>{
        this.viewLtGroupPointFn()
        store.dispatch({ type: 'IN_data_insider',insider:''});
    }
render(){
    const { searchValue, expandedKeys, autoExpandParent ,gData} = this.state;
    const loop = data => data.map((item) => {
        const index = item.title.indexOf(searchValue);
        const beforeStr = item.title.substr(0, index);
        const afterStr = item.title.substr(index + searchValue.length);
        const title = index > -1 ? (
            <span>
                {beforeStr}
                <span style={{ color: '#f50' }}>{searchValue}</span>
                {afterStr}
            </span>
            ) : <span>{item.title}</span>;
            if (item.children) {
            return (
                <TreeNode  key={item.key} title={title}>
                {loop(item.children)}
                </TreeNode>
            );
            }
            return <TreeNode  key={item.key} title={title} />;
        });
    return(
        <div className="mainwdw_group">
            <div className="barrwdw_grp">
                <ButtonGroup > 
                        <Popover
                            content={this.contenIdeIf()}
                            title="Que deseas incluir?"
                            trigger="click"
                            visible={this.state.visible}
                            onVisibleChange={this.handleVisibleChange}
                        >
                            <Button  className="barrwdw_bnt" ghost type="link" ><Icon type="plus" /></Button>
                        </Popover>
                        <Popconfirm title="Esta Seguro de Borrar esta configuracion de Apuesta?" onConfirm={()=>this.commandBarr(-2)}  okText="Borrar" cancelText="Cancelar">
                            <Button  className="barrwdw_bnt" ghost type="link"><Icon type="minus" /></Button>
                        </Popconfirm >
                        <Button className="barrwdw_bnt" ghost type="link"><Icon type="diff" /></Button>
                </ButtonGroup>
                <div className="barrwdwtitle2">
                    <label className="barrwdwtext"><Icon type="appstore" />&nbsp;Grupos/Punto de Venta</label>
                </div>
            </div>
            <Row gutter={2}>
                <Col span={7} >
                    <div className='mainList_grp'>
                    <Search style={{ marginBottom: 8 ,color:"#fff"}} placeholder="Search" onChange={this.onChange} />
                        <Tree
                        showLine
                        onExpand={this.onExpand}
                        expandedKeys={expandedKeys}
                        autoExpandParent={autoExpandParent}
                        onSelect={(e)=>this.onCallSelect(e)}
                        draggable
                        onDrop={(e)=>this.onDropChangePointSale(e)}
                        >
                        {loop(gData)}
                        </Tree>
                    </div>
                </Col>
                <Col span={9} >
                    <div className='mainContent_grp'>
                        {this.state.insider}
                    </div>
                </Col>
            </Row>
        </div>
    )
}
}
export default MainGroupPoint;