import React, { Component } from "react";
import {
	Modal,
	Tabs,
	Table,
	Tag,
	Button,
	Checkbox,
	Badge,
	Form,
	Icon,
} from "antd";
import "antd/dist/antd.css";
import "./iuUser.scss";
import * as func from "./function";
import Cloudwindows from "./cloudwindows";
import {styleBg} from "./globals.js";
import moment from "moment";
import FromiUser from "./FromiUser";
import ToolsTopes from "./compenent/toolsbar";
var _ = require("lodash");

const encode = require("nodejs-base64-encode");
const { Column } = Table;
const TabPane = Tabs.TabPane;
const ButtonGroup = Button.Group;

const EnhancedForm = Form.create()(FromiUser);

class iuUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			like: "0",
			tab: 1,
			charIdx: [],
			typeUser: ["SuperUsuario", "Directo", "Taquilla"],
			tabmenu: [1, 1, 1],
			cmpTbl: { deportes: 1, americana: 1, nacionales: 1, loteria: 1 },
			modal1Visible: false,
			loading: false,
			pasww: "******",
			iu: -1,
			validateIU: [],
			seleccion: [],
			topesConfig: [
				{ tope: 1, text: "Tope A" },
				{ tope: 2, text: "Tope B" },
				{ tope: 3, text: "Tope C" },
				{ tope: 4, text: "Tope D" },
			],
			recordnew: [
				{
					iu: "-1",
					Usuario: "",
					Nombre: "",
					email: "",
					tipo: 2,
					id: "",
					modedepor: 1,
					Moneda: "BSS",
					idioma: 1,
					activo: true,
					Deporte: true,
					Americana: true,
					Nacionales: true,
					Loteria: true,
					tope: 11,
					pago: 0,
					ticket: 0,
					fn: 946699200,
					tc: 0,
					banco: 0,
					cb: "",
					ntlf: "",
					nmsg: 0,
					AnulDeporte: 1,
					AnulAmericana: 1,
					AnulNacionales: 1,
					AnulLoteria: 1,
				},
			],
			data: [],
			data1: [],
			data2: [],
			cscList: [],
		};
		this.child = React.createRef();
		this.grabarIU = func.grabarIU.bind(this);
		this.viewListIU = func.viewListIU.bind(this);
		this.searchIU = func.searchIU.bind(this);
		this.queryAllTopes = func.queryAllTopes.bind(this);
		this.queryviewCscAllFn = func.queryviewCscAllFn.bind(this);
	}
	setClaveNueva = (pasww) => {
		this.setState({ pasww });
	};
	setStatusIU = (validateIU) => {
		this.setState({ validateIU });
	};
	setDataIU = (stbData, stboption) => {
		switch (stboption) {
			case 1:
				this.setState({ data: stbData });
				break;
			case 2:
				this.setState({ data1: stbData });
				break;
			case 3:
				this.setState({ data2: stbData });
				break;
			default:
				this.setState({ cscList: stbData });
				

		}
	};
	setModal1VisibleOk = (modal1Visible, registro, iu, patternaccesSYs) => {
		var { idPRV } = this.props;
		var activo = registro.switch1 ? 1 : 0;
		var ticket = registro.ticket ? 1 : 0;
		var nmsg = registro.nmsg ? 1 : 0;
		var usuario = "";
		var pago;
		var Tpasww = this.state.pasww;
		if (Tpasww === "??????") {
			var seleccion = this.state.seleccion;
			Tpasww = JSON.parse(encode.decode(seleccion.pw, "base64"));
		}
		if (iu === -1) usuario = _.toLower(registro.usuario.replace(/ /g, ""));

		if (registro.radiogroup2 === 3 || registro.radiogroup2 === 1) 
			pago = 0;
		else pago = registro.pago;

		var { Anul_switch2, Anul_switch3, Anul_switch4, Anul_switch5,receptor } = registro;
		var recordnew = {
			iu: iu,
			Usuario: usuario,
			Nombre: registro.nombre,
			email: registro.email,
			tipo: registro.radiogroup2,
			identif: registro.identificacion,
			Moneda: registro.select,
			idioma: _.toString(registro.select1),
			activo: activo,
			Deporte: registro.switch2,
			Americana: registro.switch3,
			Nacionales: registro.switch4,
			Loteria: registro.switch5,
			tope: _.isUndefined(registro.radiogroup1) ? 10 : registro.radiogroup1,
			pw: encode.encode(JSON.stringify(Tpasww), "base64"),
			registro: 1,
			pago: pago,
			ticket: ticket,
			nmsg,
			fn: _.toString(moment(registro.fn).unix()),
			tc: registro.tc,
			banco: registro.banco,
			cb: registro.cb,
			ntlf: registro.ntlf,
			cscDeporte: registro.cscDeporte,
			cscAmericana: registro.cscAmericana,
			cscNacionales: registro.cscNacionales,
			cscLoteria: registro.cscLoteria,
			AnulDeporte: Anul_switch2 ? 1 : 0,
			AnulAmericana: Anul_switch3 ? 1 : 0,
			AnulNacionales: Anul_switch4 ? 1 : 0,
			AnulLoteria: Anul_switch5 ? 1 : 0,
			modedepor: _.isUndefined(registro.modedepor) ? 1 : registro.modedepor,
			patternaccesSYs,
			receptor:receptor?1:0,
			_ZONE: idPRV.toString(),
		};
		this.grabarIU(recordnew, 1);
	};
	actualizarLista = () => {
		var { tabmenu } = this.state;
		var { idPRV } = this.props;
		this.setLoadingTB(true);
		if (tabmenu[0]) this.viewListIU(1, this.state.like, idPRV.toString());
		if (tabmenu[1]) this.viewListIU(2, this.state.like, idPRV.toString());
		if (tabmenu[2]) this.viewListIU(3, this.state.like, idPRV.toString());
	};
	setModal1VisibleCancel = (modal1Visible) => {
		this.setState({ modal1Visible });
		this.child.current.resetFields();
	};
	openNotification = (iu, record) => {
		var { pasww } = this.state;
		if (iu !== -1) pasww = "??????";
		else pasww = "******";
		this.setState({
			seleccion: record,
			iu,
			pasww,
		});
		this.searchIU();
		this.queryAllTopes();
		this.setState({ modal1Visible: true });
	};
	setDatalist = (topesConfig) => {
		this.setState({ topesConfig });
	};
	renderTable = (dataop) => {
		var { cmpTbl } = this.state;
		if (this.props.idPRV !== 0) cmpTbl = this.props["g1-1"];
		return (
			<div>
				<Table
					rowKey={(record) => record.iu}
					dataSource={dataop}
					className={".ant-table-content"}
					onRow={(record) => {
						var iu = record.iu;
						return {
							onClick: () => {
								this.openNotification(iu, record);
							},
						};
					}}
				>
					<Column key="iu" title="Id" dataIndex="iu" />
					<Column
						title="Usuario"
						dataIndex="Usuario"
						sorter={(a, b) => a.Usuario.length - b.Usuario.length}
					/>
					<Column
						title="Nombre"
						dataIndex="Nombre"
						sorter={(a, b) => a.Nombre.length - b.Nombre.length}
					/>
					<Column
						title="Tipo Usuario"
						dataIndex="tipo"
						render={(tipo, record) => (
							<span>
								{
									<Tag color={styleBg[tipo - 1]}>
										{this.state.typeUser[tipo - 1]}
									</Tag>
								}
							</span>
						)}
					/>
					<Column
						title="Activo"
						dataIndex="activo"
						render={(activo, record) => (
							<span>{<Checkbox value={record.iu} checked={activo} />}</span>
						)}
					/>
					<Column title="Moneda" dataIndex="Moneda" />
					{cmpTbl.deportes && (
						<Column
							title="Deporte"
							dataIndex="Deporte"
							render={(Deporte, record) => (
								<span>{<Badge status={Deporte ? "success" : "error"} />}</span>
							)}
						/>
					)}
					{cmpTbl.americana && (
						<Column
							title="Americana"
							dataIndex="Americana"
							render={(Americana, record) => (
								<span>
									{<Badge status={Americana ? "success" : "error"} />}
								</span>
							)}
						/>
					)}
					{cmpTbl.nacionales && (
						<Column
							title="Nacionales"
							dataIndex="Nacionales"
							render={(Nacionales, record) => (
								<span>
									{<Badge status={Nacionales ? "success" : "error"} />}
								</span>
							)}
						/>
					)}
					{cmpTbl.loteria && (
						<Column
							title="Loteria"
							dataIndex="Loteria"
							render={(Loteria, record) => (
								<span>{<Badge status={Loteria ? "success" : "error"} />}</span>
							)}
						/>
					)}
				</Table>
			</div>
		);
	};
	setLoadingTB = (loading) => {
		this.setState({ loading });
	};

	componentDidMount = () => {
		var { idPRV } = this.props;
		var { tabmenu } = this.state;
		if (this.props.idPRV !== 0) tabmenu = this.props["tab-menu"];
		this.setState({ tabmenu });
		this.setLoadingTB(true);
		this.viewListIU(1, this.state.like, idPRV.toString());
		this.queryviewCscAllFn();
		var charIdx = [];
		for (var i = 65; i <= 90; i++) charIdx.push(String.fromCharCode(i));
		this.setState({ charIdx, tabmenu });
	};

	onTabActivar = (tab) => {
		var { idPRV } = this.props;
		this.setLoadingTB(true);
		this.viewListIU(_.toNumber(tab), this.state.like, idPRV.toString());
		this.setState({ tab: _.toNumber(tab) });
	};
	onSearch = (like) => {
		var { idPRV } = this.props;
		this.viewListIU(this.state.tab, like, idPRV.toString());
		this.setState({ like });
	};
	barraMainP = (
		<ButtonGroup>
			<Button
				className="barrwdw_bnt"
				ghost
				type="link"
				onClick={() => this.openNotification(-1, this.state.recordnew[0])}
			>
				<Icon type="plus" />
			</Button>
		</ButtonGroup>
	);

	body_user = (tabmenu) => (
		<div key="Table-01" className="main-iuUser-tab">
			<div className="barraIndice">
				<div className="Indice" onClick={() => this.onSearch("0")}>
					<label>...</label>
				</div>
				<div className="Indice" onClick={() => this.onSearch("#")}>
					<label>#</label>
				</div>
				{this.state.charIdx.map((value, index) => (
					<div
						key={index}
						className="Indice"
						onClick={() => this.onSearch(value)}
					>
						<label>{value}</label>
					</div>
				))}
			</div>
			<Tabs
				className="tab-iUser-Main"
				type="card"
				onTabClick={(tab) => this.onTabActivar(tab)}
			>
				{tabmenu[0] && (
					<TabPane tab="Usuarios" key="1">
						{this.renderTable(this.state.data)}
					</TabPane>
				)}
				{tabmenu[1] && (
					<TabPane tab="Aministradores" key="2">
						{this.renderTable(this.state.data1)}
					</TabPane>
				)}
				{tabmenu[2] && (
					<TabPane tab="Usuarios no registrados" key="3">
						{this.renderTable(this.state.data2)}
					</TabPane>
				)}
			</Tabs>
		</div>
	);

	render() {
		var { tabmenu } = this.state;
		if (this.props.idPRV !== 0) tabmenu = this.props["tab-menu"];
		return (
			<div>
				<Cloudwindows
					title="Usuarios del Sistemas"
					classMain="mainWindowsiuUser"
					classMainInside="mainWindowsContentNac"
					classMainList="mainlistLlNacionales"
					classWindowsTxt="TextTitleLllNacionales"
					titleBkgn="#08364d"
					colorBkg="#FFF"
					barrIcons={
						<ToolsTopes custtomHalden={[() => this.openNotification(-1, this.state.recordnew[0])]} />
					}
					inside={this.body_user(tabmenu)}
					statusList={false}
				/>
				<Modal
					wrapClassName="modal-iuuser"
					title="Detalle de Usuario"
					centered
					style={{ top: 20 }}
					visible={this.state.modal1Visible}
					onCancel={() => this.setModal1VisibleCancel(false)}
					footer={false}
				>
					<EnhancedForm
						{...this.props}
						tthis={this}
						ref={this.child}
						cscList={this.state.cscList}
					/>
				</Modal>
			</div>
		);
	}
}

export default iuUser;
