import client from "../conex";
import {gql} from "@apollo/client";

// ** Query Graphql Deportes List **/
const queryViewTopesGwDepo = gql`
	query viewTopesDepo {
		viewTopesDepo {
			cnf
			texto
			mmjpd
			mmjpp
			maxpremio
			mmdp
			mma
			mma2
			cjmp
			cdpi
			Participacion1
			pVentas
			cmaxelim
			Participacion2
			pVentaspd
			Eminutos
			mxpjpd
			chp
			pdrl
			ventaMin
			ventaMinDer
		}
	}
`;

export const queryAllTopesDeportes = () => {
	return client
		.query({
			query: queryViewTopesGwDepo,
		})
		.then(({ data }) => {
			var { viewTopesDepo } = data;
			return viewTopesDepo;
		});
};

// ** Query Graphql Americana List **/
const queryViewTopesGwAme = gql`
	query viewTopesAme {
		viewTopesAme {
			cnf
			texto
			JugadaMaximaW
			JugadaMaximaP
			JugadaMaximaS
			DividendosMaximosW
			DividendosMaximosP
			DividendosMaximosS
			PremioW
			PremioP
			PremioS
			JMExacta
			DMExacta
			JMTrifecta
			DMTrifecta
			JMSuperfecta
			DMSuperfecta
		}
	}
`;
export const queryAllTopesAmericana = () => {
	return client
		.query({
			query: queryViewTopesGwAme,
		})
		.then(({ data }) => {
			var { viewTopesAme } = data;
			return viewTopesAme;
		});
};
// ** Query Graphql Nacionales List **/
const queryViewTopesGwNac = gql`
	query viewTopesNac {
		viewTopesNac {
			cnf
			texto
			ParticipaGana
			ParticipaPierde
			PorcentajeVentas
			IDBL
			EliminarTicket
			jSONcnf
		}
	}
`;
export const queryAllTopesNacionales = () => {
	return client
		.query({
			query: queryViewTopesGwNac,
		})
		.then(({ data }) => {
			var { viewTopesNac } = data;
			return viewTopesNac;
		});
};
// ** Query Graphql Loteria List **/
const queryViewTopesGwLot = gql`
	query viewTopesLot {
		viewTopesLot {
			cnf
			texto
			participacionPorcen
			ventasPorcentaje
			ticketanular
			maximoporlott
			topesxapuesta
		}
	}
`;
export const queryAllTopesLoterias = () => {
	return client
		.query({
			query: queryViewTopesGwLot,
		})
		.then(({ data }) => {
			var { viewTopesLot } = data;
			return viewTopesLot;
		});
};

const CnfNacionales = gql`
  query CnfNacionales {
    viewCngq {
      cnfBlt {
        idblt
        texto
        boleto
      }
      cnfHipo {
        idhipo
        texto
        siglas
        estatus
      }
    }
  }
`;
export  const cnfNacionalesFn=()=> {
	return client
	  .query({
		query: CnfNacionales,
	  })
	  .then(({data}) => {
		const {viewCngq} = data;
		return viewCngq
	  });
  }