import React from "react";
import { Form, Select, Col, Row, Button, Input } from "antd";
import { grabarTope } from "../../../service/mutationTopes";
import { viewMesseger } from "../../../service/viewMesseger";

const FormItem = Form.Item;
const Option = Select.Option;
const defaultSelection = {
	tope: -1,
	texto: "",
	cnfD: 0,
	cnfA: 0,
	cnfN: 0,
	cnfL: 0,
};
const fromTopes = ({
	form,
	dataDepo = [],
	dataAme = [],
	dataNaci = [],
	dataLote = [],
	recordSelect,
	sumitModalControl,
}) => {
	const { getFieldDecorator, resetFields } = form;
	recordSelect = recordSelect ? recordSelect : defaultSelection;
	const onProcessServiceTope = (registo, option) => {
		grabarTope(registo, option).then((resol) => {
			if (resol === null) {
				viewMesseger("error-network");
			} else if (resol) {
				viewMesseger("ok");
				sumitModalControl(false);
				resetFields();
			} else viewMesseger("error");
		});
	};

	const handleWebsiteChange = (e) => {
		e.preventDefault();
		form.validateFields((err, valores) => {
			if (!err) {
				valores.itope = recordSelect.tope;
				onProcessServiceTope(valores, 0);
			}
		});
	};
	const handleDeleteTope = () => {
		let valores = {
			itope: recordSelect.tope,
		};
		onProcessServiceTope(valores, 1);
	};
	const handleExit = () => {
		resetFields();
		sumitModalControl(false);
	};
	return (
		<Form onSubmit={handleWebsiteChange} className="FormTopeGenerales">
			<Row gutter={[16, 16]}>
				<Row>
					<Col>
						<FormItem label="Descricion del tope:">
							{getFieldDecorator("nombre", {
								initialValue: recordSelect.texto,
								rules: [
									{
										type: "string",
										message: "Debe tener alguna descripcion !",
									},
									{
										required: true,
										message: "Por favor requiero alguna descripcion !",
									},
								],
							})(<Input />)}
						</FormItem>
					</Col>
				</Row>
				<Row className="from-Topes-Rows-1">
					<Row>
						<Col span={11}>
							<FormItem label="Tope Deportes:">
								{dataDepo ? (
									getFieldDecorator("select1", {
										initialValue: Number(recordSelect.cnfD),
										rules: [
											{
												required: true,
												message:
													"Por favor seleccione el tope a utilizar para deportes!",
											},
										],
									})(
										<Select>
											<Option key={`A0`} value={0} disabled>
												Seleccion el Tope
											</Option>
											{dataDepo.map((value) => (
												<Option key={`A${value.cnf}`} value={value.cnf}>
													{value.cnf}.-{value.texto}
												</Option>
											))}
										</Select>
									)
								) : (
									<div></div>
								)}
							</FormItem>
						</Col>
						<Col span={11} offset={2}>
							<FormItem label="Tope Americanas:">
								{dataAme ? (
									getFieldDecorator("select2", {
										initialValue: Number(recordSelect.cnfA),
										rules: [
											{
												required: true,
												message:
													"Por favor seleccione el tope a utilizar para americanas!",
											},
										],
									})(
										<Select>
											<Option key={`B0`} value={0} disabled>
												Seleccion el Tope
											</Option>
											{dataAme.map((value) => (
												<Option key={`B${value.cnf}`} value={value.cnf}>
													{value.cnf}.-{value.texto}
												</Option>
											))}
										</Select>
									)
								) : (
									<div></div>
								)}
							</FormItem>
						</Col>
					</Row>
					<Row>
						<Col span={11}>
							<FormItem label="Tope Nacionales:">
								{dataNaci ? (
									getFieldDecorator("select3", {
										initialValue: Number(recordSelect.cnfN),
										rules: [
											{
												required: true,
												message:
													"Por favor seleccione el tope a utilizar para nacionales!",
											},
										],
									})(
										<Select>
											<Option key={`C0`} value={0} disabled>
												Seleccion el Tope
											</Option>
											{dataNaci.map((value) => (
												<Option key={`C${value.cnf}`} value={value.cnf}>
													{value.cnf}.-{value.texto}
												</Option>
											))}
										</Select>
									)
								) : (
									<div></div>
								)}
							</FormItem>
						</Col>
						<Col span={11} offset={2}>
							<FormItem label="Tope Loteria:">
								{dataLote ? (
									getFieldDecorator("select4", {
										initialValue: Number(recordSelect.cnfL),
										rules: [
											{
												required: true,
												message:
													"Por favor seleccione el tope a utilizar para loteria!",
											},
										],
									})(
										<Select>
											<Option key={`D0`} value={0} disabled>
												Seleccion el Tope
											</Option>
											{dataLote.map((value) => (
												<Option key={`D${value.cnf}`} value={value.cnf}>
													{value.cnf}.-{value.texto}
												</Option>
											))}
										</Select>
									)
								) : (
									<div></div>
								)}
							</FormItem>
						</Col>
					</Row>
				</Row>
				<Row className="from-Topes-Rows-2">
					<FormItem>
						<Row gutter={16}>
							<Col span={8}>
								<Button name="Aceptar" type="primary" htmlType="submit">
									{!recordSelect.tope === -1
										? "Grabar Nuevo Tope"
										: "Registrar el Cambio"}
								</Button>
							</Col>
							<Col span={8}>
								<Button onClick={handleExit}>Cancelar</Button>
							</Col>
							{recordSelect.tope !== -1 ? (
								<Col span={8}>
									<Button
										onClick={handleDeleteTope}
										name="Borrar"
										type="danger"
									>
										Borrar
									</Button>
								</Col>
							) : (
								""
							)}
						</Row>
					</FormItem>
				</Row>
			</Row>
		</Form>
	);
};
const EnhancedForm = Form.create()(fromTopes);
export default EnhancedForm;
