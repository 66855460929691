import React, { useState, useEffect } from "react";
import { Tabs } from "antd";

import Cloudwindows from "../../../cloudwindows";

import ModalTheActivos from "./modal-Activos";
import ModalThePendietes from "./modal-Pendientes";

import { useTranssaccion } from "../../../hooks/useTranssacciones";

import RendeTable from "./rendeTable";
import RenderPendientes from "./renderPendientes";

import "./index.scss";

const TabPane = Tabs.TabPane;

const Index = ({ defaultActiveKey = "1", idPRV = "0" }) => {
	const [stateData, setstateData] = useState([]);
	const [stateActiveKey, setstateActiveKey] = useState(defaultActiveKey)
	const [stateDataDepositos, setStateDataDeposito] = useState([]);
	const [stateDataRetiros, setStateDataRetiros] = useState([]);

	const [stateModalActivos, setstateModalActivos] = useState({
		activo: false,
		title: "",
		iu: 0,
		Moneda: "",
	});

	const [stateModalPendientes, setstateModalPendientes] = useState({
		activo: false,
		title: "",
		iu: 0,
		Moneda: "",
		record: [],
	});

	const {
		updateQueryTranssacciones,
		updateQueryPendientes,
	} = useTranssaccion();

	useEffect(() => {
		updateQueryTranssacciones(idPRV).then(setstateData);
		updateQueryPendientes(idPRV, 0, 4, 0).then(setStateDataDeposito);
		updateQueryPendientes(idPRV, 0, 5, 0).then(setStateDataRetiros);
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stateModalActivos, stateModalPendientes,stateActiveKey,idPRV]);

	const openModalWINactivos = ({ activo, title, iu, Moneda }) => {
		setstateModalActivos({ activo, title, iu, Moneda });
	};
	const openModalWINpendientes = ({ activo, title, iu, Moneda, record }) => {
		setstateModalPendientes({ activo, title, iu, Moneda, record });
	};
	const handlenOnChange=(e)=>{
		setstateActiveKey(e)
	}
	const bodyInside = (
		<div key="Table-01" className="bodyInside-Transs">
			<Tabs type="card" defaultActiveKey={stateActiveKey} onChange={handlenOnChange}>
				<TabPane tab="Activos" key="1">
					<RendeTable dataop={stateData} openModal={openModalWINactivos} />
				</TabPane>
				<TabPane tab="Depositos para aprobar" key="2">
					<RenderPendientes
						data={stateDataDepositos}
						openModal={openModalWINpendientes}
						solicitud={1}
					/>
				</TabPane>
				<TabPane tab="Retiros para aprobar" key="3">
					<RenderPendientes
						data={stateDataRetiros}
						openModal={openModalWINpendientes}
						solicitud={2}
					/>
				</TabPane>
			</Tabs>
		</div>
	);

	return (
		<>
			<Cloudwindows
				title="Transacciones"
				classMain="mainWindowsiu-Transs"
				classMainInside="mainWindowsContent-Transs"
				classMainList="mainlistLlNacionales"
				classWindowsTxt="TextTitleLll-Transs"
				titleBkgn="#08364d"
				colorBkg="#FFF"
				inside={bodyInside}
				barrIcons={false}
				statusList={false}
			/>
			<ModalTheActivos
				stateModalWindow={stateModalActivos}
				sumitModalControl={openModalWINactivos}
				idPRV={idPRV}
			/>
			<ModalThePendietes
				stateModalWindow={stateModalPendientes}
				sumitModalControl={openModalWINpendientes}
				idPRV={idPRV}
			/>
		</>
	);
};
export default Index;
