import React, { useState, useEffect } from "react";
import { Form, Select, Col, Row, Button, Input, Tag, Divider } from "antd";

import { useTopesRecover } from "../../../../hooks/useTopesRecover";
import { grabarTopeAmericana } from "../../../../service/mutationTopes";
import { viewMesseger } from "../../../../service/viewMesseger";

const recordSelectDefautl = [
	{
		cnf: -1,
		texto: "",
		JugadaMaximaW: 0,
		JugadaMaximaP: 0,
		JugadaMaximaS: 0,
		DividendosMaximosW: 0,
		DividendosMaximosP: 0,
		DividendosMaximosS: 0,
		PremioW: 0,
		PremioP: 0,
		PremioS: 0,
		JMExacta: 0,
		DMExacta: 0,
		JMTrifecta: 0,
		DMTrifecta: 0,
		JMSuperfecta: 0,
		DMSuperfecta: 0,
	},
];

const FormItem = Form.Item;
const Option = Select.Option;

const IuTopesAmericana = ({
	form,
	dataAme = recordSelectDefautl,
	sumitModalControl,
}) => {
	const { getFieldDecorator, resetFields } = form;
	const { onRecovertRecordAme } = useTopesRecover();
	const [recordSelect, setrecordSelect] = useState(dataAme[0]);
	const selectDataAme =
		dataAme[0].cnf !== -1 ? recordSelectDefautl.concat(dataAme) : dataAme;

	useEffect(() => {
		setrecordSelect(dataAme[0]);
	}, [dataAme]);

	const onSaveProccessAmericana = (record, del) => {
		grabarTopeAmericana(record, del).then((value) => {
			if (value === null) {
				viewMesseger("error-network");
			} else if (value) {
				viewMesseger("ok");
				sumitModalControl(false);
			} else viewMesseger("error");
			resetFields();
		});
	};

	const handleOnSaveAmericana = (e) => {
		e.preventDefault();
		form.validateFields((err, valores) => {
			if (!err) onSaveProccessAmericana(onRecovertRecordAme(valores), 0);
		});
	};
	const handleOnDeleteAmericana = () => {
		onSaveProccessAmericana(onRecovertRecordAme(recordSelect), 1);
	};
	const handleExit = () => {
		resetFields();
		sumitModalControl(false);
	};

	const handleChangeAmericana = (e) => {
		resetFields();
		let idx = selectDataAme.findIndex((props) => props.cnf === e);
		setrecordSelect(selectDataAme[idx]);
	};

	return (
		<Form onSubmit={handleOnSaveAmericana}>
			<Row className="from-Topes-Col-Bottom">
				<Row>
					<Col span={11}>
						<FormItem label="Numero de Tope:">
							{getFieldDecorator("cnf", { initialValue: recordSelect.cnf })(
								<Select onChange={handleChangeAmericana}>
									{selectDataAme.map((value) => (
										<Option key={`A${value.cnf}`} value={value.cnf}>
											{value.cnf === -1 ? (
												<Tag color="#f50">Nuevo</Tag>
											) : (
												`${value.cnf}.-${value.texto}`
											)}
										</Option>
									))}
								</Select>
							)}
						</FormItem>
					</Col>
					<Col span={11} offset={1}>
						<FormItem label="Nombre del Tope:">
							{getFieldDecorator("texto", {
								initialValue: recordSelect.texto,
								rules: [
									{
										type: "string",
										message: "Debe tener alguna descripcion !",
									},
									{
										required: true,
										message: "Por favor requiero alguna descripcion !",
									},
								],
							})(<Input />)}
						</FormItem>
					</Col>
				</Row>
				<Row className="from-Topes-Row">
					<Col span={7} className="from-Topes-Col">
						<Divider orientation="center" className="from-Topes-Divider">
							Ganadores (Win)
						</Divider>
						<FormItem label="Jugada Maxima">
							{getFieldDecorator("JugadaMaximaW", {
								initialValue: recordSelect.JugadaMaximaW,
								rules: [
									{ required: true, message: "Por favor requiero este dato!" },
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
								],
							})(<Input />)}
						</FormItem>
						<FormItem label="Dividendos Maximo">
							{getFieldDecorator("DividendosMaximosW", {
								initialValue: recordSelect.DividendosMaximosW,
								rules: [
									{ required: true, message: "Por favor requiero este dato!" },
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
								],
							})(<Input />)}
						</FormItem>
						<FormItem label="Premio">
							{getFieldDecorator("PremioW", {
								initialValue: recordSelect.PremioW,
								rules: [
									{ required: true, message: "Por favor requiero este dato!" },
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
								],
							})(<Input />)}
						</FormItem>
					</Col>
					<Col span={7} offset={1} className="from-Topes-Col">
						<Divider orientation="center" className="from-Topes-Divider">
							Segundo (Place)
						</Divider>
						<FormItem label="Jugada Maxima">
							{getFieldDecorator("JugadaMaximaP", {
								initialValue: recordSelect.JugadaMaximaP,
								rules: [
									{ required: true, message: "Por favor requiero este dato!" },
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
								],
							})(<Input />)}
						</FormItem>
						<FormItem label="Dividendos Maximo">
							{getFieldDecorator("DividendosMaximosP", {
								initialValue: recordSelect.DividendosMaximosP,
								rules: [
									{ required: true, message: "Por favor requiero este dato!" },
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
								],
							})(<Input />)}
						</FormItem>
						<FormItem label="Premio">
							{getFieldDecorator("PremioP", {
								initialValue: recordSelect.PremioP,
								rules: [
									{ required: true, message: "Por favor requiero este dato!" },
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
								],
							})(<Input />)}
						</FormItem>
					</Col>
					<Col span={7} offset={1} className="from-Topes-Col">
						<Divider orientation="center" className="from-Topes-Divider">
							Tercero (Show)
						</Divider>
						<FormItem label="Jugada Maxima">
							{getFieldDecorator("JugadaMaximaS", {
								initialValue: recordSelect.JugadaMaximaS,
								rules: [
									{ required: true, message: "Por favor requiero este dato!" },
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
								],
							})(<Input />)}
						</FormItem>
						<FormItem label="Dividendos Maximo">
							{getFieldDecorator("DividendosMaximosS", {
								initialValue: recordSelect.DividendosMaximosS,
								rules: [
									{ required: true, message: "Por favor requiero este dato!" },
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
								],
							})(<Input />)}
						</FormItem>
						<FormItem label="Premio">
							{getFieldDecorator("PremioS", {
								initialValue: recordSelect.PremioS,
								rules: [
									{ required: true, message: "Por favor requiero este dato!" },
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
								],
							})(<Input />)}
						</FormItem>
					</Col>
				</Row>
				<Row className="from-Topes-Row">
					<Col span={7} className="from-Topes-Col">
						<Divider orientation="center" className="from-Topes-Divider">
							Exacta
						</Divider>
						<FormItem label="Jugada Maxima">
							{getFieldDecorator("JMExacta", {
								initialValue: recordSelect.JMExacta,
								rules: [
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
								],
							})(<Input />)}
						</FormItem>
						<FormItem label="Dividendo Maxima">
							{getFieldDecorator("DMExacta", {
								initialValue: recordSelect.DMExacta,
								rules: [
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
								],
							})(<Input />)}
						</FormItem>
					</Col>

					<Col span={7} offset={1} className="from-Topes-Col">
						<Divider orientation="center" className="from-Topes-Divider">
							Trifecta
						</Divider>
						<FormItem label="Jugada Maxima">
							{getFieldDecorator("JMTrifecta", {
								initialValue: recordSelect.JMTrifecta,
								rules: [
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
								],
							})(<Input />)}
						</FormItem>
						<FormItem label="Dividendo Maxima">
							{getFieldDecorator("DMTrifecta", {
								initialValue: recordSelect.DMTrifecta,
								rules: [
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
								],
							})(<Input />)}
						</FormItem>
					</Col>
					<Col span={7} offset={1} className="from-Topes-Col">
						<Divider orientation="center" className="from-Topes-Divider">
							SuperFecta
						</Divider>
						<FormItem label="Jugada Maxima">
							{getFieldDecorator("JMSuperfecta", {
								initialValue: recordSelect.JMSuperfecta,
								rules: [
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
								],
							})(<Input />)}
						</FormItem>
						<FormItem label="Dividendo Maxima">
							{getFieldDecorator("DMSuperfecta", {
								initialValue: recordSelect.DMSuperfecta,
								rules: [
									{
										type: "number",
										message: "Este valor NO es un numero !",
										transform(value) {
											return Number(value);
										},
									},
								],
							})(<Input />)}
						</FormItem>
					</Col>
				</Row>
			</Row>
			<FormItem>
				<Row gutter={16}>
					<Col span={8}>
						<Button type="primary" htmlType="submit">
							{recordSelect.cnf !== -1
								? "Actualizar Registro"
								: "Registra Nuevo"}
						</Button>
					</Col>
					<Col span={8}>
						<Button onClick={handleExit}>Cancelar</Button>
					</Col>
					{recordSelect.cnf !== -1 ? (
						<Col span={8}>
							<Button type="danger" onClick={handleOnDeleteAmericana}>
								Borrar
							</Button>
						</Col>
					) : (
						""
					)}
				</Row>
			</FormItem>
		</Form>
	);
};

const EnhancedForm = Form.create()(IuTopesAmericana);

export default EnhancedForm;
