import React from "react";
import { Form, DatePicker, Button, Input, Row, Col } from "antd";
import { mutationRegistroCuentasFn } from "../../../../service/muttationTransacciones";
import { viewMesseger } from "../../../../service/viewMesseger";
const FormItem = Form.Item;

const Index = ({ form, iu, sumitModalControl }) => {
	const { getFieldDecorator, resetFields } = form;

	const onSaveProccessTransaccion = (registro, iu, ts) => {
		const tvalue = registro.fecha.format("YYYY-MM-DD HH:MM");
		const fecha = Math.round(new Date(tvalue).getTime() / 1000.0).toString();
		const estatus = 1;
		const recordnew = {
			fecha,
			iu,
			ts,
			monto: Number(registro.monto),
			text: registro.texto,
			estatus,
		};
		mutationRegistroCuentasFn(recordnew).then((value) => {
			if (value === null) {
				viewMesseger("error-network");
			} else if (value) {
				viewMesseger("ok");
				sumitModalControl();
			} else viewMesseger("error");
			resetFields();
		}); /// Graba la Transsaccion
	};
	const handleWebsiteChange = (e) => {
		e.preventDefault();
		form.validateFields((err, valores) => {
			if (!err) onSaveProccessTransaccion(valores, iu, 2);
		});
	};

	return (
		<div className="from-transsacc-Retiro">
			<div className="from-transsacc-Retiro-title">
				{" "}
				<label>RETIRO</label>
			</div>
			<Form onSubmit={handleWebsiteChange}>
				<FormItem label="Fecha de Transsaccion:">
					{getFieldDecorator("fecha", {
						rules: [
							{
								type: "object",
								required: true,
								message: "Por Favor coloque la fecha de la transsaccion!",
							},
						],
					})(<DatePicker showTime format="DD-MM-YYYY HH:mm:ss" />)}
				</FormItem>
				<FormItem label="Monto a retirar">
					{getFieldDecorator("monto", {
						rules: [
							{ required: true, message: "Por favor requiero este dato!" },
							{
								type: "number",
								message: "Este valor NO es un numero !",
								transform(value) {
									return Number(value);
								},
							},
						],
					})(<Input />)}
				</FormItem>
				<FormItem label="Descripcion:">
					{getFieldDecorator("texto", {
						rules: [
							{
								type: "string",
								message: "Debe tener alguna descripcion !",
							},
							{
								required: true,
								message: "Por favor requiero alguna descripcion !",
							},
						],
					})(<Input />)}
				</FormItem>
				<FormItem>
					<Row gutter={20}>
						<Col span={10}>
							<Button type="primary" htmlType="submit">
                            Registra Retiro
							</Button>
						</Col>
						<Col span={10}>
							<Button>Cancelar</Button>
						</Col>
					</Row>
				</FormItem>
			</Form>
		</div>
	);
};
const EnhancedForm = Form.create()(Index);

export default EnhancedForm;
