import React, { Component } from 'react';
import {Row,Col,Tabs,Input,Tag} from "antd";
import {colorWin,colorLtr} from './globals.js';

const _ = require('lodash');

const { TabPane } = Tabs;

const InputGroup = Input.Group;
// var HorsexRace=[]
class cloud_Gana_Jnd_Ejem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            HorsexRace:[]
       }
    }
    onChangeHorse=(register)=>{
        var {HorsexRace}=this.state;
        var {i,index,name}=register;

        var idx=_.findIndex(HorsexRace[index], (o)=> { return o.i === i; });
        if (idx!==-1)
            HorsexRace[index][idx].name=name;
        
         this.setState({HorsexRace});
    }

    initCallVal=()=>{
        var {HorsexRace}=this.state;
        var {RowsSetting,Ejemplares}=this.props;
        var autoriza=false
        ///// Valores para Cantidad Ejemplares por Carrera
        if (RowsSetting.length!==HorsexRace.length) 
            autoriza=true
        else{
            for (const prop in RowsSetting) {
                var {cantidad}=RowsSetting[prop];
                if (cantidad!==HorsexRace[prop].length) {
                    autoriza=true;
                    break;
                }
            }
        }
        if (autoriza){
                var newHorsexRace=[];
                for (const prop in RowsSetting) {
                    console.log({Ejemplares,prop})
                    var {scratch}=RowsSetting[prop];
                    const lcantidad= RowsSetting[prop].cantidad;
                    let {horse} = typeof Ejemplares[prop] !== 'undefined' ? Ejemplares[prop]:{horse:[]}
                    var linea=[]
                    for (let i=1;i<=lcantidad;i++){
                        let recordHorse = horse.find(o => o.Noeje === i);
                        var add='',name='';
                        if (recordHorse) name=recordHorse.Nombre;
                        if (HorsexRace.length!==0)
                            if (!_.isUndefined(HorsexRace[prop])) 
                             if (!_.isUndefined(HorsexRace[prop][i])) 
                                name=HorsexRace[prop][i].name;

                        if (i<=9) add='0';
                        linea.push({
                            i,
                            ident:`${add}${i}`,
                            name,
                            status:scratch.indexOf(i)===-1
                        })
                    }
                    newHorsexRace.push(linea);
                }
                this.setState({ HorsexRace:newHorsexRace })
        }
       
    }
  
    componentDidUpdate=()=>{
        this.initCallVal();
    }
    componentDidMount=()=>{
        // const {Ejemplares}=this.props;
        // let horseData=[]
        // for (const prop in Ejemplares) {
        //     let {Carrera,cantEje,horse} =Ejemplares[prop];
        //     horseData[Carrera]=[];
        //     for (const prop2 in horse) {
        //         let {Noeje,Nombre,status} = horse[prop2];
        //         horseData[Carrera][Noeje].name=Nombre
        //     }

        // }
        // console.log('horseData',horseData)
        // this.setState({HorsexRace:horseData})
        this.initCallVal();
    }
    render() {
        var {HorsexRace}=this.state;
      
        return (
            <Row>

                 <Tabs defaultActiveKey="1">
                     {HorsexRace.map((value,indexp)=>
                         <TabPane tab={`Carrera ${indexp+1}`} key={indexp.toString()}>
                              <Row className="CloudGanaRow" justify="center">
                                  <Col span={14} ><label>Nombre de Ejemplares</label></Col>
                              </Row>
                            {
                                value.map((record,index)=>
                                    <Row key={index.toString()} className="CloudGanaSettingEjemplares">
                                          <Col span={14}>
                                                <InputGroup compact>
                                                    <Tag   color={colorWin[record.i-1]} style={{color:colorLtr[record.i-1]}} >
                                                        {record.ident}
                                                    </Tag>
                                                    {record.status?
                                                    <Input 
                                                     onChange={(event)=>this.onChangeHorse({i:record.i,index:indexp,name:event.target.value})}
                                                     defaultValue={record.name}
                                                     size="small"
                                                     disabled={!record.status}
                                                     placeholder={record.status?`Nombre de Ejemplar ${record.i}`:`RETIRADO`}
                                                     style={{width:'90%',height: '22px',border:'none'}}  />:
                                                     <label className="CloudGanaSettingEjemplares_Scratch">RETIRADO</label>
                                                     }
                                                </InputGroup>
                                            </Col>  
                                    </Row>
                                )

                            }
                         </TabPane>
                     
                     )}
                </Tabs>
            </Row>
        );
    }
}

export default cloud_Gana_Jnd_Ejem;

// <Input 
// prefix={(
// <Tag   color={colorWin[record.i-1]} style={{color:colorLtr[record.i-1]}} >
//     {record.i}
// </Tag> )}
// defaultValue={record.name}
// size="small"
// placeholder={`Nombre de Ejempar ${record.i}`} 
// />