const recordGraphp_Deport={
	cnf: "cnf",
	texto: "texto",
	mmjpd: "mmjpd", 
	mmjpp: "mmjpp", 
	maxpremio: "maxpremio", 
	mmdp: "mmdp", 
	mma: "mma",
	mma2: "mma2",
	cjmp: "cjmp", 
	cdpi: "cdpi",
	Participacion1: "Participacion1",
	pVentas: "pVentas", 
	cmaxelim: "cmaxelim", 
	Participacion2: "Participacion2", 
	pVentaspd: "pVentaspd", 
	Eminutos: "Eminutos", 
	mxpjpd: "mxpjpd", 
	chp: "chp", 
	pdrl: "pdrl",
	ventaMin:"ventaMin",
	ventaMinDer:"ventaMinDer"
}
const recordGraphp_Americ = {
	cnf: "cnf",
	texto: "texto",
	dw: "DividendosMaximosW",
	dp: "DividendosMaximosP",
	ds: "DividendosMaximosS",
	jw: "JugadaMaximaW",
	jp: "JugadaMaximaP",
	js: "JugadaMaximaS",
	pw: "PremioW",
	pp: "PremioP",
	ps: "PremioS",
	jme: "JMExacta",
	dme: "DMExacta",
	jmt: "JMTrifecta",
	dmt: "DMTrifecta",
	jms: "JMSuperfecta",
	dms: "DMSuperfecta",
};
const recordGraphp_Nacional = {
	cnf: "cnf",
	texto: "texto",
	ParticipaGana: "ParticipaGana",
	ParticipaPierde: "ParticipaPierde",
	PorcentajeVentas: "PorcentajeVentas",
	IDBL: "IDBL",
	EliminarTicket: "EliminarTicket",
	jSONcnf: "jSONcnf",
};
// const zipJsonField = ["CuposMaxi_", "DividendoBanca_", "DividendosMaximos_"];

export function useTopesRecover() {
	const onRecovertRecordDep = (valores) => {
        const recordGraphp=recordGraphp_Deport
		let newRecordGraph = {};
		for (let props in recordGraphp) {
			let field = props;
			let value = recordGraphp[field];
			let fieldValue = valores[value];
			if (!fieldValue) newRecordGraph[props] = 0;
			else
				newRecordGraph[props] = isNaN(Number(fieldValue))
					? fieldValue
					: Number(fieldValue);
		}
		return newRecordGraph;
	};
	const onRecovertRecordNac = (valores, RecordHipi) => {
        const recordGraphp=recordGraphp_Nacional
		let newRecordGraph = {};
		for (let props in recordGraphp) {
			let field = props;
			let value = recordGraphp[field];
			let fieldValue = valores[value];
			if (!fieldValue) newRecordGraph[props] = 0;
			else
				newRecordGraph[props] = isNaN(Number(fieldValue))
					? fieldValue
					: Number(fieldValue);
		}
		let jsonTxt = [];
		for (var rows in RecordHipi) {
			let { id } = RecordHipi[rows];
			jsonTxt.push({
				idhipo: id,
				DividendosMaximos: valores[`DividendosMaximos_${id}`],
				DividendoBanca: valores[`DividendoBanca_${id}`],
				CuposMaxi: valores[`CuposMaxi_${id}`],
			});
		}
		// for (let props in valores) {
		// 	let  isZipJson=zipJsonField.map(val => props.search(val) !== -1 ).some(val=>val);
		// 	console.log(props,valores[props],isZipJson)
		// 	if (isZipJson){

		// 		jsonTxt[props]=valores[props]
		// 	}
		// }
		newRecordGraph.jSONcnf = JSON.stringify(jsonTxt);
		return newRecordGraph;
    }
    
    const onRecovertRecordAme = (valores) => {
        const recordGraphp=recordGraphp_Americ
		let newRecordGraph = {};
		for (let props in recordGraphp) {
			let field = props;
			let value = recordGraphp[field];
			let fieldValue = valores[value];
			if (!fieldValue) newRecordGraph[props] = 0;
			else
				newRecordGraph[props] = isNaN(Number(fieldValue))
					? fieldValue
					: Number(fieldValue);
		}
		return newRecordGraph;
	};

	return { onRecovertRecordDep,onRecovertRecordNac,onRecovertRecordAme };
}
