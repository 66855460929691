import React,{ Component } from "react";
import {Modal,Input,Select,Avatar,DatePicker} from "antd";
import moment from 'moment';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import ComponetLoteria from "./componetLoteria";
import 'overlayscrollbars/css/OverlayScrollbars.css';

import store from './store';
import * as func from './function';
import   './cloudltEscrute.scss';
import   './overlayroutheme.css';

var _ =  require('lodash');
const encode = require('nodejs-base64-encode');
const { Option } = Select;
class FromEditLottery extends Component {
    constructor(props){
        super(props);
        this.state={
            id:-1,
            idj:0,
            mfecha:moment(moment().format('YYYY-M-DD'), 'YYYY-M-DD'),
            rngLtrs:['A','B','C','D','E'],
            activarPrm:[0,0,0],
            lstapuestas:[],
            listDataLt:[],
            listHorario:[],
            listData:[],
            lisComb:[],
            globalData:[],
            PremioxAp:[],
            valuePrm:'',
            valueSel:'',
            titleModalEsc:'',
            etiqueta:'',
            visible:false,
            genPremio:[],
            seleccion:{}
        }
        this.queryLtlstapuestasFn=func.queryLtlstapuestasFn.bind(this);
        this.queryApuestaltFn=func.queryApuestaltFn.bind(this);
        this.muttationResultadosLtFn=func.muttationResultadosLtFn.bind(this);
        this.view_blockResuladosLtFn=func.view_blockResuladosLtFn.bind(this)
        store.subscribe(() => {
            this.setState({
                listDataLt:store.getState().listDataLt,
                globalData:store.getState().globalData,
            })
        })
    }
    handChgSeleccAp=()=>{
        var {id}=this.props;
        var {PremioxAp,rngLtrs}=this.state;
        var mayor=0;
        var comodin=false;
        var type=[],valLeng=[],listHorario;
        var listDataLt=store.getState().listDataLt
        var globalData=store.getState().globalData
        var lstapuestas=globalData.premios;
        var apuestasval=globalData.apuestas;
        var lisComb=[];
       // lstapuestas:[{id:1,text:'Terminal',type:1,leng:2,combi:false},
        // 3:{id:11,text:"Animalitos",type:3,leng:0,combi:false}
        var idx=_.findIndex(listDataLt, (o)=> { return o.id === id; });
        listHorario=listDataLt[idx].sorteo;
        var soloApuestas=listDataLt[idx].premiacion;
        var e=lstapuestas;
        //// Ordena las apuestas de acuerdo a la Zona Asignada!!
        soloApuestas.sort(function(a, b){
            if(a.lismark[0].ltr === b.lismark[0].ltr){
                var x = a.lismark[0].ltr.charCodeAt(0), y = b.lismark[0].ltr.charCodeAt(0);
                return x < y ? -1 : x > y ? 1 : 0;
            }
            return a.lismark[0].ltr.charCodeAt(0) - b.lismark[0].ltr.charCodeAt(0);
        });
        /////////////////////////////////////////////////////////

        for (let j=0;j<=soloApuestas.length-1;j++){
            lisComb=[];
            var idn=_.findIndex(lstapuestas, (o)=> { return o.id === soloApuestas[j].id; })
            if (lstapuestas[idn].leng>=mayor) mayor=lstapuestas[idn].leng;
            if (lstapuestas[idn].combi) comodin=true; else comodin=false;
            var tp=lstapuestas[ idn ].type;
                
            if (tp===4){
                idx=_.findIndex(apuestasval, (o)=> { return o.id === soloApuestas[j].id; });
                var {data}=apuestasval[idx];
                var valores=JSON.parse(data);
                const {ListaCombinacion}=valores[1];
                idx=_.findIndex(apuestasval, (o)=> { return o.id === ListaCombinacion; });
      
                lisComb=JSON.parse(apuestasval[idx].data);
            }
            if (tp===3){
                idx=_.findIndex(apuestasval, (o)=> { return o.id === soloApuestas[j].id; });
                lisComb=JSON.parse(apuestasval[idx].data);
            }
            valLeng=[{leng:lstapuestas[idn].leng}]
            type.push({type:lstapuestas[idn].type,value:valLeng,lisComb,zona:''});
        }
        console.log(type)
        // for (let j=0;j<=e.length-1;j++){
        //     var idn=j;
        //     var ida=lstapuestas[idn].id;
        //     lisComb=[];
        //     if (_.findIndex(soloApuestas, (o)=> { return o.id === ida; })!==-1){
        //         if (lstapuestas[idn].leng>=mayor) mayor=lstapuestas[idn].leng;
        //         if (lstapuestas[idn].combi) comodin=true; else comodin=false;

        //         var tp=lstapuestas[ idn ].type;
                
        //         if (tp===4){
        //             var idx=_.findIndex(apuestasval, (o)=> { return o.id === ida; });
        //             var {data}=apuestasval[idx];
        //             var valores=JSON.parse(data);
        //             var {ListaCombinacion}=valores[1];
        //             idx=_.findIndex(apuestasval, (o)=> { return o.id === ListaCombinacion; });
        //             var {data}=apuestasval[idx];
        //             var lisComb=JSON.parse(data);
        //         }

        //         var idx=_.findIndex(type, (o)=> { return o.type === tp; });
        //         if (idx===-1){
        //             valLeng=[{leng:lstapuestas[idn].leng}]
        //             type.push({type:lstapuestas[idn].type,value:valLeng,lisComb});
        //         }else{
        //             valLeng=type[idx].value;
        //             valLeng.push({leng:lstapuestas[idn].leng})
        //             type[idx].value=valLeng
        //             type[idx].lisComb=lisComb; 
        //         }
        //     }
        // }
        var zona=0,zonaAlt=[];
        lisComb=[];
        for (const rows in type){
            if (type[rows].type===4 || type[rows].type===1 || type[rows].type===3 ){
                
                lisComb=_.concat(lisComb,type[rows].lisComb)
                if (type[rows].lisComb.length!==0) zonaAlt.push(zona)
                zona++;
                type[rows].zona=zona;
            }else{
                var x=_.groupBy(type[rows].value, parseInt);
                for (var rows2 in x){
                    if (x[rows2].length>1) zona++;
                }
            }
        }
        var genPremio=[],temPremio=[];
        if (zona===0) zona=1;
        
            for (let j=0;j<=zona-1;j++){
                temPremio=[];
                let ltype
                idx=_.findIndex(type, (o)=> { return o.zona === (j+1); });
                if (idx!==-1) 
                     ltype=type[idx]; else ltype=0;
                for (var i in listHorario){
                    const {key}=listHorario[i]
                    var idl=_.toNumber(id);
                    idx=_.findIndex(PremioxAp, (o)=> o.idl === id && o.idh === key && o.zona === rngLtrs[j]);
                    if (idx===-1){
                        PremioxAp.push({
                            idl,
                            idh:key,
                            zona:rngLtrs[j],
                            comodin,
                            value:0
                        })
                    }
                    idx=_.findIndex(temPremio, (o)=> { return o.idl === id && o.idh === key && o.zona === rngLtrs[j]; });
                    if (idx===-1){
                        comodin=_.indexOf(zonaAlt,j)===-1?0:1
                        
                        temPremio.push({
                            idl,
                            idh:key,
                            zona:rngLtrs[j],
                            comodin,
                            value:'',
                            comoSel:'',
                            comoId:-1,
                            type:ltype
                        })
                    }
                }
                genPremio.push({
                    zona:rngLtrs[j],
                    temPremio
                })
        }
        var  activarPrm=[zona,mayor,(comodin?1:0),lisComb];///0=1-Op A, 2-Op A y B, 1=Cant Digit, 2= 1 Con Comodin, 0 Sin Comodin
        this.setState({lisChkMark:e,activarPrm,listHorario,PremioxAp,genPremio,lisComb})
    }

    chgPremioEscCmd=(e)=>{
        var {seleccion,genPremio,lisComb,valueSel}=this.state;
        var {x,y}=seleccion
        var idx=_.findIndex(lisComb, (o)=> { return o.key === e });

        genPremio[x].temPremio[y].comoSel=lisComb[idx].name
        genPremio[x].temPremio[y].comoId=e
        valueSel=lisComb[idx].name;
        this.setState({genPremio,valueSel})
    }
    chgPremioEsc=(e)=>{
        var {seleccion,genPremio,valuePrm}=this.state;
        var {x,y}=seleccion
        genPremio[x].temPremio[y].value=e.target.value;
        valuePrm=e.target.value;
        this.setState({genPremio,valuePrm})
    }
    onclickpmr=(seleccion)=>{
        var {genPremio,listHorario}=this.state;
        var x=_.findIndex(genPremio, (o)=> { return o.zona === seleccion.zona });
        var iZona=genPremio[x].temPremio;
        var y=_.findIndex(iZona, (o)=> { return o.idh === seleccion.idh });
        //Premio A, 12:45pm" Titulo de cuadro de escrute
        var ikey=_.findIndex(listHorario, (o)=> { return o.key === seleccion.idh });
        var titleModalEsc=`Premio ${seleccion.zona}, ${listHorario[ikey].descri}`;
        ///////////////////////////////////////////////
        console.log(seleccion,x,y)
        this.setState({titleModalEsc,visible:true,valueSel:iZona[y].comoSel,valuePrm:iZona[y].value,seleccion:{x,y,comodin:iZona[y].comodin,type:seleccion.type}})
    }
    hideModal=()=>{
        this.setState({visible:false})
        this.handleSaveDatosEscrutes();
    }   
    hideModal_cancelar=()=>{
        this.setState({visible:false})
    }

    calldataSorteo_Esc=(Vr_prm,comodin,listaCmd)=>{
        var {listHorario,genPremio}=this.state;
        console.log(genPremio)
        return (
            <>
                <OverlayScrollbarsComponent className="area"
                    options={{  className            : "os-theme-round-dark" }} 
                >
                <div className="esc_tblmain">
                    
                    <div className="esc_tbltitle">
                        <div className="esc_tblceld_black">
                        </div>
                        {listHorario.map((value,index)=>
                            <div  key={`p${index}`} className="esc_tblceld_hora">
                                {value.descri}
                            </div>
                        )}
                        
                    </div>
                    {genPremio.map((value,index)=>
                    <div key={`grP${index}`} className="esc_tblline">
                        <div className="esc_tblceld_main">
                            <div className="esc_tblceld_avatar">{value.zona}</div>
                        </div>
                        {value.temPremio.map((value1,index1)=>
                        value1.type===3? 
                        <div key={`s${index1}`} className="esc_tblceld_data">
                            <div className="esc_tblceld_data_prm_esp" onClick={()=>this.onclickpmr({zona:value.zona,idh:value1.idh,type:3})}>
                                {_.toUpper(value1.comoSel)}
                            </div>
                            {/* <div className=" esc_tblceld_data_ext"  >
                                {value1.value}
                            </div> */}
                            
                        </div>
                        :<div key={`s${index1}`} className="esc_tblceld_data">
                            <div className="esc_tblceld_data_prm"  onClick={()=>this.onclickpmr({zona:value.zona,idh:value1.idh,type:1})}>
                                {value1.value}
                            </div>
                            {value1.comodin===1 && 
                                <div className="esc_tblceld_data_ext">
                                    {_.toUpper(value1.comoSel.substring(0, 3))}
                                </div>
                            }
                        </div>
                        
                        )}
                    </div>
                    )}
                      <div className="esc_tblfooter">

                      </div>
                </div>
            </OverlayScrollbarsComponent>

            </>
    )}
    callonload= async ()=>{
        this.setState({
            listDataLt:store.getState().listDataLt,
            globalData:store.getState().globalData,
            id:this.props.id,
            etiqueta:this.props.etiqueta,
        })        
        this.callRefress(this.state.mfecha)
        this.handChgSeleccAp();
    }
    handleSaveDatosEscrutes=()=>{
        var {genPremio,id,idj}=this.state;
        var json=encode.encode(JSON.stringify(genPremio), 'base64'); 
        var input={
            idl:id,
            idj,
            json
        }
        this.muttationResultadosLtFn(input)
        console.log(input)
    }
    Changedate=(date)=>{
     this.setState({mfecha:date})
     this.callRefress(date)
     this.handChgSeleccAp();
    }
    callRefress=async (fecha)=>{
        await this.view_blockResuladosLtFn(fecha.format("DD-M-YYYY"),this.props.id)
    }
    componentDidMount=()=>{
        this.callonload()  
        
    }
    componentDidUpdate=(prevProps)=>{
        if (prevProps.id!==this.props.id) this.callonload()
    }

    render(){
        var {rngLtrs,activarPrm,lisComb,seleccion,mfecha}=this.state
        var TotalsPrm=activarPrm[0];
        // var CantDig=activarPrm[1];
        var comodin=activarPrm[2];
        var Vr_prm=[];
        for (let j=0;j<=TotalsPrm-1;j++) Vr_prm.push(rngLtrs[j])
        console.log(lisComb,seleccion)
        return(
            <>  <div className="datepckclass">
                    <label>Fecha : </label>
                    <DatePicker  defaultValue={mfecha} format="DD-M-YYYY" onChange={(date)=>this.Changedate(date)} />
                </div>
                {/* <div className="buttonpckclass">
                    <Button    onClick={()=>this.handleSaveDatosEscrutes(-1)} >Grabar Resultados</Button>
                </div> */}
                {this.calldataSorteo_Esc(Vr_prm,comodin,activarPrm[3])}
                
                <Modal
                        title={this.state.titleModalEsc}//"Premio A, 12:45pm"
                        wrapClassName="modal-Escrute-input"
                        closable={false}
                        visible={this.state.visible}
                        onOk={this.hideModal}
                        onCancel={this.hideModal_cancelar}
                        okText="Grabar"
                        cancelText="Cancelar"
                        >
                        <> 
                        <div className="lneAvatarModal">
                            <Avatar style={{ backgroundColor:'#f56a00', verticalAlign: 'middle' }} size="large" >{this.state.etiqueta}</Avatar>
                        </div>
                        {seleccion.type===1 &&
                            <div className="lneInputModal">
                                <Input
                                    value={this.state.valuePrm}
                                    size="large" 
                                    onChange={(e)=>this.chgPremioEsc(e)}
                                    // onPressEnter={(e)=>this.OnClickComprar()}
                                    ref="Escrute_Input"
                                    max={999}
                                    placeholder="Numero Ganador"
                                    /> 
                            </div>
                        }
                        <div  className="selInputModal">
                            {seleccion.comodin===1 &&
                                <Select 
                                    style={{ width: seleccion.type===1?150:200 }} 
                                    size="large" 
                                    onChange={(e)=>this.chgPremioEscCmd(e)}
                                    value={this.state.valueSel}
                                    >
                                    {lisComb.map((value,index)=>
                                        <Option  key={`opt${index}`} value={value.key}>{seleccion.type===1?value.name:'('+value.numero+') '+value.name}</Option>
                                    )}
                                </Select>
                            }
                        </div>
                        </>
                </Modal>
            </>
        )
    }

}

class FromEscruteLT extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
       
    }
    commandBarr_one=(type,app)=>{
       
        switch(type){
            case -1:
                app.onloadInside(<FromEditLottery id={-1} etiqueta=""/>)
                break;
            case -2:
                var json_data={
                    idl:this.state.id_mark,
                    text:''
                }
                this.mutationLoteriatsFn(json_data,2);
                break;
            default:
        }
    }
    onClickMainList_one=(id,index,etiqueta,app)=>{
        console.log('otro',id,etiqueta)
        this.setState({id_mark:id})
        app.onloadInside(<FromEditLottery id={id} etiqueta={etiqueta}/>)
    }

    render(){
        return(
            <ComponetLoteria title={"Escrute"} wth={'1000px'} barcommando={false} commandBarr_one={this.commandBarr_one} onClickMainList_one={this.onClickMainList_one}/>
        )
    }
}

export default FromEscruteLT;