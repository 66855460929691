import React from "react";
import { Icon, Tooltip } from "antd";

import IconBeisball from "../icons/iconBeisball";
import IconAmericana from "../icons/iconAmericana";
import IconNacionales from "../icons/iconNacionales";
import IconLotteri from "../icons/iconLotteri";

import "../icons/icons.scss";
import "./toolsbar.scss";

const ToolsTopes = ({ custtomHalden }) => {
	const [
		haldenPlus,
		haldenDeport,
		haldenAmericana,
		haldenNacional,
		haldenloteria,

	] = custtomHalden;
	return (
		<>
			<div>
				{haldenPlus && (
					<Tooltip placement="topLeft" title="Incluir Nuevo">
						<button onClick={haldenPlus} className="btn-toolbar">
							<Icon type="plus" style={{ fontSize: "21px" }} />
						</button>
					</Tooltip>
				)}
				{haldenDeport && (
					<Tooltip placement="topLeft" title="Topes Deportes">
						<button onClick={haldenDeport} className="btn-toolbar">
							<IconBeisball
								fill="#fff"
								height={16}
								width={16}
								className="iconTools add-iconTools"
							/>
						</button>
					</Tooltip>
				)}
				{haldenAmericana && (
					<Tooltip placement="topLeft" title="Topes Americana">
						<button onClick={haldenAmericana} className="btn-toolbar">
							<IconAmericana
								fill="#fff"
								height={24}
								width={24}
								className="iconTools"
							/>
						</button>
					</Tooltip>
				)}
				{haldenNacional && (
					<Tooltip placement="topLeft" title="Topes Nacionales">
						<button onClick={haldenNacional} className="btn-toolbar">
							<IconNacionales
								fill="#fff"
								height={24}
								width={24}
								className="iconTools"
							/>
						</button>
					</Tooltip>
				)}
				{haldenloteria && (
					<Tooltip placement="topLeft" title="Topes Loteria">
						<button onClick={haldenloteria} className="btn-toolbar">
							<IconLotteri
								fill="red"
								height={24}
								width={24}
								className="iconTools-2"
							/>
						</button>
					</Tooltip>
				)}
			</div>
		</>
	);
};

export default ToolsTopes;
