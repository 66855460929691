import React  from 'react';
import { Tag,Icon} from 'antd';
import  './getViewTk.scss';
import {styleBg,systeEt,exoticas} from './globals.js';
const _ = require('lodash');



export function line1(op,value,index,moneda) {
    console.log(op,value,index,moneda)
    /// Procesos especial de datos detalles

    switch(op){
        case 4:
            /// agrupando para mostrar solo eso detalle del ticket
                var {detalle}=value;
                var cloteria='',csorteo='',detakk=[],display=[];
                for (var i in detalle){
                    var {loteria,sorteo,numeros,monto,escrute}=detalle[i];
                    if (cloteria!==loteria || csorteo!==sorteo){
                        if (cloteria!=='' || csorteo!==''){
                            detakk.push({
                                loteria:cloteria,
                                sorteo:csorteo,
                                display
                            })
                        }
                        cloteria=loteria;
                        csorteo=sorteo;
                        display=[]
                    }
                    display.push({
                        numeros:`${numeros}x${monto}`,
                        escrute,

                    })
                }
                detakk.push({
                    loteria:cloteria,
                    sorteo:csorteo,
                    display
                })
                break;
        default:
    }



    switch(op) {
        case 1: return (
            <div className="bodyTicket">
                <div className="TextoMainTicket" style={{"background":value.status===1?styleBg[(value.isys-1)]:'#434446'}}><label className="serialTxt">{value.serial}</label> </div>
                <div className="TextoSecondTicket">
                    <div className="fechaTxt">Fecha:{value.fecha} </div>
                    <div className="horaTxt">Hora:{value.hora} </div>
                </div>
                <div className="TextoEspTicket">
                    <div className="IDC">Punto de Venta:{value.idc} </div>
                </div>
                <div className="divEtique">
                    {value.status!==1?
                    <div className="TextoAnuladoTicket">
                        <Tag color={'#310303'}  >ANULADO</Tag>
                    </div>:''}
                    <div className="TextoTreeTicket">
                        <Tag color={value.status===1?styleBg[(value.isys-1)]:'#434446'}  >{systeEt[value.isys-1]}</Tag>
                    </div>
                </div>
                <div className="TextoFourTicket">
                    <div className="tex1Txt">Hora </div>
                    <div className="tex2Txt">Partido </div>
                    <div className="tex3Txt">Odds</div>
                </div>

                <div className="BodyTicketDetalle">
                {value.detalle.map((value1,index1)=>
                    <div className="row_tk_dtt" key={index+''+index1}>
                        <div className={value.status===1?"cmp1Txt":"cmp1TxtAnu"}>{value1.hora} </div>
                        <div className={value.status===1?"cmp2Txt":"cmp2TxtAnu"}>{value1.equipo+' '+value1.apuesta} </div>
                        <div className={value.status===1?"cmp3Txt":"cmp3TxtAnu"}>{value1.odd}</div>
                        <div className="checkEscrute">
                        {value1.escrute!==''?
                                value1.escrute==='Gano' || value1.escrute==='Emp/Sup'?
                                    <Icon type="check" />:
                                    <Icon type="close" />
                                
                        :''}
                        </div>
                        
                    </div>
                )}
                </div>
                
                <div className="TextoFiveTicket">
                    <div className="apuestaTxt">Apuesta:</div>
                    <div className={value.status===1?"apuestaTxt_2":"apuestaTxt_2Anu"} >{new Intl.NumberFormat('es', { style: 'currency', currency:moneda }).format(value.apuesta)}</div>
                </div>
                <div className="TextoSixTicket">
                    <div className="cobraTxt">Posible Premio:</div>
                    <div className={value.status===1?"cobraTxt_2":"cobraTxt_2Anu"}>{new Intl.NumberFormat('es', { style: 'currency', currency:moneda }).format(value.cobra)}</div>
                </div>
                <div className="TextoSevenTicket">
                    <div className="ganaTxt">Posible Ganancia:</div>
                    <div className={value.status===1?"ganaTxt_2":"ganaTxt_2Anu"}>{new Intl.NumberFormat('es', { style: 'currency', currency:moneda}).format(value.cobra-value.apuesta)}</div>
                </div>
            </div>
        );
        case 2: return (
            
            <div className="bodyTicket">
                <div className="TextoMainTicket" style={{"background":value.estatus===0?styleBg[(value.isys-1)]:'#434446'}}><label className="serialTxt">{value.serial}</label> </div>
                <div className="TextoSecondTicket">
                    <div className="fechaTxt">Fecha:{value.fecha} </div>
                    <div className="horaTxt">Hora:{value.hora} </div>
                </div>
                <div className="TextoEspTicket">
                    <div className="IDC">Punto de Venta:{value.idc} </div>
                </div>
                <div className="divEtique">
                    {value.estatus!==0?
                    <div className="TextoAnuladoTicket">
                        <Tag color={'#310303'}  >ANULADO</Tag>
                    </div>:''}
                    <div className="TextoTreeTicket">
                        <Tag color={value.estatus===0?styleBg[(value.isys-1)]:'#434446'}  >{systeEt[value.isys-1]}</Tag>
                    </div>
                </div>
                <div className="tex1InfoTxt">{exoticas[value.tipo]}</div>
                <div className="TextoInfoAdd">
                    <div className="tex2InfoTxt">Hipodromo:{value.hipo}</div>
                    <div className="tex3InfoTxt">Carrera:{value.carr}</div>
                </div>
                
                <div className="TextoFourTicket">
                    <div className="tex1TxtA" >{value.tipo===0?"Ejem":"Pos"} </div>
                    <div className="tex2TxtA" >{value.tipo===0?"Nombre":"Jugada"} </div>
                    <div className="tex3TxtA" >{value.tipo===0?"Jug":"Apuesta"}</div>
                </div>

                <div className="BodyTicketDetalle">
                {value.detalle.map((value1,index1)=>
                    <div key={index+''+index1}>
                        
                        
                            <div className={value.estatus===0?"cmp1TxtA":"cmp1TxtAAnu"}>{value1.eje} </div>
                            <div className={value.estatus===0?"cmp2TxtA":"cmp2TxtAAnu"}>{value1.text} </div>
                            <div className={value.estatus===0?"cmp3TxtA":"cmp3TxtAAnu"}>
                            {value.tipo===0?
                                (value1.g===null?'':' Win: '+value1.g)+(value1.p===null?'':' Plc: '+value1.p)+(value1.s===null?'':' Shw: '+value1.s)
                                :
                                "x"+value1.g
                            }
                            </div>
                        
                    </div>
                )}
                </div>
                
                <div className="TextoFiveTicket">
                    <div className="apuestaTxt">Apuesta:</div>
                    <div className={value.estatus===0?"apuestaTxt_2":"apuestaTxt_2Anu"} >{new Intl.NumberFormat('es', { style: 'currency', currency:moneda }).format(value.apuesta)}</div>
                </div>
                <div className="TextoSixTicket">
                    <div className="cobraTxt">Posible Premio:</div>
                    <div className={value.estatus===0?"cobraTxt_2":"cobraTxt_2Anu"}>{new Intl.NumberFormat('es', { style: 'currency', currency:moneda }).format(value.cobra)}</div>
                </div>
                <div className="TextoSevenTicket">
                    <div className="ganaTxt">Posible Ganancia:</div>
                    <div className={value.estatus===0?"ganaTxt_2":"ganaTxt_2Anu"}>{new Intl.NumberFormat('es', { style: 'currency', currency:moneda}).format(value.cobra-value.apuesta)}</div>
                </div>
            </div>
            
        )
    case 3: return (
            
            <div className="bodyTicket">
                <div className="TextoMainTicket" style={{"background":value.estatus===0?styleBg[(value.isys-1)]:'#434446'}}><label className="serialTxt">{value.serial}</label> </div>
                <div className="TextoSecondTicket">
                    <div className="fechaTxt">Fecha:{value.fecha} </div>
                    <div className="horaTxt">Hora:{value.hora} </div>
                </div>
                <div className="TextoEspTicket">
                    <div className="IDC">Punto de Venta:{value.idc} </div>
                </div>
                <div className="divEtique">
                    {value.estatus!==0?
                    <div className="TextoAnuladoTicket">
                        <Tag color={'#310303'}  >ANULADO</Tag>
                    </div>:''}
                    <div className="TextoTreeTicket">
                        <Tag color={value.estatus===0?styleBg[(value.isys-1)]:'#434446'}  >{systeEt[value.isys-1]}</Tag>
                    </div>
                </div>
                <div className="tex1InfoTxt">{exoticas[value.tipo]}</div>
                <div className="TextoInfoAdd">
                    <div className="tex2InfoTxt">Hipodromo:{value.hipo}</div>
                    {/* <div className="tex3InfoTxt">{(value.tipo>=5?"Tanda:":"Carrera:")+value.carr}</div> */}
                    <div className="tex3InfoTxt">{value.tipo!==8?((value.tipo>=5?"Tanda:":"Carrera:")+value.carr):"P:"+_.sumBy(value.detalle,o=>{return o.g})}</div>
                </div>
                
                <div className="TextoFourTicket">
                    <div className="tex1TxtA" >{value.tipo===8?"Carr":(value.tipo===0 || value.tipo===4 ?"Ejem":value.tipo>=5?"Num":"Pos")} </div>
                    {/* <div className="tex1TxtA" >{value.tipo===0 || value.tipo===4 ?"Ejem":value.tipo>=5?"Num":"Pos"} </div> */}
                    <div className="tex2TxtA" >{value.tipo===0 || value.tipo===4 ?"Nombre":"Jugada"} </div>
                    {/* <div className="tex3TxtA" >{value.tipo===0?"Ganador":"Apuesta"}</div> */}
                    <div className="tex3TxtA" >{value.tipo!==8?(value.tipo===0?"Ganador":"Apuesta"):"Puntos"}</div>
                </div>

                <div className="BodyTicketDetalle">
                {value.detalle.map((value1,index1)=>
                    <div key={index+''+index1}>
                        
                        
                            {/* <div className={value.estatus===0?"cmp1TxtA":"cmp1TxtAAnu"}>{value1.eje} </div> */}
                            <div className={value.estatus===0?"cmp1TxtA":"cmp1TxtAAnu"}>{value.tipo===8?(index1+1):value1.eje} </div>
                            <div className={value.estatus===0?"cmp2TxtA":"cmp2TxtAAnu"}>
                                {(value.tipo===8?
                                value1.eje+')'  
                                :'')+
                                (value1.text===''?
                                'S/I'
                                :value1.text) } 
                                
                            </div>
                            {/* <div className={value.estatus===0?"cmp2TxtA":"cmp2TxtAAnu"}>{value1.text===''?'S/I':value1.text} </div> */}
                            <div className={value.estatus===0?"cmp3TxtA":"cmp3TxtAAnu"}>
                             {value.tipo===0?
                                    (value1.g===null?'':value1.g)
                                    :
                                    value.tipo===8?
                                    value1.g
                                    :
                                    "x"+value1.g
                                }
                            </div>
                        
                    </div>
                )}
                </div>
                
                <div className="TextoFiveTicket">
                    <div className="apuestaTxt">Apuesta:</div>
                    <div className={value.estatus===0?"apuestaTxt_2":"apuestaTxt_2Anu"} >{new Intl.NumberFormat('es', { style: 'currency', currency:moneda }).format(value.apuesta)}</div>
                </div>
                <div className="TextoSixTicket">
                    <div className="cobraTxt">Posible Premio:</div>
                    <div className={value.estatus===0?"cobraTxt_2":"cobraTxt_2Anu"}>{new Intl.NumberFormat('es', { style: 'currency', currency:moneda }).format(value.cobra)}</div>
                </div>
                <div className="TextoSevenTicket">
                    <div className="ganaTxt">Posible Ganancia:</div>
                    <div className={value.estatus===0?"ganaTxt_2":"ganaTxt_2Anu"}>{new Intl.NumberFormat('es', { style: 'currency', currency:moneda}).format(value.cobra-value.apuesta)}</div>
                </div>
            </div>
            
        )
    case 4: return (
            
            <div className="bodyTicket">
                <div className="TextoMainTicket" style={{"background":value.estatus===1?styleBg[(value.isys-1)]:'#434446'}}><label className="serialTxt">{value.serial}</label> </div>
                <div className="TextoSecondTicket">
                    <div className="fechaTxt">Fecha:{value.fecha} </div>
                    <div className="horaTxt">Hora:{value.hora} </div>
                </div>
                <div className="TextoEspTicket">
                    <div className="IDC">Punto de Venta:{value.idc} </div>
                </div>
                <div className="divEtique">
                    {value.estatus!==1?
                    <div className="TextoAnuladoTicket">
                        <Tag color={'#310303'}  >ANULADO</Tag>
                    </div>:''}
                    <div className="TextoTreeTicket">
                        <Tag color={value.estatus===1?styleBg[(value.isys-1)]:'#434446'}  >{systeEt[value.isys-1]}</Tag>
                    </div>
                </div>
                
                <div className="tex1InfoTxt"></div>
                <div className="BodyTicketDetalle">
                {detakk.map((valorD,index)=>
                    <div key={index}>
                            <div className="TextoQuintoTicket">{valorD.loteria}-{valorD.sorteo}</div>
                                {valorD.display.map((valorD1,index)=>
                                <>
                                    <div key={`inxd-${index}`} className={value.estatus===1?"cmp4TxtA":"cmp4TxtAAnu"}>{valorD1.numeros}</div>
                                    {valorD1.escrute!=='0' && 
                                            <div key={`inxd-esc-${index}`} className="cmpEscruteLt" ><Icon type="check" />{valorD1.escrute}</div>
                                    }
                                </>
                                )}
                    </div>
                )}
                </div>
                
                <div className="TextoFiveTicket">
                    <div className="apuestaTxt">Total Ticket:</div>
                    <div className={value.estatus===1?"apuestaTxt_2":"apuestaTxt_2Anu"} >{new Intl.NumberFormat('es', { style: 'currency', currency:moneda }).format(value.total)}</div>
                </div>
                {value.cobra!==0&&
                <>
                    <div className="TextoSixTicket">
                        <div className="cobraTxt">Cobra(Premio):</div>
                        <div className={value.estatus===1?"cobraTxt_2":"cobraTxt_2Anu"}>{new Intl.NumberFormat('es', { style: 'currency', currency:moneda }).format(value.cobra)}</div>
                    </div>
                    <div className="TextoSevenTicket">
                        <div className="ganaTxt">Ganancia:</div>
                        <div className={value.estatus===1?"ganaTxt_2":"ganaTxt_2Anu"}>{new Intl.NumberFormat('es', { style: 'currency', currency:moneda}).format(value.cobra-value.total)}</div>
                    </div>
                </>}
            </div>
            
        )
        default:

    }

}