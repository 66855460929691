import React from "react";

export default function IconLotteri(props) {
	return (
		<svg aria-hidden="true" viewBox="0 0 1024 800" {...props}>
			<path d="M238.51008 635.392l-203.9808-196.93568c-27.40224-26.46016-12.41088-72.88832 25.2928-78.31552l282.15296-40.61184 126.1568-253.19424c16.87552-33.85344 65.16736-33.83296 82.0224 0.02048l126.03392 253.17376L958.464 360.1408c37.70368 5.4272 52.69504 51.83488 25.2928 78.31552l-203.8784 196.95616 48.14848 277.95456c6.47168 37.35552-32.6656 65.96608-66.29376 48.45568L509.1328 830.3616l-252.47744 131.46112c-33.64864 17.48992-72.78592-11.10016-66.31424-48.45568l48.16896-277.97504z"></path>
			<path
				d="M505.01632 389.12c33.25952 0 59.74016 8.54016 78.62272 26.05056 17.08032 15.72864 26.03008 35.47136 26.03008 58.83904 0 17.08032-4.03456 31.45728-12.12416 43.13088-8.97024 12.57472-21.56544 22.016-38.17472 27.8528v0.90112c16.62976 4.05504 31.00672 13.47584 42.68032 27.8528 12.57472 15.27808 18.86208 33.23904 18.86208 53.90336 0 28.30336-9.87136 51.22048-29.63456 68.3008-20.6848 17.5104-49.41824 26.48064-86.26176 26.48064-37.2736 0-66.02752-8.97024-86.24128-26.48064-19.78368-17.08032-29.65504-39.99744-29.65504-68.3008 0-20.66432 6.28736-38.62528 18.86208-53.90336 11.6736-14.37696 25.6-23.79776 42.68032-27.8528v-0.90112c-17.08032-5.8368-29.65504-15.27808-38.1952-27.8528-8.0896-11.6736-12.12416-26.05056-12.12416-43.13088 0-23.36768 8.54016-43.1104 26.05056-58.83904 18.88256-17.5104 44.93312-26.05056 78.62272-26.05056z m0 30.1056c-22.91712 0-40.87808 5.8368-53.4528 17.5104-11.22304 9.87136-16.62976 22.91712-16.62976 38.62528 0 16.1792 4.93568 29.65504 14.82752 39.54688 11.6736 11.69408 30.1056 17.96096 55.25504 17.96096 24.69888 0 43.13088-6.28736 55.25504-17.96096 9.87136-9.89184 14.82752-23.36768 14.82752-39.54688 0-15.72864-5.8368-28.75392-16.62976-38.62528-13.02528-11.6736-30.98624-17.5104-53.4528-17.5104z m0 142.41792c-26.05056 0-45.81376 6.28736-59.74016 19.29216-13.47584 11.6736-19.7632 27.42272-19.7632 46.71488 0 18.86208 6.28736 34.59072 19.7632 46.2848 13.47584 12.12416 33.71008 18.41152 59.74016 18.41152 26.05056 0 45.81376-6.30784 60.19072-18.88256 12.57472-11.6736 19.31264-26.95168 19.31264-45.81376 0-19.29216-6.73792-35.04128-19.31264-46.71488-14.37696-13.02528-34.57024-19.29216-60.19072-19.29216z"
				fill="#FFEF25"
			></path>
		</svg>
	);
}
