import React from "react";
import { Form, Button, Input, Row, Col, Radio } from "antd";

import formatDateEpoch from "../../../../compenent/formatDateEpoch";
import { mutationanulchgStatusCuentasFn } from "../../../../service/muttationTransacciones";
import { viewMesseger } from "../../../../service/viewMesseger";

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const Index = ({ form, recordSelect, sumitModalControl }) => {
	const { getFieldDecorator, resetFields } = form;

	const { fecha, descripcion, origen, id } = recordSelect;
	const {
		props: { children },
	} = formatDateEpoch({
		unix: fecha,
		format: "%D %d %M %Y, %h:%m:%s%a",
	});
	const Fecha = children;
	const solicitud = origen === "Deposito" ? 1 : 2;
	let Datos = recordSelect.length!==0 ? descripcion.split("<<") : "";
	if (solicitud === 1) {
		Datos[3] = "";
		Datos[4] = "";
	}

	const onSaveProccessTransaccion = (estatus, AddDesc, id) => {
		mutationanulchgStatusCuentasFn(id, estatus, AddDesc).then((value) => {
			if (value === null) {
				viewMesseger("error-network");
			} else if (value) {
				viewMesseger("ok");
				sumitModalControl({
					activo: false,
					title: "",
					iu: 0,
					Moneda: "",
					record: [],
				});
			} else viewMesseger("error");
			resetFields();
		}); /// Graba la Transsaccion
	};

	const handleWebsiteChange = (e) => {
		e.preventDefault();
		form.validateFields((err, values) => {
			if (!err) {
				const { estatus, AddDesc } = values;
				onSaveProccessTransaccion(estatus, AddDesc, id);
			}
		});
	};

	return (
		<>
			{recordSelect ? (
				<div>
					<div className="from-transsacc-Pendientes-title">
						<label>
							{`${solicitud === 1 ? "DEPOSITO" : "RETIRO"} POR APROBRAR`}
						</label>
					</div>
					<Form onSubmit={handleWebsiteChange}>
						<Row>
							<Row>
								<Col span={11}>
									<FormItem label="Fecha de transaccion:">
										{getFieldDecorator("fecha", {
											initialValue: Fecha,
										})(<Input disabled />)}
									</FormItem>
								</Col>
								<Col span={11} offset={2}>
									<FormItem label="Monto:" styles={{ color: "#fff" }}>
										{getFieldDecorator("monto", {
											initialValue: recordSelect.monto,
											rules: [
												{
													required: true,
													message: "Por favor requiero este dato!",
												},
												{
													type: "number",
													message: "Este valor NO es un numero !",
													transform(value) {
														return Number(value);
													},
												},
											],
										})(<Input disabled />)}
									</FormItem>
								</Col>
							</Row>
							<Row>
								<Col span={11}>
									<FormItem
										label={
											solicitud === 1
												? "Documento(Transferencia/Deposito): "
												: "Numero de cuenta a Depositar: "
										}
									>
										{getFieldDecorator("documento", {
											initialValue: Datos[1],
											rules: [
												{
													type: "string",
													message: "Debe tener alguna descripcion !",
												},
												{
													required: true,
													message: "Por favor requiero alguna descripcion !",
												},
											],
										})(<Input disabled />)}
									</FormItem>
								</Col>
								<Col span={11} offset={2}>
									<FormItem label="Banco:">
										{getFieldDecorator("banco", {
											initialValue: Datos[2],
											rules: [
												{
													type: "string",
													message: "Debe tener alguna descripcion !",
												},
												{
													required: true,
													message: "Por favor requiero alguna descripcion !",
												},
											],
										})(<Input disabled />)}
									</FormItem>
								</Col>
							</Row>
							<Row>
								<Col span={11}>
									<FormItem
										label="Identificacion:"
										style={{ display: solicitud === 2 ? "" : "none" }}
									>
										{getFieldDecorator("id", {
											initialValue: Datos[3] + " ID:" + Datos[4],
											rules: [
												{
													type: "string",
													message: "Debe tener alguna descripcion !",
												},
												{
													required: true,
													message: "Por favor requiero alguna descripcion !",
												},
											],
										})(<Input disabled />)}
									</FormItem>
								</Col>
								<Col span={11} offset={2}>
									<FormItem
										label="Adicional:"
										style={{ display: solicitud === 2 ? "" : "none" }}
									>
										{getFieldDecorator("AddDesc")(<Input />)}
									</FormItem>
								</Col>
							</Row>
							<Row>
								<Col span={24}>
									<FormItem label="Estatus:">
										{getFieldDecorator("estatus", {
											initialValue: recordSelect.estatus,
										})(
											<RadioGroup>
												<RadioButton key={1} value={2}>
													Por Aprobar
												</RadioButton>
												<RadioButton key={2} value={1}>
													Aprobado
												</RadioButton>
												<RadioButton key={3} value={3}>
													Rechazado
												</RadioButton>
											</RadioGroup>
										)}
									</FormItem>
								</Col>
							</Row>
							<Row>
								<FormItem>
									<Row gutter={20}>
										<Col span={10}>
											<Button type="primary" htmlType="submit">
												{solicitud === 1
													? "Registra Deposito"
													: "Registra Retiro"}
											</Button>
										</Col>
										<Col span={10}>
											<Button>Salir</Button>
										</Col>
									</Row>
								</FormItem>
							</Row>
						</Row>
					</Form>
				</div>
			) : (
				<div></div>
			)}
		</>
	);
};

const EnhancedFormPendientes = Form.create()(Index);
export default EnhancedFormPendientes;
