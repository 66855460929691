import client from "../conex";
import {gql} from "@apollo/client";

const mutationTope = gql`
	mutation registroTopeG($input: RecordTope, $eliminar: Int) {
		registroTopeG(input: $input, eliminar: $eliminar)
	}
`;
export const grabarTope = (registro, eliminar) => {
	let input = {
		tope: registro.itope,
		texto: registro.nombre,
		cnfD: registro.select1,
		cnfA: registro.select2,
		cnfN: registro.select3,
		cnfL: registro.select4,
	};

	return client
		.mutate({
			mutation: mutationTope,
			variables: {
				input,
				eliminar,
			},
		})
		.then(({ data }) => {
			var { registroTopeG } = data;
			if (registroTopeG !== -1) {
				if (eliminar === 0) return true;
				else return true;
			} else return false;
		})
		.catch(() => {
			return null;
		});
};

const mutationTopeDeporte = gql`
	mutation registroTopeDeporte($input: RecordTopeDeporte, $eliminar: Int) {
		registroTopeDeporte(input: $input, eliminar: $eliminar)
	}
`;

export const grabarTopeDeportes = (input, eliminar) => {
	return client
		.mutate({
			mutation: mutationTopeDeporte,
			variables: {
				input,
				eliminar,
			},
		})
		.then(({ data }) => {
			const { registroTopeDeporte } = data;
			if (registroTopeDeporte || registroTopeDeporte !== -1) {
				if (eliminar === 0) return true;
				else return true;
			} else return false;
		})
		.catch(() => {
			return null;
		});
};
const mutationTopeAmericana = gql`
	mutation registroTopeAmericanas($input: RecordTopeAmericana, $eliminar: Int) {
		registroTopeAmericanas(input: $input, eliminar: $eliminar)
	}
`;
export const grabarTopeAmericana = (input, eliminar) => {
	return client
		.mutate({
			mutation: mutationTopeAmericana,
			variables: {
				input,
				eliminar,
			},
		})
		.then(({ data }) => {
			var { registroTopeAmericanas } = data;
			if (registroTopeAmericanas || registroTopeAmericanas !== -1) {
				if (eliminar === 0) return true;
				else return true;
			} else return false;
		})
		.catch(() => {
			return null;
		});
};
const mutationTopeNacionales = gql`
	mutation registroTopeNacionales(
		$input: RecordTopeNacionales
		$eliminar: Int
	) {
		registroTopeNacionales(input: $input, eliminar: $eliminar)
	}
`;

export const grabarTopeNacionales = (input, eliminar) => {
	console.log(input);
	return client
		.mutate({
			mutation: mutationTopeNacionales,
			variables: {
				input,
				eliminar,
			},
		})
		.then(({ data }) => {
			const { registroTopeNacionales } = data;
			if (registroTopeNacionales || registroTopeNacionales !== -1) {
				if (eliminar === 0) return true;
				else return true;
			} else return false;
		})
		.catch(() => {
			return null;
		});
};
