import React,{ Component } from "react";
import {Input,Table,Tabs,DatePicker,Spin,Tag,Icon,Select,message,Popconfirm,notification,Button} from "antd";
import { createIntl } from 'react-intl';
import Highlighter from "react-highlight-words";
import LoadingBar from 'react-top-loading-bar'
import Ticketcavas from './viewtkall'
import moment from 'moment';
import * as func from './function';
import   './verjugadaGen.scss'; 
import {styleBg,systeEt} from './globals.js';

const _ =  require('lodash');
const { Column } = Table;
const { Option } = Select;
const TabPane = Tabs.TabPane;

const {formatNumber} = createIntl({
    locale: 'es-ES',
    messages: {}
  })

  
class verjugadaGen extends Component {
    constructor(props){
        super(props)
        this.state = {
            loading:false,
            data1 :[],
            data2 :[],
            data3 :[],
            data4 :[],
            fecha: '',
            searchText:'',
            isystem:systeEt,
            sistem:[0,1,2,3],
        }

        this.queryviewTicketsFn=func.queryviewTicketsFn.bind(this);
        this.mutationanulOneTicketFn=func.mutationanulOneTicketFn.bind(this);
        this.mascquertt=func.mascquertt.bind(this);
    }
    
    setDatalist=(data,tipo)=>{
        this.setState({
            data1:data.sinprocesar,
            data2:data.posibleganar,
            data3:data.posibleganador,
            data4:data.anulados,
        
        });
        this.renderTable(tipo)
    }
    openNotification = (serial,tipo,moneda,isys) => {
        var icon;
        var anuBtn='';
        var iu=0;
        switch (tipo){
            case 1: icon=<Icon type="meh" style={{ color: '#abb2b9' }}  />; 
                    anuBtn=<Popconfirm  icon={<Icon type="question-circle-o" style={{ color: 'red' }} />} title="Estas seguro de anular es ticket？" okText="Si" cancelText="No" onConfirm={()=> this.mutationanulOneTicketFn(serial,iu,isys) }><Button type="danger">Anular</Button></Popconfirm>;
                    break;
            case 2: icon=<Icon type="smile" style={{ color: '#108ee9' }} />; break;
            case 3: icon=<Icon type="frown" style={{ color: '#a93226' }} />; break;
            case 4: icon=<Icon type="pause-circle" style={{ color: '#f1c40f' }} />; break;
            default:
        }
        notification.open({
        message: 'Ticket No.'+serial,
        description: <div> <Ticketcavas isys={isys} tipo={tipo} serial={serial} currency={moneda}/>{anuBtn}</div>,
        icon: icon,
        style: {
            width: 450,
            marginLeft: 335 - 450}
        });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Buscar
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Borrar
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text => (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ),
      });
      handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
    renderTable=(op)=>{
        var viewData=[];
        switch(op){
            case 1: viewData=this.state.data1; break;
            case 2: viewData=this.state.data2; break;
            case 3: viewData=this.state.data3; break;
            case 4: viewData=this.state.data4; break;
            default:
        }
        
        return (
        <Table 
        rowKey={record => record.serial} 
        dataSource={viewData}
        className={".ant-table-content"}
        onRow={(record) => {
            var {moneda,isys,serial}=record
            return {
                onClick: () => {
                    this.openNotification(serial,op,moneda,isys)
                    },    
            };}}>
            <Column
                key="serial"
                title="Serial"
                dataIndex="serial"
                sorter= {(a, b) => a.serial - b.serial}
                {...this.getColumnSearchProps('serial')}
            />
            <Column
                title=""
                dataIndex="isys_Desc"
                render={(isys_Desc,record) => (
                    <span>
                        {<Tag color={styleBg[(record.isys-1)]}  >{isys_Desc}</Tag>}
                    </span>
                )}
            />
            <Column
                title="Hora"
                dataIndex="hora"
            />
            <Column
                title="Punto de Venta"
                dataIndex="idc"
                {...this.getColumnSearchProps('idc')}
            />
            <Column
                title="Precio"
                dataIndex="apuesta"
                className='column-monto'
                align="right"
                sorter= {(a, b) => a.apuesta - b.apuesta}
                render={(apuesta) => (
                    <span>
                        {formatNumber(apuesta, {style: 'decimal', currency: 'USD',minimumFractionDigits:2,maximumFractionDigits:2})}
                     </span>
                )}
            />
            <Column
                title="Premio"
                dataIndex="cobra"
                className='column-monto'
                align="right"
                render={(cobra,record) => (
                    <span>
                           {formatNumber(Number(cobra), {style: 'currency', currency:record.moneda})}
                    </span>
                )}
            />
            <Column
                title="IP"
                dataIndex="ip"
                {...this.getColumnSearchProps('ip')}
            />
            
        </Table>
    )  }  
    callAutoRmv=(serial,isys)=>{
        var {data1,data4}=this.state;
        var evens = _.remove(data1, function(n) {
            return n.serial===serial && n.isys===isys;
        });
        if (evens.length!==0)
            this.setState({data4:[...data4,evens[0]]})
    }
    callRefress=(fecha,sistem)=>{
        console.log('sistem',sistem)
        if (sistem.length!==0){
        var activar=false;
        if (fecha==='-1') {fecha=moment().format('DD/M/YYYY');activar=true;}
        this.queryviewTicketsFn(fecha,1,sistem);
        this.queryviewTicketsFn(fecha,2,sistem);
        this.queryviewTicketsFn(fecha,3,sistem);
        this.queryviewTicketsFn(fecha,4,sistem);
        if (activar) this.renderTable(1)
       
        }else{
            this.setState({loading:false})
            message.error('Disculpe, pero debe seleccionar al menos un sistema');
        }
    }
    startFetch = () => {
        this.LoadingBar.continuousStart()
      }
    setRefress=()=>{

        var {sistem}=this.state;
        if (this.state.fecha===''){
            this.setState({fecha:moment().format('DD/M/YYYY')})
            this.callRefress(moment().format('DD/M/YYYY'),sistem)
        }else
            this.callRefress(this.state.fecha,sistem)
    }
    Changedate=(date)=>{
        var {sistem}=this.state;
        this.setState({loading:true})
        this.LoadingBar.continuousStart()
        this.setState({fecha:date.format("DD/M/YYYY")})
        this.callRefress(date.format("DD/M/YYYY"),sistem)
    }
    chanceSistem=(sistem)=>{
        this.LoadingBar.continuousStart()
        this.setState({sistem,loading:true})
        if (this.state.fecha===''){
            this.setState({fecha:moment().format('DD/M/YYYY')})
            this.callRefress(moment().format('DD/M/YYYY'),sistem)
        }else
            this.callRefress(this.state.fecha,sistem)
    }
    componentDidMount=()=>{
        this.setState({loading:true})
        this.LoadingBar.continuousStart()
        this.setRefress()
       // this.querydatafechaFn(this.props.tthis.state.id);
    }

    render() {
        var {racceso,snivel,polla}= this.mascquertt("sub5","r1-1")
        var {renderTable,Changedate,chanceSistem}=this;
        var {loading,isystem}=this.state;
        return (
            <>
                <div key="Table-01"  className="Table-01" >  
                    <div className="barrwdw">
                        <div className="barrwdwtitle_verJugada">
                            <label className="barrwdwtext"><Icon type="snippets" />&nbsp;Ver Jugada General</label>
                        </div>
                    </div> 
                    <LoadingBar onRef={ref => (this.LoadingBar = ref)} />
                <br/>
                <Spin tip="Recuperando datos solicitados..." spinning={loading} size="large" >
                    <div  className="fecha_verJugada"><label>Fecha : </label>
                    <DatePicker  defaultValue={moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')} format="DD/MM/YYYY" onChange={(date)=>Changedate(date)} />
                    </div>
                    <div className="select_verJugada">
                        <label>Sistemas: </label>
                        <Select
                            mode="multiple"
                            placeholder="Deportes Habilitados"
                            defaultValue={snivel}
                            onChange={(value)=>chanceSistem(value)}
                            style={{width:'500px'}}
                        >
                            {isystem.map((value,index)=>
                                racceso[index]?
                                    <Option key={`op_2341_${index}`} value={index} >
                                        <Tag key={`op_2342_${index}`} color={styleBg[(index)]}  >
                                            {
                                                value==='Nacionales'?
                                                polla?'Polla':value
                                                :value
                                            }
                                        </Tag>
                                    </Option>
                                :''
                            )}
                            
                        </Select>
                    </div>
                    <br/>
                    <div className="tabla_verjugada">
                        <Tabs  type="line" className="tabs_verjugada">
                            <TabPane  className="tabs_verjugada" tab="Tickets Realizados" key="1">{renderTable(1)}</TabPane>
                            <TabPane  className="tabs_verjugada" tab="Tickets Ganadores" key="2">{renderTable(2)}</TabPane>
                            <TabPane  className="tabs_verjugada" tab="Tickets Perdedores" key="3">{renderTable(3)}</TabPane>
                            <TabPane  className="tabs_verjugada" tab="Tickets Anulados" key="4">{renderTable(4)}</TabPane>
                        </Tabs>
                    </div>
                </Spin>
            </div>     
            </>
        );
    }
}

export default verjugadaGen;