import React, { Component } from 'react';
import {Icon,Button,Popconfirm,Switch,Row,Col,Select,notification,Tag,Tabs,TimePicker,DatePicker} from "antd";
import moment from 'moment';
import CloudGanaJndEjemplares from "./cloud.Gana-Jnd-Ejem";
import CloudGanaJndCondiciones from "./cloud.Gana-Jnd-Cond";
import CloudGanaJndCierres from "./Cloud.Gana-Jnd-Cierres";

import Cloudwindows from "./cloudwindows";
import Spinnerwait from './spinnerwait';
import * as func from './function';
import {colorWin,colorLtr} from './globals.js';
import   './cloud_Gana_Jnd.scss'; 


const _ = require('lodash');

const ButtonGroup = Button.Group;
const { Option } = Select;
const { TabPane } = Tabs;

var blocl=false;
var  DateJnd=moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')
class cloud_Gana_Jnd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saveInfo:false,
            IDhipo:1,
            estatus:false,
            JndEspecial:[{Tipo:1,Texto:'Pool de Cuadro',AddText:'Tanda',Cantidad:1},{Tipo:2,Texto:'Lotto Hipico',AddText:'Tanda',Cantidad:1}],
            SavaJndEspecial:[],
            Ejemplares:[],
            SavaJndGeneral:{
                Fecha:'',
                IDhipo:0,
                estatus:false,
                moment:0,
                carreras:0,
                especial:[],
                RowsSetting:[]
            },
            Hipodromos:[],
            RowCarrJnd:1,
            RowsSetting:[],
            listData:[],
            dia:'hoy',
            confJnd:[],
            inside:'',
            impRef:[],
            monedas:[],
            setting:[],
            newreg:false,
        }

        this.childEjem    = React.createRef();
        this.childCond    = React.createRef();
        this.childCierres = React.createRef();

        this.viewNacionalesDTAFnX2=func.viewNacionalesDTAFnX2.bind(this);
        this.queryCnfPollaAdmFN=func.queryCnfPollaAdmFN.bind(this);
        this.savePollaJND=func.savePollaJND.bind(this);
        this.query_viewListNACAdmFN=func.query_viewListNACAdmFN.bind(this);
        this.muttaioncaptureCnfJdnNacionalesFN=func.muttaioncaptureCnfJdnNacionalesFN.bind(this);
    }

    componentDidMount=()=>{
       this.query_viewListNACAdmFN();
    }
    queActSycn=async (IDCN,tipo)=>{
        console.log({IDCN  })
        const newSavaJndGeneral = {...this.state.SavaJndGeneral,IDCN}
        this.setState({SavaJndGeneral:newSavaJndGeneral,inside:this.fromInside(this.state.confJnd,0,this.state.RowCarrJnd,this.state.RowsSetting,this.state.JndEspecial,false)})
        if (IDCN!==0) await this.query_viewListNACAdmFN();
    }
    refresSync=()=>{
        this.setState({inside:this.fromInside(this.state.confJnd,0,this.state.RowCarrJnd,this.state.RowsSetting,this.state.JndEspecial,false)})
    }
    actuValores=async(dia,id,newreg)=>{
        this.setState({RowsSetting:[]});
        await this.viewNacionalesDTAFnX2(dia,id,newreg)
        blocl=true;
    }
    callJnd=(valores,index,idn)=>{
        var dsp=_.split(valores,'-');
        this.chanceHabCarr(dsp[1],parseInt(dsp[0])-1,7)
    }
    callJNDTrue=(valor)=>{
        this.setState({inside:<Spinnerwait/>})
        this.queActSycn(valor,1);
    }
    
    commandBarr=(type)=>{
        switch(type){
            case -1:
                var {dia}=this.state;
                DateJnd=moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')
                this.setState({inside:<Spinnerwait/>})
                this.actuValores(dia,0,true)
                break;
            case -2:
                var {setting}=this.state;
                if (setting.length!==0){
                    notification.error({
                        message: 'Error en Borrar',
                        placement: 'topLeft',
                        description: 'Disculpe, pero no tiene autorizacion de borrar esta Jornada de polla!'
                    });
                }

                break;
            default:
        }
    }
    chanceHabCarr=(option,id,op)=>{
        var {setting}=this.state;
        var premio
        switch (op) {
            case 1:
                setting.activo=option?1:0;
                this.setState({setting})
                break;
            case 2:
                var {precio}=setting;
                var vprecio=_.split(precio,'|');
                vprecio[id]=option.value
                setting.precio=_.join(vprecio,'|');
                this.setState({setting})
                break;
            case 3:
                premio=setting;
                premio[id].tipo=option?1:0;
                this.setState({setting})
                break;
            case 4:
                premio=setting;
                premio[id].monto=option.value
                this.setState({setting})
                break;
            case 5:
                var {store}=setting;
                var vstore=_.split(store,'|');
                vstore[id]=option.value
                setting.store=_.join(vstore,'|');
                this.setState({setting});
                break;
            case 6:
                var {config}=setting;
                var setCarr=_.split(config,'|');
                setCarr[id]=option?1:0;
                setting.config=_.join(setCarr,'|');
                this.setState({setting})
                break;
            case 7:
                var {settiFab}=setting;
                var setFab=_.split(settiFab,'|');
                setFab[id]=option;
                setting.settiFab=_.join(setFab,'|');
                this.setState({setting})
                break;
            case 8:
                var {settiRet}=setting;
                var setRet=_.split(settiRet,'|');
                setRet[id]=option;
                setting.settiRet=_.join(setRet,'|');
                this.setState({setting})
                break;
        
            default:
                break;
        }
        
    }
    onRef=(ref,id,op)=>{
        var {impRef}=this.state
        if (!_.isNull(ref)){
            var idx=_.findIndex(impRef,o=>{return o.id===id && o.op===op; })
            if (idx===-1){
                impRef.push({id,op,ref})
                this.setState({impRef})
            }
        }
    }
 
  
    fromInside=(confJnd,idn,RowCarrJnd,RowsSetting,JndEspecial,saveInfo)=>{

        var {setting,Ejemplares}=this.state;
        // console.log('saveInfo',saveInfo)
        if (setting.length!==0){

            var {settiFab,settiRet}=setting;
            var setFab=_.split(settiFab,'|');setFab.pop();
            var setRet=_.split(settiRet,'|');setRet.pop();
            // var setCarr=_.split(config,'|');
            // var vprecio=_.split(precio,'|');
            // var {Ejemplares}=confJnd[idn];
            var defJND='';
            if (confJnd.length!==0){
                defJND=confJnd.IDCN;
            }else{
                defJND='No hay Jornada';
            }
           
        
        }
        
        return(
            defJND === '' ? defJND :
            <Tabs type="card" >
                <TabPane tab="Configuracion" key="1">
                    {this.CnfgJornada(RowCarrJnd,RowsSetting,JndEspecial,saveInfo)}
                </TabPane>
                <TabPane tab="Ejemplares" key="2">
                    <CloudGanaJndEjemplares  RowsSetting={RowsSetting}  Ejemplares={Ejemplares} ref={this.childEjem} />
                </TabPane>
                <TabPane tab="Condiciones" key="3">
                    <CloudGanaJndCondiciones  {...this.state} RowsSetting={RowsSetting}  ref={this.childCond} />
                </TabPane>
                <TabPane tab="Cierre/Conformes" key="4">
                    <CloudGanaJndCierres  {...this.state} RowsSetting={RowsSetting} tagRender={(a,b,c,d,e)=>this.tagRender(a,b,c,d,e)}   ref={this.childCierres} />
                </TabPane>
            </Tabs>
          
        )
    }
    tagRender=(label,value,colorLtr,iden,index)=> (
         <Option key={index.toString()} value={iden}>
            <Tag  color={value} style={{color:colorLtr}} >
                {label}
            </Tag>
          </Option>
        )
    chgSettingHorse=(record,op)=>{
        var {RowsSetting,SavaJndGeneral}=this.state;
        switch (op) {
            case 1:
                var newRowsSetting=[]

                for (let i=1;i<=record;i++) {
                    newRowsSetting.push({ 
                        i,
                        cantidad:1,
                        DateJnd,
                        start:moment('12:00', 'HH:mm'),
                        scratch:[],
                        exacta:true,
                        trifec:true,
                        superfec:true}); 
                    
                    }

                for (let i=0;i<=RowsSetting.length-1;i++) {
                    var {cantidad,scratch,start,exacta,trifec,superfec}=RowsSetting[i];
                    if ((newRowsSetting.length-1)>=i){
                         newRowsSetting[i].cantidad=cantidad;
                         newRowsSetting[i].scratch=scratch;
                         newRowsSetting[i].start=start;
                         newRowsSetting[i].exacta=exacta;
                         newRowsSetting[i].trifec=trifec;
                         newRowsSetting[i].superfec=superfec;
                        }
                }
                SavaJndGeneral.RowsSetting=newRowsSetting;
                this.setState({RowsSetting:newRowsSetting,RowCarrJnd:record,inside:this.fromInside(this.state.confJnd,0,record,newRowsSetting,this.state.JndEspecial,false)})
                break;
            case 2:
                var {carrera}=record;
                cantidad=record.cantidad
                var idx=_.findIndex(RowsSetting, (o)=> { return o.i === carrera; });
                RowsSetting[idx].cantidad=cantidad;
                SavaJndGeneral.RowsSetting=RowsSetting;
                this.setState({RowsSetting,inside:this.fromInside(this.state.confJnd,0,this.state.RowCarrJnd,RowsSetting,this.state.JndEspecial,false)})
                break;
            case 3:
                var {SavaJndEspecial}=this.state;
                var {tipo,tanda}=record;
                var lrecord=record.record
                idx=-1;
                if (SavaJndEspecial.length!==0)
                    idx=_.findIndex(SavaJndEspecial, (o)=> { return o.Tipo === tipo && o.tanda === tanda; });

                if (idx===-1)
                    SavaJndEspecial.push({
                        Tipo:tipo,
                        tanda,
                        inicio:lrecord
                    })
                else
                    SavaJndEspecial[idx].inicio=record;
                
                this.setState({SavaJndEspecial});
                break;
            case 4:
                // var {scratch}=record;
                const lcarr = record.carrera
                idx=_.findIndex(RowsSetting, (o)=> { return o.i === lcarr; });
                if (idx!==-1)
                    RowsSetting[idx].scratch=record.scratch;
                
                this.setState({RowsSetting,RowCarrJnd:record,inside:this.fromInside(this.state.confJnd,0,record,RowsSetting,this.state.JndEspecial,false)})
                break;
            case 8:
                var {event}=record;
                const ltipo =record.tipo;
                const llcarr = record.carrera
                idx=_.findIndex(RowsSetting, (o)=> { return o.i === llcarr; });
                if (idx!==-1){
                    if (ltipo===1) RowsSetting[idx].exacta  =event;
                    if (ltipo===2) RowsSetting[idx].trifec  =event;
                    if (ltipo===3) RowsSetting[idx].superfec=event;
                }
                this.setState({RowsSetting,RowCarrJnd:record,inside:this.fromInside(this.state.confJnd,0,record,RowsSetting,this.state.JndEspecial,false)})

                break;
        
            default:
                const lstart=record.start;
                const lllcarr = record.carrera
                idx=_.findIndex(RowsSetting, (o)=> { return o.i === lllcarr; });
                if (idx!==-1)
                    RowsSetting[idx].start=lstart;
                
                this.setState({RowsSetting,RowCarrJnd:record,inside:this.fromInside(this.state.confJnd,0,record,RowsSetting,this.state.JndEspecial,false)})
                break;
        }
       
    }

    CapturaGeneralJnd=()=>{

       
        var {SavaJndGeneral,SavaJndEspecial,RowsSetting,estatus,IDhipo,JndEspecial}=this.state;
        if (blocl && RowsSetting.length!==0){
            if ( SavaJndGeneral.moment!==moment().unix() ){
                var currentCondi= this.childCond.current;
                var currentEjem=  this.childEjem.current;
                // var currentCierr=  this.childCierres.current;
                console.log(DateJnd)
                SavaJndGeneral.carreras=RowsSetting.length;
                SavaJndGeneral.Fecha=typeof DateJnd === 'object'? DateJnd.format('DD/M/YYYY'):DateJnd
                SavaJndGeneral.IDhipo=IDhipo;
                SavaJndGeneral.estatus=estatus;
                SavaJndGeneral.moment=moment().unix();
                var newRowsSetting=[]
                for (const prop in RowsSetting) {
                    var {i,
                        cantidad,
                        start,
                        scratch,
                        exacta,
                        trifec,
                        superfec} = RowsSetting[prop];

                        newRowsSetting.push({
                            i,
                            cantidad,
                            start:start.format('HHmm'),
                            scratch,
                            exacta,
                            trifec,
                            superfec
                        })
                }
                
                SavaJndGeneral.RowsSetting=newRowsSetting;
               
                if (!_.isNull(currentEjem)){
                    var {HorsexRace}=currentEjem.state;
                    SavaJndGeneral.Ejemplares=HorsexRace;
                }else
                    SavaJndGeneral.Ejemplares=[]
                
                if (!_.isNull(currentCondi)){
                    var {RacexCondicion}=currentCondi.state;
                    SavaJndGeneral.Condiciones=RacexCondicion;
                }else
                    SavaJndGeneral.Condiciones=[]

                // if (!_.isNull(currentCierr)){
                //     var {RaceHorse}=currentCierr.state;
                //     SavaJndGeneral.CierreDivi=JSON.toString(RaceHorse);
                // }else
                //     SavaJndGeneral.CierreDivi=[]

                var newSavaJndEspecial=[];
                for (const prop in JndEspecial) {
                    var {Cantidad}=JndEspecial[prop];
                    const llTipo =JndEspecial[prop].Tipo
                    // let nTanda=1
                    for (let i=1;i<=Cantidad;i++){

                        var idx=SavaJndEspecial.findIndex( (o)=>  o.Tipo === llTipo && o.tanda === i);
                        var inicio=1;
                        if (idx!==-1)
                            inicio=SavaJndEspecial[idx].inicio;
                        
                        newSavaJndEspecial.push({
                            Tipo:llTipo,
                            tanda:i,
                            inicio
                        })

                        // nTanda++;
                    }
                }

                SavaJndGeneral.especial=newSavaJndEspecial;

                console.log({SavaJndGeneral})
                
                this.muttaioncaptureCnfJdnNacionalesFN(JSON.stringify(SavaJndGeneral));

                this.setState({
                    SavaJndGeneral,
                    SavaJndEspecial:newSavaJndEspecial,
                    inside:this.fromInside(this.state.confJnd,0,this.state.record,this.state.RowsSetting,this.state.JndEspecial,true)
                })
               
            }
        }

    }

    CnfgJornada=(RowCarrJnd,RowsSetting,JndEspecial,saveInfo)=>{
        var {Hipodromos,SavaJndGeneral,SavaJndEspecial,estatus,IDhipo}=this.state;
        var listCarr=[],EjemSetting=[],RetirSetting=[];
        for (let i=1;i<=20;i++) listCarr.push(i)  
        if (RowsSetting.length===0) {   
            for (let i=1;i<=RowCarrJnd;i++) {
                RowsSetting.push({
                    i,
                    cantidad:1,
                    DateJnd,
                    start:moment('12:00', 'HH:mm'),
                    scratch:[],
                    exacta:true,
                    trifec:true,
                    superfec:true});    
            }
            this.setState({RowsSetting})
        }

        for (let i=1;i<=16;i++) EjemSetting.push(i);   
        for (let label=0;label<=16;label++){
            RetirSetting.push({value:colorWin[label],colorLtr:colorLtr[label],label})
        }
        
        if (SavaJndEspecial.length===0){
            var tanda=1,inicio=1;
            for (const prop in JndEspecial) {
                var {Tipo}=JndEspecial[prop];
                SavaJndEspecial.push({
                    Tipo,
                    tanda,
                    inicio
                })

            }
            this.setState({SavaJndEspecial})
        }

        ////// Registro General ///////
        if (SavaJndGeneral.Fecha===''){
            SavaJndGeneral.Fecha=DateJnd.format('DD-M-YYYY')
            SavaJndGeneral.IDhipo=IDhipo
            SavaJndGeneral.estatus=estatus
            SavaJndGeneral.moment=moment().unix()
            SavaJndGeneral.carreras=RowsSetting.length
            SavaJndGeneral.especial=[]
            SavaJndGeneral.RowsSetting=[]
            
            this.setState({SavaJndGeneral})
        }
        // console.log('SavaJndGeneral',SavaJndGeneral,'IDhipo',IDhipo)
        return(
            <Row className="CloudGanaSettingMain">
                <Row className="CloudGanaSettingRow" type="flex" justify="end">
                 
                    <Col span={2} >
                        <Icon type="cloud-upload" theme={saveInfo?"outlined":"twoTone"} twoToneColor="#B7B7B7" style={{fontSize:"25px"}}  />
                    </Col>
                    <Col span={8}   >
                    <Button
                            type="primary"
                            icon="download"
                            onClick={() =>  this.CapturaGeneralJnd()}
                        >
                            Registrar cambios
                        </Button>

                    </Col>
                </Row>
                <Row className="CloudGanaSettingRow">
                    <Col span={4}>
                        <label className="setting_label">Jornada de:</label> 
                    </Col>
                    <Col span={6}>
                        <DatePicker  
                            onChange={(date)=>DateJnd=date.format("DD/M/YYYY")}
                            defaultValue={DateJnd} 
                            size="small"
                            format="DD/MM/YYYY" 
                        />
                    </Col>
                    <Col span={4} offset={10} >
                            <Switch
                                onChange={(estatus)=>this.setState({estatus})}
                                checked={estatus}  
                                checkedChildren="Activo"
                                unCheckedChildren="Cerrado"
                            />
                    </Col>
                    
                </Row>
                <Row className="CloudGanaSettingRow">
                    <Col span={4} >
                        <label className="setting_label">Hipodromo:</label> 
                    </Col>
                    <Col span={6} >
                        <Select 
                            onChange={(IDhipo)=>this.setState({IDhipo})}
                            defaultValue={SavaJndGeneral.IDhipo}   
                            size="small"
                            style={{ width: '100%' }} 
                            >
                                {Hipodromos.map((value,index)=>
                                    <Option key={`jnd-hip-${index.toString()}`} value={value.code}>{value.Texto}</Option>
                                )}
                                
                            </Select>
                    </Col>
                    <Col span={3} offset={7}>
                        <label className="setting_label">Carreras:</label> 
                    </Col>
                    <Col span={3}>
                        <Select 
                        onChange={(value)=>this.chgSettingHorse(value,1)} 
                        defaultValue={ SavaJndGeneral.carreras }  
                        size="small"
                        style={{ width:  '100%' }} 
                        >
                            {listCarr.map((value,index)=>
                                <Option key={`jnd-carr-${index.toString()}`} value={value}>{value}</Option>
                                )
                            }
                        </Select>
                    </Col>
                    
                </Row>
                <Row className="CloudGanaRow">
                    <Col span={2}><label  className="setting_label_jn1">Carr</label></Col>
                    <Col span={4}><label  className="setting_label_jn1">Hora</label></Col>
                    <Col span={3}><label  className="setting_label_jn1">Horse</label></Col>
                    <Col span={3}><label  className="setting_label_jn1">Exacta</label></Col>
                    <Col span={3}><label  className="setting_label_jn1">Trifecta</label></Col>
                    <Col span={4}><label  className="setting_label_jn1">SuperFecta</label></Col>
                    <Col span={5}><label  className="setting_label_jn1">Retirados</label></Col>
                </Row>
                {RowsSetting.map((value,index)=>
                    <Row  key={`jnd-cnf-${value.i.toString()}-${index.toString()}`} className="CloudGanaRow_cell">
                        <Col span={2}><label  className="setting_label_jn1">{value.i}</label></Col>
                        <Col span={4}>
                            <TimePicker 
                            onChange={(start)=>this.chgSettingHorse({start,carrera:value.i},5)}
                            defaultValue={value.start}  
                            format='HH:mm'
                            size="small"
                            style={{ width: '75px'}}
                            />
                        </Col>
                        <Col span={3}>
                            <Select 
                            onChange={(record)=>this.chgSettingHorse({carrera:value.i,cantidad:record},2)}
                            defaultValue={ value.cantidad }   
                            size="small"
                            style={{ width: 60 }} 
                            >
                                {EjemSetting.map((value1,index1)=>
                                    <Option key={`jnd-horse-${value1.toString()}-${index1.toString()}`}  value={value1}>{value1}</Option>
                                    )
                                }
                            </Select>
                        </Col>
                        <Col span={3}> 
                            <Switch 
                                onChange={(event)=>this.chgSettingHorse({carrera:value.i,event,tipo:1},8)}
                                checked={value.exacta} 
                                size="small" 
                            /> </Col>
                        <Col span={3}> 
                            <Switch 
                                onChange={(event)=>this.chgSettingHorse({carrera:value.i,event,tipo:2},8)}
                                checked={value.trifec} 
                                size="small" 
                            /> </Col>
                        <Col span={4}>  
                            <Switch 
                                onChange={(event)=>this.chgSettingHorse({carrera:value.i,event,tipo:3},8)}
                                checked={value.superfec} 
                                size="small" 
                            /> </Col>
                        <Col span={5}> <Select
                                            onChange={(record)=>this.chgSettingHorse({carrera:value.i,scratch:record},4)}
                                            defaultValue={ value.scratch }  
                                            mode="multiple"
                                            style={{ width: '100%' }}

                                        >
                                            {EjemSetting.map((value1,index1)=>
                                                value1 <= value.cantidad?
                                                        this.tagRender(value1,RetirSetting[value1-1].value,RetirSetting[value1-1].colorLtr,value1,index1)
                                                        :''
                                                )
                                            }
                                        </Select>
                        </Col>
                    </Row>
                )}
                {JndEspecial.map((value)=>
                    this.addBarRow(value.Texto,value.AddText,RowsSetting,value.Cantidad,value.Tipo)
                )}

        </Row>
        )
    }
    changeAcumull=(tipo,digito)=>{
        var {JndEspecial} = this.state;

        var idx=_.findIndex(JndEspecial, (o)=> { return o.Tipo === tipo; });
        if (idx !== -1){
            if ( JndEspecial[idx].Cantidad !==0 )
                JndEspecial[idx].Cantidad=JndEspecial[idx].Cantidad+digito;
            else
                if (digito>0) JndEspecial[idx].Cantidad=JndEspecial[idx].Cantidad+digito;
            
        }

        this.setState({JndEspecial,inside:this.fromInside(this.state.confJnd,0,this.state.RowCarrJnd,this.state.RowsSetting,JndEspecial,false)})
             

    }
    addBarRow=(tilte,textoadd,RowsSetting,Cant,tipo)=>{
        var {SavaJndEspecial}=this.state;
        var CnfBarrEsp=[];
        for (let i=1;i<=Cant;i++){
            var inicio=1;
            var idx=_.findIndex(SavaJndEspecial, (o)=> { return o.Tipo === tipo && o.tanda === i });
            if (idx!==-1)
                inicio=SavaJndEspecial[idx].inicio
            
            CnfBarrEsp.push({i,inicio})
        }
        
        return(
            <Row key={`jnd-esp-top-${tipo.toString()}`}  >
                <Row className="CloudGanaRow CloudGanaTitleEsp">
                    <Col>
                        <label>{tilte}</label>
                    </Col>
                </Row>
                <Row>
                    <Col span={3}>
                        <Icon type="plus-square" theme="twoTone"  onClick={()=>this.changeAcumull(tipo,1)}/>
                    </Col>
                </Row>
               { CnfBarrEsp.map((rows,index)=>
                <Row  key={`jnd-esp-${tipo.toString()}-${index.toString()}`} className="CloudGanaRow_cell">
                        <Row>
                            <Col span={3}>
                                <Icon onClick={()=>this.changeAcumull(tipo,-1)}  type="minus-square" theme="twoTone" twoToneColor="red"   />
                            </Col>
                            <Col span={3}>
                                <label>{`${textoadd} ${rows.i}`} </label>
                            </Col>
                            <Col span={5}>
                                <label>Inicia en Carrera </label>
                            </Col>
                            <Col span={4}>
                                <Select 
                                    onChange={(record)=>this.chgSettingHorse({tipo,tanda:rows.i,record},3)}
                                    defaultValue={rows.inicio}   
                                    size="small"
                                    style={{ width: 60 }} 
                                    >
                                        {RowsSetting.map((value,index)=>
                                            <Option key={`jnd-esp-hor-${tipo.toString()}-${index.toString()}`}  value={value.i}>{value.i}</Option>
                                            )
                                        }
                                    </Select>
                            </Col>
                        </Row>
                    </Row>
                )}
            </Row>
            
        )
    }
    barraMainP=(
            <ButtonGroup >
                <Button className="barrwdw_bnt" ghost type="link"  onClick={()=>this.commandBarr(-1)} ><Icon type="plus" /></Button>
                <Popconfirm title="Esta Seguro de Borrar esta configuracion?" onConfirm={()=>this.commandBarr(-2)}  okText="Borrar" cancelText="Cancelar">
                    <Button  className="barrwdw_bnt" ghost type="link"  ><Icon type="minus" /></Button>
                </Popconfirm >
                {/* <Button  className="barrwdw_bnt" ghost type="link" ><Icon type="diff" /></Button> */}
            </ButtonGroup>
        )
    
   seleIntemJnd=(IDCN,dia)=>{
       blocl=false
    //    console.log(IDCN,dia)
       DateJnd=moment(moment().format(dia), 'DD/MM/YYYY');
       this.setState({inside:<Spinnerwait/>,newreg:false})//,IDhipo:IDCN,}
       this.actuValores(dia,IDCN,true)

   }
//    componentWillUpdate=()=>{
//     //    console.log('actualice algo',moment().unix())
//        this.CapturaGeneralJnd()
//    }
    
    render() {
        var {listData,inside}=this.state;
        return (
            <Cloudwindows
                title="Configuracion de Ganadores"
                classMain="mainSettingGanadores"
                classMainInside="mainSettingInsideGanadores"
                classMainList="mainListSettingGanadores"
                barrIcons={ this.barraMainP }
                listData={listData}
                inside={inside}
                statusList={true}
                onChange={(e,f)=>this.seleIntemJnd(e,f)}
            />
        );
    }
}



export default cloud_Gana_Jnd;