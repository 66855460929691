import React, { Component } from "react";
import { Row, Col, Button, Input, Form, Icon } from "antd";
import { iloginFnAdmin } from "./function";
import "./iloginapp.scss";

class frmLogin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			logginon: false,
			displayPago: false,
			bancos: [],
			refPws: null,
			refIu: null,
			iu: "",
			pw: "",
		};
		this.iloginFnAdmin = iloginFnAdmin.bind(this);
	}
	handleSubmit = (e) => {
		e.preventDefault();
		this.iloginFnAdmin();
	};
	render() {
		var { logginon } = this.state;
		const { getFieldDecorator } = this.props.form;
		return (
			<Row justify="center">
				<Col span={9}></Col>
				<Col span={6}>
					<div className="main-login-frm">
						<div className="main-login-logo">
							<div>
								<label style={styles.textTitulo1}>SportsBook</label>
								<label className="logo2">Master</label>{" "}
							</div>
						</div>
						<Form
							data-testid="form-login"
							onSubmit={this.handleSubmit}
							className="main-login-frm-prm"
						>
							<Form.Item>
								{getFieldDecorator("username", {
									initialValue: this.state.iu,
									rules: [
										{
											required: true,
											message: "Por favor colocar tu nombre de usuario!",
										},
									],
								})(
									<Input
										prefix={
											<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
										}
										placeholder="Usuario"
										ref={(refIu) => this.setState({ refIu })}
										onChange={(e) => this.setState({ iu: e.target.value })}
										disabled={logginon}
									/>
								)}
							</Form.Item>
							<Form.Item hasFeedback>
								{getFieldDecorator("password", {
									initialValue: this.state.pw,
									rules: [
										{ required: true, message: "Por favor colocar tu clave !" },
									],
								})(
									<Input.Password
										ref={(refPws) => this.setState({ refPws })}
										prefix={
											<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
										}
										placeholder="Clave"
										onChange={(e) => this.setState({ pw: e.target.value })}
										disabled={logginon}
										autoComplete="off"
									/>
								)}
							</Form.Item>
							<Form.Item>
								<Button
									data-testid="button-ok"
									disabled={logginon}
									type="primary"
									htmlType="submit"
									className="login-form-button"
									// onClick={this.iloginFnAdmin}
								>
									Ingresar
								</Button>
							</Form.Item>
						</Form>
					</div>
				</Col>
				<Col span={9}></Col>
			</Row>
		);
	}
}
const WrappedNormalLoginForm = Form.create({ name: "normal_login" })(frmLogin);
class iloginapp extends Component {
	render() {
		return <WrappedNormalLoginForm {...this.props} />;
	}
}

const styles = {
	textTitulo1: {
		fontSize: "25px",
		color: "#FF5733",
		fontWeight: "600",
	},
	textTitulo2: {
		fontSize: "15px",
		color: "#415d76",
	},
};
export default iloginapp;
