import React, { Component } from 'react';
import {line1} from './genViewTk';
import * as func from './function';
var _ =  require('lodash');
var Barcode = require('react-barcode');

class viewtkall extends Component {
    constructor(props) {
        super(props);        
        this.state = {
            value:[],
            detalle:[],
        }
        this.line=line1.bind(this);
        this.queryviewOneTicketFn=func.queryviewOneTicketFn.bind(this);
        
        }

    setDatalistTk=(value,detalle)=>{
        if (_.isNull(detalle)) detalle=[];
        this.setState({value,detalle})
    }
    
    componentWillMount=()=>{
        this.queryviewOneTicketFn(this.props.serial,this.props.isys)
    }
    render(){
        console.log(this.props)
        var op=this.props.isys;
        var {value}=this.state;
        return(
        value &&
        value.length!==0 &&
            <div >
                {this.line(op,value,1,this.props.currency ) }
                <Barcode value={!_.isUndefined(value.se)&&value.se} width={1} height={20} margin={0} />
            </div>
        )
    }
}

export default viewtkall;