import React,{ Component } from "react";
import {Button,List,Icon,Row,Col,Form, Input,Select,Table,Popconfirm,Avatar,Tabs,TimePicker} from "antd";
import moment from 'moment';
import store from './store';
import * as func from './function';
import   './cloudltapuesta.scss'; 
var _ =  require('lodash');
const ButtonGroup = Button.Group;
const FormItem = Form.Item;
const Option = Select.Option;
var id_select;
const TabPane = Tabs.TabPane;
const EditableContext = React.createContext();
const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);
class EditableCell extends React.Component {
    state = {
      editing: false,
    }
    getInput = () => {
        if (this.props.inputType === 'hora') {
          return <TimePicker  
            use12Hours
            format="h:mm a" 
            ref={node => (this.input = node)}
            onChange={(e)=>this.save(e)}
            onOpenChange={(e)=>this.save(e)}
           />;
        }
        return  <Input
        ref={node => (this.input = node)}
        onPressEnter={this.save}
        onBlur={this.save}
      />;
      };
    toggleEdit = () => {
      const editing = !this.state.editing;
      this.setState({ editing }, () => {
        if (editing) {
          this.input.focus();
        }
      });
    }
  
    save = () => {
      const { record, handleSave ,inputType} = this.props;
      if (inputType==='hora'){
        this.form.validateFields((_, values) => {
            handleSave({ ...record, ...values });
        });
      }else{
        this.form.validateFields((error, values) => {
            if (error) {
                return;
            }
        // this.toggleEdit();
            handleSave({ ...record, ...values });
        });
        }
    }
  
    render() {
      const { editing } = this.state;
      const {
        editable,
        dataIndex,
        title,
        record,
        index,
        handleSave,
        inputType,
        ...restProps
      } = this.props;
      return (
          
        <td {...restProps}>
          {editable ? (
            <EditableContext.Consumer>
              {(form) => {
                this.form = form;
                return (
                  editing ? (
                    <FormItem style={{ margin: 0 }}>
                      {form.getFieldDecorator(dataIndex, {
                        rules: [{
                          required: true,
                          message: `${title} es necesario.`,
                        }],
                        initialValue:this.props.inputType!=='hora'?record[dataIndex]:moment(record[dataIndex], "h:mm a" ),
                      })(
                       this.getInput(record[dataIndex])
                      )}
                    </FormItem>
                  ) : (
                    <div
                      className="editable-cell-value-wrap"
                      style={{ paddingRight: 24 }}
                      onClick={this.toggleEdit}
                    >
                      {restProps.children}
                    </div>
                  )
                );
              }}
            </EditableContext.Consumer>
          ) : restProps.children}
        </td>
      );
    }
  }
  
  
    var seleccionRow,handleSel;
    const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        seleccionRow=selectedRowKeys;
    },
    getCheckboxProps: record => ({
        disabled: record.name === 'Disabled User',
        name: record.name,
    }),
    };
//{id:1,text:'Terminal',type:1,leng:2,combi:false},{id:2,text:'Triple',type:1,leng:3,combi:false},{id:3,text:'Terminalazo A',type:4,leng:2,combi:true},{id:4,text:'Terminalozo B',type:4,leng:2,combi:true},{id:5,text:'Tripletazo',type:4,leng:3,combi:true},
class FromEditLottery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id:-1,
            idasig:-1,
            PremioxAp:[],
            asignAp:[],
            seleccion_ap_txt:[],
            initSelect_prm:[],
            rngLtrs:['A','B','C','D','E'],
            lstapuestas:[],
            dataSource: [],
            dataSource_l2: [{key:1,dia:'Lunes',hora:'00:00'},{key:2,dia:'Martes',hora:'00:00'},{key:3,dia:'Miercoles',hora:'00:00'},{key:4,dia:'Jueves',hora:'00:00'},
            {key:5,dia:'Viernes',hora:'00:00'},{key:6,dia:'Sabado',hora:'00:00'},{key:7,dia:'Domingo',hora:'00:00'}],
            lismark:[],
            lisChkMark:[],
            activarPrm:[0,0,0],///0=1-Op A, 2-Op A y B, 1=Cant Digit, 2= 1 Con Comodin, 0 Sin Comodin
            columns:[{
                title: 'id',
                dataIndex: 'key',
                width: '20%',
                editable: false,
            }, {
                title: 'Hora',
                dataIndex: 'hora',
                width: '30%',
                editable: true,
            }, {
                title: 'Descripcion',
                dataIndex: 'descri',
                width: '50%',
                editable: true,
            }],
            columns_l2:[{
                title: 'ID',
                dataIndex: 'key',
                width: '10%',
                editable: false,
            }, {
                title: 'Dia',
                dataIndex: 'dia',
                width: '40%',
                editable: false,
            }, {
                title: 'Hora Ape.',
                dataIndex: 'hora',
                width: '50%',
                editable: true,
            }]
        }
        this.queryLtlstapuestasFn=func.queryLtlstapuestasFn.bind(this);
        this.mutationLoteriatsFn=func.mutationLoteriatsFn.bind(this);
    }
    isset=(_var)=>{
        return !!_var; // converting to boolean.
      }
    handleWebsiteChange = () => {
        this.props.form.validateFields(
            (err,valores) => {
                if (!err) {
                    var {asignAp,rngLtrs,dataSource,dataSource_l2,id,lisChkMark,idasig}=this.state;
                    var lismark2= this.state.lismark;
                    var PremioxAp2=this.state.PremioxAp;
                    _.remove(asignAp, function(n) {
                        return _.indexOf(lisChkMark,n.id) === -1;
                    });
                // this.props.tthis.CambiarEstatus(valores.estatus,valores.AddDesc); 
                /// Armar json de Apuestas
                    if (idasig!==-1) {
                        var idx1=_.findIndex(asignAp, function(o) { return o.id === idasig; });
                        if (idx1===-1) asignAp.push({id:idasig,lismark:lismark2,PremioxAp2});
                        else {asignAp[idx1].lismark=lismark2; asignAp[idx1].PremioxAp=parseInt(lismark2.premio)}
                    }
                    console.log('Grabar asignAp',asignAp)
                    var json_apuestas=[];

                    for (var x in lisChkMark){
                        const lisChkSearch= lisChkMark[x]
                        var idL=asignAp.findIndex((o)=> { return o.id === lisChkSearch });
                        if (idL!==-1){
                            var {lismark,PremioxAp}=asignAp[idL]
                            console.log('Grabar lismark',lismark,PremioxAp,asignAp)
                            var ida=asignAp[x].id;
                            var zona='',dig='';
                            var {premio}=lismark;
                            if (!this.isset(premio)) premio=PremioxAp;
                            for (var x1 in lismark){
                                var idx=_.indexOf(rngLtrs,lismark[x1].ltr );
                                if (idx!==-1) zona=idx+1;
                                dig+=lismark[x1].dig;
                            }
                            json_apuestas.push({
                                ida,
                                premio,
                                zona,
                                formato:parseInt(dig)
                            })
                        }else{
                            json_apuestas.push({
                                ida:lisChkMark[x],
                                premio:0,
                                zona:1,
                                formato:0
                            })
                        }
                    }
                    /// Armar json de Sorteo
                    var json_sorteo=[];
                    for (var y in dataSource){
                        json_sorteo.push({
                            ids:dataSource[y].key,
                            hora:moment.unix(dataSource[y].temphour).format('HH:mm'),
                            text:dataSource[y].descri,
                        })
                    }
                    /// Armar json de Apertura
                    var json_apertura=[];
                    var dia=['Lunes','Martes','Miercoles','Jueves','Viernes','Sabado','Domingo',];
                    for (var z in dataSource_l2){
                        var idz=_.indexOf(dia,dataSource_l2[z].dia );
                        var hora=dataSource_l2[z].hora
                        if (_.isObject(dataSource_l2[z].hora)){
                            var temphour=moment(dataSource_l2[z].hora).unix();
                            hora=moment.unix(temphour).format('HH:mm');
                        } 

                        json_apertura.push({
                            idap:dataSource_l2[z].idap,
                            dia:idz,
                            hora,
                        })
                    }
                    var json_data=[];

                    json_data={
                        idl:id,
                        text:valores.nombre,
                        json_apuestas:JSON.stringify(json_apuestas),
                        json_sorteo:JSON.stringify(json_sorteo),
                        json_apertura:JSON.stringify(json_apertura),
                    }
                    console.log(json_data);
                    this.mutationLoteriatsFn(json_data,1)
                }
            },
        );
    }
    handleAdd = () => {
        if (handleSel===1) {
            const {  dataSource } = this.state;
            
            var count=dataSource.length;
            const newData = {
                key: count+1,
                hora: `Nuevo ${count+1}`,
                descri: `Descripcion ${count+1}`,
                temphour:'',
            };
            this.setState({
                dataSource: [...dataSource, newData]
            });
        }
    }
    
    handleSave = (row) => {
        var  newData;
        if (handleSel===1) 
            newData = [...this.state.dataSource];
        if (handleSel===2) 
            newData = [...this.state.dataSource_l2];

        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        console.log('SALVADO',newData)
        if (handleSel===1) {
            for (var rows in newData){
                var i=parseInt(rows)+1;
                console.log(`Descripcion ${i}`,newData[rows].descri)
                //if (newData[rows].descri===`Descripcion ${i}`) 
                if (_.isObject(newData[rows].hora)) newData[rows].descri=moment(newData[rows].hora).format('LT');
                if (_.isObject(newData[rows].hora)) newData[rows].temphour=moment(newData[rows].hora).unix();
            }
            this.setState({ dataSource: newData });
        }
        if (handleSel===2) 
            this.setState({ dataSource_l2: newData });
    }

    handleDelete = (key) => {
        var dataSource ;
        if (handleSel===1) 
            dataSource = [...this.state.dataSource];

        if (handleSel===1) 
            this.setState({ dataSource: dataSource.filter(item =>   _.indexOf(seleccionRow,item.key)  === -1 ) });
    }
    handleSelect=(key)=>{
        handleSel=key;
    }
   
    handTabsChg=(e)=>{
        handleSel=parseInt(e);
    }
    handclickselec=(ltr,dig)=>{
        var {lismark} =this.state;
        var idx=_.findIndex(lismark, function(o) { return o.ltr === ltr && o.dig === dig; });
        if (idx===-1) 
            lismark.push({ltr,dig})
        else {
            _.remove(lismark, function(o) {
                return o.ltr === ltr && o.dig === dig
            });
        }
        this.setState({lismark})

    }
    dick_chk=(ltr,dig)=>{
        
            var {lismark} =this.state;
            var idx=_.findIndex(lismark, function(o) { return o.ltr === ltr && o.dig === dig; });
            return idx;
    }
    handChgSeleccAp=(e)=>{
        var {lstapuestas,asignAp}=this.state;
        var mayor=0;
        var comodin=false;
        var type=[],valLeng=[];
        // lstapuestas:[{id:1,text:'Terminal',type:1,leng:2,combi:false},
        // 3:{id:11,text:"Animalitos",type:3,leng:0,combi:false}
        for (let j=0;j<=e.length-1;j++){
            var idn=_.findIndex(lstapuestas, function(o) { return o.id === e[j]; });
            if (lstapuestas[idn].leng>=mayor) mayor=lstapuestas[idn].leng;
            if (lstapuestas[idn].combi) comodin=true;
            const tp=lstapuestas[ idn ].type;
            var idx=type.findIndex((o)=>  o.type === tp );
            if (idx===-1){
                valLeng=[{leng:lstapuestas[idn].leng}]
                type.push({type:lstapuestas[idn].type,value:valLeng});
            }else{
                valLeng=type[idx].value;
                valLeng.push({leng:lstapuestas[idn].leng})
                type[idx].value=valLeng
            }
        }
        var zona=1
        for (var rows in type){
            if (type[rows].type===4 || type[rows].type===1)
                zona++;
            else{
                var x=_.groupBy(type[rows].value, parseInt);
                for (var rows2 in x){
                    if (x[rows2].length>1) zona++;
                }
            }
        }
        _.remove(asignAp, function(n) {
            return _.indexOf(e,n.id) === -1;
        });
        console.log('e==>',e,asignAp)
        //if (zona===0) zona=1;
        var  activarPrm=[zona,mayor,(comodin?1:0)];///0=1-Op A, 2-Op A y B, 1=Cant Digit, 2= 1 Con Comodin, 0 Sin Comodin
        this.setState({lisChkMark:e,activarPrm,asignAp})
    }
    viewSeleccAp=(value,index)=>{
        var {lisChkMark}=this.state;
        if (_.indexOf(lisChkMark, value.id)!==-1){
            return(
                <Option key={index} value={value.id}>{value.text}</Option>
            )
        }
    }
    handleChgPremio=(id,i)=>{
        
        var {asignAp,PremioxAp,lismark,idasig}=this.state;
        if (idasig!==-1) {
            var idx=_.findIndex(asignAp, function(o) { return o.id === idasig; });
            if (idx===-1) asignAp.push({id:idasig,lismark,PremioxAp});
            else {asignAp[idx].lismark=lismark; asignAp[idx].PremioxAp=parseInt(lismark.premio)}
        }
        var idx1=_.findIndex(asignAp, function(o) { return o.id === id; });
        console.log('asignAp[idx1].',asignAp[idx1])
        if (idx1===-1) {
            lismark=[];PremioxAp=[];
            lismark.premio=0;
        }else{
            lismark=asignAp[idx1].lismark;
            lismark.premio=asignAp[idx1].PremioxAp;
        }
           // lismark.premio=parseInt(asignAp[idx1].PremioxAp);
           // PremioxAp[idx1]=asignAp[idx1].PremioxAp
        
        this.setState({lismark,idasig:id,asignAp,PremioxAp,initSelect_prm:i.props.children})
    }
    handlePremioxAp=(e,idx,ltr)=>{
                
            var {lismark}=this.state;
            var idy=_.findIndex(lismark, (o)=> { return o.ltr === ltr; });
            if (idy!==-1)
                lismark.premio=e.target.value;
            
            this.setState({lismark})
    }
    premioValueFn=(ltr)=>{
        var {lismark} =this.state;
        var idy=_.findIndex(lismark, (o)=> { return o.ltr === ltr; });
        var valores=''
        if (idy!==-1){
            var {premio}=lismark;
            valores =(premio==='0'?'':premio);
        }

        return valores

    }
    handLoad_data=async ()=>{
        var {id}=this.props;
        var {dataSource_l2}=this.state;
        
        await this.queryLtlstapuestasFn();

        if (id!==-1){
            var {lisdata}=this.props;
            var {seleccion_ap,premiacion,sorteo,horario,title}=lisdata;
            var {lstapuestas,lisChkMark}=this.state;
            var idasig=-1,lismark=[],PremioxAp=[];
            lismark.premio=0
            var asignAp=premiacion;
            var nombre=title;
            lisChkMark=[];
            var  iDate= moment().format("YYYY-MM-DD")
            for (const rows in sorteo){
                sorteo[rows].hora=moment(sorteo[rows].hora, "h:mm a" ).format('LT')
                sorteo[rows].temphour=moment(iDate+' '+sorteo[rows].hora).unix() 
            }
           
            console.log(sorteo,iDate)
            for (const rows in premiacion){
                PremioxAp.push(premiacion[rows].PremioxAp);
            }
            console.log(PremioxAp,premiacion)
            for (const rows in seleccion_ap){
                var idx=_.findIndex(lstapuestas, (o)=> { return o.text === seleccion_ap[rows]; });
                if (idx!==-1) {
                    lisChkMark.push(lstapuestas[idx].id);
                    if (idasig===-1){
                        const searcgIdasig=lstapuestas[idx].id;
                        var idn=premiacion.findIndex( (o)=>  o.id ===  searcgIdasig );
                        if (idn!==-1){
                           // lismark=premiacion[idn].lismark;
                            var premio=premiacion[idn].PremioxAp
                            lismark=[...premiacion[idn].lismark]
                            lismark['premio']=premio
                        }
                    } 
                }
            }
            
            for (var rows2 in dataSource_l2){
                // var idy=_.findIndex(horario, (o)=> { return o.key === dataSource_l2[rows2].key; });
                // if (idy!==-1){
                    dataSource_l2[rows2].hora=horario[rows2].hora;
                //}
            }
            console.log('dataSource_l2',dataSource_l2,horario)
            if (seleccion_ap.length!==0){
                var initSelect_prm=seleccion_ap[0];
                console.log(initSelect_prm)
            }

            this.setState({id,seleccion_ap_txt:lisChkMark,lisChkMark,initSelect_prm,idasig,asignAp,lismark,PremioxAp,dataSource:sorteo,dataSource_l2,nombre})
            this.handChgSeleccAp(lisChkMark)
            
        }else{
            for (var rows3 in dataSource_l2){
                    dataSource_l2[rows3].hora='00:00';
            }
            this.setState({id,seleccion_ap_txt:[],lisChkMark:[],initSelect_prm:[],idasig:-1,asignAp:[],lismark:[],PremioxAp:[],dataSource:[],dataSource_l2,nombre:'',activarPrm:[0,0,0]})
        }
    }
    
    componentDidUpdate= ()=>{
        if (this.state.id!==this.props.id)  { this.handLoad_data();  }
        
    }
    componentDidMount=()=>{
        handleSel=1;
        this.handLoad_data()
    }
render() {
    const { getFieldDecorator} = this.props.form;
    const {nombre,activarPrm,seleccion_ap_txt,initSelect_prm}=this.state;
    var TotalsPrm=activarPrm[0];
    var CantDig=activarPrm[1];
    var comodin=activarPrm[2];
    var Vr_prm=[];
    var {rngLtrs}=this.state

    

    for (let j=0;j<=TotalsPrm-1;j++) Vr_prm.push(rngLtrs[j])
    const components = {
        body: {
          row: EditableFormRow,
          cell: EditableCell,
        },
      };
    const columns = this.state.columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: record => ({
            record,
            editable: col.editable,
            inputType: col.dataIndex === 'hora' ? 'hora' : 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
          }),
        };
      });
      const columns_l2 = this.state.columns_l2.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: record => ({
            record,
            editable: col.editable,
            inputType: col.dataIndex === 'hora' ? 'hora' : 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
          }),
        };
      });
    return (
        <div className="fromPendientes">
        <Form className="FromTransDB" >
            <FormItem label="Nombre (Descripcion):" className="FromiTemone">
                    {getFieldDecorator('nombre', {initialValue:nombre,
                    rules: [{required: true, message: 'Por favor requiero la Descripcion de la Apuesta!'},
                        {type: 'string',  message: 'Debe tener alguna descripcion !'}],
                    })(
                    <Input />
                    )}
            </FormItem>
            <FormItem label="Apuestas:"  className="FromiTemtwo">
                {getFieldDecorator('apuesta', {initialValue:seleccion_ap_txt,
                rules: [{ required: true, message: 'Por favor seleccione que tipo de apuesta es la configuracion!' }],
                })(
                    <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    onChange={(e)=>this.handChgSeleccAp(e)}
                    placeholder="Seleccion de apuestas"//
                    >
                        {this.state.lstapuestas.map((value,index)=>
                            <Option key={index} value={value.id}>{value.text}</Option>
                        )}
                    </Select>,
                )}
            </FormItem>
            <Tabs defaultActiveKey="1" onChange={(e)=>this.handTabsChg(e)} className="tabLt">
                <TabPane tab="Sorteos" key="1">
                        <FormItem label={"Sorteos:"}>
                            <div className='FromiTemLista'>
                                <div className="barrwdw">
                                    <ButtonGroup >
                                            <Button  className="barrwdw_bnt" ghost type="link" onClick={()=>this.handleAdd()}><Icon type="plus" /></Button>
                                            <Button  className="barrwdw_bnt" ghost type="link" onClick={()=>this.handleDelete()} ><Icon type="minus" /></Button>
                                            <Button  className="barrwdw_bnt" ghost type="link" ><Icon type="diff" /></Button>
                                    </ButtonGroup>
                                </div>
                                <Table
                                    key="index_tbl_01"
                                    components={components}
                                    rowClassName={() => 'editable-row'}
                                    bordered
                                    dataSource={this.state.dataSource}
                                    columns={columns}
                                    rowSelection={rowSelection}
                                    pagination={{ pageSize: 50 }} 
                                    scroll={{ y: 180 }}
                                />
                            </div>
                    </FormItem>
                </TabPane>
                <TabPane tab="Apertura" key="2">
                    <FormItem label={"Apertura de Loteria:"}>
                            <div className='FromiTemLista'>
                                <div className="barrwdw">
                                    {/* <ButtonGroup >
                                            <Button  type="primary" disabled onClick={()=>this.handleAdd()}><Icon type="plus" /></Button>
                                            <Button  type="primary" disabled onClick={()=>this.handleDelete()} ><Icon type="minus" /></Button>
                                            <Button  type="primary"><Icon type="diff" /></Button>
                                    </ButtonGroup> */}
                                </div>
                                <Table
                                    key="index_tbl_02"
                                    components={components}
                                    rowClassName={() => 'editable-row'}
                                    bordered
                                    dataSource={this.state.dataSource_l2}
                                    columns={columns_l2}//rowSelection={rowSelection}
                                    pagination={{ pageSize: 50 }} 
                                    scroll={{ y: 180 }}
                                />
                            </div>
                    </FormItem>
                </TabPane>
                <TabPane tab="Premio" key="3">
                    <FormItem label={"Configuracion de Premio:"}>
                        <div className='FromiTemLista_prm'>
                            <div className='FromiTemSelect_prm'><Select
                                showSearch
                                value={initSelect_prm}
                                onChange={(e,i)=>this.handleChgPremio(e,i)}
                                style={{ width: 200 }}
                                placeholder="Seleccione la apuesta"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {this.state.lstapuestas.map((value,index)=>
                                    this.viewSeleccAp(value,index)
                                )}
                            </Select></div>
                            {Vr_prm.map((value,index)=>
                                <div key={index} className="FromiTemlist1_prm">
                                        <div className="boxLtr"><label className="boxLtrText">{value}</label></div>
                                        {CantDig>=1 &&
                                        <div className="boxPrm_dig1" onClick={()=>this.handclickselec(value,1)}><label className="boxPrm_text1">{this.dick_chk(value,1)!==-1?'X':''}</label></div>
                                        }
                                        {CantDig>=2 &&
                                        <div className="boxPrm_dig2" onClick={()=>this.handclickselec(value,2)}><label className="boxPrm_text2">{this.dick_chk(value,2)!==-1?'X':''}</label></div>
                                        }
                                        {CantDig===3 &&
                                        <div className="boxPrm_dig3" onClick={()=>this.handclickselec(value,3)}><label className="boxPrm_text3">{this.dick_chk(value,3)!==-1?'X':''}</label></div>
                                        }
                                        {comodin===1 &&
                                        <div>
                                            <div className="boxPrm_sig"><label className="boxPrm_sig1">-</label></div>
                                            <div className="boxPrm_com"  onClick={()=>this.handclickselec(value,4)}><label className="boxPrm_com1">{this.dick_chk(value,4)!==-1?'X':''}</label></div>
                                        </div>
                                        }
                                        <div className="boxPrm_input"> 
                                            <Input 
                                                placeholder="Premio" 
                                                type="Number"  
                                                style={{ width: 120 }} 
                                                value={this.premioValueFn(value)} 
                                                onChange={(e)=>this.handlePremioxAp(e,index,value)}/></div>
                                </div>
                            )}
                        </div>
                    </FormItem>
                </TabPane>
            </Tabs>
            <FormItem >
            <Row gutter={20}>
                <Col span={10}>
                    <Button  onClick={(e)=>this.handleWebsiteChange()}> Grabar</Button>
                </Col>
                
            </Row>
            </FormItem>
        </Form>
        </div>
        )
    }
}

const EnhancedFromEditLoteria = Form.create()(FromEditLottery);


//[{key:1,dia:'Lunes',hora:'00:00'},{key:2,dia:'Martes',hora:'00:00'},{key:3,dia:'Miercoles',hora:'00:00'},{key:4,dia:'Jueves',hora:'00:00'},{key:5,dia:'Viernes',hora:'00:00'},{key:6,dia:'Sabado',hora:'00:00'},{key:7,dia:'Domingo',hora:'00:00'}]

class Mainltloteria extends Component{
    constructor(props) {
        super(props);
        this.state = {
            id_mark:0,
            insider:'',
            collapsed:false,
            listDataLt:[]//NumeroCombinacion,ListaCombinacion
        }
        this.queryLoterialtFn=func.queryLoterialtFn.bind(this);
        this.mutationLoteriatsFn=func.mutationLoteriatsFn.bind(this);
        store.subscribe(() => {
            this.setState({
                listDataLt:store.getState().listDataLt,
                insider:store.getState().insider,
            })
        })
    }
    commandBarr=(type)=>{
        switch(type){
            case -1:
                id_select=-1;
                this.setState({insider:<EnhancedFromEditLoteria id={type}/>});///-1:Add
                break;
            case -2:
                var json_data={
                    idl:this.state.id_mark,
                    text:''
                }
                this.mutationLoteriatsFn(json_data,2);
                break;
            default:
        }
    }
    onClickMainList=(id,index)=>{
        this.setState({id_mark:id})
        id_select=index;
        this.setState({insider:<EnhancedFromEditLoteria id={id} lisdata={this.state.listDataLt[index]}/>})
    }
    componentDidMount=()=>{
        store.dispatch({ type: 'IN_data_insider',insider:''});
        this.queryLoterialtFn();
    }
render(){
    return(
        <div className="mainwdw_lt">
            <div className="barrwdw">
                <ButtonGroup >
                        <Button className="barrwdw_bnt" ghost type="link" onClick={()=>this.commandBarr(-1)} ><Icon type="plus" /></Button>
                        <Popconfirm title="Esta Seguro de Borrar esta configuracion de Loteria?" onConfirm={()=>this.commandBarr(-2)}  okText="Borrar" cancelText="Cancelar">
                            <Button  className="barrwdw_bnt" ghost type="link"><Icon type="minus" /></Button>
                        </Popconfirm >
                        <Button  className="barrwdw_bnt" ghost type="link"><Icon type="diff" /></Button>
                </ButtonGroup>
                <div className="barrwdwtitle">
                    <label className="barrwdwtext"><Icon type="deployment-unit" />&nbsp;Loteria</label>
                </div>
            </div>
            <Row gutter={2}>
                <Col span={9} >
                    <div className='mainList_lt'>
                        <List
                                itemLayout="vertical"
                                size="small"
                                locale={{ emptyText:<label style={{color:'#fff'}}> <Icon type="delete" style={{fontSize: '22px',color:'#fff'}}/>No hay Informacion</label> }}
                                dataSource={this.state.listDataLt}
                                renderItem={(item,index) => (
                                <List.Item
                                    className={id_select===index?"listStyItem":''}
                                    key={item.title}
                                    onClick={()=>this.onClickMainList(item.id,index)}
                                    >
                                    <List.Item.Meta
                                    avatar={<Avatar style={{ backgroundColor:'#f56a00', verticalAlign: 'middle' }} size="large" >{item.etiqueta}</Avatar>}
                                    title={<label className="mainLabletitle_lt">{item.title}</label>}
                                    />
                                </List.Item>
                                )}
                            />
                    </div>
                </Col>
                <Col span={9} >
                    <div className='mainContent'>
                        {this.state.insider}
                    </div>
                </Col>
            </Row>
        </div>
    )
}
}
export default Mainltloteria;