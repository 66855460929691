import React, { useState } from "react";
import { message, notification, Icon } from "antd";
import { setCaptures } from "../service/setCaptures";

export function useCapture() {
	const [statecapture, setcapture] = useState(false);

	const haldlenNotific = (message, description) => {
		notification.error({
			message,
			placement: "topLeft",
			description,
		});
	};
	const haldlenOnClickCapture = ({ code, cantidadCarr,setDisplayBtn,hadlenListData}) => {
		if (!isNaN(Number(cantidadCarr))) {
			setcapture(true);
			setCaptures(code, parseInt(cantidadCarr))
				.then((value) => {
					setcapture(false);
					if (value) {
						let { error, codeerror, text } = value;
						if (error) {
							haldlenNotific(`Error ${codeerror}`, text);
						} else {
							setDisplayBtn(false);
							message.success(<Icon type="like" theme="twoTone" />);
							hadlenListData();
						}
					} else {
						setcapture(false);
						setDisplayBtn(true);
						haldlenNotific(
							`Error `,
							"Ups! no pudimos conectarnos con el sistema de Captacion, intenta de nuevo mas tarde!"
						);
					}
				})
				.catch(() => {
					setDisplayBtn(true);
					haldlenNotific(
						`Error `,
						"Ups! no pudimos conectarnos con el sistema de Captacion, intenta de nuevo mas tarde!"
					);
				});
		} else {
			setDisplayBtn(true);
			haldlenNotific(
				`Error `,
				"Ups! No puedo aceptar ese valor en la Cantidad!"
			);
		}
	};

	return { statecapture, haldlenOnClickCapture };
}
