import React, { useRef, useState, useEffect } from "react";
import { Button, Card, Tag, Input } from "antd";
import { useCapture } from "../../../hooks/useCapture";

const inicRecord = {
	description: "",
	existe: "",
	inicio: "",
	cantidad: 0,
	code: "",
};

const Index = ({ record = inicRecord,hadlenListData }) => {
	const { description, existe, inicio, cantidad, code } = record
		? record
		: inicRecord;
	const refInput = useRef(null);
	const [cantidadCarr, setCantidad] = useState(record ? cantidad : 0);
	const { statecapture, haldlenOnClickCapture } = useCapture();
	const [displayBtn, setDisplayBtn] = useState(!existe);

	const haldlenChangeCantidad = ({ target: { value } }) => {
		setCantidad(value);
	};
	useEffect(() => {
		console.log(record)
		if (refInput.current) refInput.current.state.value = cantidad;
		setCantidad(cantidad);
		setDisplayBtn(!existe)
		return () => {};
	}, [record,existe,cantidad]);

	return description ? (
		<div className="card-main">
			<Card
				title={`Hipodromo ${description}`}
				bordered={false}
				className="capture-card"
			>
				<div className="capture-card-main">
					{existe ? <Tag color="red">Captado</Tag> : <div></div>}
					<label>Inicia {inicio}</label>
					<Input
						ref={refInput}
						onChange={haldlenChangeCantidad}
						addonBefore="Cantidad"
						defaultValue={cantidad}
						disabled={Boolean(existe)}
					/>
					{displayBtn && (
						<div className="capture-card-btn">
							<Button
								onClick={() => haldlenOnClickCapture({code, cantidadCarr,setDisplayBtn,hadlenListData})}
								type="primary"
								disabled={existe}
								loading={statecapture}
							>
								Captar Hipodromo
							</Button>
						</div>
					)}
				</div>
			</Card>
		</div>
	) : (
		<div></div>
	);
};

export default Index;
