import React from "react";
import { Table, Tag } from "antd";
import FormatNumber from "../../../compenent/formatNumber";
import { styleBg, typeUser } from "../../../globals.js";

const { Column } = Table;

export const RendeTable = ({ dataop, openModal }) => {
	return (
		<>
			{dataop ? (
				<Table
					rowKey={(record) => record.iu}
					dataSource={dataop}
					onRow={(record) => {
						return {
							onClick: () => {
								const {iu,Nombre,Moneda}=record
								openModal({
									activo: true,
									title: `Detalle de la transaccion Usuario ${Nombre.toUpperCase()}`,
									iu,Moneda
								});
								//
							},
						};
					}}
				>
					<Column key="iu" title="Id" dataIndex="iu" />
					<Column
						title="Usuario"
						dataIndex="Usuario"
						sorter={(a, b) => a.Usuario.length - b.Usuario.length}
					/>
					<Column
						title="Nombre"
						dataIndex="Nombre"
						sorter={(a, b) => a.Nombre.length - b.Nombre.length}
					/>
					<Column
						title="Tipo Usuario"
						dataIndex="tipo"
						render={(tipo, record) => (
							<span>
								{<Tag color={styleBg[tipo - 1]}>{typeUser[tipo - 1]}</Tag>}
							</span>
						)}
					/>
					<Column
						title="Balance"
						dataIndex="Balance"
						className="column-monto"
						render={(Balance, record) => (
							<span>
								<FormatNumber
									currency={record.Moneda}
									number={Number(Balance)}
								/>
							</span>
						)}
					/>
				</Table>
			) : (
				<div></div>
			)}
		</>
	);
};

export default RendeTable;
