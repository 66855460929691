import React,{ Component } from "react";
import {Button,List,Icon,Row,Col,Form, Input,Select,Table,Radio,Popconfirm} from "antd";
import   './cloudltapuesta.scss'; 
import * as func from './function';
import store from './store';
var _ =  require('lodash');
const ButtonGroup = Button.Group;
const FormItem = Form.Item;
const Option = Select.Option;
var Inicial,Final,id_select;
const InputGroup = Input.Group;
const EditableContext = React.createContext();
const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);
class EditableCell extends React.Component {
    state = {
      editing: false,
    }
  
    toggleEdit = () => {
      const editing = !this.state.editing;
      this.setState({ editing }, () => {
        if (editing) {
          this.input.focus();
        }
      });
    }
  
    save = (e) => {
      const { record, handleSave } = this.props;
      this.form.validateFields((error, values) => {
        if (error && error[e.currentTarget.id]) {
          return;
        }
        this.toggleEdit();
        handleSave({ ...record, ...values });
      });
    }
  
    render() {
      const { editing } = this.state;
      const {
        editable,
        dataIndex,
        title,
        record,
        index,
        handleSave,
        ...restProps
      } = this.props;
      return (
        <td {...restProps}>
          {editable ? (
            <EditableContext.Consumer>
              {(form) => {
                this.form = form;
                return (
                  editing ? (
                    <FormItem style={{ margin: 0 }}>
                      {form.getFieldDecorator(dataIndex, {
                        rules: [{
                          required: true,
                          message: `${title} is required.`,
                        }],
                        initialValue: record[dataIndex],
                      })(
                        <Input
                          ref={node => (this.input = node)}
                          onPressEnter={this.save}
                          onBlur={this.save}
                        />
                      )}
                    </FormItem>
                  ) : (
                    <div
                      className="editable-cell-value-wrap"
                      style={{ paddingRight: 24 }}
                      onClick={this.toggleEdit}
                    >
                      {restProps.children}
                    </div>
                  )
                );
              }}
            </EditableContext.Consumer>
          ) : restProps.children}
        </td>
      );
    }
  }
  
var seleccionRow,handleSel;
const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        seleccionRow=selectedRowKeys;
    },
    getCheckboxProps: record => ({
        disabled: record.name === 'Disabled User',
        name: record.name,
    }),
};
class FromEditApuesta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id:-1,
            inicTxt:'',
            val_data:{nombre:'',apuesta:0},
            lista_nums:[],
            lista_list:[],
            apuesta:[{id:1,Text:'Numero'},{id:2,Text:'Lista'},{id:3,Text:'Nombres'},{id:4,Text:'Combinado'}],
            dataSource: [],
            dataSource_l2: [],
            NumeroCombinacion:0,
            ListaCombinacion:0,
            columns:[{
                title: 'Posicion',
                dataIndex: 'key',
                editable: false,
            }, {
                title: 'Lista',
                dataIndex: 'name',
                width: '70%',
                editable: true,
            }],
            columns_l2:[{
                title: 'id',
                dataIndex: 'key',
                width: '10%',
                editable: false,
            },{
                title: 'Numero',
                dataIndex: 'numero',
                width: '30%',
                editable: true,
            }, {
                title: 'Nombre',
                dataIndex: 'name',
                width: '40%',
                editable: true,
            }, {
                title: 'Imagen',
                dataIndex: 'svg',
                width: '20%',
                editable: true,
            }],
            columns_l3:[{
                title: 'Id',
                dataIndex: 'key',
                width: '20%',
                editable: true,
            }, {
                title: 'Numero',
                dataIndex: 'num',
                width: '40%',
                editable: true,
            }, {
                title: 'Lista',
                dataIndex: 'lista',
                width: '40%',
                editable: true,
            }]
        }
        this.mutationApuestaltsFn=func.mutationApuestaltsFn.bind(this);
    }
    handleWebsiteChange = () => {
        var {id,apuesta}=this.state;
        this.props.form.validateFields(
            (err,value) => {
            if (!err) {
                var idx=_.findIndex(apuesta, function(o) { return o.Text === value.apuesta; })
                var type=apuesta[idx].id;
                var vJson=[];
                switch(type){
                    case 1:
                        vJson=[{id:0,value:Inicial},{id:1,value:Final}]
                        break;
                    case 2:
                        vJson=this.state.dataSource
                        break;
                    case 3:
                        vJson=this.state.dataSource_l2
                        break;
                    case 4:
                        vJson=[{key:0,NumeroCombinacion:value.NumeroCombinacion},{key:1,ListaCombinacion:value.ListaCombinacion}]
                        break;
                    default:
                }
                var input_json={ida:id,text:value.nombre,type,valores:JSON.stringify(vJson)}
                this.mutationApuestaltsFn(input_json,1);
            }
            },
        );
    }
    // ,{
    //     id:4,
    //     title: `Tripletazo A`,
    //     description: 'Tipo: Combinado, Terminal-Signo',
    //     idap:4,data:[{key: 0,NumeroCombinacion:1}, {key: 1,ListaCombinacion:2}]
    //     }
    handleAdd = () => {
        if (handleSel==='Lista') {
            const {  dataSource } = this.state;
            
            var count=dataSource.length;
            const newData = {
                key: count+1,
                name: `Nuevo ${count+1}`,
            };
            this.setState({
                dataSource: [...dataSource, newData]
            });
        }
        if (handleSel==='Nombres')  {
            const {  dataSource_l2 } = this.state;
            
            var count_l2=dataSource_l2.length;
            const newData = {
                key: count_l2+1,
                numero: '000',
                name: `Nuevo ${count_l2+1}`,
                svg: `Nuevo ${count_l2+1}`
            };
            this.setState({
                dataSource_l2: [...dataSource_l2, newData]
            });
        }
       
    }
    
    handleSave = (row) => {
        var  newData;
        if (handleSel==='Lista') 
            newData = [...this.state.dataSource];
        if (handleSel==='Nombres') 
            newData = [...this.state.dataSource_l2];

        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        if (handleSel==='Lista') 
            this.setState({ dataSource: newData });
        if (handleSel==='Nombres') 
            this.setState({ dataSource_l2: newData });
    }

    handleDelete = (key) => {
        var dataSource ;
        if (handleSel==='Lista') 
            dataSource = [...this.state.dataSource];
        if (handleSel==='Nombres') 
            dataSource = [...this.state.dataSource_l2];

        if (handleSel==='Lista') 
            this.setState({ dataSource: dataSource.filter(item =>   _.indexOf(seleccionRow,item.key)  === -1 ) });
        if (handleSel==='Nombres') 
            this.setState({ dataSource_l2: dataSource.filter(item =>   _.indexOf(seleccionRow,item.key)  === -1 ) });
    }
    handleSelect=(key)=>{
        handleSel=key;
    }
    handLoad_data=()=>{
        var  v_listData=store.getState().listData;
        var lista_nums=[],lista_list=[];
        for (var rows in v_listData){
            switch(parseInt(v_listData[rows].idap)){
                case 1:
                        lista_nums.push({id:v_listData[rows].id,text:v_listData[rows].title})
                        break;
                case 2:
                case 3:
                        lista_list.push({id:v_listData[rows].id,text:v_listData[rows].title})
                        break;
                default:

            }
        }
        this.setState({lista_nums,lista_list});
        var {id}=this.props;

        if (id!==-1){
            var {lisdata}=this.props;
            var {apuesta}=this.state;
            var val_data={nombre:lisdata.title,apuesta: parseInt(lisdata.idap)}
            var idx=_.findIndex(apuesta, function(o) { return o.id === parseInt(lisdata.idap); });
            handleSel=apuesta[idx].Text;
            var inicTxt=apuesta[idx].Text;
            this.setState({val_data,id,inicTxt}) //data:[{id:0,value:'00'},{id:2,value:'99'}]
            var json=JSON.parse(lisdata.data)
            switch(parseInt(lisdata.idap)){
                case 1:
                    Inicial=json[0].value;
                    Final=json[1].value;
                    break;
                case 2:
                    this.setState({dataSource:json});
                    break;
                case 3:
                    this.setState({dataSource_l2:json});
                    break;
                case 4:
                    var {NumeroCombinacion}=json[0];
                    var {ListaCombinacion}=json[1];
                    this.setState({NumeroCombinacion,ListaCombinacion});
                    break;
                default:
                    break;
            }
            
        }else{
            Inicial='';Final='';handleSel='';
            this.setState({inicTxt:'',NumeroCombinacion:0,ListaCombinacion:0,dataSource:[],dataSource_l2:[],val_data:{nombre:'',apuesta:0},id});
        }
    }
    handleNumberChange = (currency,tipo) => {
      
            switch (tipo){
                case 1: 
                    this.setState({ ini:currency.target.value });
                    Inicial=currency.target.value ; 
                    break;
                default: 
                    this.setState({ end:currency.target.value });
                    Final=currency.target.value ; 
                    break;
            }
       
    }
    componentDidUpdate=()=>{
        if (this.state.id!==this.props.id) this.handLoad_data()
    }
    componentDidMount=()=>{
        this.handLoad_data()
    }
render() {
    const { getFieldDecorator} = this.props.form;
    const {val_data,apuesta,inicTxt} =this.state;
    const components = {
        body: {
          row: EditableFormRow,
          cell: EditableCell,
        },
      };
    const columns = this.state.columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: record => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
          }),
        };
      });
      const columns_l2 = this.state.columns_l2.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: record => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
          }),
        };
      });
    return (
        <div className="fromPendientes">
        <Form className="FromTransDB" >
            <FormItem label="Nombre (Descripcion):" className="FromiTemone">
                    {getFieldDecorator('nombre', {initialValue:val_data.nombre,
                    rules: [{required: true, message: 'Por favor requiero la Descripcion de la Apuesta!'},
                        {type: 'string',  message: 'Debe tener alguna descripcion !'}],
                    })(
                    <Input />
                    )}
            </FormItem>
            <FormItem label="Tipo de apuesta:"  className="FromiTemtwo">
                {getFieldDecorator('apuesta', {initialValue:inicTxt,
                rules: [
                    { required: true, message: 'Por favor seleccione que tipo de apuesta es la configuracion!' },
                    ],
                })(
                    <Select placeholder="Tipo de Apuesta" onChange={(e)=>this.handleSelect(e)}>
                        <Option value={0} ></Option>
                        {apuesta.map((value,index)=>
                            <Option key={index} value={value.Text}>{value.Text}</Option>
                        )}
                    </Select>
                )}
            </FormItem>
            {handleSel==='Numero' &&
            <FormItem label="Rango:">
                <InputGroup compact>
                    <Input   value={Inicial} style={{ width: 135, textAlign: 'center' }} onChange={(e)=>this.handleNumberChange(e,1)} placeholder="Inicial" />
                    <label>~</label>
                    <Input    value={Final} style={{ width: 135, textAlign: 'center', borderLeft: 0 }} onChange={(e)=>this.handleNumberChange(e,2)} placeholder="Final" />
                </InputGroup>
            </FormItem>
            }
            {(handleSel==='Lista' || handleSel==='Nombres') &&
            <FormItem label={handleSel+":"}>
                    <div className='FromiTemLista'>
                        <div className="barrwdw">
                            <ButtonGroup >
                                    <Button  className="barrwdw_bnt" ghost type="link" onClick={()=>this.handleAdd()}><Icon type="plus" /></Button>
                                    <Button  className="barrwdw_bnt" ghost type="link" onClick={()=>this.handleDelete()} ><Icon type="minus" /></Button>
                                    <Button  className="barrwdw_bnt" ghost type="link"><Icon type="diff" /></Button>
                            </ButtonGroup>
                        </div>
                        <Table
                            components={components}
                            rowClassName={() => 'editable-row'}
                            bordered
                            dataSource={handleSel==='Lista'?this.state.dataSource:this.state.dataSource_l2}
                            columns={handleSel==='Lista'?columns:columns_l2}
                            rowSelection={rowSelection}
                            pagination={{ pageSize: 50 }} 
                            scroll={{ y: 180 }}
                        />
                    </div>
            </FormItem>
            }
            {(handleSel==='Combinado') &&
            <div>
                <FormItem label="Numeros">
                    {getFieldDecorator('NumeroCombinacion', {initialValue:this.state.NumeroCombinacion,rules: [{required: true, message: 'Por favor requiero la Descripcion de la Apuesta!'}]})(
                        <Radio.Group>
                            {
                            this.state.lista_nums.map((values,index)=>
                                <Radio.Button key={index}  value={values.id}>{values.text}</Radio.Button>
                                )
                            }
                        </Radio.Group>
                    )}
                </FormItem>
                <FormItem label="Listas">
                    {getFieldDecorator('ListaCombinacion', {initialValue:this.state.ListaCombinacion,rules: [{required: true, message: 'Por favor requiero la Descripcion de la Apuesta!'}]})(
                    <Radio.Group>{
                            this.state.lista_list.map((values,index)=>
                                <Radio.Button key={index} value={values.id}>{values.text}</Radio.Button>
                        )
                    }
                    </Radio.Group>
                )}
                </FormItem>
            </div>
            }
            <FormItem >
            <Row gutter={20}>
                <Col span={10}>
                    <Button onClick={(e)=>this.handleWebsiteChange()}>{this.state.id===-1?'Grabar':'Modificar'}</Button>
                </Col>
            </Row>
            </FormItem>
        </Form>
        </div>
        )
    }
}

const EnhancedFromEditApuesta = Form.create()(FromEditApuesta);




class Mainltapuesta extends Component{
    constructor(props) {
        super(props);
        this.state = {
            id_mark:0,
            insider:'',
            collapsed:false,
            listData:[]
        }
        this.queryApuestaltFn=func.queryApuestaltFn.bind(this);
        this.mutationApuestaltsFn=func.mutationApuestaltsFn.bind(this);
        store.subscribe(() => {
            this.setState({
                listData:store.getState().listData,
                insider:store.getState().insider,
            })
        })
    }

    commandBarr=(type)=>{
        switch(type){
            case -1:
                id_select=-1;
                store.dispatch({ type: 'IN_data_insider',insider:<EnhancedFromEditApuesta id={type}/>});
                break;
            case -2:
                var input_json={ida:this.state.id_mark,text:'',type:0,valores:''}
                this.mutationApuestaltsFn(input_json,2);
                break;
            default:
        }
    }
    onClickMainList=(id,index)=>{
        this.setState({id_mark:id})
        id_select=index;
        store.dispatch({ type: 'IN_data_insider',insider:<EnhancedFromEditApuesta id={id} lisdata={this.state.listData[index]}/>});
    }
    componentDidMount=()=>{
        store.dispatch({ type: 'IN_data_insider',insider:''});
        this.queryApuestaltFn();
    }
render(){
    return(
        <div className="mainwdw">
            <div className="barrwdw">
                <ButtonGroup >
                        <Button className="barrwdw_bnt" ghost type="link"  onClick={()=>this.commandBarr(-1)} ><Icon type="plus" /></Button>
                        <Popconfirm title="Esta Seguro de Borrar esta configuracion de Apuesta?" onConfirm={()=>this.commandBarr(-2)}  okText="Borrar" cancelText="Cancelar">
                            <Button  className="barrwdw_bnt" ghost type="link"  ><Icon type="minus" /></Button>
                        </Popconfirm >
                        <Button  className="barrwdw_bnt" ghost type="link" ><Icon type="diff" /></Button>
                </ButtonGroup>
                <div className="barrwdwtitle">
                    <label className="barrwdwtext"><Icon type="deployment-unit" />&nbsp;Apuestas</label>
                </div>
            </div>
            <Row gutter={2}>
                <Col span={9} >
                    <div className='mainList'>
                        <List
                                itemLayout="vertical"
                                size="small"
                                locale={{ emptyText:<label style={{color:'#fff'}}> <Icon type="delete" style={{fontSize: '22px',color:'#fff'}}/>No hay Informacion</label> }} 
                                dataSource={this.state.listData}
                                renderItem={(item,index) => (
                                <List.Item
                                    className={id_select===index?"listStyItem":''}
                                    key={item.title}
                                    onClick={()=>this.onClickMainList(item.id,index)}
                                    >
                                    <List.Item.Meta
                                    title={<label className="mainLabletitle">{item.title}</label>}
                                    description={<label className="mainLabledescrp">{item.description}</label>}
                                    />
                                </List.Item>
                                )}
                            />
                    </div>
                </Col>
                <Col span={9} >
                    <div className='mainContent'>
                        {this.state.insider}
                    </div>
                </Col>
            </Row>
        </div>
    )
}
}
export default Mainltapuesta;