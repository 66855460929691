import client from "../conex";
import {gql} from "@apollo/client";

const queryViewTopesGw = gql`
	query viewTopesG {
		viewTopesG {
			tope
			texto
			cnfD
			cnfA
			cnfN
			cnfL
		}
	}
`;

export default function queryAllTopes() {
	return client
		.query({
			query: queryViewTopesGw,
		})
		.then(({ data }) => {
			let { viewTopesG } = data;
			return viewTopesG;
		});
}
