import React, { Component } from "react";
import _ from "lodash";
import {
	InputNumber,
	Button,
	Row,
	Col,
	Modal,
	Table,
	Select,
	notification,
	Tag,
	Divider,
	Switch,
	Icon,
} from "antd";
import { colorWin, colorLtr } from "./globals";
import moment from "moment";

import Cloudwindows from "./cloudwindows";
import Spinnerwait from "./spinnerwait";
import * as func from "./function";
import "./cloudLlNacionales.scss";

const { confirm } = Modal;
const { Option } = Select;

class cloudLlNacionales extends Component {
	constructor(props) {
		super(props);
		this.state = {
			idxSelect: -1,
			chancePRC: false,
			tmpdata: [],
			pagination: { defaultPageSize: 20 },
			listData: [],
			llegada: [],
			dia: "15/12/2019",
			confJnd: [],
			defau: [{ text: "" }, { text: "" }, { text: "" }],
			inside: "",
			inside_div: "",
			setting: [],
			impRef: [],
			data: [],
			visible: false,
			IDCN: 0,
			columns: [
				{ title: "Carrera", dataIndex: "carr", key: "carr" },
				{
					title: "Llegadas",
					children: [
						{
							title: "1",
							dataIndex: "lle1",
							key: "lle1",
							render: (values) => (
								<span>
									{values.split("-").map((tag, index) => {
										return (
											<Tag
												key={`${index}-${tag}`}
												style={{ color: colorLtr[Number(tag) - 1] }}
												color={colorWin[Number(tag) - 1]}
											>
												{tag}
											</Tag>
										);
									})}
								</span>
							),
						},
						{
							title: "2",
							dataIndex: "lle2",
							key: "lle2",
							render: (values) => (
								<span>
									{values.split("-").map((tag, index) => {
										return (
											<Tag
												key={`${index}-${tag}`}
												style={{ color: colorLtr[Number(tag) - 1] }}
												color={colorWin[Number(tag) - 1]}
											>
												{tag}
											</Tag>
										);
									})}
								</span>
							),
						},
						{
							title: "3",
							dataIndex: "lle3",
							key: "lle3",
							render: (values) => (
								<span>
									{values.split("-").map((tag, index) => {
										return (
											<Tag
												key={`${index}-${tag}`}
												style={{ color: colorLtr[Number(tag) - 1] }}
												color={colorWin[Number(tag) - 1]}
											>
												{tag}
											</Tag>
										);
									})}
								</span>
							),
						},
					],
				},
				{
					title: "Acciones",
					key: "action",
					render: (values) => (
						<span>
							<Switch
								checkedChildren={<Icon type="check" />}
								unCheckedChildren={<Icon type="close" />}
								defaultChecked={values.confirmado}
								onChange={(status) =>
									this.changStatusOnOff(status, values.carr)
								}
							/>
							<Divider type="vertical" />
							<Button
								size="small"
								icon="edit"
								onClick={() => this.configSet(values.carr, true)}
							>
								Conformes
							</Button>
						</span>
					),
				},
			],
		};
		this.viewNacionalesDTAFn = func.viewNacionalesDTAFn.bind(this);
		this.queryGanadoresGenFN = func.queryGanadoresGenFN.bind(this);
		this.muttationChngDiviNacFn = func.muttationChngDiviNacFn.bind(this);
	}

	configSet = (carr, origen) => {
		var { confJnd, IDCN, tmpdata, data, llegada } = this.state;

		if (origen) tmpdata = JSON.parse(JSON.stringify(data));
		const idx = _.findIndex(confJnd, function (o) {
			return o.IDCN === IDCN;
		});
		var { Ejemplares } = confJnd[idx];
		const idxSelect = _.findIndex(tmpdata, function (o) {
			return o.carr === carr;
		});
		var selec_data = tmpdata[idxSelect];
		var { dividendos } = selec_data;

		let tllegada = [];
		let tollegada = "";
		if (origen) {
			if (selec_data.llegada) {
				var dllegada = selec_data.llegada.split("|");

				tllegada.push(0);
				tllegada.push(
					parseInt(
						dllegada[0] && dllegada[0] !== "NaN" && Number(dllegada[0])
							? dllegada[0]
							: 1
					)
				);
				tllegada.push(
					parseInt(
						dllegada[1] && dllegada[1] !== "NaN" && Number(dllegada[1])
							? dllegada[1]
							: 2
					)
				);
				tllegada.push(
					parseInt(
						dllegada[2] && dllegada[2] !== "NaN" && Number(dllegada[2])
							? dllegada[2]
							: 3
					)
				);
				tollegada = `${tllegada[1]}|${tllegada[2]}|${tllegada[3]}|0|`;
			} else {
				tllegada = [0, 1, 2, 3];
				tollegada = "1|2|3|0|";
			}
			tmpdata[idxSelect].llegada = tollegada;
			llegada = tllegada;
			this.setState({ llegada });
		}

		this.setState({
			tmpdata,
			idxSelect,
			visible: true,
			inside_div: this.inside(
				Ejemplares[carr - 1],
				carr,
				selec_data,
				dividendos.split("|"),
				llegada
			),
		});
	};
	onClickLLegada = (pos, carr) => {
		var { llegada, tmpdata, idxSelect } = this.state;
		if (llegada[pos] === 3) llegada[pos] = 1;
		else llegada[pos]++;

		// var idx=_.findIndex(tmpdata,function(o) { return o.carr === carr;  })
		var tmllegada = `${llegada[1]}|${llegada[2]}|${llegada[3]}|0|`;
		tmpdata[idxSelect].llegada = tmllegada;
		this.setState({ llegada, tmpdata });
		this.configSet(carr, false);
	};
	onChangeSelectLlegada = (value, carr, op) => {
		var { tmpdata } = this.state;
		if (op <= 3) {
			var idx = _.findIndex(tmpdata, function (o) {
				return o.carr === carr;
			});
			var selec_data_temp = tmpdata[idx];
		}
		if (op >= 11 && op <= 13) {
			idx = _.findIndex(tmpdata, function (o) {
				return o.carr === carr;
			});
			selec_data_temp = tmpdata[idx];
			var { dividendos } = selec_data_temp;
			dividendos = dividendos.split("|");
		}
		switch (op) {
			case 1:
				selec_data_temp.lle1 = value;
				break;
			case 2:
				selec_data_temp.lle2 = value;
				break;
			case 3:
				selec_data_temp.lle3 = value;
				break;
			case 11:
				dividendos[0] = value;
				dividendos = _.join(dividendos, "|");
				break;
			case 12:
				dividendos[1] = value;
				dividendos = _.join(dividendos, "|");
				break;
			case 13:
				dividendos[2] = value;
				dividendos = _.join(dividendos, "|");
				break;
			default:
				break;
		}
		if (op >= 11 && op <= 13) tmpdata[idx].dividendos = dividendos;
		this.setState({ tmpdata, chancePRC: true });
		this.configSet(carr, false);
	};

	inside = (despliegue, carr, selec_data, idividendos, llegada) => {
		return (
			<>
				{llegada && llegada.length >= 4 ? (
					<div>
						<Row className="titleDividendos">
							<Col span={2}></Col>
							<Col span={16}>
								<label>Ejemplares</label>
							</Col>
							<Col span={4} offset={1}>
								<label>Dividendos</label>
							</Col>
						</Row>
						<Row className="titleDividendos_ln1">
							<Col span={2}>
								<Button
									type="primary"
									shape="round"
									size="small"
									onClick={() => this.onClickLLegada(1, carr)}
								>
									{llegada[1]}
								</Button>
							</Col>
							<Col span={16}>
								<Select
									style={{ width: "100%" }}
									size="small"
									value={selec_data.lle1}
									onChange={(value) =>
										this.onChangeSelectLlegada(value, carr, 1)
									}
								>
									{despliegue.horse.map((value2, index2) => (
										<Option
											key={`1-${index2.toString()}`}
											value={value2.Noeje.toString()}
										>
											{`${value2.Noeje}-${value2.Nombre}`}
										</Option>
									))}
								</Select>
							</Col>
							<Col span={4} offset={1}>
								<InputNumber
									size="small"
									value={parseInt(idividendos[0])}
									onChange={(value) =>
										this.onChangeSelectLlegada(value, carr, 11)
									}
								/>
							</Col>
						</Row>
						<Row className="titleDividendos_ln2">
							<Col span={2}>
								<Button
									type="primary"
									shape="round"
									size="small"
									onClick={() => this.onClickLLegada(2, carr)}
								>
									{llegada[2]}
								</Button>
							</Col>
							<Col span={16}>
								<Select
									style={{ width: "100%" }}
									size="small"
									value={selec_data.lle2}
									onChange={(value) =>
										this.onChangeSelectLlegada(value, carr, 2)
									}
								>
									{despliegue.horse.map((value2, index2) => (
										<Option
											key={`2-${index2.toString()}`}
											value={value2.Noeje.toString()}
										>
											{`${value2.Noeje}-${value2.Nombre}`}
										</Option>
									))}
								</Select>
							</Col>
							<Col span={4} offset={1}>
								<InputNumber
									size="small"
									value={parseInt(idividendos[1])}
									onChange={(value) =>
										this.onChangeSelectLlegada(value, carr, 12)
									}
								/>
							</Col>
						</Row>
						<Row className="titleDividendos_ln3">
							<Col span={2}>
								<Button
									type="primary"
									shape="round"
									size="small"
									onClick={() => this.onClickLLegada(3, carr)}
								>
									{llegada[3]}
								</Button>
							</Col>
							<Col span={16}>
								<Select
									style={{ width: "100%" }}
									size="small"
									value={selec_data.lle3}
									onChange={(value) =>
										this.onChangeSelectLlegada(value, carr, 3)
									}
								>
									{despliegue.horse.map((value2, index2) => (
										<Option
											key={`3-${index2.toString()}`}
											value={value2.Noeje.toString()}
										>
											{`${value2.Noeje}-${value2.Nombre}`}
										</Option>
									))}
								</Select>
							</Col>
							<Col span={4} offset={1}>
								<InputNumber
									size="small"
									value={parseInt(idividendos[2])}
									onChange={(value) =>
										this.onChangeSelectLlegada(value, carr, 13)
									}
								/>
							</Col>
						</Row>
					</div>
				) : (
					""
				)}
			</>
		);
	};
	instable = (IDCN) => {
		var { columns, listData, pagination, data, tmpdata } = this.state;
		data = [];
		var idx = _.findIndex(listData, function (o) {
			return o.id === IDCN;
		});
		data = listData[idx].ganadores;
		tmpdata = JSON.parse(JSON.stringify(listData[idx].ganadores));
		this.setState({ data, tmpdata });
		return (
			data && (
				<Table
					rowKey={(record) => record.carr}
					columns={columns}
					dataSource={data}
					pagination={pagination}
					size="small"
					expandedRowRender={(record) => {
						var { dividendos, lle1, lle2, lle3 } = record;
						var divsp = _.split(dividendos, "|");
						return (
							<div>
								<p style={{ margin: 0 }}>
									({lle1})-{divsp[0]}
								</p>
								<p style={{ margin: 0 }}>
									({lle2})-{divsp[1]}
								</p>
								<p style={{ margin: 0 }}>
									({lle3})-{divsp[2]}
								</p>
							</div>
						);
					}}
				/>
			)
		);
	};

	queActSycn = async (IDCN) => {
		this.setState({ inside: this.instable(IDCN) });
	};

	actualizaData = async (IDCN, dia, index) => {
		this.setState({ inside: <Spinnerwait />, IDCN });
		await this.viewNacionalesDTAFn(dia, IDCN);
	};
	componentDidMount() {
		this.queryGanadoresGenFN(10);
	}
	changStatusOnOff = (confirmado, s_carr) => {
		var { data, IDCN } = this.state;
		var diff = [];
		data.forEach((values, index) => {
			let { carr, dividendos, lle1, lle2, lle3, llegada } = values;
			if (carr === s_carr) {
				data[index] = {
					carr,
					confirmado,
					dividendos,
					lle1,
					lle2,
					lle3,
					llegada,
				};
				diff.push({ carr, confirmado, dividendos, lle1, lle2, lle3, llegada });
			}
			this.setState({ data });
		});
		if (diff.length !== 0) {
			var sndChange = { IDCN, _seg: moment().unix(), diff };
			this.muttationChngDiviNacFn(sndChange);
		}
	};
	handleOk = (visible, save) => {
		var { tmpdata, data, chancePRC, IDCN } = this.state;
		var diff = [],
			errodif = false;
		//construir LLegada
		if (save) {
			tmpdata.forEach((values, index) => {
				let {
					carr,
					confirmado,
					dividendos,
					llegada,
					lle1,
					lle2,
					lle3,
				} = values;
				if (
					!(
						data[index].carr === carr &&
						data[index].confirmado === confirmado &&
						data[index].dividendos === dividendos &&
						data[index].llegada === llegada &&
						data[index].lle1 === lle1 &&
						data[index].lle2 === lle2 &&
						data[index].lle3 === lle3
					)
				) {
					if (lle1 !== lle2 && lle1 !== lle3 && lle2 !== lle3) {
						data[index] = {
							carr,
							confirmado,
							dividendos,
							llegada,
							lle1,
							lle2,
							lle3,
						};
						diff.push({
							carr,
							confirmado,
							dividendos,
							llegada,
							lle1,
							lle2,
							lle3,
						});
					} else if (lle1 !== "" && lle2 !== "" && lle3 !== "") errodif = true;
				}
			});
			if (!errodif) {
                if (diff.length !== 0) {
                    var sndChange = { IDCN, _seg: moment().unix(), diff };
                    this.muttationChngDiviNacFn(sndChange).then( resp => {
                        if (resp){
                            this.setState({ data, chancePRC: false, visible });
                        }else{
                            this.setState({ chancePRC: false, visible });
                        }
                        this.queActSycn(this.state.IDCN);
                    })
                }
			} else {
				notification.error({
					message: "Error de Diferencia",
					placement: "topLeft",
					description:
						"No podemos grabar su informacion,debido a que posee ejemplares iguales en diferentes llegadas del conforme, corríjalo por favor!",
				});
			}
		} else {
			if (chancePRC) {
				confirm({
					title: "Esta Seguro de No quiere Modificar los Conformes?",
					cancelText: "Si",
					okText: "No",
					okType: "danger",
					onCancel: () => {
						this.setState({ chancePRC: false, visible });
					},
				});
			} else this.setState({ chancePRC: false, visible });
		}
	

		// this.setState({ llegada: [] });
	};
	render() {
		const { listData, inside, visible, inside_div } = this.state;
		return (
			<div>
				<Modal
					title="Llegadas Y Dividendos"
					visible={visible}
					cancelText="Cancelar"
					okText="Grabar"
					onOk={() => this.handleOk(false, true)}
					onCancel={() => this.handleOk(false, false)}
				>
					{inside_div}
				</Modal>

				<Cloudwindows
					title="Llegadas y Dividendos de Nacionales"
					classMain="mainwindowsNacionales"
					classMainInside="mainWindowsContentNac"
					classMainList="mainlistLlNacionales"
					classWindowsTxt="TextTitleLllNacionales"
					titleBkgn="#607d8b"
					colorBkg="#FFF"
					listData={listData}
					onChange={(id, titulo, index) =>
						this.actualizaData(id, titulo, index)
					}
					inside={inside}
					statusList={true}
				/>
			</div>
		);
	}
}

export default cloudLlNacionales;
