import client from "../conex";
import {gql} from "@apollo/client";

const mutationRegistroCuentas = gql`
	mutation registroCuentas($input: RecordTranss) {
		registroCuentas(input: $input) {
			res
			text
		}
	}
`;

export const mutationRegistroCuentasFn = (registo) => {
	return client
		.mutate({
			mutation: mutationRegistroCuentas,
			variables: {
				input: registo,
			},
		})
		.then(({ data }) => {
			const {
				registroCuentas: { res },
			} = data;
			if (res === 0) {
				return true;
			} else return false;
		})
		.catch(() => {
			return null;
		});
};

const mutationanulchgStatusCuentas = gql`
	mutation chgStatusCuentas($idtss: Int, $Status: Int, $AddDesc: String) {
		chgStatusCuentas(idtss: $idtss, Status: $Status, AddDesc: $AddDesc) {
			res
			text
		}
	}
`;

export const mutationanulchgStatusCuentasFn = (idtss, Status, AddDesc) => {
	return client
		.mutate({
			mutation: mutationanulchgStatusCuentas,
			variables: {
				idtss,
				Status,
				AddDesc,
			},
		})
		.then(({ data }) => {
			const {
				chgStatusCuentas: { res },
			} = data;
			if (res === -1) {
				return false;
			} else {
				return true;
			}
		})
		.catch(() => {
			return null;
		});
};
