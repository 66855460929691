import React from 'react'
import { Table } from "antd";

import FormatNumber from "../../../compenent/formatNumber";
import FormatDateEpoch from "../../../compenent/formatDateEpoch";

const { Column } = Table;
const RenderPendientes =  ({data,openModal}) => {


    return (
        <Table
            rowKey={(record) => record.id}
            dataSource={data}
            onRow={(record) => {
                return {
                    onClick: () => {
                        const {login,origen,iu}=record
                        openModal({
                            activo: true,
                            title: `Detalle de ${origen.toUpperCase()} por el Usuario ${login.toUpperCase()}`,
                            iu,Moneda:'',record
                        });
                        //
                    },
                };
            }}
        >
            <Column
                key="id"
                title="Fecha"
                dataIndex="fecha"
                render={(fecha, record) => (
                    <span>
                        	<FormatDateEpoch format={"%M %d %Y, %h:%m%a"} unix={fecha} />
                    </span>
                )}
            />
            <Column title="Descripcion" dataIndex="descripcion" />
            <Column
                title="Monto"
                dataIndex="monto"
                className="column-monto"
                render={(monto, record) => (
                    <span>
                        <FormatNumber
									number={Number(monto)}
								/>
                     </span>
                )}
            />
            <Column
                title="Usuario"
                dataIndex="iu"
                render={(iu, record) => (
                    <span>
                        ({iu}){record.login}
                    </span>
                )}
            />
            <Column
                title="Estatus"
                dataIndex="estatus"
                render={(estatus, record) =>
                    estatus === 2 ? <span>Esperando Aprobacion</span> : <span></span>
                }
            />
        </Table>
    );
}
export default RenderPendientes;