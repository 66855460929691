import React, { Component } from 'react';
import {Row,Col,Switch,Input} from "antd";



var _ = require('lodash');

class cloud_Gana_Jnd_Cond extends Component {
    constructor(props) {
        super(props);
        this.state = {
            RacexCondicion:[]
       }
    }

    onChangeValues=(register,code)=>{
        var {RacexCondicion}=this.state;
        var {i,value}=register;

        var idx=_.findIndex(RacexCondicion, (o)=> { return o.i === i; });
       
        
       

         switch (code) {
            case 1:
                RacexCondicion[idx].diviFj=value;
                break;
            case 2:
                RacexCondicion[idx].premio=value;
                break;
            case 3:
                RacexCondicion[idx].porCupos=value;
                break;
             
             default:
                RacexCondicion[idx].blq=value;
                break;
         }

         this.setState({RacexCondicion});
    }


    initCallVal=()=>{ 
        var {RacexCondicion}=this.state;
        var {RowsSetting}=this.props;
        var autoriza=false
        if (RowsSetting.length!==RacexCondicion.length) autoriza=true;
        if ( autoriza ){
            var newRacexCondicion=[];
            for (const prop in RowsSetting) {
                var {i}=RowsSetting[prop];

                if (!_.isUndefined(RacexCondicion[prop])) 
                    var {diviFj,premio,porCupos,blq}=RacexCondicion[prop];
                else{
                    diviFj=''
                    premio=''
                    porCupos=''
                    blq=false
                }

                
                    newRacexCondicion.push({
                        i,
                        diviFj,
                        premio,
                        porCupos,
                        blq
                    })

            }

            this.setState({RacexCondicion:newRacexCondicion});
        }
    }

    componentDidUpdate=()=>{
        this.initCallVal();
    }
    componentDidMount=()=>{
        this.initCallVal();
    }

    render() {
        var {RacexCondicion}=this.state;
        return (
            <Row>
                <Row className="CloudGanaRow">
                    <Col span={2}><label  className="setting_label_jn1">Carr</label></Col>
                    <Col span={6}><label  className="setting_label_jn1">Dividendo Fijo</label></Col>
                    <Col span={6}><label  className="setting_label_jn1">Premio(+)</label></Col>
                    <Col span={6}><label  className="setting_label_jn1">% Cupo a Vender</label></Col>
                    <Col span={2}><label  className="setting_label_jn1">Bloquear</label></Col>
                </Row>
                {RacexCondicion.map((value,index)=>

                <Row key={index.toString()} className="CloudGanaRow_cell">
                      <Col span={2}><label  className="setting_label_jn1">{value.i}</label></Col>
                      <Col span={6}><Input
                            onChange={(event)=>this.onChangeValues({i:value.i,value:event.target.value},1)}
                            defaultValue={value.diviFj} 
                            type="number"
                            size="small"
                            style={{ width: '90%' }} 
                         /></Col>
                      <Col span={6}>
                       <Input 
                            onChange={(event)=>this.onChangeValues({i:value.i,value:event.target.value},2)}
                            defaultValue={value.premio} 
                            type="number"
                            size="small"
                            addonBefore="+"
                            style={{ width: '90%' }} 
                         />
                      </Col>
                      <Col span={6}>
                        <Input 
                            onChange={(event)=>this.onChangeValues({i:value.i,value:event.target.value},3)}
                            defaultValue={value.porCupos} 
                            type="number"
                            min={0}
                            max={100}
                            size="small"
                            addonAfter="%"
                            style={{ width: '90%' }} 
                         />
                      </Col>
                      <Col span={2} offset={1}>
                      <Switch
                        onChange={(event)=>this.onChangeValues({i:value.i,value:event},4)}
                        checked={value.blq} 
                        size="small" 
                        checkedChildren="Si"
                        unCheckedChildren="No"
                        />
                      </Col>
                </Row>
                    
                )}
            </Row>
        );
    }
}

export default cloud_Gana_Jnd_Cond;