import React, { useState, useEffect } from "react";
import {
	Form,
	Select,
	Col,
	Row,
	Button,
	Input,
	Tag,
	Divider,
	Radio,
	Tabs,
} from "antd";
import { useTopesRecover } from "../../../../hooks/useTopesRecover";
import { grabarTopeNacionales } from "../../../../service/mutationTopes";
import { viewMesseger } from  "../../../../service/viewMesseger";

const recordSelectDefautl = [
	{
		cnf: -1,
		texto: "",
		ParticipaGana: 0,
		ParticipaPierde: 0,
		PorcentajeVentas: 0,
		IDBL: 0,
		EliminarTicket: 0,
		jSONcnf: "0",
	},
];

const FormItem = Form.Item;
const Option = Select.Option;
const TabPane = Tabs.TabPane;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const FromTopesAmericanas = ({
	form,
	dataNac = recordSelectDefautl,
	recordHipo,
	recordBoleto,
	sumitModalControl,
}) => {
	const { getFieldDecorator, resetFields } = form;
	const { onRecovertRecordNac } = useTopesRecover();
	const [recordSelect, setrecordSelect] = useState(dataNac[0]);
	const selectDataNaci =
		dataNac[0].cnf !== -1 ? recordSelectDefautl.concat(dataNac) : dataNac;
	const { RecordHipi, Ifield } = recordHipo;

	useEffect(() => {
		setrecordSelect(dataNac[0]);
	}, [dataNac]);

	const onSaveProccessNacionales = (record, del) => {
		grabarTopeNacionales(record, del).then((value) => {
			if (value === null) {
				viewMesseger("error-network");
			} else if (value) {
				viewMesseger("ok");
				sumitModalControl(false);
			} else viewMesseger("error");
			resetFields();
		});
	};

	const handleOnSaveNacional = (e) => {
		e.preventDefault();
		form.validateFields((err, valores) => {
			if (!err)
				onSaveProccessNacionales(onRecovertRecordNac(valores, RecordHipi), 0);
		});
	};
	const handleOnDeleteAmericana = () => {
		onSaveProccessNacionales(onRecovertRecordNac(recordSelect, RecordHipi), 1);
	};
	const handleExit = () => {
		resetFields();
		sumitModalControl(false);
	};
	const handleChangeNacional = (e) => {
		let idx = selectDataNaci.findIndex((props) => props.cnf === e);
		setrecordSelect(selectDataNaci[idx]);
	};

	return (
		<>
			{recordSelect ? (
				<Form onSubmit={handleOnSaveNacional}>
					<Row className="from-Topes-Col-Bottom">
						<Row>
							<Col span={11}>
								<FormItem label="Numero de Tope:">
									{getFieldDecorator("cnf", {
										initialValue: Number(recordSelect.cnf),
										rules: [
											{
												required: true,
												message:
													"Por favor seleccione el tope a utilizar para deportes!",
											},
										],
									})(
										<Select onChange={handleChangeNacional}>
											{selectDataNaci.map((value) => (
												<Option key={`A${value.cnf}`} value={value.cnf}>
													{value.cnf === -1 ? (
														<Tag color="#f50">Nuevo</Tag>
													) : (
														`${value.cnf}.-${value.texto}`
													)}
												</Option>
											))}
										</Select>
									)}
								</FormItem>
							</Col>
							<Col span={11} offset={1}>
								<FormItem label="Nombre del Tope:">
									{getFieldDecorator("texto", {
										initialValue: recordSelect.texto,
										rules: [
											{
												type: "string",
												message: "Debe tener alguna descripcion !",
											},
											{
												required: true,
												message: "Por favor requiero alguna descripcion !",
											},
										],
									})(<Input />)}
								</FormItem>
							</Col>
						</Row>
						<Row>
							<Col span={11} className="from-Topes-Col">
								<Divider orientation="center" className="from-Topes-Divider">
									Porcentajes
								</Divider>
								<FormItem label="% de Venta">
									{getFieldDecorator("PorcentajeVentas", {
										initialValue: recordSelect.PorcentajeVentas,
										rules: [
											{
												required: true,
												message: "Por favor requiero este dato!",
											},
											{
												type: "number",
												message: "Este valor NO es un numero !",
												transform(value) {
													return Number(value);
												},
											},
										],
									})(<Input />)}
								</FormItem>
								<FormItem label="% de Ganancias:">
									{getFieldDecorator("ParticipaGana", {
										initialValue: recordSelect.ParticipaGana,
										rules: [
											{
												required: true,
												message: "Por favor requiero este dato!",
											},
											{
												type: "number",
												message: "Este valor NO es un numero !",
												transform(value) {
													return Number(value);
												},
											},
										],
									})(<Input />)}
								</FormItem>
								<FormItem label="% de Perdidas:">
									{getFieldDecorator("ParticipaPierde", {
										initialValue: recordSelect.ParticipaPierde,
										rules: [
											{
												required: true,
												message: "Por favor requiero este dato!",
											},
											{
												type: "number",
												message: "Este valor NO es un numero !",
												transform(value) {
													return Number(value);
												},
											},
										],
									})(<Input />)}
								</FormItem>
							</Col>
							<Col span={11} offset={1} className="from-Topes-Col">
								<Divider orientation="center" className="from-Topes-Divider">
									Otros
								</Divider>
								<FormItem label="Boleto:">
									{getFieldDecorator("IDBL", {
										initialValue: recordSelect.IDBL,
									})(
										<RadioGroup>
											{recordBoleto
												? recordBoleto.map((value, index) => (
														<RadioButton key={index} value={value.idblt}>
															{value.texto}
														</RadioButton>
												  ))
												: ""}
										</RadioGroup>
									)}
								</FormItem>
								<FormItem label="Tickets a Anular:">
									{getFieldDecorator("EliminarTicket", {
										initialValue: recordSelect.EliminarTicket,
										rules: [
											{
												required: true,
												message: "Por favor requiero este dato!",
											},
											{
												type: "number",
												message: "Este valor NO es un numero !",
												transform(value) {
													return Number(value);
												},
											},
										],
									})(<Input />)}
								</FormItem>
							</Col>
						</Row>
						<Row>
							<Col className="from-Topes-Col">
								<Divider orientation="center" className="from-Topes-Divider">
									Configuracion de Jugada
								</Divider>
								<FormItem>
									<Tabs type="line">
										{RecordHipi
											? RecordHipi.map((value, index) => (
													<TabPane tab={value.texto} key={index}>
														<FormItem label="Dividendo Banca (Ganador):+">
															{getFieldDecorator(Ifield[index][0], {
																initialValue: recordSelect[Ifield[index][0]],
																rules: [
																	{
																		required: true,
																		message: "Por favor requiero este dato!",
																	},
																	{
																		type: "number",
																		message: "Este valor NO es un numero !",
																		transform(value) {
																			return Number(value);
																		},
																	},
																],
															})(<Input />)}
														</FormItem>

														<FormItem label="Cupo Maximo de Ganadores:">
															{getFieldDecorator(Ifield[index][1], {
																initialValue: recordSelect[Ifield[index][1]],
																rules: [
																	{
																		required: true,
																		message: "Por favor requiero este dato!",
																	},
																	{
																		type: "number",
																		message: "Este valor NO es un numero !",
																		transform(value) {
																			return Number(value);
																		},
																	},
																],
															})(<Input />)}
														</FormItem>

														<FormItem label="Dividendos Maximos:">
															{getFieldDecorator(Ifield[index][2], {
																initialValue: recordSelect[Ifield[index][2]],
																rules: [
																	{
																		required: true,
																		message: "Por favor requiero este dato!",
																	},
																	{
																		type: "number",
																		message: "Este valor NO es un numero !",
																		transform(value) {
																			return Number(value);
																		},
																	},
																],
															})(<Input />)}
														</FormItem>
													</TabPane>
											  ))
											: ""}
									</Tabs>
								</FormItem>
							</Col>
						</Row>
					</Row>
					<FormItem>
						<Row gutter={16}>
							<Col span={8}>
								<Button type="primary" htmlType="submit">
									{recordSelect.cnf !== -1
										? "Actualizar Registro"
										: "Registra Nuevo"}
								</Button>
							</Col>
							<Col span={8}>
								<Button onClick={handleExit}>Cancelar</Button>
							</Col>
							{recordSelect.cnf !== -1 ? (
								<Col span={8}>
									<Button type="danger" onClick={handleOnDeleteAmericana}>
										Borrar
									</Button>
								</Col>
							) : (
								""
							)}
						</Row>
					</FormItem>
				</Form>
			) : (
				<div></div>
			)}
		</>
	);
};

const EnhancedForm = Form.create()(FromTopesAmericanas);

export default EnhancedForm;
