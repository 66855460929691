import React, { useState, useEffect } from "react";
import { Table } from "antd";

import Cloudwindows from "../../../cloudwindows";
import ToolsTopes from "../../../compenent/toolsbar";

import ModalCloudGeneral from "./modalCloudGeneral";
import ModalCloudDeportes from "./modalCloudDeportes";
import ModalCloudAmericana from "./modalCloudAmerican";
import ModalCloudNacionales from "./modalCloudNacional";

import { useTopesGeneral } from "../../../hooks/useTopesGeneral";

import "./cssComponet.scss";

const { Column } = Table;

export const IuTopesGeneral = () => {
	const {
		updateQueryAllTopes,
		updateQueryDeportes,
		updateQueryAmericana,
		updateQueryNacionales,
		updateQueryLoterias,
	} = useTopesGeneral();
	const [stateModalWindow, setModalWindow] = useState(false);
	const [stateSeleccion, setstateSeleccion] = useState();
	const [stateModalWindowDeporte, setstateModalWindowDeporte] = useState(false);
	const [stateModalWindowAmericana, setstateModalWindowAmericana] = useState(
		false
	);
	const [stateModalWindowNacional, setstateModalWindowNacional] = useState(
		false
	);

	const [topesGeneral, setTopesGeneral] = useState([]);
	const [stateTopesDeportes, setstateTopesDeportes] = useState([]);
	const [stateTopesAmericana, setstateTopesAmericana] = useState([]);
	const [stateNacionales, setstateNacionales] = useState([]);
	const [stateTopesLoteria, setstateTopesLoteria] = useState([]);

	const openNotification1 = (seleccion) => {
		setModalWindow(true);
		setstateSeleccion(seleccion);
		// setstatePross(Math.round(new Date().getTime() / 1000.0));
	};

	const bodyInside = (
		<div className="from-Topes-Main">
			{topesGeneral ? (
				<Table
					rowKey={(record) => record.tope}
					dataSource={topesGeneral}
					className={"from-Topes-main"}
					onRow={(record) => {
						return {
							onClick: () => {
								openNotification1(record);
							},
						};
					}}
				>
					<Column
						key="texto"
						title="Tope"
						dataIndex="texto"
						sorter={(a, b) => a.texto.length - b.texto.length}
					/>
					<Column title="Tope Deportes" dataIndex="cnfD" />
					<Column title="Tope Americana" dataIndex="cnfA" />
					<Column title="Tope Nacionales" dataIndex="cnfN" />
					<Column title="Tope Loteria" dataIndex="cnfL" />
				</Table>
			) : (
				<div></div>
			)}
		</div>
	);

	useEffect(() => {
		updateQueryAllTopes().then(setTopesGeneral);
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stateModalWindow]);
	useEffect(() => {
		updateQueryDeportes().then(setstateTopesDeportes);
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stateModalWindowDeporte]);
	useEffect(() => {
		updateQueryAmericana().then(setstateTopesAmericana);
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stateModalWindowAmericana]);
	useEffect(() => {
		updateQueryNacionales().then(setstateNacionales);
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stateModalWindowNacional]);
	useEffect(() => {
		updateQueryLoterias().then(setstateTopesLoteria);
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<Cloudwindows
				title="Topes para los Clientes"
				classMain="mainWindowsiuTopes"
				classMainInside="mainWindowsContentNac"
				classMainList="mainlistLlNacionales"
				classWindowsTxt="TextTitleLllNacionales"
				titleBkgn="#08364d"
				colorBkg="#FFF"
				inside={bodyInside}
				barrIcons={
					<ToolsTopes
						custtomHalden={[
							() => openNotification1(false),
							() => setstateModalWindowDeporte(true),
							() => setstateModalWindowAmericana(true),
							() => setstateModalWindowNacional(true),
						]}
					/>
				}
				statusList={false}
			/>
			<ModalCloudGeneral
				stateModalWindow={stateModalWindow}
				stateTopesDeportes={stateTopesDeportes}
				stateTopesAmericana={stateTopesAmericana}
				stateNacionales={stateNacionales}
				stateTopesLoteria={stateTopesLoteria}
				onSummitStateWindows={setModalWindow}
				stateSeleccion={stateSeleccion}
			/>
			<ModalCloudDeportes
				stateModalWindow={stateModalWindowDeporte}
				stateTopesDeportes={stateTopesDeportes}
				sumitModalControl={setstateModalWindowDeporte}
			/>
			<ModalCloudAmericana
				stateModalWindow={stateModalWindowAmericana}
				stateTopesAmericana={stateTopesAmericana}
				sumitModalControl={setstateModalWindowAmericana}
			/>
			<ModalCloudNacionales
				stateModalWindow={stateModalWindowNacional}
				stateTopesNacionales={stateNacionales}
				sumitModalControl={setstateModalWindowNacional}
			/>
		</>
	);
};
