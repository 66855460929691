import client from "../conex";
import {gql} from "@apollo/client";


const muttationCaptureOn = gql`
mutation capturaOn($code:String!,$cant: Int!){
    capturaOn(code:$code,cant:$cant) {
      error
      codeerror
      text
    }
   }
`;
export const setCaptures = (code,cant) => {
    return client.mutate({
        mutation: muttationCaptureOn,
        variables: { code,cant },
    })
    .then(({ data }) => {
        const { capturaOn } = data;
        return capturaOn;

    })
    .catch(() => {
        return null;
    });
}
