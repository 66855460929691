import React, { Component } from 'react';
import Cloudwindows from "./cloudwindows";
import   './cloudSettingPolla.css'; 
import * as func from './function';
import { Row,Col,Input,message,Divider,Button } from 'antd';
import Spinnerwait from './spinnerwait';
var _ = require('lodash');

class cloudSettingGenPolla extends Component {
    constructor(props) {
        super(props);
        this.state = {
            impRef:[],
            monedas:[],
            setting:[]
        }
        this.queryCnfPollaPrmAdmFN=func.queryCnfPollaPrmAdmFN.bind(this);
        this.savePollaGenJND=func.savePollaGenJND.bind(this);
    }
    componentDidMount=()=>{
        this.queryCnfPollaPrmAdmFN();
    }

    chanceHabCarr=(option,id,op)=>{
        const {setting}=this.state;
        let tposs=setting.poss;
        switch (op) {
            case 1:
                if (option.value<=12){
                    setting.puestos=option.value
                    let {poss}=setting
                    if (poss.length>setting.puestos){
                        let dif=poss.length-setting.puestos
                        for (let i=1;i<=dif;i++) poss.pop()
                    }
                    if (poss.length<setting.puestos){
                        let dif=setting.puestos-poss.length
                        if (poss.length===0){
                            id=0;
                        }else{
                            var last=_.last(poss);
                            id=last.id;
                        }
                        for (let i=1;i<=dif;i++){ id++; poss.push({id,puntos:0}); }
                    }
                    this.setState({setting})
                }else
                 message.error('Disculpe, pero solo hasta 12 puestos acepto');
                break;
            case 2:
                const tem_poss = tposs.map((o,i)=>{
                    if (i === id){
                        return {
                            ...o,
                            puntos:Number(option.value)
                        }
                    }
                    return o
                })

                this.setState({setting:{...setting,poss:tem_poss}})
                break;
            // case 3:
            //     var {premio}=setting;
            //     premio[id].tipo=option?1:0;
            //     this.setState({setting})
            //     break;
            // case 4:
            //     premio=setting.premio;
            //     premio[id].monto=option.value
            //     this.setState({setting})
            //     break;
            default:
                const tem_poss_e = tposs.map((o,i)=>{
                    if (i === id){
                        return {
                            ...o,
                            empate:Number(option.value)
                        }
                    }
                    return o
                })
                this.setState({setting:{...setting,poss:tem_poss_e}})
                

        }
    }
    onKepress=(id,op)=>{
        var {impRef,setting}=this.state
        let count,nid,poss,idx,iRef
        switch (op) {
            // case 4:
            //     var {premio}=setting;
            //     count=premio.length;
            //     nid=id
            //     if (id===(count-1)) nid=0; else nid++;
            //     idx=_.findIndex(impRef, function(o) { return (o.id === nid && o.op === op ); });
            //     if (idx!==-1){
            //         iRef=impRef[idx].ref;
            //         iRef.focus();
            //         iRef.select();
            //     }
            //     break;
            case 5:
                poss=setting.poss;
                count=poss.length;
                nid=id
                if (id===(count-1)) nid=0; else nid++;
                idx=_.findIndex(impRef, function(o) { return (o.id === nid && o.op === op ); });
                if (idx!==-1){
                    iRef=impRef[idx].ref;
                    iRef.focus();
                    iRef.select();
                }
                break;
            case 6:
                poss=setting.poss;
                count=poss.length;
                nid=id
                if (id===(count-1)) nid=0; else nid++;
                idx=_.findIndex(impRef, function(o) { return (o.id === nid && o.op === op ); });
                if (idx!==-1){
                    iRef=impRef[idx].ref;
                    iRef.focus();
                    iRef.select();
                }
                break;
         
            default:
                break;
        }
    }
    onRef=(ref,id,op)=>{
        var {impRef}=this.state
        if (!_.isNull(ref)){
            var idx=_.findIndex(impRef,o=>{return o.id===id && o.op===op; })
            if (idx===-1){
                impRef.push({id,op,ref})
                this.setState({impRef})
            }
        }
    }
    inside=(setting,monedas)=>{
        return(
            setting.length!==0&&
                <div className="settingGen_MainBox">
                    <Row className="settingGen_buttom_command">
                            <Row type="flex" justify="end">
                                <Col >
                                    <Button type="primary"  icon="download" onClick={()=>this.savePollaGenJND(setting)} > Grabar </Button>
                                </Col>
                            </Row>
                        </Row>
                    {/* <Row  >
                        <Col span={8}>
                            <label className="settingGen_Label1">Premiar hasta</label>
                        </Col>
                        <Col  span={5}> 
                            <Input
                                type="Number"
                                placeholder="Puestos"
                                defaultValue={setting.puestos===''?'':setting.puestos}
                                onChange={(e)=>this.chanceHabCarr(e.target,0,1)}
                                size="small"
                            />
                        </Col >
                        <Col  span={6}>
                            <label  className="settingGen_Label1">Puesto</label>
                        </Col>
                    </Row>
                    <Row className="setting_row2">
                            <Col span={4}>
                                <label className="setting_label">Premio Estandar:</label> 
                            </Col>
                            {monedas.map((value,index)=>
                                <Col key={`prm-${index.toString()}`} span={6}>
                                    <label className="setting_label3">{value.moneda}</label>
                                    <Switch 
                                        onChange={(e)=>this.chanceHabCarr(e,index,3)}
                                        className="setting_switch_premio" 
                                        checkedChildren="%" 
                                        unCheckedChildren="Monto" 
                                        defaultChecked={setting.premio[index].tipo===1}
                                        />
                                    <Input
                                        ref={(ref)=>this.onRef(ref,index,4)}
                                        type="Number"
                                        placeholder={value.text}
                                        defaultValue={setting.premio[index].monto===''?'':setting.premio[index].monto}
                                        onChange={(e)=>this.chanceHabCarr(e.target,index,4)}
                                        onPressEnter={()=> this.onKepress(index,4) }
                                    />
                                </Col>
                                )}
                        </Row> */}
                    <Divider type="horizontal" />
                    <Row>
                        <Col span={6}>
                            <label  className="settingGen_Label2">Puestos</label>
                        </Col>
                        <Col span={5}>
                            <label  className="settingGen_Label2">Puntaje</label>
                        </Col>
                        <Col span={5}>
                            <label  className="settingGen_Label2">Empate</label>
                        </Col>
                    </Row>
                    <Row>
                        {setting.poss.map((value,index)=>
                        <Row key={index.toString()} className={`settingGen_Rows1 ${index%2===0?"GENlevelgrid1":"GENlevelgrid2"}`}>
                            <Col  span={6}>
                                <label  className="settingGen_Label3">{index+1}</label>
                            </Col>
                            <Col span={5}>
                            <Input
                                    ref={(ref)=>this.onRef(ref,index,5)}
                                    type="Number"
                                    placeholder="Puntos"
                                    defaultValue={value.puntos==='0'?'':value.puntos}
                                    onChange={(e)=>this.chanceHabCarr(e.target,index,2)}
                                    size="small"
                                    onPressEnter={()=> this.onKepress(index,5) }
                                />
                            </Col>
                            <Col span={5}>
                            <Input
                                    ref={(ref)=>this.onRef(ref,index,6)}
                                    type="Number"
                                    placeholder="Empates"
                                    defaultValue={value.empate==='0'?'':value.empate}
                                    onChange={(e)=>this.chanceHabCarr(e.target,index,5)}
                                    size="small"
                                    onPressEnter={()=> this.onKepress(index,6) }
                                />
                            </Col>
                            </Row>
                        
                        )}
                        
                    </Row>
                </div>
        )
    }

    render() {
        var {setting,monedas}=this.state;

        return (
            setting.length!==0?
            <Cloudwindows
                inside={this.inside(setting,monedas)}
                title="Premiacion"
                classMain="mainwindowsGenPolla"
                classMainList="mainListSettingPolla"
                statusList={false}
            />:
            <Spinnerwait/>
        );
    }
}

export default cloudSettingGenPolla;