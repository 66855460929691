import React, { Component } from "react";
import {
	message,
	Button,
	Form,
	Input,
	Icon,
	Switch,
	Select,
	Radio,
	Col,
	Row,
	DatePicker,
	Divider,
} from "antd";
import "antd/dist/antd.css";
import "./iuUser.scss";
import * as func from "./function";
import moment from "moment";
var _ = require("lodash");

const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

class FromiUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			displayPago: false,
			bancos: [],
			monedas: [],
			defaulRoot: {
				pattern: {
					radiogroup2: [1, 1, 1],
					radiogroup1: [1],
					switch2: 1,
					switch3: 1,
					switch4: 1,
					switch5: 1,
					modedepor: 1,
					cscDeporte: 1,
					cscAmericana: 1,
					cscNacionales: 1,
					cscLoteria: 1,
					"pattern-accesSYs": "1-1-1-1",
				},
			},
		};
		this.queryviewBancosFn = func.queryviewBancosFn.bind(this);
		this.generarClave = func.generarClave.bind(this);
	}

	handleWebsiteChange = (value, patternaccesSYs) => {
		if (this.props.tthis.state.pasww !== "******") {
			this.props.form.validateFields((err, valores) => {
				if (!err) {
					this.props.tthis.setModal1VisibleOk(
						false,
						valores,
						this.props.tthis.state.iu,
						patternaccesSYs
					);
				}
			});
		} else message.error("Debes generar la clave!");
	};
	validateLogin = (rule, value, callback) => {
		var xvalue = _.toLower(value.replace(/ /g, ""));
		if (_.indexOf(this.props.tthis.state.validateIU, xvalue) !== -1)
			callback("Lamentablemente este usuario ya existe!");
		else callback();
	};
	validaCSC = (rule, value, callback) => {
		if (_.isUndefined(value))
			callback("Debe Indicar el Asociado para el Punto de Venta");
		else callback();
	};
	setDatalist = (bancos) => {
		this.setState({ bancos });
	};

	componentDidMount = () => {
		this.queryviewBancosFn();
	};

	render() {
		var { monedas, defaulRoot } = this.state;
		var { pasww } = this.props.tthis.state;

		/////////pattern/////////
		if (this.props.idPRV !== 0) defaulRoot = this.props["default-user"];
		var { pattern } = defaulRoot;
		var {
			radiogroup1,
			radiogroup2,
			switch2,
			switch3,
			switch4,
			switch5,
			modedepor,
			cscDeporte,
			cscAmericana,
			cscNacionales,
			cscLoteria,
		} = pattern;
		var patternaccesSYs = pattern["pattern-accesSYs"];
		/////////pattern/////////
		const { getFieldDecorator } = this.props.form;
		const recordSelect = this.props.tthis.state.seleccion;

		const itemTope = this.props.tthis.state.topesConfig;

		if (recordSelect.iu === "-1") {
			let { tope } = itemTope[0];
			recordSelect.tope = tope;
		}
		var itemCscDep = this.props.cscList.ListaDeporte;
		var itemCscAme = this.props.cscList.ListaAmericana;
		var itemCscNac = this.props.cscList.ListaNacionales;
		// var itemCscLot = [];
		if (this.props.cscList.length === 0) {
			itemCscDep = [];
			itemCscAme = [];
			itemCscNac = [];
		}
		var fecha = moment.unix(recordSelect.fn).format("YYYY-MM-DD");
		return (
			<Form className="FromUsuario">
				<Row gutter={16}>
					<Row>
						<Col span={11} className="from-iUser-Col-1">
							<FormItem label="Activo">
								{getFieldDecorator("switch1", {
									initialValue: recordSelect.activo,
									valuePropName: "checked",
								})(
									<Switch
										checkedChildren={<Icon type="check" />}
										unCheckedChildren={<Icon type="close" />}
									/>
								)}
							</FormItem>
							<FormItem label="Nombre:">
								{getFieldDecorator("nombre", {
									initialValue: recordSelect.Nombre,
									rules: [
										{
											type: "string",
											message: "Debe tener algun nombre, si es valido mejor!",
										},
										{
											required: true,
											message: "Por favor requiero algun Nombre!",
										},
									],
								})(<Input />)}
							</FormItem>
							{recordSelect.iu !== "-1" ? (
								<FormItem label="Usuario (Login):">
									<label>{recordSelect.Usuario}</label>
								</FormItem>
							) : (
								<FormItem label="Usuario (Login):">
									{getFieldDecorator("usuario", {
										initialValue: recordSelect.Usuario,
										rules: [
											{
												type: "string",
												message: "El usuario que introduces no es valido",
											},
											{
												required: true,
												message: "Por favor requiero el nombre del usuario!",
											},
											{
												validator: this.validateLogin,
											},
										],
									})(<Input />)}
								</FormItem>
							)}
							<Divider type="vertical" />
						</Col>
						<Col span={11} offset={1} className="from-iUser-Col-2">
							<FormItem label="Clave:">
								<div>
									<Button
										type="primary"
										onClick={() => this.generarClave(this.props.tthis)}
									>
										Genera la clave!
									</Button>
									&nbsp;&nbsp;
									<label>{pasww}</label>
								</div>
							</FormItem>
							<FormItem label="Correo/email">
								{getFieldDecorator("email", {
									initialValue: recordSelect.email,
									rules: [
										{
											type: "email",
											message: "Este correo no es valido!",
										},
										{
											required: true,
											message: "Por Favor el correo es requerido!",
										},
									],
								})(<Input />)}
							</FormItem>
						</Col>
					</Row>
					<Row>
						<Col span={23} className="from-iUser-Col-wh">
							<Col span={11}>
								<FormItem label="Tipo de Usuario">
									{getFieldDecorator("radiogroup2", {
										initialValue: recordSelect.tipo,
									})(
										<RadioGroup>
											{radiogroup2[0] ? (
												<RadioButton key={1} value={1}>
													Super Usuario
												</RadioButton>
											) : (
												""
											)}
											{radiogroup2[1] ? (
												<RadioButton key={2} value={2}>
													Directo
												</RadioButton>
											) : (
												""
											)}

											{radiogroup2[2] ? (
												<RadioButton key={3} value={3}>
													Taquilla
												</RadioButton>
											) : (
												""
											)}
										</RadioGroup>
									)}
								</FormItem>
							</Col>
							<Col span={11} offset={1}>
								<FormItem label="Receptor">
									{getFieldDecorator("receptor", {
										initialValue: Boolean(recordSelect.receptor),
										valuePropName: "checked",
									})(
										<Switch
											checkedChildren={<Icon type="check" />}
											unCheckedChildren={<Icon type="close" />}
										/>
									)}
								</FormItem>
							</Col>
						</Col>
					</Row>
					<Row
						style={{
							display:
								recordSelect.tipo === 1
									? "none"
									: this.props.form.getFieldValue("radiogroup2") === 2
									? ""
									: "none",
						}}
					>
						<Col span={23} className="from-iUser-Col-wh">
							{radiogroup1[0] !== -1 ? (
								<FormItem
									label="Topes Maximos"
									style={{
										display:
											recordSelect.tipo === 1
												? "none"
												: this.props.form.getFieldValue("radiogroup2") === 2
												? ""
												: "none",
									}}
								>
									{getFieldDecorator("radiogroup1", {
										initialValue: recordSelect.tope,
									})(
										<RadioGroup>
											{itemTope.map((value) => (
												<RadioButton key={"T" + value.tope} value={value.tope}>
													Tope {value.texto}
												</RadioButton>
											))}
										</RadioGroup>
									)}
								</FormItem>
							) : (
								""
							)}

							<Row
								gutter={16}
								style={{
									display:
										recordSelect.tipo === 1
											? "none"
											: this.props.form.getFieldValue("radiogroup2") === 2
											? ""
											: "none",
								}}
							>
								<Col span={24}>
									<label className="from-iUser-subTitle">
										Sistemas validos
									</label>
								</Col>
								{switch2 ? (
									<Col span={5}>
										<FormItem label="Deportes">
											{getFieldDecorator("switch2", {
												initialValue: Boolean(recordSelect.Deporte),
												valuePropName: "checked",
											})(
												<Switch
													checkedChildren={<Icon type="check" />}
													unCheckedChildren={<Icon type="close" />}
												/>
											)}
										</FormItem>
									</Col>
								) : (
									""
								)}
								{switch3 ? (
									<Col span={5}>
										<FormItem label="Americanas">
											{getFieldDecorator("switch3", {
												initialValue: Boolean(recordSelect.Americana),
												valuePropName: "checked",
											})(
												<Switch
													checkedChildren={<Icon type="check" />}
													unCheckedChildren={<Icon type="close" />}
												/>
											)}
										</FormItem>
									</Col>
								) : (
									""
								)}
								{switch4 ? (
									<Col span={5}>
										<FormItem label="Nacionales">
											{getFieldDecorator("switch4", {
												initialValue: Boolean(recordSelect.Nacionales),
												valuePropName: "checked",
											})(
												<Switch
													checkedChildren={<Icon type="check" />}
													unCheckedChildren={<Icon type="close" />}
												/>
											)}
										</FormItem>
									</Col>
								) : (
									""
								)}
								{switch5 ? (
									<Col span={5}>
										<FormItem label="Loterias">
											{getFieldDecorator("switch5", {
												initialValue: Boolean(recordSelect.Loteria),
												valuePropName: "checked",
											})(
												<Switch
													checkedChildren={<Icon type="check" />}
													unCheckedChildren={<Icon type="close" />}
												/>
											)}
										</FormItem>
									</Col>
								) : (
									""
								)}
							</Row>
						</Col>
					</Row>
					<Row style={{ display: recordSelect.tipo === 1 ? "none" : "" }}>
						<Col span={23} className="from-iUser-Col-wh">
							<Row
								gutter={16}
								style={{ display: recordSelect.tipo === 1 ? "none" : "" }}
							>
								<Col span={24}>
									<label className="from-iUser-subTitle">
										Permitir Anular Ticket's
									</label>
								</Col>

								{switch2 ? (
									<Col span={5}>
										<FormItem label="Deportes">
											{getFieldDecorator("Anul_switch2", {
												initialValue:
													recordSelect.AnulDeporte === 1 ? true : false,
												valuePropName: "checked",
											})(
												<Switch
													checkedChildren={<Icon type="check" />}
													unCheckedChildren={<Icon type="close" />}
												/>
											)}
										</FormItem>
									</Col>
								) : (
									""
								)}
								{switch3 ? (
									<Col span={5}>
										<FormItem label="Americanas">
											{getFieldDecorator("Anul_switch3", {
												initialValue:
													recordSelect.AnulAmericana === 1 ? true : false,
												valuePropName: "checked",
											})(
												<Switch
													checkedChildren={<Icon type="check" />}
													unCheckedChildren={<Icon type="close" />}
												/>
											)}
										</FormItem>
									</Col>
								) : (
									""
								)}
								{switch4 ? (
									<Col span={5}>
										<FormItem label="Nacionales">
											{getFieldDecorator("Anul_switch4", {
												initialValue:
													recordSelect.AnulNacionales === 1 ? true : false,
												valuePropName: "checked",
											})(
												<Switch
													checkedChildren={<Icon type="check" />}
													unCheckedChildren={<Icon type="close" />}
												/>
											)}
										</FormItem>
									</Col>
								) : (
									""
								)}
								{switch5 ? (
									<Col span={5}>
										<FormItem label="Loterias">
											{getFieldDecorator("Anul_switch5", {
												initialValue:
													recordSelect.AnulLoteria === 1 ? true : false,
												valuePropName: "checked",
											})(
												<Switch
													checkedChildren={<Icon type="check" />}
													unCheckedChildren={<Icon type="close" />}
												/>
											)}
										</FormItem>
									</Col>
								) : (
									""
								)}
							</Row>
						</Col>
					</Row>
					{modedepor ? (
						<Row
							style={{
								display:
									this.props.form.getFieldValue("radiogroup2") === 1
										? "none"
										: "",
							}}
						>
							<Col span={23} className="from-iUser-Col-wh">
								<FormItem label="Modo de Venta para Deportes:">
									{getFieldDecorator("modedepor", {
										initialValue: recordSelect.modedepor,
									})(
										<RadioGroup>
											<RadioButton key={"pdep" + 1} value={1}>
												Mejorado
											</RadioButton>
											<RadioButton key={"pdep" + 2} value={2}>
												Estandar
											</RadioButton>
										</RadioGroup>
									)}
								</FormItem>
							</Col>
						</Row>
					) : (
						""
					)}
					<Row
						style={{
							display:
								this.props.form.getFieldValue("radiogroup2") === 2
									? ""
									: "none",
						}}
					>
						<Col span={23} className="from-iUser-Col-wh">
							<FormItem label="Manejo de Cuenta:">
								{getFieldDecorator("pago", {
									initialValue: recordSelect.pago,
								})(
									<RadioGroup>
										<RadioButton key={"p" + 1} value={0}>
											PostPago
										</RadioButton>
										<RadioButton key={"p" + 2} value={1}>
											Credito
										</RadioButton>
									</RadioGroup>
								)}
							</FormItem>
						</Col>
					</Row>
					{this.props.form.getFieldValue("radiogroup2") === 3 ? (
						<Row>
							<Col span={23} className="from-iUser-Col-wh">
								<Row gutter={30}>
									<Col span={24}>
										<label className="from-iUser-subTitle">
											Asociacion de Punto de Venta
										</label>
									</Col>
									{cscDeporte ? (
										<Col span={12}>
											<FormItem label="Deportes">
												{getFieldDecorator("cscDeporte", {
													initialValue: recordSelect.Deporte
														? recordSelect.cscDeporte
														: "0",
													rules: [
														{
															require: true,
															message:
																"Debe Indicar el Asociado para el Punto de Venta",
														},
														{
															validator: this.validaCSC,
														},
													],
												})(
													<Select>
														<Option key={"d0"} value="0">
															Bloqueado
														</Option>
														{itemCscDep.map((value, index) => (
															<Option key={"d" + index} value={value.IDC}>
																{value.IDC + "-" + value.Text}
															</Option>
														))}
													</Select>
												)}
											</FormItem>
										</Col>
									) : (
										""
									)}
									{cscAmericana ? (
										<Col span={12}>
											<FormItem label="Americanas">
												{getFieldDecorator("cscAmericana", {
													initialValue: recordSelect.Americana
														? recordSelect.cscAmericana
														: "0",
													rules: [
														{
															require: true,
															message:
																"Debe Indicar el Asociado para el Punto de Venta",
														},
														{
															validator: this.validaCSC,
														},
													],
												})(
													<Select>
														<Option key={"a0"} value="0">
															Bloqueado
														</Option>
														{itemCscAme.map((value, index) => (
															<Option key={"a" + index} value={value.IDC}>
																{value.IDC + "-" + value.Text}
															</Option>
														))}
													</Select>
												)}
											</FormItem>
										</Col>
									) : (
										""
									)}
									{cscNacionales ? (
										<Col span={12}>
											<FormItem label="Nacionales">
												{getFieldDecorator("cscNacionales", {
													initialValue: recordSelect.Nacionales
														? recordSelect.cscNacionales
														: "0",
													rules: [
														{
															require: true,
															message:
																"Debe Indicar el Asociado para el Punto de Venta",
														},
														{
															validator: this.validaCSC,
														},
													],
												})(
													<Select>
														<Option key={"n0"} value="0">
															Bloqueado
														</Option>
														{itemCscNac.map((value, index) => (
															<Option key={"n" + index} value={value.IDC}>
																{value.IDC + "-" + value.Text}
															</Option>
														))}
													</Select>
												)}
											</FormItem>
										</Col>
									) : (
										""
									)}
									{cscLoteria ? (
										<Col span={12}>
											<FormItem label="Loterias">
												{getFieldDecorator("cscLoteria", {
													initialValue: recordSelect.Loteria
														? recordSelect.cscLoteria
														: "0",
													rules: [
														{
															require: true,
															message:
																"Debe Indicar el Asociado para el Punto de Venta",
														},
														{
															validator: this.validaCSC,
														},
													],
												})(
													<Select>
														<Option value="0">Bloqueado</Option>
													</Select>
												)}
											</FormItem>
										</Col>
									) : (
										""
									)}
								</Row>
							</Col>
						</Row>
					) : (
						""
					)}

					<Row className="from-iUser-Row-gen">
						<Col span={11} className="from-iUser-Col-gen-1">
							<FormItem label="Identifiacion (DIN/CI/PASSAPORTE)">
								{getFieldDecorator("identificacion", {
									initialValue: recordSelect.identif,
									rules: [
										{
											type: "string",
											message: "Introduce una identifacion valida!",
										},
									],
								})(<Input />)}
							</FormItem>

							<FormItem label="Fecha de Nacimiento:">
								{getFieldDecorator("fn", {
									initialValue: moment(fecha, "YYYY-MM-DD"),
									rules: [
										{
											type: "object",
											required: true,
											message: "Por Favor coloque su fecha de Nacimiento!",
										},
									],
								})(<DatePicker format="DD/MM/YYYY" />)}
							</FormItem>
						</Col>

						<Col span={11} offset={1} className="from-iUser-Col-gen-2">
							<FormItem label="Numero Telefonico:">
								{getFieldDecorator("ntlf", {
									initialValue: recordSelect.ntlf,
									rules: [
										{
											type: "string",
											message: "Introduce un Numero Telefonico!",
										},
									],
								})(<Input />)}
							</FormItem>
							<FormItem label="Idioma">
								{getFieldDecorator("select1", {
									initialValue: _.toNumber(recordSelect.idioma),
									rules: [
										{
											required: true,
											message: "Por favor seleccione el idioma utilizar!",
										},
									],
								})(
									<Select placeholder="Idioma">
										<Option value={1}>Español</Option>
										<Option value={2}>Ingles</Option>
									</Select>
								)}
							</FormItem>
						</Col>
					</Row>
					<Row className="from-iUser-Row-gen">
						<Col span={11} className="from-iUser-Col-gen-1">
							<FormItem label="Cuenta Bancaria">
								{getFieldDecorator("cb", {
									initialValue: recordSelect.cb,
									rules: [
										{
											type: "string",
											message: "Introduce una cuenta bancaria Valida!",
										},
									],
								})(<Input />)}
							</FormItem>
							<FormItem label="Indique el Banco de la Cuenta:">
								{getFieldDecorator("banco", {
									initialValue: recordSelect.banco,
									rules: [
										{
											required: true,
											message: "Por favor seleccione el banco !",
										},
									],
								})(
									<Select placeholder="Seleccione el Banco">
										<Option value={0}></Option>
										{this.state.bancos.map((value, index) => (
											<Option key={"b" + index} value={value.key}>
												{value.Text}
											</Option>
										))}
									</Select>
								)}
							</FormItem>
						</Col>
						<Col span={11} offset={1} className="from-iUser-Col-gen-2">
							<FormItem label="Tipo de Cuenta:">
								{getFieldDecorator("tc", {
									initialValue: recordSelect.tc,
									rules: [
										{
											required: true,
											message: "Por favor seleccione su tipo de cuenta!",
										},
									],
								})(
									<Select placeholder="Tipo de Cuenta">
										<Option value={1}>Corriente</Option>
										<Option value={2}>Ahorros</Option>
									</Select>
								)}
							</FormItem>
							<FormItem
								label="Moneda"
								style={{ display: recordSelect.tipo === 1 ? "none" : "" }}
							>
								{getFieldDecorator("select", {
									initialValue: recordSelect.Moneda,
									rules: [
										{
											required: true,
											message: "Por favor seleccione la moneda a utilizar!",
										},
									],
								})(
									<Select placeholder="Moneda">
										{monedas.map((value, index) => (
											<Option key={index.toString()} value={value.moneda}>
												{value.text}
											</Option>
										))}
									</Select>
								)}
							</FormItem>
						</Col>
					</Row>

					<FormItem
						label="Puede Imprimir el ticket?"
						style={{ display: recordSelect.tipo === 1 ? "none" : "" }}
					>
						{getFieldDecorator("ticket", {
							initialValue: recordSelect.ticket === 1 ? true : false,
							valuePropName: "checked",
						})(
							<Switch
								checkedChildren={<Icon type="check" />}
								unCheckedChildren={<Icon type="close" />}
							/>
						)}
					</FormItem>
					{recordSelect.tipo === 1 && (
						<FormItem label="Este Usuario puede recibir notificaciones?">
							{getFieldDecorator("nmsg", {
								initialValue: recordSelect.nmsg,
								valuePropName: "checked",
							})(
								<Switch
									checkedChildren={<Icon type="check" />}
									unCheckedChildren={<Icon type="close" />}
								/>
							)}
						</FormItem>
					)}
					<FormItem>
						<Row gutter={16}>
							<Col span={8}>
								<Button
									type="primary"
									onClick={(e) => this.handleWebsiteChange(e, patternaccesSYs)}
								>
									Registra Cambio
								</Button>
							</Col>
							<Col span={8}>
								<Button
									type="danger"
									onClick={() => this.props.tthis.setModal1VisibleCancel(false)}
								>
									Cancelar
								</Button>
							</Col>
						</Row>
					</FormItem>
				</Row>
			</Form>
		);
	}
}
export default FromiUser;
