import React from "react";

import CloudWinwdowsModal from "../../../../compenent/modals";
import TransaccionesActivas from '../transacc-Activos/'

const Index = ({
	stateModalWindow,
	sumitModalControl,
	idPRV
}) => {
	const {activo,title,Moneda,iu}=stateModalWindow;
	return (
		<CloudWinwdowsModal
			children={
				<TransaccionesActivas
					iu={iu}
					idPRV={idPRV}
					sumitModalControl={sumitModalControl}
					Moneda={Moneda}
				/>
			}
			modalState={activo}
			onCancel={() => sumitModalControl({activo:false,title:'',iu:0,Moneda})}
			classNm={{ background: "rgb(244, 244, 244)" }}
			classFrm={"from-modal-transsacc-Activo"}
			title={title}
		/>
	);
};
export default Index;
