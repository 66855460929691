import {notification} from 'antd'

export const errorServerMessage = () => {
 
        notification.error({
            message: 'Error de COMUNICACION',
            placement: 'topLeft',
            description: 'Disculpe, Tenemos Problemas con la Comunicacion con el Servidor !'
        });
    
}
