import React, { useState, useEffect } from "react";
import { Button, Table } from "antd";

import FormDeposito from "../transacc-Deposito";
import FormRetiro from "../transacc-Retiro";

import FormatNumber from "../../../../compenent/formatNumber";
import FormatDateEpoch from "../../../../compenent/formatDateEpoch";

import { useTranssaccion } from "../../../../hooks/useTranssacciones";

const { Column } = Table;
const Index = ({ idPRV, Moneda, iu }) => {
	const { updateQueryPendientes,stateLodding } = useTranssaccion();
	const [stateFormDepositos, setstateFormDepositos] = useState(false);
	const [stateFormRetiro, setstateFormRetiro] = useState(false);
	const [stateDetalle, setstateDetalle] = useState([]);
	const onActionDeposito = () => {
		setstateFormDepositos((value) => !value);
		setstateFormRetiro(false)
	};
	const onActionRetiro = () => {
		setstateFormRetiro((value) => !value);
		setstateFormDepositos(false)
	};
	useEffect(() => {
		updateQueryPendientes(idPRV, iu, 1, 0).then(setstateDetalle);
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stateFormDepositos,stateFormRetiro,iu]);
	return (
		<div>
			<Button type="primary" onClick={onActionDeposito}>
				Depositar
			</Button>
			&nbsp;
			<Button type="danger" onClick={onActionRetiro}>
				Retirar
			</Button>
			<br />
			<br />
			{stateFormDepositos ? (
				<FormDeposito iu={iu} sumitModalControl={onActionDeposito} />
			) : (
				<div></div>
			)}
			{stateFormRetiro ? (
				<FormRetiro iu={iu} sumitModalControl={onActionRetiro} />
			) : (
				<div></div>
			)}
			{(stateDetalle && stateLodding) ? (
				<Table rowKey={(record) => record.id} dataSource={stateDetalle}>
					<Column
						key="id"
						title="Fecha"
						dataIndex="fecha"
						render={(fecha, record) => (
							<span>
								<FormatDateEpoch format={"%M %d %Y, %h:%m%a"} unix={fecha} />
							</span>
						)}
					/>
					<Column title="Origen" dataIndex="origen" />
					<Column
						title="Descripcion de la Transaccion"
						dataIndex="descripcion"
					/>
					<Column
						title="Monto"
						dataIndex="monto"
						className="column-monto"
						render={(monto, record) => (
							<span>
								<FormatNumber currency={Moneda} number={Number(monto)} />
							</span>
						)}
					/>
					<Column title="D/C" dataIndex="operacion" />
					<Column
						title="Saldo"
						dataIndex="saldo"
						className="column-monto"
						render={(saldo, record) => (
							<span>
								<FormatNumber currency={Moneda} number={Number(saldo)} />
							</span>
						)}
					/>
				</Table>
			) : (
				<div></div>
			)}
		</div>
	);
};
export default Index;
