import React from "react";

const formatDays = [
	{ lg: "Domingo", sm: "Dom" },
	{ lg: "Lunes", sm: "Lun" },
	{ lg: "Martes", sm: "Mar" },
	{ lg: "Miercoles", sm: "Mie" },
	{ lg: "Jueves", sm: "Jue" },
	{ lg: "Viernes", sm: "Vie" },
	{ lg: "Sabado", sm: "Sab" },
];
const formatMonth = [
	{ mlg: "Enero", msm: "Ene" },
	{ mlg: "Febrero", msm: "Feb" },
	{ mlg: "Marzo", msm: "Mar" },
	{ mlg: "Abril", msm: "Abr" },
	{ mlg: "Mayo", msm: "May" },
	{ mlg: "Junio", msm: "Jun" },
	{ mlg: "Julio", msm: "Jul" },
	{ mlg: "Agosto", msm: "Ago" },
	{ mlg: "Septiembre", msm: "Sep" },
	{ mlg: "Octurbe", msm: "Oct" },
	{ mlg: "Noviembre", msm: "Nov" },
	{ mlg: "Diciembre", msm: "Dic" },
];
// *** Parametros permitidos en (format) ***
// %d = Dia numero,
// %DD = Nombre largo de dia (Lunes,Martes...)
// %D = Nombre corto de dia (Lun,Mar...)
// %mm = Mes numero
// %MM = Mes Nombre largo (Enero,Febrero...)
// %M = Mes Nombre corto (Ene,Feb...)
// %Y = Año largo
// %H = Hora formato 24H
// %h = Hora formato 12H
// %m = Minutos
// %s = Segundos
// %a = formato am/pm
const Index = ({ unix = 0, format = "%d-%mm-%Y" }) => {
	const convertEpoch = (unixTab) => {
		if (unixTab === 0) return null;
		const baseMilliseg = unixTab * 1000;
		const newDateFormat = new Date(baseMilliseg);
		//! Get Date
		const Day = newDateFormat.getDay();
		const Dat = newDateFormat.getDate();
		const Month = newDateFormat.getMonth();
		const Year = newDateFormat.getFullYear();
		//! Get Hours
		const Hour24 = newDateFormat.getHours();
		const Minute = newDateFormat.getMinutes();
		const Second = newDateFormat.getSeconds();
		let AmPm = "am";
		let Hour = Hour24;
		if (Hour > 12) {
			AmPm = "pm";
			Hour -= 12;
		}
		return {
			DatObj: formatDays[Day],
			Day:Day<9?`0${Day}`:Day,
			Dat,
			MonObj: formatMonth[Month],
			Month:Month<9?`0${Month}`:Month,
			Year,
			Hour24,
			Hour:Hour<9?`0${Hour}`:Hour,
			Minutes:Minute<9?`0${Minute}`:Minute,
			Seconds:Second<9?`0${Second}`:Second,
			AmPm,
		};
	};
	const formatDate = (format) => {
		const objConvert = convertEpoch(unix);
		if (objConvert) {
			const {
				DatObj: { lg, sm },
				Dat,
				MonObj: { mlg, msm },
				Month,
				Year,
				Hour24,
				Hour,
				Minutes,
				Seconds,
				AmPm,
			} = objConvert;

			const countObj = [
				{ mrk: "%d", obj: Dat },
				{ mrk: "%DD", obj: lg },
				{ mrk: "%D", obj: sm },
				{ mrk: "%mm", obj: Month },
				{ mrk: "%MM", obj: mlg },
				{ mrk: "%M", obj: msm },
				{ mrk: "%Y", obj: Year },
				{ mrk: "%h", obj: Hour },
				{ mrk: "%H", obj: Hour24 },
				{ mrk: "%m", obj: Minutes },
				{ mrk: "%s", obj: Seconds },
				{ mrk: "%a", obj: AmPm },
			];
			let convFormat = format;

			for (let props in countObj) {
				const { mrk, obj } = countObj[props];
				convFormat = convFormat.replaceAll(mrk, obj);
			}
			return convFormat;
		} else return <label>Unix Epoch Errada!</label>;
	};

	return <>{formatDate(format)}</>;
};
export default Index;
