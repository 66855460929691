import React, { Component } from "react";
import {
	Icon,
	Button,
	Popconfirm,
	Switch,
	Row,
	Col,
	Input,
	Divider,
	Select,
	notification,
	Tag,DatePicker,Popover,
	message
} from "antd";
import moment from 'moment';

import InputCow from './compenent/inputCow'
import Cloudwindows from "./cloudwindows";
import Spinnerwait from "./spinnerwait";
import * as func from "./function";
import "./cloudSettingPolla.css";

var _ = require("lodash");
const CANT_PREMIOS_DEFAULT =3;
const ButtonGroup = Button.Group;
const { Option } = Select;


// const pSpecial = [
// 	{id:1,points:30,add:100},
// 	{id:2,points:28,add:50},

// ]
class cloudSettingPolla extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listData: [],
			dia: "hoy",
			confJnd: [],
			inside: "",
			impRef: [],
			monedas: [],
			setting: [],
			defaPremio:[],
            fecha: '',
			newreg: false,
			set_cantidad_premio:CANT_PREMIOS_DEFAULT,
			addNewConfigPollaBtn:false
		};
		this.viewNacionalesDTAFn = func.viewNacionalesDTAFn.bind(this);
		this.queryCnfPollaAdmFN = func.queryCnfPollaAdmFN.bind(this);
		this.savePollaJND = func.savePollaJND.bind(this);
		this.query_viewListPollaAdmFN = func.query_viewListPollaAdmFN.bind(this);
	}

	componentDidMount = () => {
		this.query_viewListPollaAdmFN();
	};
	
	ChangedateJournal=(date)=>{
        this.setState({dia:date.format("DD/M/YYYY")})
    }
	queActSycn = async (IDCN, tipo) => {
		await this.queryCnfPollaAdmFN(IDCN, tipo);
		if (IDCN){ 
			// const {setting} = this.state
			this.setState({ inside: this.fromInside(this.state.confJnd, 0,this.state.set_cantidad_premio) });
		}
		else this.setState({ inside: "" });
	};
	actuValores = async (dia, id, newreg) => {
		await this.viewNacionalesDTAFn(dia, id, newreg);
	};
	callJnd = (valores, index, idn) => {
		var dsp = _.split(valores, "-");
		this.chanceHabCarr(dsp[1], parseInt(dsp[0]) - 1, 7);
	};
	callJNDTrue = (valor) => {
		this.setState({ inside: <Spinnerwait /> });
		this.queActSycn(valor, 1);
	};

	handlerSetPremios=(set_cantidad_premio)=>{
		const npremios = this.chanceHabCarr(set_cantidad_premio, 0, 13)
		const inside = this.fromInside(this.state.confJnd, 0,set_cantidad_premio,npremios)
		this.setState({set_cantidad_premio,inside})
	}

	commandBarr = (type) => {
		switch (type) {
			case -1:
				if (!this.state.addNewConfigPollaBtn){
					let { dia } = this.state;
					this.setState({ inside: <Spinnerwait /> });
					this.actuValores(dia, 0, true);
				}else
				message.error("Disculpe, pero ya no hay Jornada NUEVAS que configurar por hoy");
				break;
			case -2:
				var { setting } = this.state;
				if (setting.length !== 0) {
					notification.error({
						message: "Error en Borrar",
						placement: "topLeft",
						description:
							"Disculpe, pero no tiene autorizacion de borrar esta Jornada de polla!",
					});
				}

				break;
			default:
		}
	};
	onIntlValue = (valor,id)=>{
		let { impRef } = this.state;
		let idx = _.findIndex(impRef, (o) => {
			return o.id === id && o.op === 2;
		});
		if (idx !== -1){
			let {ref} = impRef[idx]
			ref.state.value=Intl.NumberFormat().format(valor)
		}

	}


	get_record_default_premio=()=>{
		const Tpremio=[]
		const Tprecio = []
		const Tstore = []
		const { monedas  } = this.state;

		Array(CANT_PREMIOS_DEFAULT).fill(0).forEach((_, idx) => {
			Tstore.length = 0
			monedas.forEach((values) => {
			// Como cada varlor , Precio, Acumulado (store) y premio se paga de acuerdo a la moneda que tenga configurado
			  const { id } = values // el cliente
			  if (Tprecio.length !== monedas.length) { Tprecio.push('') } // Esta opcion te permite inicializarlo,
			  // if (resolv2.length === 0) {
			  Tstore.push({
				// Creo mi JSON de premio ya que el se seleccion el tipo por cada moneda
				id, // 0=Unidad monetario 1=es un procentaje de los vendido de las pollas
				tipo: 0,
				monto: 0
			  })
			  // }
			})
			Tpremio.push(Tstore)
		  })
		  return Tpremio
	}
	
	chanceHabCarr = (option, id, op,row) => {
		var { setting ,defaPremio} = this.state;
		const { premio,pSpecial } = setting;

		switch (op) {
			case 1:
				setting.activo = option ? 1 : 0;
				this.setState({ setting });
				break;
			case 2:
				var { precio } = setting;
				var vprecio = _.split(precio, "|");
				// console.log(option)
				vprecio[id] = option.currentTarget.value.replace(/[.]/g, "");
				setting.precio = _.join(vprecio, "|");
				this.setState({ setting });
				break;
			case 3:
				// const { premio } = setting;
				const npremio = premio.map(e => {
					const tipo	= option ? 1 : 0;
					e[id].tipo = tipo
					return e
				});
				this.setState({ setting:{...setting,premio:npremio} });
				break;
			case 4:
				
				premio[row][id].monto = parseFloat(option.value);

				this.setState({  setting:{...setting,premio} });
				break;


			case 5:
				var { store } = setting;
				var vstore = _.split(store, "|");
				vstore[id] = option.value;
				setting.store = _.join(vstore, "|");
				this.setState({ setting });
				break;
			case 6:
				var { config } = setting;
				var setCarr = _.split(config, "|");
				setCarr[id] = option ? 1 : 0;
				setting.config = _.join(setCarr, "|");
				this.setState({ setting });
				break;
			case 7:
				var { settiFab } = setting;
				var setFab = _.split(settiFab, "|");
				setFab[id] = option;
				setting.settiFab = _.join(setFab, "|");
				this.setState({ setting });
				break;
			case 8:
				var { settiRet } = setting;
				var setRet = _.split(settiRet, "|");
				setRet[id] = option;
				setting.settiRet = _.join(setRet, "|");
				this.setState({ setting });
				break;
			case 9:
				const setPrespP = pSpecial
				setPrespP[id].points = Number(option.value);
				this.setState({ setting:{...setting,pSpecial:setPrespP}, });
				break;
			case 10:
				const setPrespA = pSpecial
				setPrespA[id].add = Number(option.value);
				this.setState({ setting:{...setting,pSpecial:setPrespA}, });
				break;
			case 11:
				const thisdolar = parseFloat(option.value);
				this.setState({ setting:{...setting,dolar:thisdolar} });
				break;
			case 12:
				this.setState({ setting:{...setting,fecha:option.format("DD/M/YYYY")} });
				break
			case 13:
				const default_premio  = this.get_record_default_premio()
				let c=0
				const cant_set =option
				const npremio2=[]
				for (const el of default_premio){
					if (c<cant_set){
						const f = typeof premio[c] !== 'undefined'
									?premio[c]
									:typeof defaPremio[c] !== 'undefined'
										? defaPremio[c]
										: el
						npremio2.push(f)
					}
					c++
				}
				this.setState({ setting:{...setting,premio:npremio2} });
				return npremio2
			default:
				break;
		}
	};
	
	onRef = (ref, id, op) => {
		var { impRef } = this.state;
		if (!_.isNull(ref)) {
			var idx = _.findIndex(impRef, (o) => {
				return o.id === id && o.op === op;
			});
			if (idx === -1) {
				impRef.push({ id, op, ref });
				this.setState({ impRef });
			}
		}
	};
	onKepress = (id, op) => {
		var { impRef, setting } = this.state;
		switch (op) {
			case 5:
			case 2:
				var { precio } = setting;
				var count = precio.split("|").length;
				var nid = id;
				if (id === count - 1) nid = 0;
				else nid++;
				var idx = _.findIndex(impRef, function (o) {
					return o.id === nid && o.op === op;
				});
				if (idx !== -1) {
					var iRef = impRef[idx].ref;
					iRef.focus();
					iRef.select();
				}
				break;

			case 4:
				var { premio } = setting;
				count = premio.length;
				nid = id;
				if (id === count - 1) nid = 0;
				else nid++;
				idx = _.findIndex(impRef, function (o) {
					return o.id === nid && o.op === op;
				});
				if (idx !== -1) {
					iRef = impRef[idx].ref;
					iRef.focus();
					iRef.select();
				}
				break;
			case 8:
				var { settiRet } = setting;
				count = settiRet.split("|").length;
				nid = id;
				if (id === count - 2) nid = 0;
				else nid++;
				idx = _.findIndex(impRef, function (o) {
					return o.id === nid && o.op === op;
				});
				if (idx !== -1) {
					iRef = impRef[idx].ref;
					iRef.focus();
					iRef.select();
				}
				break;
			default:
				break;
		}
	};

	fromInside = (confJnd, idn,set_cantidad_premio,newpremios=[]) => {
		var { monedas, setting, newreg  } = this.state;
		let fdesde = moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')
		if (setting.length !== 0) {
			var {
				settiFab,
				settiRet,
				config,
				precio,
				premio,
				activo,
				store,
				IDCN,
				pSpecial,
				dolar,
				fecha
			} = setting;
			if (newpremios.length){
				premio = newpremios
			}
			set_cantidad_premio = premio.length === 0 ?CANT_PREMIOS_DEFAULT:premio.length 
			fdesde =  fecha
			console.log({fecha,fdesde})
			const idFind = confJnd.findIndex((o) => o.IDCN === IDCN);
			if (idFind === -1) {
				const idFind2 = confJnd.findIndex((o) => o.idHipo === IDCN);
				idn = idFind2;
			} else idn = idFind;

			// setting.IDCN = IDCN;
			var setFab = _.split(settiFab, "|");
			setFab.pop();
			var setRet = _.split(settiRet, "|");
			setRet.pop();
			var setCarr = _.split(config, "|");
			var vprecio = _.split(precio, "|");
			var vstore = _.split(store, "|");
			var { Ejemplares } = confJnd[idn];

			var defJND = "";
			if (confJnd.length !== 0) {
				defJND = confJnd[idn].IDCN;
			} else {
				defJND = "No hay Jornada";
			}
			// }else
			//     setting=null;

			// console.log('setting2',setting)
		}
		return (

			setting && (
				<>
					<div>
						<Row className="setting_buttom_command">
							{newreg && (
								<Col>
									<Tag color="red">Nuevo</Tag>
								</Col>
							)}
							<Row type="flex" justify="end">
								<Col>
									<Button
										type="primary"
										icon="download"
										onClick={() => this.savePollaJND(this.state.setting)}
									>
										{" "}
										Registrar cambios
									</Button>
								</Col>
							</Row>
						</Row>
						{confJnd.length !== 0 ? (
							<div>
								<Row>
									<Col span={4}>
										<label className="setting_label1">Jornada:</label>
									</Col>
									<Col span={12}>
										<Select
											defaultValue={defJND}
											size="small"
											onChange={(e) => this.callJNDTrue(e)}
										>
											{confJnd.map((value, index) => (
												<Option
													key={`jnd-${index.toString()}`}
													value={value.IDCN}
												>
													{value.Descripcion}
												</Option>
											))}
										</Select>
									</Col>
								</Row>
								<Divider type="horizontal" />
								<Row className="setting_row1">
									<Col span={4}>
										<label className="setting_label1">Activo:</label>
									</Col>
									<Col span={6}>
										<Switch
										    data-testid='button-OnOff'
											onChange={(e) => this.chanceHabCarr(e, 0, 1)}
											checkedChildren="Activo"
											unCheckedChildren="Cerrado"
											defaultChecked={activo === 1}
										/>
									</Col>
								</Row>
								<Row className="setting_row2">
									<Col span={4}>
										<label className="setting_label">Precio:</label>
										
									</Col>

									{monedas.map((value, index) => (
										<Col key={`mnd-${index.toString()}`} span={6}>
											<label className="setting_label2">{value.moneda}</label>
											<InputCow
												ref={(ref) => this.onRef(ref, index, 2)}
												placeholder={value.text}
												defaultValue={
													vprecio[index] === "" ? "" : vprecio[index]
												}
												onChange={(e) => this.chanceHabCarr(e, index, 2)}
												onPressEnter={() => this.onKepress(index, 2)}
											/>
										</Col>
									))}
								</Row>
								<Row className="setting_row2_premiacion">
									<Col span={6}>
										<label className="setting_label">Premiacion:</label>
									</Col>
									<Col span={2}>
									<Select
											defaultValue={set_cantidad_premio===0?CANT_PREMIOS_DEFAULT:set_cantidad_premio}
											onChange={(e) => this.handlerSetPremios(e)}
											size="small"
										>
											{Array(4).fill(0).map((_, index) => (
												<Option
													value={index+1}
												>
													{index+1}
												</Option>
											))}
										</Select>
									</Col>
									</Row>
									<Row className="setting_row2_premiacion">
									<Col span={4}>
											<label className="setting_label">Monedas:</label>
										</Col>
									{monedas.map((value, index) => (
										<Col key={`prm-${index.toString()}`} span={6}>
											<label className="setting_label3">{value.moneda}</label>
											<Switch
												onChange={(e) => this.chanceHabCarr(e, index, 3)}
												className="setting_switch_premio"
												checkedChildren="%"
												unCheckedChildren="Monto"
												defaultChecked={premio[0][index].tipo === 1}
											/>
										</Col>
									))}
									</Row>

									{Array(set_cantidad_premio).fill(0).map((_, idx) => (
											<Row className="setting_row2_premiacion">
												<Col span={4}>
													<label className="setting_label">P {idx+1}:</label>
												</Col>
											{monedas.map((value, index) => (
													<Col key={`prm-${index.toString()}`} span={6}>
												
													<Input
														ref={(ref) => this.onRef(ref, index, 4)}
														type="Number"
														placeholder={value.text}
														defaultValue={
															premio[idx][index].monto === "" ? "" : premio[idx][index].monto
														}
														onChange={(e) => this.chanceHabCarr(e.target, index, 4,idx)}
													/>
												</Col>
												
											))}
										</Row>
									))}
									<Row className="setting_row2_premiacion_esx">
										<Col span={6}>
											<label className="setting_label">Premio Especial:</label>
										</Col>
										<Col span={4}>
											<label className="setting_label3">Puntos</label>
										</Col>
										<Col span={6}>
											<label className="setting_label3">Premios</label>
										</Col>
									</Row>
									{pSpecial.map((o, index) => (
									<Row className="setting_row2_premiacion_esx">
										<Col span={6}>
										</Col>
										<Col key={`espec-${index.toString()}`} span={4}>
											<Input
												ref={(ref) => this.onRef(ref, index, 9)}
												type="Number"
												placeholder="Puntos"
												defaultValue={
													o.points === "" ? "0" :o.points
												}
												onChange={(e) => this.chanceHabCarr(e.target, index, 9)}
											/>
											</Col>
											<Col key={`espec2-${index.toString()}`} span={6}>
											<Input
												ref={(ref) => this.onRef(ref, index, 10)}
												type="Number"
												placeholder="Premio"
												defaultValue={
													o.add === "" ? "0" :o.add 
												}
												onChange={(e) => this.chanceHabCarr(e.target, index, 10)}
											/>
										</Col>
										</Row>
									))}
									<Row className="setting_row2_publicacion_show">
										<Col span={6}>
											<label className="setting_label">Publicar desde:</label>
										</Col>
										<Col span={10}>
											<DatePicker  
											defaultValue={moment(fdesde, 'DD/M/YYYY')} 
											format="DD/MM/YYYY" 
											onChange={(date)=> this.chanceHabCarr(date, 0, 12)} />
										</Col>
									</Row>
								<Row className="setting_row2">
									<Col span={6}>
										<label className="setting_label">Precio del $:</label>
									</Col>
									<Col span={6}>
										<Input
											ref={(ref) => this.onRef(ref, 0, 11)}
											type="Number"
											placeholder="Dolar"
											defaultValue={
												dolar=== "" ? "0" :dolar
											}
											onChange={(e) => this.chanceHabCarr(e.target, 0, 11)}
										/>
									</Col>
								</Row>
								<Row className="setting_row2">
									<Col span={4}>
										<label className="setting_label">Acumulado:</label>
									</Col>
									{monedas.map((value, index) => (
										<Col key={`acum-${index.toString()}`} span={6}>
											<label className="setting_label3">{value.moneda}</label>
											<Input
												ref={(ref) => this.onRef(ref, index, 5)}
												type="Number"
												placeholder={value.text}
												defaultValue={
													vstore[index] === "" ? "0" : vstore[index]
												}
												onChange={(e) => this.chanceHabCarr(e.target, index, 5)}
												onPressEnter={() => this.onKepress(index, 5)}
											/>
										</Col>
									))}
								</Row>
								<Divider type="horizontal" />
								<Row className="setting_group_gen setting_from_main">
									<Col span={8}>
										<Row className="setting_conf1">
											<Col span={8}>
												<label className="setting_label_carreras">
													Habilitadas
												</label>
											</Col>
										</Row>
										{setCarr.map((value, index) => (
											<Row
												key={`setCarr-${index.toString()}`}
												className={`setting_conf1 ${
													index % 2 === 0 ? "levelgrid1" : "levelgrid2"
												}`}
											>
												<Col span={6} className="setting_colm_carr ">
													{index + 1}ª
												</Col>
												<Col span={6}>
													<Switch
														onChange={(e) => this.chanceHabCarr(e, index, 6)}
														checkedChildren={<Icon type="check" />}
														unCheckedChildren={<Icon type="close" />}
														defaultChecked={parseInt(value) === 1}
													/>
												</Col>
											</Row>
										))}
									</Col>
									<Col span={8}>
										<Row className="setting_conf1">
											<Col span={8}>
												<label className="setting_label_carreras">
													Favoritos
												</label>
											</Col>
										</Row>
										{setFab.map((value, index) => {
											// console.log('Ejemplares[index]',index,Ejemplares[index])
											if (Ejemplares[index])
												var { horse, Carrera } = Ejemplares[index];
											else {
												horse = []
												Carrera = index + 1;
											}
											return (
												<Row
													key={`setFab-${index.toString()}`}
													className={`setting_conf1 ${
														index % 2 === 0 ? "levelgrid1" : "levelgrid2"
													}`}
												>
													<Col span={6}>
														<Select
															defaultValue={`${Carrera}-${value}`}
															onChange={(e) => this.callJnd(e, index, idn)}
															style={{ width: 120 }}
															size="small"
														>
															{horse.map((value2, index2) => (
																<Option
																	key={`ejm-${Carrera}-${index.toString()}-${index2.toString()}`}
																	value={`${Carrera}-${value2.Noeje}`}
																>{`${value2.Noeje}.-${value2.Nombre}`}</Option>
															))}
														</Select>
													</Col>
												</Row>
											);
										})}
									</Col>
									<Col span={8}>
										<Row className="setting_conf1">
											<Col span={8}>
												<label className="setting_label_carreras">
													Retirados
												</label>
											</Col>
										</Row>
										{setRet.map((value, index) => (
											<Row
												key={`setRet-${index.toString()}`}
												className={`setting_conf1 ${
													index % 2 === 0 ? "levelgrid1" : "levelgrid2"
												}`}
											>
												<Col span={18}>
													<Input
														ref={(ref) => this.onRef(ref, index, 8)}
														size="small"
														placeholder={`Retirado ${index + 1}ª`}
														defaultValue={value}
														onChange={(e) =>
															this.chanceHabCarr(e.target.value, index, 8)
														}
														onPressEnter={() => this.onKepress(index, 8)}
													/>
												</Col>
											</Row>
										))}
									</Col>
								</Row>
							</div>
						) : (
							<div></div>
						)}
					</div>
				</>
			)
		);
	};

 barraMainP=(
		<ButtonGroup>
			<Popover  title="Captar desde la Fecha" content="Puede seleccionar la fecha donde se captara la jornada">
				<DatePicker  className="barrwdw_date"  defaultValue={moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')} format="DD/MM/YYYY" onChange={(date)=>this.ChangedateJournal(date)} />
			</Popover>
			<Button
				className="barrwdw_bnt"
				ghost
				type="link"
				onClick={() => this.commandBarr(-1)}
			>
				<Icon type="plus" />
			</Button>

			<Popconfirm
				title="Esta Seguro de Borrar esta configuracion?"
				onConfirm={() => this.commandBarr(-2)}
				okText="Borrar"
				cancelText="Cancelar"
			>
				<Button className="barrwdw_bnt" ghost type="link">
					<Icon type="minus" />
				</Button>
			</Popconfirm>
			{/* <Button  className="barrwdw_bnt" ghost type="link" ><Icon type="diff" /></Button> */}
		</ButtonGroup>
	)

	seleIntemJnd = (IDCN, dia) => {
		this.setState({ inside: <Spinnerwait /> });
		this.actuValores(dia, IDCN, false);
	};

	render() {
		var { listData, inside } = this.state;
		return (
			<Cloudwindows
				title="Configuracion de Pollas"
				classMain="mainSettingPollaWindows"
				classMainList="mainListSettingPolla"
				barrIcons={this.barraMainP}
				listData={listData}
				inside={inside}
				statusList={true}
				onChange={(e, f) => this.seleIntemJnd(e, f)}
			/>
		);
	}
}

export default cloudSettingPolla;
