import React from "react";

import CloudWinwdowsModal from "../../../../compenent/modals/";
import IuTopesAmericana from "../topesAmericana";

const ModalCloudAmericana = ({
	stateModalWindow,
	sumitModalControl,
	stateTopesAmericana,
}) => {
	return (
		<CloudWinwdowsModal
			children={
				<IuTopesAmericana
					dataAme={stateTopesAmericana}
					sumitModalControl={sumitModalControl}
				/>
			}
			modalState={stateModalWindow}
			onCancel={() => sumitModalControl(false)}
			classNm={{ background: "rgb(244, 244, 244)" }}
			classFrm={"from-modal-tope-American"}
			title="Detalle de Topes AMERICANA"
		/>
	);
};
export default ModalCloudAmericana;
