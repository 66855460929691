import React, { useState } from "react";
import Cloudwindows from "./../../../cloudwindows";
import BodyIn from './BodyIn'
import { useListData } from "../../../hooks/useListData";


import "./index.scss";

function Index() {
	const [onRecordFind, setstateonRecordFind] = useState(null);
	const { listData,getCaptureFunction } = useListData();

	const haldlenOnClickList = (index) => {
		const onRecordFindone = listData.find((o) => o.id === index);
		if (onRecordFindone) {
			setstateonRecordFind(onRecordFindone);
		}
	};

	return (

		<>
			<Cloudwindows
				title="Capturas"
				classMain="mainWindowsiuCaptures"
				classMainInside="mainWindowsContentNac"
				classMainList="mainlistLlCaptures"
				classWindowsTxt="TextTitleLllNacionales"
				titleBkgn="#08364d"
				colorBkg="#FFF"
				inside={<BodyIn record={onRecordFind} hadlenListData={getCaptureFunction} />}
				listData={listData}
				statusList={true}
				onChange={(e) => haldlenOnClickList(e)} />
		</>
	);
}


export default Index;
