import { graphqlTEST, graphqlUrl, SECRETO, Nivel } from './globals.js'
import { ApolloClient, HttpLink, ApolloLink, concat, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { hashGet } from './service/general'

const ENV = process.env.REACT_APP_MODE
const enviroment = ENV === 'TEST'

const uri = enviroment ? graphqlTEST : graphqlUrl

const httpLink = new HttpLink({
  uri,
  credentials: 'same-origin'
}) //,fetch:fetch

// adding auth headers
const authMiddleware = new ApolloLink((operation, forward) => {
  const tokent = hashGet()
  const authorization = SECRETO
  operation.setContext({
    headers: {
      tokent,
      authorization,
      Nivel
    }
  })
  return forward(operation)
})

/// Create On Error de Apollo
const linkError = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    )

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

// Creating a client instance
const Client = new ApolloClient({
  link: concat(authMiddleware, httpLink, linkError),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'all'
    }
  }
})

//module.exports = { client:Client,customFetch:customFetch} ;
//module.exports.client=Client
//module.exports.customFetch=customFetch
export default Client
