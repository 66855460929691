import React, { useState } from "react";
import { Button, Select } from "antd";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";

import Cloudwindows from "../../../cloudwindows";
import { viewMesseger } from "../../../service/viewMesseger";

import { mutationiwebsiteFn, queryvieWebSiteFn } from "../../../service/getEditorPage";

import "./react-draft-wysiwyg.css";
import "./index.scss";

const Option = Select.Option;

export const Index = () => {
	const [editorState, setStateeditorState] = useState(
		EditorState.createEmpty()
	);
	const [dyspla, setstateDyspla] = useState(false);
	const [idSite, setstateIdSite] = useState(0);

	const _savetoIwen = () => {
		const contentState = editorState.getCurrentContent();
		const data = convertToRaw(contentState);
		mutationiwebsiteFn(data, parseInt(idSite)).then((value) => {
			if (value === null) {
				viewMesseger("error-network");
			} else if (value) {
				viewMesseger("ok");
			} else viewMesseger("error");
		});
	};
	const handleChange = (e) => {
		setStateeditorState(e);
	};
	const handleChangeSelector = (idSite) => {
		setstateIdSite(idSite);
		setstateDyspla(true);
		queryvieWebSiteFn(parseInt(idSite)).then((data) =>
			setStateeditorState(
				EditorState.createWithContent(convertFromRaw(JSON.parse(data)))
			)
		);
	};
	const EditorHtml = () => {
		return (
			<div
				className="PanelCustom_p"
				style={{ display: dyspla ? "block" : "none" }}
			>
				<Editor
					// toolbarOnFocus
					editorState={editorState}
					onEditorStateChange={handleChange}
					wrapperClassName="wrapper-class"
					editorClassName="editor-class editor-class_p"
					toolbarClassName="toolbar-class"
					localization={{
						locale: "es",
					}}
				/>
			</div>
		);
	};
	const bodyInside = (
		<>
			<div className="editor-barra-main">
				<div className="editor-barra-selector">
					<label>Debe seleccionar la Pagina a Modificar</label>
					<Select
						id="seleccPage"
						style={{ width: 200 }}
						placeholder="Seleccione su Pagina"
						onChange={handleChangeSelector}
					>
						<Option value="1">INICIO</Option>
						<Option value="2">COMO JUGAR</Option>
						<Option value="3">NORMATIVA</Option>
						<Option value="4">JUEGO RESPONSABLE</Option>
						<Option value="5">CONTACTOS</Option>
					</Select>
				</div>
				<div className="editor-barra-bottom">
					<Button
						type="primary"
						size="small"
						style={{ display: dyspla ? "block" : "none" }}
						onClick={_savetoIwen}
					>
						Grabar
					</Button>
				</div>
			</div>
			<EditorHtml />
		</>
	);

	return (
		<>
			<Cloudwindows
				title="Editor de Pagina"
				classMain="mainWindowsiu-Editor"
				classMainInside="mainWindowsContent-Editor"
				classMainList="mainlistLlNacionales"
				classWindowsTxt="TextTitleLll-Editor"
				titleBkgn="#08364d"
				colorBkg="#FFF"
				inside={bodyInside}
				barrIcons={false}
				statusList={false}
			/>
		</>
	);
};

export default Index;
