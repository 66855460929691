import client from "../conex";
import {gql} from "@apollo/client";

const queryRecordCuentas = gql`
	query viewRecordCuentas($activo: Int, $idPRV: String) {
		viewRecordCuentas(activo: $activo, idPRV: $idPRV) {
			iu
			Usuario
			Nombre
			tipo
			Balance
			Moneda
			email
		}
	}
`;

export const queryRecordCuentasFn = (idPRV) => {
	return client
		.query({
			query: queryRecordCuentas,
			variables: { activo: 1, idPRV },
		})
		.then(({ data }) => {
			const { viewRecordCuentas } = data;
			return viewRecordCuentas;
		})
		.catch(() => {
			return null;
		});
};

const queryHistoryCuentas = gql`
	query viewHistoryCuentas(
		$iu: Int
		$ord: String
		$modo: Int
		$limited_min: Int
		$idPRV: String
	) {
		viewHistoryCuentas(
			iu: $iu
			ord: $ord
			modo: $modo
			limited_min: $limited_min
			idPRV: $idPRV
		) {
			id
			fecha
			origen
			monto
			saldo
			iu
			login
			operacion
			descripcion
			estatus
		}
	}
`;
export function queryHistoryCuentasFn(idPRV, iu, modo, limited_min) {
	return client
		.query({
			query: queryHistoryCuentas,
			variables: { iu, ord: "DESC", modo, limited_min, idPRV },
		})
		.then(({ data }) => {
			const { viewHistoryCuentas } = data;
			return viewHistoryCuentas;
		})
		.catch(() => {
			return null;
		});
}


