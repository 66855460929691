import React, { Component } from 'react'
import { ApolloProvider } from '@apollo/client'
import 'moment/locale/es'
import { IntlProvider } from 'react-intl'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Iloginapp from './iloginapp'
import InitMenu from './InitMenu'
import 'antd/dist/antd.css'
import './InitMenu.css'
import { hashSet } from './service/general/index'
import Client from './conex'

class App extends Component {
  componentDidMount = () => {
    window.process = {
      ...window.process
    }
    hashSet('0')
  }
  render() {
    return (
      <ApolloProvider client={Client}>
        <IntlProvider locale="es">
          <Router>
            <Route exact={true} path="/" render={(props) => <Iloginapp {...props} />} />
            <Route path="/InitMenu" component={InitMenu} />
          </Router>
        </IntlProvider>
      </ApolloProvider>
    )
  }
}

export default App
