import React, { useRef } from "react";
import "./index.css";
function  IndexRef  (props,ref) {
	const refInput = useRef(null);
    const {placeholder,defaultValue,onChange,onPressEnter}=props
	const haldlenChangeInput = (e) => {
		let newNumber = Number(refInput.current.value.replace(/[.]/g, ""));
		if (newNumber) {
			let newValue = new Intl.NumberFormat("es-ES").format(newNumber);
			refInput.current.value = newValue;
		} else refInput.current.value = 0;
        // console.log('e=>',e.currentTarget.value,e.value)
        onChange(e)
	};
    const keypress=(e)=>{
        const {keyCode}=e
        if (keyCode === 13){
            onPressEnter()
        }
       
    }
    const formatCurrent=(value)=>{
        return  new Intl.NumberFormat("es-ES").format(value);
    }
	return (
		<input
			ref={refInput}
			className="InputCow-body"
			placeholder={placeholder}
            defaultValue={formatCurrent(defaultValue)}
            
			onChange={haldlenChangeInput}
            onKeyUp={keypress}
		/>
	);
};
const Index = React.forwardRef(IndexRef)
export default Index;
