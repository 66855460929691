import { SECRETO } from '../../globals';




export const hashSet = (hash) => {
    window.localStorage.setItem('hashjtw',hash)
    return true
}


export const hashGet = () => {
    const hash = window.localStorage.getItem('hashjtw') 

   return typeof hash === 'undefined' || hash === '0' || hash === null ? `${SECRETO}-QA`:hash

}
