import { createStore } from 'redux';

const defaultVar={
    listData:[],
    listDataLt:[],
    dataList:[],
    gData:[],
    data_blockPointVentas:[],
    lstapuestas:[],
    insiderInside:'',
    activeKey_tab:'1',
    globalData:[],
    }

function reducer(state=defaultVar,action){
    switch (action.type) {
        case 'IN_data_globalData':
        return{
            ...state,
            globalData:action.globalData
        } 
        case 'IN_data_activeKey_tab':
        return{
            ...state,
            activeKey_tab:action.activeKey_tab
        } 
        case 'IN_data_insiderInside':
        return{
            ...state,
            insiderInside:action.insiderInside
        } 
        case 'IN_data_List_lstapuestas':
        return{
            ...state,
            lstapuestas:action.lstapuestas
        } 
        case 'IN_data_List_dataListPointVentas':
        return{
            ...state,
            data_blockPointVentas:action.data_blockPointVentas
        } 
        case 'IN_data_List_dataList':
        return{
            ...state,
            dataList:action.dataList
        } 
        case 'IN_data_List_gData':
        return{
            ...state,
            gData:action.gData
        } 
        case 'IN_data_List_Apuesta':
        return{
            ...state,
            listData:action.listData
        } 
        case 'IN_data_List_Loteria':
        return{
            ...state,
            listDataLt:action.listDataLt
        } 
        case 'IN_data_insider':
        return{
            ...state,
            insider:action.insider
        } 
        default:
            return state;
        }
}


export default createStore(reducer,defaultVar);