import React, { Component } from 'react';
import {Row,Col,TimePicker,Input,Switch,Select} from "antd";
import moment from 'moment';
import  {colorWin,colorLtr} from './globals.js';


const _ = require('lodash');

class Cloud_Gana_Jnd_Cierres extends Component {
    constructor(props) {
        super(props);
        this.state = {
            RaceHorse:[]
       }
    }
    initCallVal=()=>{
        var {RowsSetting}=this.props;
        var {RaceHorse}=this.state;

        var autoriza=false
        ///// Valores para Cantidad Ejemplares por Carrera
        if (RowsSetting.length!==RaceHorse.length) 
            autoriza=true
        else{
            for (const prop in RowsSetting) {
                var {cantidad}=RowsSetting[prop];
                if (cantidad!==RaceHorse[prop].cantidad) {
                    autoriza=true;
                    break;
                }
            }
        }

        if (autoriza){
        var newRaceHorse=[];
        for (const prop in RowsSetting) {
            var {DateJnd,start,scratch}=RowsSetting[prop];
            let lcantidad= RowsSetting[prop].cantidad;
            var ArrDate=DateJnd.toArray();
            var ArrTime=start.toArray();
            var complexDate=[ArrDate[0],ArrDate[1],ArrDate[2],ArrTime[3],ArrTime[4],ArrTime[5],ArrTime[6]];
            var EjemSetting=[];
            for (let i=1;i<=lcantidad;i++){
                if (scratch.indexOf(i)===-1) EjemSetting.push(i);
                }
                var Ganadores=[],status=false,Dividendos=[];
                if (!_.isUndefined(RaceHorse[prop])){
                    Ganadores=RaceHorse[prop].Ganadores;
                    status=RaceHorse[prop].status;
                    Dividendos=RaceHorse[prop].Dividendos;
                }
                newRaceHorse.push({
                i:(parseInt(prop)+1),
                start,
                cantidad:lcantidad,
                complexDate,
                status,
                Ganadores,
                Dividendos,
                EjemSetting
            })
        }
        this.setState({RaceHorse:newRaceHorse})
        
        }
        console.log('newRaceHorse',newRaceHorse);
    }

    inputDivi=(value,index)=>{
        var {RaceHorse} = this.state;
        RaceHorse[index].Ganadores=value;
        this.setState({RaceHorse})
    }
    inputSwith=(value,index)=>{
        var {RaceHorse} = this.state;
        RaceHorse[index].status=value;
        if (!value)  RaceHorse[index].Ganadores=[];
        this.setState({RaceHorse})
    }
    onChangeValues=(value,index,nivel)=>{
        var {RaceHorse} = this.state;
        RaceHorse[index].Dividendos[nivel]=value;
        this.setState({RaceHorse})
    }
    componentDidUpdate=()=>{
        this.initCallVal();
    }
    componentDidMount=()=>{
        this.initCallVal();
    }
    render() {
        var {tagRender}=this.props;
        var {RaceHorse}=this.state;
        return (
            <Row>
              
                <Row className="CloudGanaRow">
                    <Col span={2}><label  className="setting_label_jn1">Carr</label></Col>
                    <Col span={4}><label  className="setting_label_jn1">Cierre</label></Col>
                    <Col span={5}><label  className="setting_label_jn1">Lapso</label></Col>
                    <Col span={3}><label  className="setting_label_jn1">Estatus</label></Col>
                    <Col span={4}><label  className="setting_label_jn1">Ganadores</label></Col>
                    <Col span={4}><label  className="setting_label_jn1">Dividendos</label></Col>
                </Row>
                {RaceHorse.map((value,index)=>
                <Row key={index.toString()} className={`CloudGanaRow_cell ${index%2===0?'CloudGanaRow_cell_Bold':''}`}>
                    <Col span={2}><label  className="setting_label_jn1">{value.i}</label></Col>
                    <Col span={4}>   <TimePicker 
                            value={value.start}  
                            format='HH:mm'
                            size="small"
                            disabled
                            style={{ width: '75px'}}
                            /></Col>
                    <Col span={5}>
                            <label  className="setting_label_jn1">
                                {moment(value.complexDate).fromNow() }
                            </label></Col>
                    <Col span={3}> 
                        <Switch
                        onChange={(event)=>this.inputSwith(event,index)}
                        size="small" 
                        checkedChildren="Si"
                        unCheckedChildren="No"
                        checked={!_.isUndefined(value.status)?value.status:false}
                        /></Col>
                    <Col span={4}> 
                      {!_.isUndefined(value.status)?value.status?
                            <Select
                                onChange={(event)=>this.inputDivi(event,index)}
                                mode="multiple"
                                className="CloudGanaCierresGanadores"
                                style={{ width: '95%',background:'none' }}
                            >
                                {value.EjemSetting.map((value1,index1)=>
                                            tagRender(value1,colorWin[value1-1],colorLtr[value1-1],value1,index1)
                                        
                                )}
                            </Select>:<div></div>:<div></div>
                        }
                         </Col>
                    <Col key={`ci-div-${index.toString()}`} span={5}>
                       { 
                       value.status?
                       !value.Ganadores.length!==0?value.Ganadores.map((value,index3)=> 
                            <div key={`ci-div-ga${index.toString()}-${index3.toString()}`} className="CloudGanaCierresGanaDividendos">
                                <Input 
                                onChange={(event)=>this.onChangeValues(event.target.value,index,index3)}
                                type="number"
                                size="small"
                                />
                            </div>
                            ):<div></div>:<div></div>}
                    </Col>
                </Row>
                )}
            </Row>
            
        );
    }
}

export default Cloud_Gana_Jnd_Cierres;