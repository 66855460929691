import React, { Component } from 'react'
import { Row,Col } from 'antd';


export class spinnerwait extends Component {
    render() {
        return (
            <Row>
            <Col span={10}></Col>
            <Col span={8}>
                <svg >
                    <circle cx="50" cy="50" r="20" stroke="white" strokeWidth="4" fill="none" />
                    <line fill="white" strokeLinecap="round" stroke="white" strokeWidth="4" strokeMiterlimit="10" x1="50" y1="50"  x2="70" y2="50.5">
                        <animateTransform 
                            attributeName="transform" 
                            dur="2s"
                            type="rotate"
                            from="0 50 50"
                            to="360 50 50"
                            repeatCount="indefinite" />
                    </line>
                    <line fill="white" strokeLinecap="round" stroke="white" strokeWidth="4" strokeMiterlimit="10" x1="50" y1="50"  x2="60" y2="55">
                        <animateTransform 
                            attributeName="transform" 
                            dur="15s"
                            type="rotate"
                            from="0 50 50"
                            to="360 50 50"
                            repeatCount="indefinite" />
                    </line>
                    Buscando datos...
                </svg> 
            </Col>
            <Col span={6}></Col>
        </Row>
        )
    }
}

export default spinnerwait
