import React from "react";

import CloudWinwdowsModal from "../../../../compenent/modals";
import TransaccionesPendientes from '../transacc-Pendietes'
const Index = ({
	stateModalWindow,
	sumitModalControl,
	idPRV
}) => {
	const {activo,title,iu,record}=stateModalWindow;
	return (
		<CloudWinwdowsModal
			children={
				<TransaccionesPendientes
					iu={iu}
					idPRV={idPRV}
					sumitModalControl={sumitModalControl}
					recordSelect={record}
				/>
			}
			modalState={activo}
			onCancel={() => sumitModalControl({activo:false,title:'',iu:0})}
			classNm={{ background: "rgb(244, 244, 244)" }}
			classFrm={"from-modal-transsacc-Pendiente"}
			title={title}
		/>
	);
};
export default Index;
