import React from "react";
import { Modal } from "antd";

const CloudWinwdowsModal = ({
	children,
	title,
	classNm,
	classFrm,
	modalState,
	onCancel,
}) => {
	return (
		<Modal
			wrapClassName={classFrm}
			bodyStyle={classNm}
			centered
			title={title}
			style={{ top: 20, width: 600 }}
			visible={modalState}
			onCancel={onCancel}
			footer={false}
		>
			{children}
		</Modal>
	);
};
export default CloudWinwdowsModal;
